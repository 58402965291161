import { Fragment, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import { materialTableES } from "../../services/materialTableES";
import { successToast, errorToast } from "../../services/toasts";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialTable, { MTableToolbar } from "material-table";
import ProductModal from "../../components/ProductModal/ProductModal";
import CustomButton from "../../components/Button/CustomButton";
import Switch from "@material-ui/core/Switch";
import Modal from "../../components/Modal/Modal";
import axios from "axios";

/**
 * Admin is able to revise all products and
 * mantain an inventory control
 */
function AdminProducts() {
  let data = useLocation();
  let quotePurchase = data.state ? data.state.quotePurchase : null;

  const [utility, setUtility] = useState(true);
  const [products, setProducts] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [productModal, setProductModal] = useState(false);
  const [productDetail, setProductDetail] = useState();

  // FIXME: we should migrate away from jssUseStyles()
  const classes = jssUseStyles();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    let response = await axios.get("/v2/admin/products?status=approved");
    setProducts(response.data);
    setFilteredProducts(response.data);
  };

  const allProducts = () => {
    setFilteredProducts(products);
  };

  // NOTE: currently the filtering is being done via javascript, we could also
  // do it through an api endpoint, and that way we wouldn't have to load all
  // products every time we filter something
  const filterByCoatings = () => {
    // Show only products with the 'type' coating
    var coatings = products.filter((product) => {
      return product.type === "revestimiento";
    });
    setFilteredProducts(coatings);
  };

  const filterByDecoratives = () => {
    var decoratives = products.filter((product) => {
      return product.type === "decorativo";
    });
    setFilteredProducts(decoratives);
  };

  const handleClose = (event) => {
    setProductModal(false);
  };

  const handleBulkUpload = async () => {
    try {
      const excel = document.getElementById("file").files[0];
      if (
        excel.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        errorToast("El archivo debe ser un documento de Excel");
        return;
      }
      const formData = new FormData();
      formData.append("excel", excel);
      await axios.post("/v2/admin/products/excel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      successToast(
        "Productos cargados exitosamente, recuerda que para que estos sean aprobados, debes subir las imágenes de producto y de ambientación."
      );
    } catch (error) {
      errorToast(`Ocurrió un error al cargar los productos`);
    } finally {
      setTimeout(() => {
        window.location.href = "/admin/revision-productos";
      }, 5000);
    }
  };

  const toggleIsFeatured = async (rowData) => {
    setProductModal(false);
    let isFeatured = rowData.is_featured === true ? false : true;
    let copyProducts = [...products];
    let index = copyProducts.indexOf(rowData);
    copyProducts[index].is_featured = isFeatured;
    setProducts(copyProducts);

    try {
      const res = await axios.patch(
        `/v2/admin/products/${rowData.product_id}/featured`,
        {
          is_featured: isFeatured,
        }
      );
      if (res.status === 200) {
        const action = isFeatured ? "destacado" : "no destacado";
        const successMessage = `El producto ha sido marcado como ${action} con éxito.`;
        successToast(successMessage);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <section className="layout-dashboard-container">
      <Modal
        showModal={productModal}
        closeModal={handleClose}
        contentMaxWidth="1000px"
        contentMaxHeight="500px"
      >
        <ProductModal product={productDetail} />
      </Modal>
      <div className={classes.mtable}>
        <MaterialTable
          title="Catálogo de Productos"
          columns={[
            {
              title: "Imagen",
              width: "0px",
              field: "image",
              render: (rowData) =>
                rowData.principal_image[0] && (
                  <img
                    alt="producto"
                    src={rowData.principal_image[0].url}
                    style={{
                      width: 40,
                      height: 40,
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  />
                ),
              filtering: false,
            },
            { title: "Proveedor", field: "company_name" },
            {
              title: "Marca",
              render: (rowData) => rowData.brand,
              customFilterAndSearch: (term, rowData) =>
                (
                  rowData.brand +
                  rowData.description +
                  rowData.comission_percentage / 2
                )
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) !== -1,
            },
            { title: "Referencia", field: "name" },
            { title: "MUV", field: "min_sale_unit" },
            { title: "Unidades por empaque", field: "units_per_package" },
            {
              title: "Presentación",
              field: "presentation",
              type: "numeric",
            },
            {
              title: "Precio",
              field: "taxed_price",
              type: "currency",
              currencySetting: {
                currencyCode: "COP",
                locale: "col",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            },
            {
              title: "Utilidad (%)",
              type: "numeric",
              render: (rowData) => {
                if (utility) {
                  // Divided by two since it's the designer's comission
                  return rowData.comission_percentage / 2;
                }
              },
            },
            {
              title: "Unidades en Inventario",
              field: "units_available",
              type: "numeric",
            },
            {
              title: "Producto Destacado",
              field: "is_featured",
              width: "0px",
              editable: "never",
              render: function (rowData) {
                return (
                  <Switch
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    checked={rowData.is_featured}
                    onChange={(event) => {
                      return toggleIsFeatured(rowData);
                    }}
                    name={rowData.product_id}
                  />
                );
              },
            },
          ]}
          data={filteredProducts}
          actions={[
            {
              icon: () => (
                <>
                  <input
                    accept=".xlsx, .xls, .csv"
                    id="file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleBulkUpload}
                  />
                  <CustomButton
                    color="success"
                    shape="little-rounded"
                    size="small"
                    icon={NoteAddIcon}
                    onClick={() => {
                      document.querySelector('input[type="file"]').click();
                    }}
                  >
                    Cargar productos desde Excel
                  </CustomButton>
                </>
              ),
              tooltip: "Cargar productos desde Excel",
              isFreeAction: true,
            },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    marginBottom: "1rem",
                  }}
                >
                  {/* TODO: It's better to intead of saving which purchase the quote it's going to be this from state, do this as url params
          this way if the page is refreshed it keeps the reference to the purchase*/}
                  <div>
                    {quotePurchase ? (
                      <p style={{ marginBottom: "0.5rem" }}>
                        <i
                          class="fa fa-check"
                          aria-hidden="true"
                          style={{ color: "#39b54a", marginRight: "0.25rem" }}
                        ></i>
                        Estas cotizando para el cliente{" "}
                        <i>
                          {`${quotePurchase.client.first_name} ${quotePurchase.client.last_name}`}{" "}
                        </i>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <FormControlLabel
                      style={{ marginLeft: "1rem" }}
                      control={
                        <Switch
                          checked={utility}
                          onChange={(event) => {
                            setUtility(utility === true ? false : true);
                          }}
                        />
                      }
                      label="Mostrar utilidad"
                    />
                  </div>
                </div>
              </div>
            ),
          }}
          onRowClick={(evt, selectedRow) => {
            setProductModal(true);
            setProductDetail(selectedRow);
          }}
          options={{
            padding: "dense",
            selection: quotePurchase ? true : false,
            clickable: true,
          }}
          localization={materialTableES}
          isLoading={products ? false : true}
        />
      </div>
    </section>
  );
}

const jssUseStyles = createUseStyles({
  mtable: {
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiToolbar-root .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  chip: {
    marginRight: 10,
  },
  chip1: {
    color: "#112A36",
    backgroundColor: "#E2CA8A",
  },
  chip2: {
    color: "#FFF",
    backgroundColor: "#5D777B",
  },
  chip3: {
    color: "#FFF",
    backgroundColor: "#AA8684",
  },
  chip4: {
    color: "#112A36",
    backgroundColor: "#DADDD8",
  },
});

export default AdminProducts;
