import { yupResolver } from "@hookform/resolvers";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { supplierFormSchema2 } from "../../services/formValidation";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionIcon from "@material-ui/icons/Description";

import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import successTeamImage from "../../assets/images/success-team.jpg";
import suppliersImage from "../../assets/images/suppliers2.jpeg";
import Button from "../../components/Button/Button.js";
import IconLabelButtons from "../../components/Button/IconLabelButtons.js";
import Input from "../../components/Input/Input.js";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import SuccessBox from "../../components/SuccessBox/SuccessBox.js";
import { validateFile } from "../../services/formValidation";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function SupplierFormStep2(props) {
  const classes = useStyles();
  const history = useHistory();
  const data = useLocation();
  const formData = data.state ? data.state.formData : [];
  const [RUT, setRUT] = useState();
  const [CDC, setCDC] = useState();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowsuccess] = useState(false);
  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    resolver: yupResolver(supplierFormSchema2),
  });
  const [termsAndCond, setTermsAndCond] = useState(false);
  const [isTaxable, setIsTaxable] = useState();
  const fileInputRef = useRef(null);
  const RutInputRef = useRef(null);

  const handleChangeIsWithIva = ({ target }) => {
    setIsTaxable(Number(target.value));
  };

  // If the user attemps to skip the first step, re-direct them
  if (formData.length === 0) {
    history.push("/proveedor/paso-1");
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      // validate isTaxable and there is a CDC (cámara de comercio)
      if (isTaxable === 0 && !CDC)
        setError("CDC", {
          type: "manual",
          message: "Por favor carga la Cámara de comercio.",
        });

      if (!RUT) {
        setError("RUT", {
          type: "manual",
          message: "Por favor carga el RUT",
        });
      } else {
        clearErrors();
        // validate files
        validateFile(RUT, /pdf/);
        if (CDC) {
          validateFile(CDC, /pdf/);
        }

        let formDataForRequest = new FormData();

        // append is_Taxable
        data.is_taxable = isTaxable === 0 ? 1 : 0;
        // append rut
        formDataForRequest.append("rut", RUT);

        // append chamber of commerce
        formDataForRequest.append("chamberOfCommerce", CDC);

        let supplierData = { ...data, ...formData };

        // convert object with designer info to form data
        for (let key in supplierData) {
          formDataForRequest.append(key, supplierData[key]);
        }

        await axios.post("/v2/suppliers", formDataForRequest);
        setShowsuccess(true);
      }
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setError("email", {
        type: "manual",
        message: requestError ? requestError : error.message,
      });
    }
    setLoading(false);
  };

  if (showSuccess) {
    return (
      <SplitScreen
        fullheight
        logoWithLink
        overlay
        image={successTeamImage}
        title="Recibimos tu solicitud"
        description="Nos pondremos en contacto contigo lo ántes posible"
      >
        <SuccessBox />
      </SplitScreen>
    );
  } else {
    return (
      <SplitScreen
        fullheight
        logoWithLink
        overlay
        image={suppliersImage}
        title="Ya casi terminas"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Información de contacto</Title>
          <Row>
            <Input
              name="contact_first_name"
              register={register}
              placeholder="Nombre"
              defaultValue={formData.contact_first_name}
            />
            <Input
              name="contact_last_name"
              register={register}
              placeholder="Apellido"
              defaultValue={formData.contact_last_name}
            />
          </Row>
          <Row>
            <Input
              name="contact_email"
              type="email"
              register={register}
              placeholder="Correo de contacto"
              defaultValue={formData.contact_email}
            />
            <Input
              name="contact_phone"
              register={register}
              placeholder="Celular"
              defaultValue={formData.contact_phone}
            />
          </Row>

          <Title>Archivos adjuntos</Title>

          <FormControl
            component="fieldset"
            style={{
              marginLeft: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <Label component="legend">¿Usted es responsable de IVA?</Label>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={isTaxable}
              onChange={handleChangeIsWithIva}
            >
              <FormControlLabel
                style={{ color: "gray" }}
                value="0"
                control={<Radio color="primary" />}
                label="Sí"
              />
              <FormControlLabel
                style={{ color: "gray" }}
                value="1"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

          {/* Cámara de comercio button */}
          <FileInput
            type="file"
            name="CDC"
            onChange={(e) => setCDC(e.target.files[0])}
            ref={fileInputRef}
          />

          {isTaxable === 0 ? (
            <ContainerInputFile>
              <Label htmlFor="CDC">Cámara de comercio (pdf)</Label>
              <IconLabelButtons
                width="13rem"
                onClick={() => fileInputRef.current.click()}
                label="Seleccionar archivo"
                icon={<CloudUploadIcon />}
              />
              {CDC ? (
                <LabelFileSelected>
                  <DescriptionIcon fontSize="small" color="action" />
                  <span>{CDC.name} </span>
                </LabelFileSelected>
              ) : (
                <LabelFileSelected>
                  <DescriptionIcon fontSize="small" color="action" />
                  <span>No hay un archivo seleccionado</span>
                </LabelFileSelected>
              )}
            </ContainerInputFile>
          ) : null}

          {/* Rut buttton */}
          <FileInput
            type="file"
            name="RUT"
            onChange={(e) => setRUT(e.target.files[0])}
            ref={RutInputRef}
          />

          <ContainerInputFile>
            <Label htmlFor="RUT">RUT (pdf)</Label>
            <IconLabelButtons
              width="13rem"
              onClick={() => RutInputRef.current.click()}
              label="Seleccionar archivo"
              icon={<CloudUploadIcon />}
            />
            {RUT ? (
              <LabelFileSelected>
                <DescriptionIcon fontSize="small" color="action" />
                <span>{RUT.name} </span>
              </LabelFileSelected>
            ) : (
              <LabelFileSelected>
                <DescriptionIcon fontSize="small" color="action" />
                <span>No hay un archivo seleccionado</span>
              </LabelFileSelected>
            )}
          </ContainerInputFile>

          <div>
            {Object.keys(errors)
              .slice(0, 2)
              .map((errorName) => {
                return <p className="error">{errors[errorName].message}</p>;
              })}
          </div>

          {/* Terms and conditions */}
          <TermsAndConditions>
            <label>
              <input
                type="checkbox"
                name="TermsAndConditions"
                onChange={(e) => setTermsAndCond(e.target.checked)}
              />
              <span>
                Acepto los
                <a
                  href="https://r1d-assets-migrated.s3.amazonaws.com/TerminosYCondicionesProveedores.pdf"
                  target="__blank"
                >
                  Términos y Condiciones y la Política de Privacidad
                </a>
              </span>
            </label>
          </TermsAndConditions>

          <div style={{ display: "flex" }}>
            <Button
              onClick={() =>
                history.push({
                  pathname: "/proveedor/paso-1",
                  state: { formData: formData },
                })
              }
              label="Volver"
            />
            {termsAndCond ? (
              <Button label="Enviar" type="submit" $isLoading={loading} />
            ) : (
              <Button label="Enviar" type="submit" disabled />
            )}
          </div>
        </Form>
      </SplitScreen>
    );
  }
}

const Title = styled.h4`
  margin: 0 0.5rem;
  margin-bottom: 0.7rem;
  color: #000000;
  font-size: 1.2rem;
`;

const Label = styled.label`
  display: block;
  font-family: Roboto;
  font-size: 1rem;
  color: #454545;
  margin-bottom: 0.4rem;
`;

const FileInput = styled.input`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: none;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  h4:nth-of-type(2) {
    margin-top: 1rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContainerInputFile = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const LabelFileSelected = styled.div`
  margin-top: 0.4rem;
  display: flex;
  font-size: 0.9rem;
  color: gray;

  span {
    margin-left: 0.3rem;
  }
`;

const TermsAndConditions = styled.div`
  margin-bottom: 0.8rem;
  margin-top: 1rem;

  label {
    display: flex;
    font-family: Roboto;
    align-items: center;
    font-size: 13.3333px;
    color: grey;
    margin: 0 0.5rem;
    input {
      margin: 0 0.25rem 0 0;
      align-self: flex-start;
    }

    span {
      a {
        cursor: pointer;
        color: grey;
        text-decoration: underline;
      }
    }
  }
`;

export default withRouter(SupplierFormStep2);
