import styled from "styled-components";
import logo from "../../assets/svg/logow.svg";

const Footer = () => {
  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <Heading>Enlace de interés</Heading>
            <FooterLink href="#">Políticas de Privacidad</FooterLink>
            <FooterLink href="https://r1d-assets-migrated.s3.amazonaws.com/TerminosYCondicionesClientes.pdf">
              Términos y condiciones
            </FooterLink>
            <FooterLink href="#">Cookies</FooterLink>
            <FooterLink href="#">Preguntas Frecuentes</FooterLink>
          </Column>
          <Column>
            <Heading>Síguenos</Heading>
            <IconsColumn>
              <Icons
                href="https://www.instagram.com/fliping_co/"
                target="__blank"
              >
                <i className="fa fa-instagram"></i>
              </Icons>
              <Icons
                href="https://www.facebook.com/Fliping.co/"
                target="__blank"
              >
                <i className="fa fa-facebook-f"></i>
              </Icons>
              <Icons
                href="https://www.linkedin.com/company/fliping-co/"
                target="__blank"
              >
                <i className="fa fa-linkedin"></i>
              </Icons>
              <Icons
                href="https://www.youtube.com/channel/UClp95CsyM-Gare4C58V0bOA"
                target="__blank"
              >
                <i className="fa fa-youtube"></i>
              </Icons>
            </IconsColumn>
          </Column>
          <Logo>
            <img style={{ width: "12rem" }} src={logo} alt="logo-fliping"></img>
          </Logo>
        </Row>
      </Container>
    </Box>
  );
};

export const Box = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: black;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 8rem;

  @media (max-width: 1000px) {
    margin: 0;
    padding: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 60px;
  @media (max-width: 1000px) {
    margin-left: 1.5rem;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const FooterLink = styled.a`
  color: #fff;
  margin-bottom: 20px;
  font-size: 0.9375rem;
  text-decoration: none;
  margin-left: 2rem;
  &:hover {
    color: yellow;
    transition: 200ms ease-in;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icons = styled.a`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: yellow;
    transition: 200ms ease-in;
  }

  > i {
    font-size: 2rem;
  }
`;

export const IconsColumn = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Heading = styled.p`
  font-size: 1.25rem;
  color: #fff;
  margin-bottom: 40px;
`;

export default Footer;
