import styles from "./orderSummary.module.scss";

interface PurchaseInformation {
  subtotal: number;
  iva: number;
  total: number;
  paymentStatus: string;
}
interface OrderSumaryProps {
  summary: PurchaseInformation;
}

const OrderSummary = ({
  summary: { subtotal, iva, total, paymentStatus },
}: OrderSumaryProps) => {
  return (
    <div className={styles.container}>
      <div>
        <p>Subtotal:</p>
        <p>${subtotal}</p>
      </div>
      <div>
        <p>IVA:</p>
        <p>${iva}</p>
      </div>
      <div>
        <h3>Total:</h3>
        <h3>${total}</h3>
      </div>
      <div className={styles.paymentStatus}>
        <p>status: </p>
        <span
          className={`${styles.badge} ${
            paymentStatus === "Pagado"
              ? styles.alertSuccess
              : styles.alertPending
          }  `}
        >
          {paymentStatus}
        </span>
      </div>
    </div>
  );
};

export default OrderSummary;
