import React from "react";
import styled from "styled-components";

/**
 * Styled select component for forms building.
 */
function Select({ placeholder, children, register, ...props }) {
  return (
    <StyledSelect
      placeholder={placeholder}
      placeholderTextColor="grey"
      ref={register}
      {...props}
    >
      {children}
    </StyledSelect>
  );
}

const StyledSelect = styled.select`
  width: 100%;
  outline: none;
  margin: 1rem 0.5rem;
  padding: 0.25rem 1rem;
  background-color: inherit;
  border: none;
  border-bottom: 1.25px solid black;
  transition: all 300ms ease 0s;
  color: grey;

  // make select same width as input
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;

  option {
    color: grey;
  }

  &:focus {
    border-bottom: 1.25px solid #fdd440;
    transition: all 300ms ease 0s;
  }
`;

Select.defaultProps = {};

export default Select;
