//help to query keys QueryCheckout and queryVirtualConsultancy and queryEcommerce

export enum QUERY_KEYS {
  // Checkout keys
  GET_CITIES = "cities",
  GET_ALL_ADDRESSES = "addresses",
  GET_SHIPPING_COST = "shippingCost",

  // Virtual Consultancy keys
  GET_DESIGNERS = "designers",

  // Ecommerce keys
  GET_CATEGORIES = "categories",
  GET_FAVORITES = "favorites",
  GET_PRODUCT_BY_ID = "productById",
  GET_COMPANY_DATA = "getCompanyData",
  GET_SALES_PERSON_BY_COMPANYNAME = "getSalesPerson",
  GET_ALL_PRODUCTS = "getAllProducts",

  // Supplier keys
  GET_COLORS = "getColors",
  GET_ADDRESSES = "getAddresses",
  GET_ECOMMERCE_PREFERENCES = "getEcommercePreferences",
  GET_WOMPI_PUBLIC_KEY = "getWompiPublicKey",
  GET_WOMPI_PUBLIC_KEY_BY_SUPPLIER = "getWompiPublicKeyBySupplier",
  GET_SALES_PERSON = "getSalesPerson",
  GET_SALES_PERSON_BY_ID = "getSalesPersonById",
}
