import Axios from "axios";
import { handleError } from "../../utils/utils";

export async function getShippingCost() {
  try {
    const shippingCost = await Axios.get(`/v2/cart/shipping-cost`);

    if (!shippingCost) throw Error("No se encontró el costo de envío");

    return shippingCost.data;
  } catch (error) {
    handleError(error);
  }
}
