import React from "react";
import { Link } from "react-router-dom";
import styles from "./CustomLink.module.scss";

interface CustomLinkProps {
  to: string;
  children: React.ReactNode;
}

const CustomLink = ({ to, children }: CustomLinkProps) => {
  return (
    <Link to={to} className={styles.customLink}>
      {children}
    </Link>
  );
};

export default CustomLink;
