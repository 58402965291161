import Axios from "axios";
import { handleError } from "../../utils/utils";

export async function getAllDesigners(
  currentPage: number,
  itemsPerPage: number
) {
  try {
    const designers = await Axios.get(
      `/v1/designers/public?page=${currentPage}&itemsPerPage=${itemsPerPage}`
    );

    if (!designers) throw Error("No se encontraron diseñadores");

    return designers.data;
  } catch (error) {
    handleError(error);
  }
}

// create function to crateate a newVirtual Consultancy
export async function createVirtualConsultancy(
  designerUuid: string,
  spaceQuantity: number
) {
  try {
    const newVirtualConsultancy = await Axios.post(`/v1/virtual-consultancy`, {
      designerUuid,
      spaceQuantity,
    });

    if (!newVirtualConsultancy)
      throw Error("No se pudo crear la asesoría virtual");

    return newVirtualConsultancy.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getAllAddresses() {
  try {
    const addresses = await Axios.get(`/v2/users/addresses`);

    if (!addresses) throw Error("No se encontraron direcciones");

    return addresses.data;
  } catch (error) {
    handleError(error);
  }
}

export async function deleteAddressById(id: number) {
  try {
    const deletedAddress = await Axios.delete(`/v2/users/addresses/${id}`);

    if (!deletedAddress) throw Error("No se pudo eliminar la dirección");

    return deletedAddress.data;
  } catch (error) {
    handleError(error);
  }
}
