import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    justifyContent: "flex-center",
    textTransform: "none",
    fontSize: "0.8rem",
    height: "1.7rem",
    backgroundColor: "#fdd400",
    "&:hover": {
      backgroundColor: "#fdd400",
    },
  },
}));

export default function IconLabelButtons({
  onClick = null,
  label,
  icon,
  width = "10rem",
}) {
  const classes = useStyles();
  return (
    <Button
      style={{ width: width }}
      onClick={onClick}
      variant="contained"
      color="primary"
      className={classes.button}
      startIcon={icon}
    >
      {label}
    </Button>
  );
}
