import SimpleBreadcrumbs from "../../../../components/SimpleBreadCrumb/SimpleBreadCrumb";
import { OrderItem } from "../../components";
import { productReviewBreadcrumbs } from "../productReviewPage/data/productReviewBreadcrumbs";
import PurchaseDetailData from "./components/purchaseDetailData/PurchaseDetailData";
import styles from "./purchaseDetailPage.module.scss";
import useFetchData from "../../../../hooks/useFetchData";
import { useParams } from "react-router";

const PurchaseDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: purchase } = useFetchData(`/v2/orders/${id}/`);
  return (
    <section className="layout-dashboard-container">
      <div className={styles.purchaseDetailPage}>
        <div className="container">
          <div>
            <SimpleBreadcrumbs breadcrumbs={productReviewBreadcrumbs} />
          </div>
          {purchase && (
            <>
              <section className="section">
                <PurchaseDetailData purchase={purchase} />
              </section>
              <section>
                <div>
                  <OrderItem purchase={purchase} isViewingOrderDetail />
                </div>
              </section>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default PurchaseDetailPage;
