import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import styles from "./orderSearch.module.scss";

type OrderSearchProps = {
  searchText: string;
  setSearchText: (searchText: string) => void;
};

const OrderSearch = ({ searchText, setSearchText }: OrderSearchProps) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <div className={styles.searchInput}>
      <SearchIcon className={styles.searchIcon} />
      <input
        type="text"
        placeholder="Buscar..."
        value={searchText}
        onChange={handleSearch}
        className={styles.searchInput}
      />
    </div>
  );
};

export default OrderSearch;
