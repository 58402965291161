import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import styled from "styled-components";
import image from "../../assets/images/checkout-cart.jpeg";
import Button from "../../components/Button/Button";
import Input2 from "../../components/Input/Input2";
import DefaultContent from "../../components/Modal/DefaultContent";
import Modal from "../../components/Modal/Modal";
import Navbar from "../../components/Navbar";
import Select from "../../components/Select/Select";
import { BASE_URL } from "../../services/baseURL";
import {
  clientReceiptCompanyForm,
  clientReceiptPersonForm,
} from "../../services/formValidation";
import { successToast } from "../../services/toasts";

function DesignsCheckout() {
  const history = useHistory();
  const { hashid } = useParams();

  const locationData = useLocation();
  const client = locationData.state && locationData.state.client;
  const formRef = useRef(null);

  const [legalDocTypes, setLegalDocTypes] = useState();
  const [paymentReference, setPaymentReference] = useState();
  const [error, setError] = useState();
  const [checkReceiptType, setCheckReceiptType] = useState("person");
  const [receipt, setReceipt] = useState({
    showModal: false,
    type: "person",
    receiptData: {
      first_name: client.first_name,
      last_name: client.last_name,
      legal_document_type_id: 1,
      document_number: client.cedula,
    },
  });
  const [total, setTotal] = useState(450000);
  const [isMissingData, setIsMissingData] = useState(true);
  const [showMissingData, setShowMissingData] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const isBigScreen = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    getLegalDocTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLegalDocTypes = async () => {
    let response = await axios.get(`/v1/users/legaldocs`);
    setLegalDocTypes(response.data);
  };

  // Creates a payment, Wompi required to send a unique payment id
  const handlePaymentRequest = async (event) => {
    try {
      event.preventDefault();

      let response;
      // Create the receipt first
      if (receipt.type === "person") {
        response = await axios.post(
          `/v1/presential-consultancy/payments/receipts?person=true`,
          receipt.receiptData
        );
      } else if (receipt.type === "company") {
        response = await axios.post(
          `/v1/presential-consultancy/payments/receipts?company=true`,
          receipt.receiptData
        );
      }

      // Create a payment and hook up the receipt
      let payment = await axios.post(
        `/v1//presential-consultancy/${hashid}/payments`,
        {
          toPay: total,
          receipt: {
            type: receipt.type,
            receiptData: {
              id: response.data.info.id,
            },
          },
        }
      );

      // Set unique payment id to state, this is what Wompi is going to use
      setPaymentReference(payment.data.info.uuid);
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setError({
        message: requestError ? requestError : "Ha ocurrido un error",
      });
    }
  };

  // Activate when there is not cedula, address or city
  const missingData = async () => {
    setShowMissingData(true);
  };

  useEffect(() => {
    if (!paymentReference) return;

    formRef.current.submit();
  }, [paymentReference]);

  return (
    <Fragment>
      <Navbar />
      <Modal
        showModal={receipt.showModal}
        closeModal={() => setReceipt({ ...receipt, showModal: false })}
      >
        <DefaultContent
          label="Facturación"
          heading="Datos para tu factura"
          showFooter={false}
          onSecondaryClick={() => setReceipt({ ...receipt, showModal: false })}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              onClick={() =>
                setReceipt({
                  ...receipt,
                  type: receipt.type === "person" ? "company" : "person",
                })
              }
              label={
                receipt.type === "person"
                  ? "Facturar como empresa"
                  : "Facturar como persona natural"
              }
              size="small"
            />
          </div>
          {receipt.type === "person" ? (
            <ReceiptPersonForm
              setCheck={setCheckReceiptType}
              receipt={receipt}
              legalDocTypes={legalDocTypes}
              setReceipt={(data) =>
                setReceipt({ ...receipt, showModal: false, receiptData: data })
              }
              setIsMissingData={(boolean) => setIsMissingData(boolean)}
              setShowMissingData={(boolean) => setShowMissingData(boolean)}
            />
          ) : (
            <ReceiptCompanyForm
              setCheck={setCheckReceiptType}
              setReceipt={(data) =>
                setReceipt({ ...receipt, showModal: false, receiptData: data })
              }
              setIsMissingData={(boolean) => setIsMissingData(boolean)}
              setShowMissingData={(boolean) => setShowMissingData(boolean)}
            />
          )}
        </DefaultContent>
      </Modal>

      <Background>
        <Container>
          <LeftColumn>
            {isSmallScreen && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingRight: "1rem",
                  paddingTop: "1rem",
                  cursor: "pointer",
                }}
                className="close-icon"
                onClick={() =>
                  history.push(`/cliente/compra/asesoria-presencial/${hashid}`)
                }
              >
                <HighlightOffIcon
                  style={{ fontSize: "3rem", color: "#000000" }}
                />
              </div>
            )}
            <Header>
              <h2>Datos de compra del diseño(s)</h2>

              <p style={{ marginTop: "0.5rem" }}>
                Estás a un paso de obtener tus diseños, verifica que el precio y
                los datos de facturación sean correctos, por último da clic en
                el botón pagar
              </p>
            </Header>
            <DividerImage src={image} />
            {error && <Alert severity="error">{error.message}</Alert>}
            <Body>
              {total && (
                <TotalContainer>
                  <h3>
                    Total a pagar: ${parseInt(total).toLocaleString("es-CO")}
                  </h3>
                </TotalContainer>
              )}

              {!isMissingData ? (
                <WompiForm
                  formRef={formRef}
                  hashid={hashid}
                  totalInCents={(total * 100).toFixed()}
                  reference={paymentReference}
                  handlePaymentRequest={handlePaymentRequest}
                />
              ) : (
                <div>
                  <Button
                    label="Pagar"
                    style={{
                      backgroundColor: "#1a4594",
                      color: "white",
                      marginTop: "2rem",
                      marginBottom: "1rem",
                    }}
                    onClick={missingData}
                  />
                </div>
              )}
            </Body>
          </LeftColumn>

          <RightColumn>
            {isBigScreen && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingRight: "1rem",
                  paddingTop: "1rem",
                  cursor: "pointer",
                }}
                className="close-icon"
                onClick={() =>
                  history.push(`/cliente/compra/asesoria-presencial/${hashid}`)
                }
              >
                <HighlightOffIcon
                  style={{ fontSize: "3rem", color: "#000000" }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem 2rem",
              }}
            >
              <h2>
                <i class="fa fa-user"></i> Mis datos
              </h2>
              {checkReceiptType === "person" ? (
                <DataPerson receipt={receipt} missingData={showMissingData} />
              ) : (
                <DataCompany receipt={receipt} />
              )}

              <Button
                onClick={() => setReceipt({ ...receipt, showModal: true })}
                label="Modificar datos para tu factura"
                size="small"
              />

              {showMissingData && (
                <div style={{ padding: "1rem" }}>
                  <p style={{ color: "red" }}>
                    Por favor completa los datos faltantes (*)
                  </p>
                </div>
              )}
            </div>
          </RightColumn>
        </Container>
      </Background>
    </Fragment>
  );
}

function ReceiptPersonForm({
  receipt,
  legalDocTypes,
  setReceipt,
  setCheck,
  setIsMissingData,
  setShowMissingData,
}) {
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(clientReceiptPersonForm),
  });
  const onSubmit = (data) => {
    setReceipt(data);
    setCheck("person");

    // Remove missing data warnings if there is not
    // document_number, address or city yet
    if (data.document_number && data.address && data.city) {
      setIsMissingData(false);
      setShowMissingData(false);
    }
    successToast("¡Has actualizado tus datos exitosamente!");
  };
  return (
    <Fragment>
      <StyledForm
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Row>
          <Row2>
            <Input2
              defaultValue={receipt.receiptData.first_name}
              placeholder="Nombre"
              name="first_name"
              register={register}
            />
            <Validator>{errors?.first_name?.message} </Validator>
          </Row2>

          <Row2>
            <Input2
              defaultValue={receipt.receiptData.last_name}
              placeholder="Apellido"
              name="last_name"
              register={register}
            />
            <Validator>{errors?.last_name?.message} </Validator>
          </Row2>
        </Row>

        <Row>
          {legalDocTypes && (
            <Row2>
              <Select
                style={{ width: "75%" }}
                name="legal_document_type_id"
                register={register}
              >
                {legalDocTypes.map((docType) => (
                  <option value={docType.id} key={docType.id}>
                    {docType.name}
                  </option>
                ))}
              </Select>
            </Row2>
          )}
          <Row2>
            <Input2
              defaultValue={receipt.receiptData.document_number}
              placeholder="Número de documento"
              name="document_number"
              register={register}
            />
            <Validator>{errors?.document_number?.message} </Validator>
          </Row2>
        </Row>
        <Row>
          <Row2>
            <Input2
              defaultValue={receipt.receiptData.email}
              placeholder="Correo"
              name="email"
              register={register}
            />
            <Validator>{errors?.email?.message} </Validator>
          </Row2>
          <Row2>
            <Input2
              defaultValue={receipt.receiptData.address}
              placeholder="Dirección"
              name="address"
              register={register}
            />
            <Validator>{errors?.address?.message} </Validator>
          </Row2>
        </Row>
        <Row>
          <Row2>
            <Input2
              defaultValue={receipt.receiptData.city}
              placeholder="Ciudad"
              name="city"
              register={register}
            />
            <Validator>{errors?.city?.message} </Validator>
          </Row2>
        </Row>
        <Button label="Aceptar" />
      </StyledForm>
    </Fragment>
  );
}

function ReceiptCompanyForm({
  setReceipt,
  setCheck,
  setIsMissingData,
  setShowMissingData,
}) {
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(clientReceiptCompanyForm),
  });
  const onSubmit = (data) => {
    setReceipt(data);
    setCheck("company");

    // TODO: Make validations for this form
    setIsMissingData(false);
    setShowMissingData(false);

    successToast("¡Has actualizado tus datos exitosamente!");
  };
  return (
    <Fragment>
      <StyledForm
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Row>
          <Row3>
            <Input2
              placeholder="Razón social"
              name="company_name"
              register={register}
            />
            <Validator>{errors?.company_name?.message} </Validator>
          </Row3>
        </Row>
        <Row>
          <Row3>
            <Input2 placeholder="NIT" name="NIT" register={register} />
            <Validator>{errors?.NIT?.message} </Validator>
          </Row3>
        </Row>
        <Row>
          <Row3>
            <Input2
              placeholder="Dirección"
              name="company_address"
              register={register}
            />
            <Validator>{errors?.company_address?.message} </Validator>
          </Row3>
        </Row>
        <Button label="Aceptar" />
      </StyledForm>
    </Fragment>
  );
}

function DataPerson({ receipt, missingData }) {
  // TODO: Decide if keep or remove the <p> inside <li>
  return (
    <>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "1rem 0",
        }}
      >
        <List>
          <strong>Nombre:</strong>
          <span>
            {receipt.receiptData.first_name}
            {receipt.receiptData.last_name}
          </span>
        </List>
        <List>
          <strong>CC:</strong>
          <span>{receipt.receiptData.document_number}</span>
        </List>
        <List>
          <strong>Correo:</strong>
          <span>{receipt.receiptData.email}</span>
        </List>
        <List>
          <strong>Dirección:</strong>
          <span>{receipt.receiptData.address}</span>
        </List>
        <List>
          <strong>Ciudad:</strong> <span>{receipt.receiptData.city}</span>
        </List>
      </ul>
    </>
  );
}

function DataCompany({ receipt }) {
  return (
    <>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "1rem 0",
        }}
      >
        <List>
          <strong>Razón social:</strong>
          <span>{receipt.receiptData.company_name}</span>
        </List>
        <List>
          <strong>NIT:</strong>
          <span>{receipt.receiptData.NIT}</span>
        </List>
        <List>
          <strong>Dirección de la compañía:</strong>
          <span>{receipt.receiptData.company_address}</span>
        </List>
      </ul>
    </>
  );
}

function WompiForm({
  formRef,
  totalInCents,
  reference,
  handlePaymentRequest,
  hashid,
}) {
  let wompiPublicKey =
    process.env.NODE_ENV === "production"
      ? "pub_prod_1i0nDDKUoKnWKEYDNg5sTRupkrKit6mS"
      : "pub_test_21cQ2tvNzNya4oRUNQ4M6bxrqxViRZZ9";

  return (
    <form
      ref={formRef}
      action="https://checkout.wompi.co/p/"
      method="GET"
      onSubmit={(event) => handlePaymentRequest(event)}
    >
      <input type="hidden" name="public-key" value={wompiPublicKey} />
      <input type="hidden" name="currency" value="COP" />
      <input type="hidden" name="amount-in-cents" value={totalInCents} />
      <input type="hidden" name="reference" value={reference} />
      <input
        type="hidden"
        name="redirect-url"
        value={`${BASE_URL}/cliente/compra/asesoria-presencial/${hashid}/wompi-transaction`}
      />
      <Button
        label="Pagar"
        type="submit"
        style={{
          backgroundColor: "#1a4594",
          color: "white",
          marginTop: "2rem",
        }}
      />
    </form>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const Row2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Row3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Background = styled.div`
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  font-size: 16px;
  font-weight: 300;
  background-color: #606060;
  padding: 2rem 4rem;
  min-height: 80vh;

  @media (max-width: 768px) {
    padding: 1rem 0rem;
  }
`;

const Container = styled.div`
  display: flex;
  background-color: #e9e9e9;
  margin: 1rem;
  border-radius: 0 1rem 1rem 0;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = styled.div`
  padding: 2rem 4rem;
`;

const DividerImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
`;

const Body = styled.div`
  padding: 1rem 2rem;

  @media (max-width: 400px) {
    padding: 0;

    button {
      margin: auto;
    }
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1rem;
`;

const TotalContainer = styled.div`
  padding-left: 2rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
    padding-left: 4rem;
  }
`;

const Validator = styled.span`
  color: #ec7063;
  width: 100%;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
`;

const List = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
`;

export default DesignsCheckout;
