import { BrowserRouter as Router } from "react-router-dom";

import ReactDOM from "react-dom";
import App from "./App";
import Fab from "./components/fab.js";
import ScrollToTop from "./services/scrollToTop";

import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import "./assets/fonts/Roboto/Roboto-Regular.ttf";
import "./index.scss";

import { BASE_API_URL } from "./services/baseURL";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFontAwesome, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Suspense } from "react";
import FullScreenLoading from "./components/FullScreenLoading/FullScreenLoading";

axios.defaults.baseURL = BASE_API_URL;

library.add(fas, faGoogle, faFontAwesome);

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<FullScreenLoading />}>
      <Router>
        <ScrollToTop />
        <App />
        <Fab />
      </Router>
    </Suspense>
  </QueryClientProvider>,
  document.getElementById("root")
);
