import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { validateFile } from "../../services/formValidation";
import { materialTableES } from "../../services/materialTableES";

import Chip from "@material-ui/core/Chip";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PostAddIcon from "@material-ui/icons/PostAdd"; // Bill
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import DefaultContent from "../../components/Modal/DefaultContent";
import Modal from "../../components/Modal/Modal";
import fileTypes from "../../services/fileTypes";
import { errorToast, successToast } from "../../services/toasts";

/**
 * Manage all virtual consultancies processes and filter by the ones that are
 * unpaid, paid, approved and finished
 *
 * TODO: Add alert option to filter virtual consultancies that haven't
 * had been approved after 24 hours
 */
function VirtualConsultanciesManagement() {
  // const [hashid, setHashid] = useState();
  const [currentConsultancy, setCurrentConsultancy] = useState();
  const [consultancies, setConsultancies] = useState();
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState();

  const [toPay, setToPay] = useState({
    designer: 0,
    fliping: 0,
    total: 0,
  });

  const [filteredBy, setFilterBy] = useState({
    finished: false,
    paid: false,
  });

  useEffect(() => {
    getConsultancies();
  }, []);

  const getConsultancies = async () => {
    let response = await axios.get(`/v1/virtual-consultancy/admin`);
    setConsultancies(response.data.body.info);
    setFilterBy({ finished: false, paid: false });
  };

  const getFinishedConsultancies = async () => {
    let response = await axios.get(
      `/v1/virtual-consultancy/admin?finished=true`
    );
    setConsultancies(response.data.body.info);

    setFilterBy({ finished: true, paid: false });
  };

  const getPaidConsultancies = async () => {
    let response = await axios.get(`/v1/virtual-consultancy/admin?paid=true`);
    setConsultancies(response.data.body.info);

    setFilterBy({ finished: false, paid: true });
  };

  const getUnpaidConsultancies = async () => {
    let response = await axios.get(`/v1/virtual-consultancy/admin?unpaid=true`);
    setConsultancies(response.data.body.info);

    setFilterBy({ finished: false, paid: true });
  };

  const getApprovedConsultancies = async () => {
    let response = await axios.get(
      `/v1/virtual-consultancy/admin?approved=true`
    );
    setConsultancies(response.data.body.info);

    setFilterBy({ finished: false, paid: true });
  };

  const handleShowModal = async (consultancy) => {
    let response = await axios.get(
      `/v1/virtual-consultancy/${consultancy.hashid}`
    );
    let paidAmount = response.data.body.info;

    let toPayDesigner = paidAmount.to_pay_designer;

    let toPayFliping = paidAmount.to_pay_fliping;

    let total = paidAmount.to_pay;

    setCurrentConsultancy(consultancy);

    setShowModal(true);
    setToPay({
      designer: toPayDesigner,
      fliping: toPayFliping,
      total,
    });
  };

  const handleBillUpload = async (event) => {
    try {
      const file = event.target.files[0];

      // Upload a file only if user chooses a valid file.
      if (file) {
        // PDF validation
        validateFile(file, /pdf/);

        const data = new FormData();
        data.append("file", file);
        data.append("originalname", "Factura Flipping");
        await axios.post(
          `/v1/virtual-consultancy/${currentConsultancy.hashid}/files/${fileTypes.consultancyLegalDoc}`,
          data
        );

        successToast("¡Has añadido tu factura exitosamente!");
      }
    } catch (error) {
      // Display a red alert of the kind of error
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
    }
  };

  return (
    <section className="layout-dashboard-container">
      <div>
        <Modal showModal={showModal} closeModal={() => setShowModal(false)}>
          <DefaultContent
            label="Pagos"
            heading={`Remodelación #${
              currentConsultancy && currentConsultancy.hashid
            }`}
            onSecondaryClick={() => setShowModal(false)}
            onSubmit={() => setShowModal(false)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <table style={{ border: "1px solid grey" }}>
                <tr>
                  <td>
                    Diseñador (
                    {currentConsultancy &&
                      currentConsultancy.designer.first_name}{" "}
                    {currentConsultancy &&
                      currentConsultancy.designer.last_name}
                    )
                  </td>
                  <td>${toPay.designer.toLocaleString("ES-es")}</td>
                </tr>
                <tr>
                  <td>Fliping</td>
                  <td>${toPay.fliping.toLocaleString("ES-es")}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>${toPay.total.toLocaleString("ES-es")}</td>
                </tr>
              </table>
            </div>
          </DefaultContent>
        </Modal>
        <Modal
          showModal={detailData ? true : false}
          closeModal={() => setDetailData()}
        >
          <CustomerDetail data={detailData} />
        </Modal>
        <input
          onChange={handleBillUpload}
          type="file"
          id="bill-upload"
          style={{ visibility: "hidden" }}
        />
        <MaterialTable
          title="Asesorías Virtuales"
          columns={[
            {
              title: "Fecha",
              field: "created_at",
              type: "date",
            },
            {
              title: "Fecha finalización",
              field: "finished_at",
              type: "date",
              hidden: filteredBy.finished ? false : true,
            },
            {
              title: "Hoja de proceso",
              render: (rowData) =>
                rowData.is_approved ? (
                  <Link
                    to={`/admin/compra/asesoria-virtual/${rowData.hashid}`}
                    target="_blank"
                  >
                    {rowData.hashid}
                  </Link>
                ) : (
                  <p>{rowData.hashid}</p>
                ),
            },
            {
              title: "Cliente",
              render: (rowData) =>
                `${rowData.client.first_name} ${rowData.client.last_name}`,
            },
            {
              title: "Profesional",
              render: (rowData) =>
                `${rowData.designer.first_name} ${rowData.designer.last_name}`,
            },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    marginBottom: "1rem",
                  }}
                >
                  <div>
                    <Chip
                      style={{
                        color: "#112A36",
                        backgroundColor: "#E2CA8A",
                        marginRight: 10,
                      }}
                      label="Todos"
                      clickable={true}
                      onClick={getConsultancies}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#061225",
                        marginRight: 10,
                      }}
                      label="Pendientes"
                      clickable={true}
                      onClick={getUnpaidConsultancies}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#ce4760",
                        marginRight: 10,
                      }}
                      label="Pagados"
                      clickable={true}
                      onClick={getPaidConsultancies}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#5D777B",
                        marginRight: 10,
                      }}
                      label="Aprobados"
                      clickable={true}
                      onClick={getApprovedConsultancies}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#AA8684",
                        marginRight: 10,
                      }}
                      label="Terminados"
                      clickable={true}
                      onClick={getFinishedConsultancies}
                    />
                  </div>
                </div>
              </div>
            ),
          }}
          actions={[
            (rowData) => {
              return rowData.finished_at
                ? {
                    icon: () => <LocalAtmIcon />,
                    tooltip: "Resumen de pagos",
                    onClick: (event, rowData) => {
                      handleShowModal(rowData);
                    },
                  }
                : null;
            },
            (rowData) => {
              return rowData.finished_at
                ? {
                    icon: () => <PostAddIcon />,
                    tooltip: "Subir factura",
                    onClick: (event, rowData) => {
                      setCurrentConsultancy(rowData);
                      document.getElementById("bill-upload").click(); // Upload bill
                    },
                  }
                : null;
            },
            {
              icon: () => <ZoomInIcon />,
              tooltip: "Detalle",
              onClick: (event, rowData) => {
                setDetailData(rowData);
              },
            },
          ]}
          data={consultancies && consultancies}
          options={{
            actionsColumnIndex: -1,
          }}
          localization={materialTableES}
          isLoading={consultancies ? false : true}
        />
      </div>
    </section>
  );
}

function CustomerDetail({ data }) {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información del cliente</h3>
      <span>
        <i>Nombre: </i> {`${data.client.first_name} ${data.client.last_name}`}
      </span>
      <span>
        <i>Correo: </i> {data.client.email}
      </span>
      <span>
        <i>Teléfono: </i> {data.client.phone}
      </span>
      <span>
        <i>Espacios: </i> {data.space.quantity}
      </span>
    </div>
  );
}

export default VirtualConsultanciesManagement;
