import React, { useState } from "react";
import { useStateMachine } from "little-state-machine";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import {
  receiptPersonSchema,
  receiptCompanySchema,
} from "../../services/formValidation";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import updateAction from "../../services/updateAction.js";

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "#FDD400",
  },
  tabsRoot: {
    backgroundColor: "white",
    color: "grey",
  },
  demo2: {
    overflow: "hidden",
    borderRadius: "100px",
  },
}));

function ReceiptModal({ onNext }) {
  const classes = useStyles();
  const { state } = useStateMachine(updateAction);
  const [receiptType, setReceiptType] = useState(
    state.data.receiptType ? state.data.receiptType : "person"
  );
  const [value, setValue] = useState(
    state.data.receiptType && state.data.receiptType === "company" ? 1 : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 0 ? setReceiptType("person") : setReceiptType("company");
  };

  return (
    <div className="receipt-modal-content">
      <AppBar position="static" className={classes.demo2}>
        <Tabs
          classes={{
            indicator: classes.indicator,
            root: classes.tabsRoot,
          }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Soy una persona" />
          <Tab label="Soy una empresa" />
        </Tabs>
      </AppBar>
      <TabPanel value={receiptType} index={"person"}>
        <ReceiptPersonForm onNext={() => onNext()} />
      </TabPanel>
      <TabPanel value={receiptType} index={"company"}>
        <ReceiptCompanyForm onNext={() => onNext()} />
      </TabPanel>
    </div>
  );
}

function ReceiptPersonForm({ onNext }) {
  const { action, state } = useStateMachine(updateAction);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(receiptPersonSchema),
  });

  const onSubmit = (data) => {
    // Save receipt data on global state
    action({
      receiptType: "person",
      receiptData: data,
    });
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Datos personales</h2>
      <div className="name-fields">
        <div>
          <label htmlFor="first_name">Nombre</label>
          <input
            name="first_name"
            ref={register}
            defaultValue={
              state.data.receiptData && state.data.receiptData.first_name
            }
            type="text"
          />
          {errors.first_name && (
            <p className="error">{errors.first_name.message}</p>
          )}
        </div>
        <div>
          <label htmlFor="last_name">Apellido</label>
          <input
            name="last_name"
            ref={register}
            defaultValue={
              state.data.receiptData && state.data.receiptData.last_name
            }
            type="text"
          />
          {errors.last_name && (
            <p className="error">{errors.last_name.message}</p>
          )}
        </div>
      </div>

      <label htmlFor="document_type">Tipo de documento</label>
      <select
        name="document_type"
        ref={register}
        defaultValue={
          state.data.receiptData && state.data.receiptData.document_type
        }
      >
        <option value="CC">Cedula de ciudadania</option>
        <option value="CE">Cedula de extranjeria</option>
      </select>

      <label htmlFor="document_number">Número de documento (sin puntos)</label>
      <input
        name="document_number"
        ref={register}
        defaultValue={
          state.data.receiptData && state.data.receiptData.document_number
        }
        type="text"
        maxLength="10"
      />
      {errors.document_number && (
        <p className="error">{errors.document_number.message}</p>
      )}
      <button type="submit">Continuar</button>
    </form>
  );
}

function ReceiptCompanyForm({ onNext }) {
  const { action, state } = useStateMachine(updateAction);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(receiptCompanySchema),
  });

  const onSubmit = (data) => {
    // Save receipt data on global state
    action({
      receiptType: "company",
      receiptData: data,
    });
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Datos de la empresa</h2>
      <label htmlFor="business_name">Razón Social</label>
      <input
        name="business_name"
        ref={register}
        defaultValue={
          state.data.receiptData && state.data.receiptData.business_name
        }
        type="text"
      />
      {errors.business_name && (
        <p className="error">{errors.business_name.message}</p>
      )}

      <label htmlFor="NIT">Número de NIT</label>
      <input
        name="NIT"
        ref={register}
        defaultValue={state.data.receiptData && state.data.receiptData.NIT}
        type="text"
      />
      {errors.NIT && <p className="error">{errors.NIT.message}</p>}
      <button type="submit">Continuar</button>
    </form>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ReceiptModal;
