import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

import styles from "./orderHeader.module.scss";

interface OrderHeaderProps {
  orderNumber: string;
  purchaseDate: string;
}

const OrderHeader = ({ orderNumber, purchaseDate }: OrderHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.orderDate}>
          <DateRangeOutlinedIcon />
          <h3>{purchaseDate}</h3>
        </div>
        <p>Order ID: {orderNumber}</p>
      </div>
    </div>
  );
};
export default OrderHeader;
