import { useParams } from "react-router";

import CustomMaterialTable from "../../../../components/CustomMaterialTable/CustomMaterialTable";

import useFetch from "../../../../ecommerce/hooks/useFetch";
import SuppliersTableColumns from "./components/SuppliersTableColumns";
import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import CustomBreadCrumb from "../../../../ecommerce/components/CustomBreadCrumb/CustomBreadCrumb";

import { type OrderWithSuppliers } from "./types";

const SuppliersAdminTable = () => {
  const { id } = useParams<{ id: string }>();
  const orderId = Number(id);
  const url = `/v2/admin/orders/${orderId}`;

  const { isLoading, apiData: orderDetails } = useFetch<OrderWithSuppliers>({
    method: "GET",
    url,
  });

  if (isLoading || !orderDetails) {
    return <LoadingScreen />;
  }

  return (
    <section className="layout-dashboard-container">
      <CustomBreadCrumb orderId={orderDetails.orderNumber} userRole="admin" />
      <CustomMaterialTable
        title="Proveedores de la orden"
        columns={SuppliersTableColumns()}
        data={orderDetails.suppliers}
      />
    </section>
  );
};

export default SuppliersAdminTable;
