import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  getApprovedBudgets,
  getPurchaseInfo,
  postPayUCheckout,
  postWompiCheckout,
} from "../../services/api.js";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { useStyles } from "../../services/muiStyles";
import ReceiptModal from "./receiptModal.js";
import Modal from "../../components/modal.js";
import Navbar from "../../components/Navbar";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import updateAction from "../../services/updateAction.js";

function Checkout(props) {
  const classes = useStyles();
  const history = useHistory();
  const { action, state } = useStateMachine(updateAction);
  const [purchase, setPurchase] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [total, setTotal] = useState(0);
  const [signature, setSignature] = useState();
  const [reference, setReference] = useState();
  const [receiptModal, setReceiptModal] = useState(false);
  const inProduction =
    process.env.NODE_ENV === "production"
      ? "pub_prod_1i0nDDKUoKnWKEYDNg5sTRupkrKit6mS"
      : "pub_test_21cQ2tvNzNya4oRUNQ4M6bxrqxViRZZ9"; // When you run npm run build, NODE_ENV is always "production"

  let { id } = useParams();
  let payUFormRef = React.useRef(null);
  let wompiFormRef = React.useRef(null);

  useEffect(() => {
    getPurchaseInfo(id).then((res) => {
      setPurchase(res);
      action({
        receiptType: "person",
        receiptData: {
          first_name: res.client.first_name,
          last_name: res.client.last_name,
          document_type: "CC",
          document_number: res.client.cedula,
        },
      });
    });
    getApprovedBudgets(id).then((res) => {
      res.map((i) => (i.checked = true));
      setBudgets(res);
    });
    // .catch((err) => {
    // 	history.push("/404");
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let checkedItems = budgets.filter((row) => row.checked);
    let total = checkedItems.reduce((a, b) => {
      return a + b["value"];
    }, 0);
    setTotal(total);
  }, [budgets]);

  const handleClose = (event) => {
    setReceiptModal(false);
  };

  const handleShow = (event) => {
    setReceiptModal(true);
  };

  const updateChecked = (k) => {
    return budgets.map((el) =>
      el.id_purchase_budget === k
        ? Object.assign({}, el, { checked: !el.checked })
        : el
    );
  };

  const handleRowClick = (key) => {
    setBudgets(updateChecked(key));
  };

  const handleChange = (key) => {
    setBudgets(updateChecked(key));
  };

  const handlePayUSubmit = (event) => {
    event.preventDefault();
    // This route creates the unique signature and references for PayU
    postPayUCheckout(id, {
      total: total,
      budgets: budgets,
      receiptType: state.data.receiptType,
      receiptData: state.data.receiptData,
    }).then((res) => {
      setSignature(res.signature);
      setReference(res.reference);
      payUFormRef.current.submit();
      //TODO: check if this comments lines in the future would be needed or not
      // if (state.data.receiptData) {
      // 	let receiptData = state.data.receiptData;
      // receiptData.id_purchase_payment = res.purchasePaymentId;
      // Post receipt data saved on state
      // postReceipt(state.data.receiptType, receiptData).then((res) => {
      // });
      // }
    });
  };

  const handleWompiSubmit = (event) => {
    event.preventDefault();
    // This route creates the unique signature and references for Wompi
    postWompiCheckout(id, {
      total: total,
      budgets: budgets,
      receiptType: state.data.receiptType,
      receiptData: state.data.receiptData,
    }).then((res) => {
      setReference(res.reference);
      wompiFormRef.current.submit(); // Wompi form
      // TODO: check if this comments lines in the future would be needed or not
      // if (state.data.receiptData) {
      // 	let receiptData = state.data.receiptData;
      // receiptData.id_purchase_payment = res.purchasePaymentId;
      // postReceipt(state.data.receiptType, receiptData).then((res) => {
      // });
      // }
    });
  };

  return (
    <>
      <Navbar />
      {purchase.client && (
        <div className="order-container">
          <div className="order">
            <div className="main">
              <div className="checkout-selection">
                <div className="checkout-info">
                  <div className="checkout-info-header">
                    <Link to={{ pathname: `/compra/${id}` }}>
                      <button className="button-back-checkout">
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                      </button>
                    </Link>
                    <h1>Formulario de pago</h1>
                  </div>
                  <p>
                    Por favor selecciona las cotizaciones que deseas pagar y una
                    vez estes listo, haz clic sobre el medio de pago de tu
                    preferencia.
                  </p>
                </div>
                <img
                  className="image"
                  src="https://images.pexels.com/photos/4482900/pexels-photo-4482900.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt="Podras seleccionar los presupuestos que deseas pagar"
                />
              </div>
              <div className="status-info">
                <Table>
                  <TableBody>
                    {budgets && budgets.length === 0 ? (
                      <h3>
                        Aun no tienes presupuestos aprobados, por favor aprueba
                        al menos uno en tu hoja de proceso para continuar.
                      </h3>
                    ) : (
                      ""
                    )}
                    {budgets &&
                      budgets.map((b) => (
                        <TableRow
                          onClick={() => handleRowClick(b.id_purchase_budget)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="default"
                              className={classes.checkbox}
                              checked={b.checked}
                              onChange={() =>
                                handleChange(b.id_purchase_budget)
                              }
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {b.name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            ${b.value.toLocaleString("es-CO")}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <div className="button-container">
                {inProduction ? (
                  <form
                    ref={payUFormRef}
                    method="post"
                    id="payu-checkout-form"
                    action="https://checkout.payulatam.com/ppp-web-gateway-payu/"
                    onSubmit={handlePayUSubmit}
                  >
                    <input name="merchantId" type="hidden" value="877143" />
                    <input name="accountId" type="hidden" value="884853" />
                    <input
                      name="description"
                      type="hidden"
                      value="Pago cotización fliping"
                    />
                    <input
                      name="referenceCode"
                      type="hidden"
                      value={reference}
                    />
                    <input name="amount" type="hidden" value={total} />
                    <input name="tax" type="hidden" value="0" />
                    <input name="taxReturnBase" type="hidden" value="0" />
                    <input name="currency" type="hidden" value="COP" />
                    <input name="signature" type="hidden" value={signature} />
                    <input name="test" type="hidden" value="1" />
                    <input
                      name="payerFullName"
                      type="hidden"
                      value={`${purchase.client.first_name} ${purchase.client.last_name}`}
                    />
                    <input
                      name="buyerEmail"
                      type="hidden"
                      value={purchase.client.email}
                    />
                    <input
                      name="responseUrl"
                      type="hidden"
                      value={`http://remodelaen1dia.com/compra/${id}/res/payu/`}
                    />
                  </form>
                ) : (
                  // Form sent on development, using PayU's sandbox
                  <form
                    ref={payUFormRef}
                    method="post"
                    id="payu-checkout-form"
                    action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu"
                    onSubmit={handlePayUSubmit}
                  >
                    <input name="merchantId" type="hidden" value="508029" />
                    <input name="accountId" type="hidden" value="512321" />
                    <input
                      name="description"
                      type="hidden"
                      value="Sandbox test"
                    />
                    <input
                      name="referenceCode"
                      type="hidden"
                      value={reference}
                    />
                    <input name="amount" type="hidden" value={total} />
                    <input name="tax" type="hidden" value="0" />
                    <input name="taxReturnBase" type="hidden" value="0" />
                    <input name="currency" type="hidden" value="COP" />
                    <input name="signature" type="hidden" value={signature} />
                    <input name="test" type="hidden" value="1" />
                    <input
                      name="payerFullName"
                      type="hidden"
                      value={`${purchase.client.first_name} ${purchase.client.last_name}`}
                    />
                    <input
                      name="buyerEmail"
                      type="hidden"
                      value={purchase.client.email}
                    />
                    <input
                      name="responseUrl"
                      type="hidden"
                      value={`http://localhost:3000/compra/${id}/res/payu/`}
                    />
                  </form>
                )}
                {/* WOMPI form */}
                <form
                  ref={wompiFormRef}
                  action="https://checkout.wompi.co/p/"
                  method="GET"
                  id="wompi-checkout-form"
                  onSubmit={handleWompiSubmit}
                >
                  <input type="hidden" name="public-key" value={inProduction} />
                  <input type="hidden" name="currency" value="COP" />
                  <input
                    type="hidden"
                    name="amount-in-cents"
                    value={total * 100}
                  />
                  <input type="hidden" name="reference" value={reference} />
                  <input
                    type="hidden"
                    name="redirect-url"
                    value={`http://localhost:3000/compra/${id}/res/wompi/${reference}/`}
                  />
                </form>
              </div>
            </div>
            <div className="info">
              <h1 className="info-title">Resumen de tu compra</h1>
              <h3>
                {" "}
                Nombre:{" "}
                <p>{`${purchase.client.first_name} ${purchase.client.last_name}`}</p>
              </h3>
              <h3>
                {" "}
                Dirección: <p>{purchase.address}</p>{" "}
              </h3>
              <h3>
                {" "}
                Celular: <p>3052238589</p>{" "}
              </h3>
              <h1>Total: ${total.toLocaleString("es-CO")}</h1>
              <button
                disabled={total === 0}
                className="btn btn-payu"
                type="submit"
                form="payu-checkout-form"
              >
                Paga con <strong>PayU</strong>
              </button>
              <button
                disabled={total === 0}
                className="btn btn-wompi"
                type="submit"
                form="wompi-checkout-form"
              >
                Paga con <strong>Wompi</strong>
              </button>
              <a href="/#" onClick={handleShow}>
                <p
                  style={{
                    margin: "0.8rem 0rem",
                    cursor: "pointer",
                    color: "grey",
                    textDecoration: "underline",
                  }}
                >
                  Datos para tu factura
                </p>
              </a>
              <Modal
                className={"receipt-modal"}
                showModal={receiptModal}
                closeModal={handleClose}
              >
                <ReceiptModal onNext={() => setReceiptModal(false)} />
              </Modal>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Checkout;
