import { Column } from "material-table";
import { Link } from "react-router-dom";

import ActionLink from "../../../../../../components/ActionLink/ActionLink";
import CustomButton from "../../../../../../components/Button/CustomButton";
import styles from "./ordersAdminTableColumns.module.scss";

import { type Suborder } from "../../types";

const OrdersAdminTableColumns = () => {
  const tableColumns: Array<Column<Suborder>> = [
    {
      title: "Nro. de suborden",
      field: "id",
      type: "numeric",
      align: "left",
    },
    {
      title: "Producto",
      render: (rowData) => (
        <div className={styles.productInfo}>
          <img
            src={rowData.productImageUrl}
            alt={rowData.productName}
            className={styles.productImage}
          />
          <p className="product-name">{rowData.productName}</p>
        </div>
      ),
    },
    {
      title: "Fecha",
      field: "created_at",
      type: "date",
    },
    {
      title: "Guia",
      field: "trackUrl",
      render: (rowData) => (
        <ActionLink href={rowData.trackUrl} text="Ver guia" />
      ),
    },
    {
      title: "Pedido",
      render: (rowData) => (
        <Link to={`/admin/ordenes/detalle/${rowData.id}`}>
          <CustomButton
            shape="little-rounded"
            color="outlined"
            size="small"
            className="btn btn-primary"
          >
            Ver detalle
          </CustomButton>
        </Link>
      ),
    },
    {
      title: "Etiqueta",
      field: "label",
      render: (rowData) => (
        <ActionLink href={rowData.label} text="Ver etiqueta" />
      ),
    },
  ];

  return tableColumns;
};
export default OrdersAdminTableColumns;
