import { Installer } from "../types";

type InstallersListProps = {
  installers: Installer[];
};

export default function InstallersList({ installers }: InstallersListProps) {
  return (
    <div className="detail-panel-container">
      {installers.map((installer, index) => {
        return (
          <div className="detail-panel-row" key={index}>
            <div className="file-details">
              <i className="fa fa-address-card-o" aria-hidden="true" />
              <span>{installer.full_name}</span>
              <span style={{ marginLeft: "1rem" }}>
                Cédula. {installer.cedula}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
