import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  textField: {
    width: "25ch",
  },
  mediumWidth: {
    width: "32ch!important",
  },
  margin: {
    margin: theme.spacing(1),
  },
  icons: {
    color: "#232f3e",
    fontSize: "1.5rem",
    margin: "5px 10px 5px 5px",
  },
  heading: {
    color: "#232f3e",
    paddingBottom: "1.8rem",
  },
  headingTextForm: {
    width: "100%",
    color: "#232f3e",
    paddingBottom: "1rem",
    fontSize: "1.25rem",
    borderBottom: "1px solid rgba(145, 158, 171, 0.3)",
    marginBottom: "1.5rem",
  },
  fileInputLabel: {
    marginBottom: "0.5rem",
    color: "#232f3e",
  },
  imageUploadInstructions: {
    color: "#0000008a",
    display: "block",
    textAlign: "center",
  },
  gridContainerForm: {
    marginBottom: theme.spacing(6),
  },
  gridMarginTop: {
    marginTop: theme.spacing(2),
  },
}));
