import React from "react";
import { Tab as MaterialTab, TabProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selected: {
    background: "rgba(163, 175, 199, 0.2)",
  },
}));

const Tab: React.FC<TabProps> = (props) => {
  const classes = useStyles();
  return <MaterialTab {...props} classes={{ selected: classes.selected }} />;
};

export default Tab;
