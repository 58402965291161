import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLightbox, SRLWrapper } from "simple-react-lightbox";
import { validateFile } from "../../services/formValidation";
import { withRouter } from "react-router-dom";
import { Upload } from "antd";

import HearingIcon from "@material-ui/icons/Hearing";
import InstagramIcon from "@material-ui/icons/Instagram";
import SchoolIcon from "@material-ui/icons/School";
import SuccessBox from "../../components/SuccessBox/SuccessBox.js";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import Button from "../../components/Button/Button.js";
import Input from "../../components/Input/Input.js";
import Select from "../../components/Select/Select.js";

import successTeamImage from "../../assets/images/success-team.jpg";
import curriculumImage from "../../assets/images/curriculum.jpg";
import styled from "styled-components";
import axios from "axios";

import "antd/lib/upload/style/index.css";

const INSTAGRAM_USERNAME_REGEX = /^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/i;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function DesignerFormStep2() {
  const history = useHistory();
  const data = useLocation();
  const formData = data.state ? data.state.formData : [];

  const [portfolioImages, setPortfolioImages] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [termsAndCond, setTermsAndCond] = useState(false);
  const [showSuccess, setShowsuccess] = useState(false);
  const [referredFrom, setReferredFrom] = useState();
  const [referralSources, setReferralSources] = useState([]);
  const [instagramUser, setInstagramUser] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [validateSize, setValidateSize] = useState();
  const [open, setOpen] = useState(false);

  // If the user attemps to skip the first step, re-direct them
  if (formData.length === 0) {
    history.push("/disenador/paso-1");
  }

  const props = {
    customRequest: dummyRequest,
    maxCount: 1,
    onRemove: () => {
      setProfileImage();
    },
    beforeUpload: (file) => {
      setProfileImage(file);
    },
    onChange: ({ file }) => {
      // trim filenames that are over 30 characters long
      if (file.name.length > 30) {
        let fileExtension = file.name.split(".").pop();

        file.name = `${file.name.slice(0, 20)}.${fileExtension}`;
      }
    },
  };

  const propsMultiple = {
    customRequest: dummyRequest,
    maxCount: 3,
    multiple: true,
    fileList: portfolioImages,
    onRemove: (file) => {
      const index = portfolioImages.indexOf(file);
      const copyPortfolioImages = [...portfolioImages];
      copyPortfolioImages.splice(index, 1);

      setPortfolioImages(copyPortfolioImages);
    },
    beforeUpload: (file) => {
      if (portfolioImages.length === 3) {
        setErrors({
          message: "Solo puedes añadir máximo 3 imágenes de portafolio",
        });
      } else {
        setErrors();
      }
    },
    onChange: ({ file, fileList }) => {
      // trim filenames that are over 30 characters long
      if (file.name.length > 30) {
        let fileExtension = file.name.split(".").pop();

        file.name = `${file.name.slice(0, 20)}.${fileExtension}`;
      }

      if (file.size > 15000000) {
        setValidateSize("El tamaño de la imagen no puede ser mayor a 15MB");

        return false;
      } else {
        setValidateSize("");
      }

      setPortfolioImages([...fileList]);
    },
  };

  useEffect(() => {
    getReferralSources();
  }, []);

  const getReferralSources = async () => {
    let response = await axios.get(`/v1/users/sources`);
    setReferralSources(response.data);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (universityName.length < 1) {
        setErrors({
          message:
            "Por favor escribe el nombre de la institución donde te graduaste",
        });
      } else if (graduationYear.length < 0) {
        setErrors({
          message: "Por favor escribe el año en el que te graduaste",
        });
      } else if (graduationYear.length !== 4) {
        setErrors({
          message:
            "Por favor escribe un año válido de cuatro digitos, por ejemplo: 2006",
        });
      } else if (!profileImage) {
        setErrors({ message: "Por favor carga tu imagen de perfil" });
      } else if (portfolioImages.length < 1) {
        setErrors({ message: "Por favor carga imágenes de tu portafolio" });
      } else if (portfolioImages.length > 3) {
        setErrors({ message: "Máximo se permiten 3 imágenes de portafolio" });
      } else if (
        !instagramUser.match(INSTAGRAM_USERNAME_REGEX) &&
        instagramUser.length !== 0
      ) {
        setErrors({
          message:
            "Por favor escribe un usuario de instagram válido (sin @), por ejemplo: fliping",
        });
      } else if (!referredFrom) {
        setErrors({
          message: "Cuentanos cómo conociste Fliping",
        });
      } else {
        setErrors(); // clear errors

        // Validate file extensions and size
        let images = [profileImage, ...portfolioImages];
        for (let f of images) {
          validateFile(f, /jpeg|png|jpg/);
        }

        formData.instagram =
          instagramUser.length > 0
            ? `https://www.instagram.com/${instagramUser}`
            : "";
        formData.referred_from_id = referredFrom;
        formData.university_name = universityName;
        formData.graduation_year = graduationYear;

        let formDataForRequest = new FormData();

        // append avatar
        formDataForRequest.append("avatar", profileImage);

        // append portfolio images
        portfolioImages.forEach((image) => {
          formDataForRequest.append("portfolio", image.originFileObj);
        });

        // convert object with designer info to form data
        for (let key in formData) {
          formDataForRequest.append(key, formData[key]);
        }

        await axios.post("/v1/designers", formDataForRequest);

        setShowsuccess(true);
      }
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setErrors({ message: requestError ? requestError : error.message });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { openLightbox } = useLightbox();

  const IMAGE_EXAMPLES = [
    {
      id: 1,
      src: "https://r1d-assets.s3.us-east-2.amazonaws.com/professional2021_profile_example_1.jpg",
      caption: "Ejemplo foto de perfil #1",
    },
    {
      id: 2,
      src: "https://r1d-assets.s3.us-east-2.amazonaws.com/professional2021_profile_example_2.jpg",
      caption: "Ejemplo foto de perfil #2",
    },
    {
      id: 3,
      src: "https://r1d-assets.s3.us-east-2.amazonaws.com/professional2021_portfolio_example_1.jpeg",
      caption: "Ejemplo imagen de portafolio #1",
    },
    {
      id: 4,
      src: "https://r1d-assets.s3.us-east-2.amazonaws.com/professional2021_portfolio_example_2.jpeg",
      caption: "Ejemplo imagen de portafolio #2",
    },
    {
      id: 5,
      src: "https://r1d-assets.s3.us-east-2.amazonaws.com/professional2021_portfolio_example_3.jpeg",
      caption: "Ejemplo imagen de portafolio #3",
    },
  ];

  if (showSuccess) {
    return (
      <SplitScreen
        fullheight
        logoWithLink
        overlay
        image={successTeamImage}
        title="Recibimos tu solicitud"
        description="Nos pondremos en contacto contigo lo ántes posible"
      >
        <SuccessBox />
      </SplitScreen>
    );
  } else {
    return (
      <SplitScreen
        fullheight
        logoWithLink
        overlay
        image={curriculumImage}
        title="Ya casi estás"
        description="Estás a punto de terminar tu inscripción, por favor completa la siguiente información y sube las imágenes requeridas"
      >
        {referralSources && (
          <Fragment>
            <p style={{ fontSize: "13.3px", marginTop: "1rem" }}>
              Nombre de la universidad, ciudad y año de la graduación. Recuerda
              que haremos una verificación de esta información
            </p>
            <Row>
              <SchoolIcon />
              <Input
                name="university_name"
                placeholder="Universidad-ciudad"
                onChange={(event) => setUniversityName(event.target.value)}
              />
              <Input
                name="graduation_year"
                placeholder="Año"
                onChange={(event) => setGraduationYear(event.target.value)}
              />
            </Row>

            <p style={{ fontSize: "13.3px", marginTop: "1rem" }}>
              Tu instagram aparecerá en tu perfil para que tus clientes puedan
              conocerte más
            </p>
            <Row>
              <InstagramIcon />
              <Input
                name="instagram"
                placeholder="Usuario de instagram (opcional)"
                onChange={(event) => setInstagramUser(event.target.value)}
              />
            </Row>

            <Row>
              <HearingIcon />
              <Select
                name="referred-from"
                style={{ padding: "0.25rem 0.5rem" }}
                onChange={(event) => setReferredFrom(event.target.value)}
                value={referredFrom}
              >
                <option hidden selected disabled>
                  ¿Cómo nos conociste?
                </option>
                {referralSources.map((source) => (
                  <option value={source.id} key={source.id}>
                    {source.name}
                  </option>
                ))}
              </Select>
            </Row>
            <p
              style={{
                fontSize: "13.3px",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              Sube una foto de perfil donde tu cara sea el elemento principal de
              la imagen.
              <span
                style={{
                  cursor: "pointer",
                  color: "grey",
                  textDecoration: "underline",
                  paddingLeft: 3,
                }}
                onClick={() => {
                  openLightbox();
                }}
              >
                Ejemplos
              </span>
            </p>
            <Upload {...props} data-testid="avatar_file">
              <Button label="Foto de perfil" icon="fa-user-circle" />
            </Upload>
            <p
              style={{
                fontSize: "13.3px",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              Podras subir hasta tres fotografías o renders de proyectos que
              hayas realizado o en el caso que no cuentas con alguna de éstas
              podrás subir imágenes gratuitas que representen tu estilo.
              <span
                style={{
                  cursor: "pointer",
                  color: "grey",
                  textDecoration: "underline",
                  paddingLeft: 3,
                }}
                onClick={() => {
                  openLightbox(2);
                }}
              >
                Ejemplos
              </span>
            </p>

            <Upload {...propsMultiple} data-testid="portfolio_file">
              <Button
                style={{ margin: ".5rem 0" }}
                label="Imágenes de portafolio"
                icon="fa-picture-o"
              />
            </Upload>

            {errors && (
              <p className="error" style={{ padding: "1rem 0 0.5rem 0" }}>
                {errors.message}
              </p>
            )}

            {validateSize && (
              <p className="error" style={{ padding: "1rem 0 0.5rem 0" }}>
                {validateSize}
              </p>
            )}

            <Row style={{ marginBottom: "0.8rem", marginTop: "0.8rem" }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  color: "grey",
                  fontSize: "13.3333px",
                }}
              >
                <input
                  type="checkbox"
                  name="TermsAndConditions"
                  style={{ margin: "0 0.25rem 0 0" }}
                  onChange={(e) => setTermsAndCond(e.target.checked)}
                />
                <span>
                  Acepto los{" "}
                  <a
                    href="https://r1d-assets.s3.us-east-2.amazonaws.com/TerminosYCondicionesProfesionales.pdf"
                    target="__blank"
                    style={{
                      cursor: "pointer",
                      color: "grey",
                      textDecoration: "underline",
                    }}
                  >
                    términos y condiciones
                  </a>
                </span>{" "}
              </label>
            </Row>

            <div style={{ display: "flex" }}>
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/disenador/paso-1",
                    state: { formData: formData },
                  })
                }
                label="Volver"
              />
              <Button
                onClick={handleSubmit}
                label="Enviar"
                type="submit"
                $isLoading={loading}
                disabled={!termsAndCond}
              />
              <SRLWrapper elements={IMAGE_EXAMPLES} />
            </div>
          </Fragment>
        )}
      </SplitScreen>
    );
  }
}

const Label = styled.label`
  font-family: Roboto;
  font-size: 1rem;
`;

const FileInput = styled.input`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const Divider = styled.div`
  border-bottom: 1px solid gray;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default withRouter(DesignerFormStep2);
