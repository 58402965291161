import styles from "./inputValidationMessage.module.scss";

type InputValidationMessageProps = {
  message: string | undefined;
};

const InputValidationMessage = ({ message }: InputValidationMessageProps) => {
  return (
    <div className={styles.inputValidationMessage}>
      <p>{message}</p>
    </div>
  );
};

export default InputValidationMessage;
