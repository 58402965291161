import { Fragment, createRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  statusTableColumns,
  statusTableOptions,
  statusTableEditable,
  statusTableActions,
  statusTableDetail,
  stepStatuses,
} from "./StatusTable";
import { materialTableES } from "../../../services/materialTableES.js";
import { roles } from "../../../services/auth";
import { StepName } from "./StatusTable";
import { successToast } from "../../../services/toasts";
import { Remodeling, Token } from "../types";

import MaterialTable from "material-table";

import axios from "axios";
import fileTypes from "../../../services/fileTypes";

type RemodelingStatusTableProps = {
  data: Remodeling;
  updateData: () => void;
  decodedToken: Token;
  handleShowStepHelpModal: (stepName: StepName) => void;
  isSmallScreen: boolean;
};

function RemodelingStatusTable({
  data,
  updateData,
  decodedToken,
  handleShowStepHelpModal,
  isSmallScreen,
}: RemodelingStatusTableProps) {
  // TODO: this could be passed down from RemodelingResume.tsx
  const { hashid } = useParams<{ hashid: string }>();

  const tableRef = createRef();
  const history = useHistory();

  const isClient =
    decodedToken.role_id === roles.client &&
    decodedToken.uuid === data.client.uuid;
  const isDesigner =
    decodedToken.role_id === roles.designer &&
    decodedToken.uuid === data.designer.uuid;
  const isSupplier = decodedToken.role_id === roles.supplier;

  const [isLoading, setIsLoading] = useState(false);

  const handleDesignFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) return;

    setIsLoading(true);

    const designStep = data.steps[1];
    const designFile = new FormData();
    const file = event.target.files[0];
    designFile.append("file", file);

    const insert = await axios.post(
      `/v1/remodelings/${hashid}/files/${fileTypes.remodelingDesign}`,
      designFile
    );
    await axios.post(`/v1/remodelings/${hashid}/designs?whatsapp=true`, {
      remodeling_process_file_id: insert.data.id,
    });

    // Update date of the design step
    await axios.patch(`/v1/remodelings/steps/${designStep.id}`, {
      step_status_id: stepStatuses.inProgress,
    });

    successToast("Has subido un diseño exitosamente");
    updateData();

    setIsLoading(false);
  };

  const handleDesignApproval = async (design_id: number) => {
    await axios.patch(
      `/v1/remodelings/${hashid}/designs/${design_id}?whatsapp=true`,
      {
        is_approved: true,
      }
    );

    successToast("Has aprobado un diseño exitosamente");
    updateData();
  };

  const handleInvoiceApproval = async (invoice_id: number) => {
    await axios.patch(
      `/v1/remodelings/${hashid}/invoices/${invoice_id}?whatsapp=true`,
      {
        is_approved: true,
      }
    );

    successToast("Has aprobado una cotización exitosamente");
    updateData();
  };

  return (
    <Fragment>
      <input onChange={handleDesignFileUpload} type="file" id="design_files" />
      {/*@ts-ignore*/}
      <MaterialTable
        isLoading={isLoading}
        tableRef={tableRef}
        data={data.steps}
        options={statusTableOptions}
        localization={materialTableES}
        columns={statusTableColumns(
          handleShowStepHelpModal,
          isSmallScreen,
          isDesigner
        )}
        detailPanel={statusTableDetail(
          isClient,
          isDesigner,
          isSupplier,
          data,
          handleDesignApproval,
          handleInvoiceApproval
        )}
        editable={statusTableEditable(isDesigner, data.steps, updateData)}
        actions={statusTableActions(isDesigner, data.steps, data, history)}
        onRowClick={(event, rowData, togglePanel: any) => togglePanel()}
      />
    </Fragment>
  );
}

export default RemodelingStatusTable;
