import styled from "styled-components";

export const ListContainer = styled("div")`
  border: 1px solid grey;
  border-radius: 10px;
  margin: 1rem;
`;

export const Title = styled("h4")`
  color: grey;
`;

export const ItemContainer = styled("div")`
  cursor: pointer;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid grey;

  &:last-child {
    border-bottom: none;
  }
`;
