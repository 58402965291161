import { Product } from "../../../ecommerce/models";
import { infoToast } from "../../../services/toasts";
import { REDUCER_ACTION_TYPE } from "../Actions/cartActions";
import { CartActionType, CartStateType } from "../models";

export const CartInitialState: CartStateType = {
  cart: [],
};

export const cartReducer = (
  state: CartStateType,
  action: CartActionType
): CartStateType => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.ADD_ITEM: {
      if (!action.payload)
        throw new Error(`${action.payload} missing in ADD action`);
      const {
        cart_item_id,
        product_id,
        name,
        price,
        taxed_price,
        principal_image,
        min_sale_unit,
        presentation,
        category,
        comission_percentage,
        price_per_m2,
        units_available,
      } = action.payload as Product;
      const filteredCart: Product[] = state.cart.filter(
        (item) => item.product_id !== product_id
      );

      const itemExists: Product | undefined = state.cart.find(
        (item) => item.product_id === product_id
      );
      const quantity: number = itemExists
        ? (itemExists?.quantity as number) + 1
        : 1;
      return {
        ...state,
        cart: [
          ...filteredCart,
          {
            cart_item_id,
            product_id,
            name,
            price,
            taxed_price,
            quantity,
            principal_image,
            min_sale_unit,
            presentation,
            category,
            comission_percentage,
            price_per_m2,
            units_available,
          },
        ],
      };
    }
    case REDUCER_ACTION_TYPE.ADD_QUANTITY: {
      if (!action.payload) {
        throw new Error("action.payload missing in QUANTITY action");
      }

      const { product_id, quantity } = action.payload as Product;

      const itemExists: Product | undefined = state.cart.find(
        (item) => item.product_id === product_id
      );

      if (!itemExists) {
        throw new Error("Item must exist in order to update quantity");
      }

      const updatedItem: Product = { ...itemExists, quantity };

      const filteredCart: Product[] = state.cart.filter(
        (item) => item.product_id !== product_id
      );

      return { ...state, cart: [...filteredCart, updatedItem] };
    }
    case REDUCER_ACTION_TYPE.INITIALIZE_CART:
      if (!action.payload) {
        throw new Error("action.payload missing in INITIALIZE action");
      }
      return { ...state, cart: [...(action.payload as Product[])] };

    case REDUCER_ACTION_TYPE.REMOVE_ITEM:
      if (!action.payload)
        throw new Error(`${action.payload} missing in REMOVE action`);
      const { product_id } = action.payload as Product;

      const filteredCart: Product[] = state.cart.filter(
        (item) => item.product_id !== product_id
      );

      return { ...state, cart: [...filteredCart] };
    case REDUCER_ACTION_TYPE.INCREMENT_ITEM:
      if (!action.payload)
        throw new Error(`${action.payload} missing in REMOVE action`);

      const { product_id: itemId, units_available } = action.payload as Product;

      return {
        ...state,
        cart: state.cart.map((item) => {
          if (item.product_id === itemId) {
            // Validation to check if the increase exceeds the available stock
            if ((item.quantity as number) + 1 > units_available!) {
              // Shows a notification or a toast if the quantity exceeds the available stock
              infoToast(
                `La cantidad solicitada para el producto ${item.name} no está disponible en el stock. Solo hay ${units_available} unidades disponibles.`
              );
              // Do not increase if the stock is exceeded
              return item;
            }
            // Increase the quantity if it does not exceed the available stock
            return { ...item, quantity: (item.quantity as number) + 1 };
          }
          // Returns the item unchanged if it is not the item to increase
          return item;
        }),
      };
    case REDUCER_ACTION_TYPE.DECREMENT_ITEM:
      if (!action.payload)
        throw new Error(`${action.payload} missing in REMOVE action`);
      const { product_id: idItem } = action.payload as Product;
      return {
        ...state,
        cart: state.cart
          .map((item) =>
            item.product_id === idItem && (item.quantity as number) >= 1
              ? { ...item, quantity: (item.quantity as number) - 1 }
              : item
          )
          .filter((item) => item.quantity !== 0),
      };
    case REDUCER_ACTION_TYPE.CLEAR_CART:
      return {
        ...state,
        cart: [],
      };

    default:
      return state;
  }
};
