export const REDUCER_ACTION_TYPE = {
  LOAD_PRODUCTS: "LOAD_PRODUCTS",
  SET_LISTVIEW: "SET_LISTVIEW",
  SET_GRIDVIEW: "SET_GRIDVIEW",
  UPDATE_SORT: "UPDATE_SORT",
  SORT_PRODUCTS: "SORT_PRODUCTS",
  UPDATE_FILTERS: "UPDATE_FILTERS",
  FILTER_PRODUCTS: "FILTER_PRODUCTS",
  UPDATE_FILTERS_BY_PRICE: "UPDATE_FILTERS_BY_PRICE",
  UPDATE_FILTERS_BY_MATERIAL: "UPDATE_FILTERS_BY_MATERIAL",
  UPDATE_FILTERS_BY_BRAND: "UPDATE_FILTERS_BY_BRAND",
  UPDATE_FILTERS_BY_COLOR: "UPDATE_FILTERS_BY_COLOR",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  UPDATE_CURRENT_PAGE: "UPDATE_CURRENT_PAGE",
  UPDATE_TOTAL_ITEMS: "UPDATE_TOTAL_ITEMS",
  RESET_FILTERS: "RESET_FILTERS",
  UPDATE_TOTAL_PAGES: "UPDATE_TOTAL_PAGES",
  UPDATE_PAGINATION: "UPDATE_PAGINATION",
  GET_ALL_PRODUCTS_BEGIN: "GET_ALL_PRODUCTS_BEGIN",
  GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_ERROR: "GET_ALL_PRODUCTS_ERROR",
};
