import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { FC } from "react";
import CustomButton from "../Button/CustomButton";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  contentText: string;
  cancelText: string;
  confirmText: string;
  onConfirm: () => void;
}

const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  contentText,
  cancelText,
  confirmText,
  onConfirm,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              borderRadius: "50%",
              marginRight: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px",
              height: "40px",
            }}
          >
            <ReportProblemOutlinedIcon
              style={{ color: "red", fontSize: "24px" }}
            />
          </div>
          {title}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <hr className="hr-separator" />
      <DialogActions>
        <CustomButton
          onClick={onClose}
          color="outlined"
          shape="little-rounded"
          size="small"
        >
          {cancelText}
        </CustomButton>
        <CustomButton
          onClick={onConfirm}
          color="danger"
          shape="little-rounded"
          size="small"
        >
          {confirmText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
