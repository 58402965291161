import React from "react";
import { createUseStyles } from "react-jss";

function Modal({ showModal, closeModal, children, ...props }) {
  const classes = useStyles(props);

  return (
    <div>
      {showModal ? (
        <div className={classes.container}>
          <div className={classes.overlay} onClick={closeModal}></div>
          <div className={classes.content}>
            {children}
            <span className={classes.closeIcon} onClick={closeModal}>
              &times;
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

Modal.defaultProps = {
  width: "auto",
  smWidth: "100%",
  clientWidth: "40%",
};

const useStyles = createUseStyles({
  container: {
    position: "fixed",
    zIndex: 10,
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    overflow: "scroll",
  },
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.65)",
  },
  content: {
    backgroundColor: "white",
    borderRadius: "15px",
    margin: "5% auto",
    zIndex: 100,
    width: (props) => props.width,
    maxWidth: "90vw",
    position: "relative",
    boxShadow:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  },
  closeIcon: {
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FDD440",
    borderRadius: "100px",
    fontSize: "24px",
    fontWeight: "bold",
    zIndex: "inherit",
    position: "absolute",
    width: "32px",
    right: "8px",
    top: "8px",
  },
  "@media (max-width: 650px)": {
    content: {
      width: (props) => props.smWidth,
    },
  },
});

export default Modal;
