import Axios from "axios";
import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { REDUCER_ACTION_TYPE } from "../Actions/productActions";
import {
  ProductInitialState,
  productsReducer,
} from "../Reducer/ProductReducer";

const initProductContextState = {
  ...ProductInitialState,
  openSidebar: () => {},
  closeSidebar: () => {},
  fetchProducts: (categoryId: number) => {},
  fetchSingleProduct: (productId: number) => {},
};

const ProductContext = createContext(initProductContextState);

type ChildrenType = {
  children: ReactElement | ReactElement[];
};

export const ProductProvider = ({ children }: ChildrenType) => {
  const [state, dispatch] = useReducer(productsReducer, ProductInitialState);

  const openSidebar = () => {
    dispatch({ type: REDUCER_ACTION_TYPE.SIDEBAR_OPEN });
  };

  const closeSidebar = () => {
    dispatch({ type: REDUCER_ACTION_TYPE.SIDEBAR_CLOSE });
  };

  const fetchProducts = async (categoryId: number) => {
    dispatch({ type: REDUCER_ACTION_TYPE.GET_PRODUCTS_BEGIN });

    try {
      const response = await Axios.get(
        `/v2/products/category/${categoryId}/products`
      );
      const products = response.data;

      dispatch({
        type: REDUCER_ACTION_TYPE.GET_PRODUCTS_SUCCESS,
        payload: products,
      });
    } catch (error) {
      dispatch({ type: REDUCER_ACTION_TYPE.GET_PRODUCTS_ERROR });
    }
  };

  const fetchSingleProduct = async (productId: number) => {
    dispatch({ type: REDUCER_ACTION_TYPE.GET_SINGLE_PRODUCT_BEGIN });
    try {
      const response = await Axios.get(`/v2/products/${productId}`);
      const singleProduct = response.data;
      dispatch({
        type: REDUCER_ACTION_TYPE.GET_SINGLE_PRODUCT_SUCCESS,
        payload: singleProduct,
      });
    } catch (error) {
      dispatch({ type: REDUCER_ACTION_TYPE.GET_SINGLE_PRODUCT_ERROR });
    }
  };

  const fetchFeaturedProducts = async () => {
    dispatch({ type: REDUCER_ACTION_TYPE.GET_FEATURED_PRODUCTS_BEGIN });
    try {
      const response = await Axios.get(`/v2/products/offering`);
      const featuredProducts = response.data;
      dispatch({
        type: REDUCER_ACTION_TYPE.GET_FEATURED_PRODUCTS_SUCCESS,
        payload: featuredProducts,
      });
    } catch (error) {
      dispatch({ type: REDUCER_ACTION_TYPE.GET_FEATURED_PRODUCTS_ERROR });
    }
  };

  const fetchSeasonalOffers = async () => {
    dispatch({ type: REDUCER_ACTION_TYPE.GET_SEASONAL_OFFERS_BEGIN });
    try {
      const response = await Axios.get(`/v2/products/seasonal`);
      const seasonalOffers = response.data;
      dispatch({
        type: REDUCER_ACTION_TYPE.GET_SEASONAL_OFFERS_SUCCESS,
        payload: seasonalOffers,
      });
    } catch (error) {
      dispatch({ type: REDUCER_ACTION_TYPE.GET_SEASONAL_OFFERS_ERROR });
    }
  };

  useEffect(() => {
    fetchFeaturedProducts();
    fetchSeasonalOffers();
  }, []);

  return (
    <ProductContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        fetchProducts,
        fetchSingleProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProductsContext = () => {
  return useContext(ProductContext);
};
