import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

const COLORS = ["#E2CA8A", "#5D777B", "#AA8684"];
const MONTHS = [
  "Ene.",
  "Feb.",
  "Mar.",
  "Abr.",
  "May.",
  "Jun.",
  "Jul.",
  "Ago.",
  "Sep.",
  "Oct.",
  "Nov.",
  "Dic.",
];
const CURRENT_MONTH = new Date().getMonth();
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
];

/*
 * Statistics for supplier
 */
function Stats() {
  const [stats, setStats] = useState();

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    let { data } = await axios.get(`/v1/suppliers/stats`);

    data.salesPerMonth.map((month, index) => (month.monthName = MONTHS[index]));
    setStats(data);
  };

  return (
    <div className="layout-dashboard-container">
      {stats && (
        <Fragment>
          <div>
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
                padding: "10px",
                flex: 4,
              }}
            >
              <h3
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: "300",
                  marginLeft: "0.5rem",
                }}
              >
                ¡Ya llevas{" "}
                <span
                  style={{
                    fontSize: "1.17rem",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  {stats.daysSinceRegistration}
                </span>{" "}
                días en la plataforma!{" "}
                <span role="img" aria-label="party-popper">
                  🎉
                </span>
              </h3>
            </Paper>
          </div>

          <StatsRow>
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "2rem",
                margin: "10px",
                flex: 4,
              }}
            >
              <div style={{ marginLeft: "3.5rem" }}>
                <h4
                  style={{
                    fontSize: "0.75rem",
                    color: "#5a6872",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    margin: "0 0 0.5rem 0",
                  }}
                >
                  Análisis de ventas
                </h4>
              </div>
              <div style={{ width: "100%", height: "200px" }}>
                <ResponsiveContainer>
                  <LineChart
                    width={730}
                    height={250}
                    data={stats.salesPerMonth}
                    margin={{ top: 30, right: 30, left: 0, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="4 1 2" />
                    <XAxis dataKey="monthName" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="sales"
                      stroke="#3f51b5"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper
              style={{
                display: "flex",
                padding: "2rem",
                margin: "10px",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-around",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h4
                    style={{
                      fontSize: "0.75rem",
                      color: "#5a6872",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      margin: "0 0 0.5rem 0",
                    }}
                  >
                    Ventas totales
                  </h4>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1
                      style={{
                        color: "darkblue",
                      }}
                    >
                      {stats.totalUnitsSold}
                    </h1>
                    <h3
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Unidades
                    </h3>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1
                      style={{
                        color: "darkblue",
                      }}
                    >
                      ${stats.totalSold.toLocaleString("ES-es")}
                    </h1>
                    <h3
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Monto
                    </h3>
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}></div>
                <div>
                  <h4
                    style={{
                      fontSize: "0.75rem",
                      color: "#5a6872",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      margin: "0 0 0.5rem 0",
                    }}
                  >
                    Ventas mensuales
                  </h4>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1
                    style={{
                      color: "darkblue",
                    }}
                  >
                    $
                    {stats.salesPerMonth &&
                    stats.salesPerMonth[CURRENT_MONTH].amount
                      ? stats.salesPerMonth[
                          CURRENT_MONTH
                        ].amount.toLocaleString("ES-es")
                      : "0.00"}
                  </h1>
                  <h3
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "300",
                      marginLeft: "0.5rem",
                    }}
                  >
                    Monto
                  </h3>
                </div>
                <div style={{ marginTop: "2rem" }}></div>
                <Link
                  to="dashboard"
                  style={{ fontFamily: "Open Sans", fontWeight: "300" }}
                >
                  Clic para ver tus ventas
                </Link>
              </div>
            </Paper>
          </StatsRow>

          <div style={{ margin: "10px" }}>
            <TableContainer component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell align="right">Referencia</TableCell>
                    <TableCell align="right">Unidades</TableCell>
                    <TableCell align="right">Monto</TableCell>
                    <TableCell align="right">Porcentaje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stats.products.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">
                        <Avatar src={row.image} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.brand}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.units}</TableCell>
                      <TableCell align="right">
                        ${row.subtotal.toLocaleString("ES-es")}
                      </TableCell>
                      <TableCell align="right">{row.percentage}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fragment>
      )}
    </div>
  );
}

const Wrapper = styled.div``;

const StatsRow = styled.div`
  min-height: 260px;
  display: flex;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export default Stats;
