import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: "none",
  },
}));

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#7e7b7b",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    padding: 15,
  },
}))(Tooltip);

export default function TooltipLargeText({
  text,
  icon,
  color,
  fontSize,
  right = 0,
}) {
  // const classes = useStyles();
  const Icon = icon;
  return (
    <>
      <LightTooltip arrow Tooltip title={text}>
        <Icon
          style={{
            fontSize,
            color,
            position: "absolute",
            top: -20,
            right,
          }}
        />
      </LightTooltip>
    </>
  );
}
