import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TechnicalSheet } from "../../../../../../ecommerce/pages/ProductInformation/models/productDescription";
import SingleFileUploader from "../ImagesForm/SingleFileUploader";
import { useUpdateTechnicalSheet } from "../../../../../../utils/react-query/querySupplier/queries";
import { errorToast, successToast } from "../../../../../../services/toasts";

interface TechnicalSheetFormProps {
  technicalSheet: TechnicalSheet[];
  productId: number;
}

const TechnicalSheetForm = ({
  technicalSheet,
  productId,
}: TechnicalSheetFormProps) => {
  const {
    mutateAsync: updateTechnicalSheet,
    isLoading: isUpdatingTechnicalSheet,
  } = useUpdateTechnicalSheet();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      technicalSheet: technicalSheet[0],
    },
  });

  async function onSubmit(data: any) {
    const formdata = new FormData();

    formdata.append("technicalSheet", data.technicalSheet[0]);
    
    if (technicalSheet.length > 0) {
      formdata.append("id", technicalSheet[0].id.toString());
    } else {
      formdata.append("id", "");
    }
    
    const updatedTechnicalSheet = await updateTechnicalSheet({
      productId,
      technicalSheet: formdata,
    });
    if (!updatedTechnicalSheet) {
      errorToast("Error al actualizar la ficha técnica");
    }
    setIsButtonDisabled(true);

    return successToast("Ficha técnica actualizada correctamente");
  }

  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Controller
          name="technicalSheet"
          control={control}
          render={({ onChange }) => (
            <SingleFileUploader
              image={technicalSheet[0]}
              fileldChange={onChange}
              isLoading={isUpdatingTechnicalSheet}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              fileType="pdf"
            />
          )}
        />
      </form>
    </div>
  );
};
export default TechnicalSheetForm;
