import { Fragment } from "react";
import { Remodeling, Invoice } from "../types";
import { Link } from "react-router-dom";

import Button from "../../../components/Button/Button";

type InvoicesListProps = {
  remodeling: Remodeling;
  isClient: boolean;
  isDesigner: boolean;
  invoices: Invoice[];
  handleInvoiceApproval: (id: number) => void;
};

export default function InvoicesList({
  remodeling,
  isClient,
  isDesigner,
  invoices,
  handleInvoiceApproval,
}: InvoicesListProps) {

  return (
    <Fragment>
      <div className="detail-panel-container">
        {invoices.map((invoice, index) => (
          <div className="detail-panel-row" key={index}>
            <div className="file-details">
              <i className="fa fa-file-image-o" aria-hidden="true" />
              <p>{invoice.filename}</p>
            </div>
            <div className="button-container" style={{ display: "flex" }}>
              <a href={invoice.url} target="__blank">
                {/* @ts-ignore */}
                <Button
                  style={{ marginRight: "0.25rem" }}
                  size="small"
                  label="Ver"
                />
              </a>
              {isClient &&
                (invoice.is_approved === true ? (
                  <Fragment>
                    {/* @ts-ignore */}
                    <Button
                      style={{ marginRight: "0.25rem" }}
                      size="small"
                      label="Aprobado"
                      color="white"
                      backgroundColor="#39b54a"
                      disabled
                    />
                    {invoice.paid_at ? (
                      // @ts-ignore
                      <Button
                        size="small"
                        label="Pagado"
                        color="white"
                        backgroundColor="#39b54a"
                        disabled
                      />
                    ) : (
                      <Link
                        to={{
                          pathname: `/checkout2/${remodeling.hashid}`,
                          state: { client: remodeling.client },
                        }}
                      >
                        {/* @ts-ignore */}
                        <Button size="small" label="Pagar" />
                      </Link>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {/* @ts-ignore */}
                    <Button
                      style={{ marginRight: "0.25rem" }}
                      size="small"
                      label="Aprobar"
                      onClick={() => handleInvoiceApproval(invoice.id)}
                    />
                    {/* @ts-ignore */}
                    <Button size="small" label="Pagar" disabled />
                  </Fragment>
                ))}
              {isDesigner && invoice.paid_at && (
                // @ts-ignore
                <Button
                  size="small"
                  label="Pagado"
                  color="white"
                  backgroundColor="#39b54a"
                  disabled
                />
              )}
              {isDesigner && invoice.is_approved && (
                // @ts-ignore
                <Button
                  style={{ marginRight: "0.25rem" }}
                  size="small"
                  label="Aprobado"
                  color="white"
                  backgroundColor="#39b54a"
                  disabled
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
