import { BillingInformation } from "../../types";

type BillingInformationDetailProps = {
  billingInformation: BillingInformation;
};

const BillingInformationDetail = ({
  billingInformation,
}: BillingInformationDetailProps) => {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Datos de facturación</h3>
      {billingInformation.type === "natural_person" ? (
        <>
          <p>
            <span>Nombre: </span>
            {billingInformation.first_name}
          </p>
          <p>
            <span>Apellido: </span>
            {billingInformation.last_name}
          </p>
          {billingInformation.legal_document_type_id === 1 ? (
            <p>
              <span>Cédula: </span>
              {billingInformation.identification_code}
            </p>
          ) : (
            <p>
              <span>Pasaporte: </span>
              {billingInformation.identification_code}
            </p>
          )}
        </>
      ) : (
        <>
          <p>
            <span>Razón social: </span>
            {billingInformation.company_name}
          </p>
          <p>
            <span>NIT: </span>
            {billingInformation.NIT}
          </p>
        </>
      )}
    </div>
  );
};

export default BillingInformationDetail;
