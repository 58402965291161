import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { errorToast, successToast } from "../services/toasts";

const useFileUpload = (fileName: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleFileUpload = async (url: string, method: "post" | "patch") => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.onchange = async (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      const selectedFile = files?.[0];

      const formData = new FormData();
      if (selectedFile) {
        formData.append(fileName, selectedFile);
      }
      try {
        setIsLoading(true);
        const response = await axios[method](url, formData);

        if (response.status === 201) {
          method === "post"
            ? successToast("El documento se subió con éxito")
            : successToast("El documento se actualizó con éxito");
          setTimeout(() => {
            history.go(0);
          }, 1000);
        } else {
          errorToast("Ocurrió un error al subir el archivo");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    input.click();
  };

  return { isLoading, handleFileUpload };
};

export default useFileUpload;
