import axios from "axios";
import { SearchedProduct } from "../models/product.model";

// Service for fetching products filtered by by name, brand or category

export const getSearchedProducts = async (searchTerm: string) => {
  const res = await axios.get<SearchedProduct[]>(
    `/v2/products/searched-list?search=${searchTerm}`
  );
  return res.data;
};
