import Axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../services/auth";
import jwt from "jwt-decode";
import { asyncCart } from "../ecommerce/services/cartSyncService";

const useClientRegister = (onSuccess) => {
  const [referralSources, setReferralSources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setAuthToken } = useAuth();
  const [termsAndCond, setTermsAndCond] = useState(false);

  useEffect(() => {
    getReferralSources();
  }, []);

  const handleTermsAndCond = (e) => {
    setTermsAndCond(e.target.checked);
  };

  const getReferralSources = async () => {
    let response = await Axios.get(`/v1/users/sources`);
    setReferralSources(response.data);
  };

  const onRegister = async (data) => {
    try {
      setIsLoading(true);
      //Check if the email is already registered
      let dataToCheck = {
        email: data.email,
        phone: data.phone,
        cedula: null,
      };

      // when client tried to register with an existing user
      await Axios.post("/v1/users/check", dataToCheck);

      // Create a new client
      let user = await Axios.post("/v1/clients", data);

      // Set up token
      if (user) {
        setAuthToken(user.data.token);
        window.location.reload();

        // set user tokens
        let { token, ...userTokens } = user.data;
        localStorage.setItem("user", JSON.stringify(userTokens));

        let decoded = token && jwt(token);

        if (
          (decoded.role_id === 1 || decoded.role_id === 2) &&
          typeof onSuccess === "function"
        ) {
          onSuccess();
          await asyncCart();
        }
      }
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setError(requestError ? requestError : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    onRegister,
    handleTermsAndCond,
    termsAndCond,
    referralSources,
  };
};
export default useClientRegister;
