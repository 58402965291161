import axios from "axios";
import { useEffect, useState } from "react";

import MaterialTable from "material-table";
import { materialTableES } from "../../../../services/materialTableES";

import StatusChip from "../../../../components/StatusChip/StatusChip";
import { formatNumberToPrice } from "../../../../helpers/priceFormatting";
import { errorToast, successToast } from "../../../../services/toasts";
import { handleError } from "../../../../utils/utils";
import ItemActionsMenu from "./ItemActionsMenu";

function ProductTable() {
  const [data, setData] = useState();

  useEffect(() => {
    getProdcuts();
    // eslint-disable-next-line
  }, []);

  const getProdcuts = async () => {
    let res = await axios.get(`/v2/suppliers/products`);
    setData(res.data);
  };

  const handleRowDelete = async (id) => {
    const dataDelete = [...data];
    const index = id;
    try {
      const deletedProdcut = await axios.delete(`/v1/products/${id}`);

      if (deletedProdcut.status === 200) {
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        successToast("Producto eliminado con éxito.");
      }
    } catch (error) {
      handleError(error);
      errorToast("No se pudo eliminar el producto.");
    }
  };

  return (
    <div>
      <MaterialTable
        title={""}
        columns={[
          {
            title: "Estado",
            field: "is_approved",
            editable: "never",
            render: (rowData) => (
              <>
                {rowData.is_approved ? (
                  <StatusChip nameStatus="aprobado" color="green" />
                ) : (
                  <StatusChip nameStatus="pendiente" color="yellow" />
                )}
              </>
            ),
          },
          {
            title: "Imagen",
            field: "image",
            editable: "never",
            render: (rowData) => {
              if (!rowData?.principal_image[0]?.url) {
                return null;
              }
              return (
                <img
                  alt="producto"
                  src={rowData?.principal_image[0]?.url}
                  style={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                />
              );
            },
          },

          {
            title: "Nombre Producto",
            field: "name",
            editable: "never",
          },
          {
            title: "Precio",
            field: "taxed_price",
            type: "float",
            render: (rowData) => {
              return formatNumberToPrice(rowData.taxed_price) + " COP";
            },
          },

          {
            title: "Inventario",
            field: "units_available",
          },

          {
            title: "Comisión",
            field: "comission_percentage",
            lookup: {
              20: "20%",
              25: "25%",
              30: "30%",
              35: "35%",
              40: "40%",
              45: "45%",
              50: "50%",
              55: "55%",
              60: "60%",
            },
          },
          {
            title: "Categoría",
            field: "category",
          },
          {
            title: "",
            field: "",
            render: (rowData) => (
              <ItemActionsMenu
                item={rowData.product_id}
                editRoute={`/proveedor/producto/${rowData.product_id}/editar`}
                onDelete={handleRowDelete}
              />
            ),
          },
        ]}
        data={data}
        actions={[]}
        components={{}}
        isLoading={data ? false : true}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
          headerStyle: {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: "14px",
            fontWeight: 600,
            height: "4rem",
          },
          rowStyle: {
            fontSize: "14px",
            fontWeight: 400,
            color: "#1C252E",
          },
          searchFieldStyle: {
            marginBlock: "1rem",
          },
          searchFieldVariant: "outlined",
        }}
        localization={materialTableES}
      />
    </div>
  );
}

export default ProductTable;
