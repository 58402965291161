import styled from "styled-components";

import logoSVG from "../../assets/svg/logow.svg";

/**
 * Component with two columns displaying one image on the left and
 * content on the right side.
 */
function SplitScreen({
  children,
  logo,
  logoWithLink,
  overlay,
  image,
  ...props
}) {
  return (
    <StyledContainer
      id={props.id}
      ref={props.ref}
      fullheight={props.fullheight}
    >
      <Left overlay={overlay} image={image}>
        {logoWithLink && (
          <a href="https://fliping.co/">
            <img style={{ width: "9rem" }} src={logoSVG} alt="logo" />
          </a>
        )}
        <LeftContent>
          <Heading>{props.title}</Heading>
          <Paragraph>{props.description}</Paragraph>
        </LeftContent>
      </Left>
      <Right>
        <RightContent>{children}</RightContent>
      </Right>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  color: white;

  @media (min-width: 768px) {
    max-height: ${(props) => (props.fullheight ? "auto" : "800px")};
    min-height: 100vh;
    display: flex;
  }
`;

const Left = styled.div`
  overflow: auto;
  width: 100%;
  background-image: ${(props) => props.image && `url(${props.image})`};
  background-size: cover;
  box-shadow: ${(props) =>
    props.overlay ? "inset 0 0 0 2000px rgba(0, 0, 0, 0.7)" : ""};
  padding: 1rem 0;

  img {
    display: none;

    @media (min-width: 768px) {
      display: block;
      display: initial;
      position: absolute;
      width: 40px;
      top: 0;
      margin: 2rem;
    }
    @media (min-width: 768px) {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

const LeftContent = styled.div`
  margin: 1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 2rem 4rem;
  }
`;

const Heading = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  line-height: 0.8;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const Paragraph = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 0.5rem 0;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
  background-color: #f2f2f2;
`;

const RightContent = styled.div`
  margin: 4rem;

  @media (min-width: 768px) {
    width: 60%;
    max-width: 350px;
    margin: 2rem;
  }
`;

SplitScreen.defaultProps = {};

export default SplitScreen;
