import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";

import { orderStatus } from "../data/orderStatus";
import filterDataByStatus from "../utils/statusFilter";

import {
  Suborder,
  type Status,
} from "../privatePages/supplier/pages/salesManager/types";

const useFilteredData = (subordersArray: Suborder[]) => {
  const [orderStatusCounts, setOrderStatusCounts] = useState<
    Record<string, number>
  >({});
  const [selectedTab, setSelectedTab] = useState<Status>("Preparation");
  const [isDelivery, setIsDelivery] = useState(false);

  useEffect(() => {
    const newCounts: Record<string, number> = {
      [orderStatus.Preparation]: 0,
      [orderStatus.Shipped]: 0,
      [orderStatus.Delivered]: 0,
      [orderStatus.Canceled]: 0,
    };

    subordersArray.forEach((suborder) => {
      if (
        suborder.status === orderStatus.Created ||
        suborder.status === orderStatus.Preparation
      ) {
        newCounts[orderStatus.Preparation]++;
      } else {
        newCounts[suborder.status]++;
      }
    });

    setOrderStatusCounts(newCounts);
  }, [subordersArray]);

  const filteredData = useMemo(
    () => filterDataByStatus(subordersArray, selectedTab),
    [subordersArray, selectedTab]
  );

  const handleTabChange = useCallback((value: Status) => {
    setSelectedTab(value);
    setIsDelivery(value === orderStatus.Delivered);
  }, []);

  return {
    selectedTab,
    isDelivery,
    filteredData,
    handleTabChange,
    orderStatusCounts,
  };
};
export default useFilteredData;
