import React from "react";
import styled from "styled-components";

/**
 * Primary button with styling
 * @param  {string} {icon
 * @param  {HTMLElement} children
 * @param  {function} onClick}
 * Icon prop should be the name of one font awesome asset
 * See: https://fontawesome.com/v4.7.0/icons/
 */
function Button({ icon, children, onClick }) {
  return (
    <StyledButton onClick={onClick}>
      {icon && (
        <i
          className={`fa ${icon}`}
          aria-hidden="true"
          style={{ fontSize: "1rem", marginRight: "0.5rem" }}
        ></i>
      )}
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0.2rem;
  padding: 0.5rem 1.5rem;
  background-color: #fdd440;
  color: black;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: Open sans;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  text-align: center;
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    opacity: 0.8;
  }

  &:disabled: {
	cursor: not-allowed,
	border: 1px solid #999999,
	background-color: #cccccc,
	color: #666666,
  }
`;

export default Button;
