import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import styled from "styled-components";
import Button from "../../components/Button/Button";
import WompiResponseModal from "../virtualConsultancyResume/VirtualConsultancyModals/WompiResponseModal";
import CardConsultancy from "./CardConsultancy";

export default function () {
  const history = useHistory();
  const { hashid } = useParams();
  const { pathname } = useLocation();

  const [clientConsultancies, setClientConsultancies] = useState([]);
  const [statePay, setStatePay] = useState("slopes");
  const [btn1, setBtn1] = useState(true);
  const [btn2, setBtn2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Modals
  const [isShowingWompiResponseModal, setIsShowingWompiResponseModal] =
    useState(false);

  // Rating object and default values for when the modal shows up
  const [isSuccessfulPaymentWompi, setIsSuccessfulPaymentWompi] =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      getClientConsultancies();
    }, 500);
  }, []);

  useEffect(() => {
    getClientConsultancies();
    handleWompiReturn();
  }, [hashid]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleWompiReturn = async () => {
    // If user returns from Wompi payment website
    if (pathname.split("/").includes("wompi")) {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);

      // Get wompi reference id from url
      let wompiReference = urlParams.get("id");

      // Verify that the transaction is valid
      let response = await axios.get(
        `/v1/remodelings/payments/wompi/${wompiReference}`
      );
      let paymentStatus = response.data.status;

      // If response from Wompi
      if (response) {
        // Show modal with information about payment response
        if (paymentStatus === "APPROVED") {
          setIsSuccessfulPaymentWompi(true);
          setIsShowingWompiResponseModal(true);
        } else if (paymentStatus === "DECLINED") {
          setIsSuccessfulPaymentWompi(false);
          setIsShowingWompiResponseModal(true);
        }
      }
      history.push(`/cliente/asesorias/`);
    }
  };

  const getClientConsultancies = async () => {
    let res = await axios.get(`/v1/virtual-consultancy/clients`);
    setClientConsultancies(res.data.body.info);
    setIsLoading(false);
  };

  const handleButtonPaid = () => {
    setStatePay((pay) => (pay = "paid"));
    setBtn1((b) => (b = false));
    setBtn2((b) => (b = true));
  };

  const handleButtonSlopes = () => {
    setStatePay((pay) => (pay = "slopes"));
    setBtn1((b) => (b = true));
    setBtn2((b) => (b = false));
  };

  return (
    <section className="layout-dashboard-container">
      <Section>
        <h1>Asesorías Virtuales</h1>
        <WompiResponseModal
          closeModal={() => setIsShowingWompiResponseModal(false)}
          showWompiResponseModal={isShowingWompiResponseModal}
          wompiPaymentSuccess={isSuccessfulPaymentWompi}
        />
        <div>
          <Button
            style={{
              background: `${btn1 ? "#F4D03F" : "#232f3e"}`,
              color: "#fff",
              margin: "0",
              width: "7rem",
            }}
            label={"Pendientes"}
            onClick={handleButtonSlopes}
          />
          <Button
            style={{
              background: `${btn2 ? "#F4D03F" : "#232f3e"}`,
              color: "#fff",
              margin: "0",
              width: "7rem",
            }}
            label={"Pagadas"}
            onClick={handleButtonPaid}
          />
        </div>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <ContentCards>
            {statePay === "slopes" &&
              clientConsultancies
                .filter((consultancies) => consultancies.is_paid !== true)
                .map((consultancies) => (
                  <CardConsultancy
                    hashid={consultancies.hashid}
                    designer={`${consultancies.designer.first_name} ${consultancies.designer.last_name}`}
                    spaces={consultancies.space.quantity}
                    isPaid={consultancies.is_paid}
                    client={`${consultancies.client.first_name} ${consultancies.client.last_name}`}
                  />
                ))}
            {statePay === "paid" &&
              clientConsultancies
                .filter((consultancies) => consultancies.is_paid === true)
                .map((consultancies) => (
                  <CardConsultancy
                    hashid={consultancies.hashid}
                    designer={`${consultancies.designer.first_name} ${consultancies.designer.last_name}`}
                    spaces={consultancies.space.quantity}
                    isPaid={consultancies.is_paid}
                    client={`${consultancies.client.first_name} ${consultancies.client.last_name}`}
                    isApproved={consultancies.is_approved}
                  />
                ))}
          </ContentCards>
        )}
      </Section>
    </section>
  );
}

const Section = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 321px) {
    & > div {
      justify-content: center;
      gap: 2rem;
    }
  }

  @media screen and (min-width: 481px) {
    & > div {
      justify-content: flex-start;
    }
  }
`;
const ContentCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 3rem 0;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 3rem;
`;
