import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, { MouseEvent, useState } from "react";
import { useUserStore } from "../../../Store/User";
import { useCurrentProfile } from "../../../hooks/useCurrentProfile";

const ProfileMenu: React.FC = () => {
  const { startLogout } = useUserStore();
  const { profile } = useCurrentProfile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {profile && (
        <div>
          <IconButton color="inherit" onClick={handleMenuClick}>
            <Avatar alt="imagen de perfil">
              {profile?.first_name ? profile?.first_name[0] : `P`}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              style={{ padding: "10px", display: "flex", alignItems: "center" }}
            >
              <Avatar alt="imagen de perfil">
                {profile?.first_name ? profile?.first_name[0] : `P`}
              </Avatar>
              <div style={{ marginLeft: "10px" }}>
                {profile?.first_name && profile?.last_name && (
                  <div>
                    {profile?.first_name} {profile?.last_name}
                  </div>
                )}
                <div>{profile?.email}</div>
              </div>
            </div>
            <Divider />
            <MenuItem onClick={startLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              Cerrar Sesión
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};

export default ProfileMenu;
