import ChatMessage from "./ChatMessage";

const ChatMessageList = ({ chatMessages, dataChat }) => {
  return (
    <>
      {chatMessages &&
        chatMessages.map((message) => {
          return (
            <div
              key={message._id}
              className="message"
              id={message.user._id !== dataChat.currentUserId ? "you" : "other"}
            >
              <div>
                <ChatMessage
                  user={message.user.name}
                  text={message.message}
                  direction={
                    message.user._id === dataChat.currentUserId
                      ? "right"
                      : "left"
                  }
                  background={
                    message.user._id === dataChat.currentUserId
                      ? "message-background"
                      : "message-background own"
                  }
                />
              </div>
            </div>
          );
        })}
    </>
  );
};
export default ChatMessageList;
