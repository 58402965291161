import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import DesignerVirtualConsultancyCard from "./DesignerVirtualConsultancyCard";
import EmptyState from "../../assets/images/searching-data.png";
import EmptyApproved from "../../assets/images/empty-box.png";
import EmptyFinished from "../../assets/images/empty-icon3.png";
import FilterButton from "./FilterButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const VirtualConsultanciesList = () => {
  const [allConsultancies, setAllConsultancies] = useState([]);
  const [ConsultanciesOnHold, setConsultanciesOnHold] = useState([]);
  const [ConsultanciesApproved, setConsultanciesApproved] = useState([]);
  const [consultanciesFinished, setConsultanciesFinished] = useState([]);
  const [consultancyState, setConsultancyState] = useState("OnHold");
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    let response = await axios.get(
      "/v1/virtual-consultancy/professional?paid=true"
    );
    let data = await response.data.body.info;
    await setAllConsultancies(data);
    await setConsultanciesOnHold(
      data.filter((presential) => presential.is_approved === null)
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [consultancyState]);

  if (isLoading) return <CircularProgress />;
  return (
    <>
      <Container>
        <Section>
          <h1>Asesorías Virtuales</h1>
          <FilterButton
            allConsultancies={allConsultancies}
            setConsultanciesOnHold={setConsultanciesOnHold}
            setConsultanciesApproved={setConsultanciesApproved}
            setConsultanciesFinished={setConsultanciesFinished}
            consultancyState={setConsultancyState}
          />

          {consultancyState === "OnHold" && (
            <ContentCards>
              {ConsultanciesOnHold.map((presential) => (
                <DesignerVirtualConsultancyCard
                  key={presential.hashid}
                  to_pay={presential.to_pay}
                  hashid={presential.hashid}
                  date={presential.created_at}
                  client={presential.client}
                  designer={presential.designer}
                  virtualConsultancyId={presential.id}
                  openCard={false}
                  Approved={true}
                />
              ))}
              {ConsultanciesOnHold.length === 0 && (
                <EmptyStateImg>
                  <img src={EmptyState} alt="empty" />
                  <span>Aún no hay Asesorías Virtuales por aprobar</span>
                </EmptyStateImg>
              )}
            </ContentCards>
          )}

          {consultancyState === "Approved" && (
            <ContentCards>
              {ConsultanciesApproved.map((presential) => (
                <DesignerVirtualConsultancyCard
                  key={presential.hashid}
                  to_pay={presential.to_pay}
                  hashid={presential.hashid}
                  date={presential.created_at}
                  client={presential.client}
                  designer={presential.designer}
                  openCard={true}
                />
              ))}
              {ConsultanciesApproved.length === 0 && (
                <EmptyStateImg>
                  <img src={EmptyApproved} alt="empty" />
                  <span>Aún no hay Asesorías Aprobadas</span>
                </EmptyStateImg>
              )}
            </ContentCards>
          )}

          {consultancyState === "Finished" && (
            <ContentCards>
              {consultanciesFinished.map((presential) => (
                <DesignerVirtualConsultancyCard
                  key={presential.hashid}
                  to_pay={presential.to_pay}
                  hashid={presential.hashid}
                  date={presential.created_at}
                  client={presential.client}
                  designer={presential.designer}
                  openCard={false}
                />
              ))}
              {consultanciesFinished.length === 0 && (
                <EmptyStateImg>
                  <img src={EmptyFinished} alt="empty" />
                  <span>Aún no hay Asesorías Virtuales terminadas</span>
                </EmptyStateImg>
              )}
            </ContentCards>
          )}
        </Section>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto 0 auto;
`;

const EmptyStateImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: rgb(175, 172, 172);
  }

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 481px) {
    width: 80%;
  }
  @media screen and (min-width: 769px) {
    width: 40%;
  }
`;
const Section = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-size: 1.2rem;
    @media screen and (min-width: 481px) {
      font-size: 2rem;
    }
  }
`;
const ContentCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export default VirtualConsultanciesList;
