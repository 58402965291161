const fileTypes = {
  profileImage: 1,
  portfolioImage: 2,
  CV: 3,
  remodelingDesign: 4,
  remodelingInvoice: 5,
  remodelingTechnicalDoc: 6,
  remodelingLegalDoc: 7,
  productImage: 8,
  productAmbientImage: 9,
  productTechnicalSheet: 10,
  productInstallationSheet: 11,
  supplierRUT: 12,
  supplierChamberOfCommerce: 13,
  supplierReceiptData: 14,
  virtualConsultancyDesign: 15,
  consultancyLegalDoc: 16,
  consultancySharedFile: 17,
  presentialConsultancyInvoice: 18,
};

module.exports = fileTypes;
