import { createContext, ReactElement } from "react";
import { REDUCER_ACTION_TYPE } from "../Actions";
import { useCartContext } from "../hooks";
import { UseCartContextType } from "../models";
import { CartInitialState } from "../Reducer";

const initCartContextState: UseCartContextType = {
  dispatch: () => {},
  REDUCER_ACTIONS: REDUCER_ACTION_TYPE,
  totalItems: 0,
  totalPrice: 0,
  designerDiscount: 0,
  cart: [],
};

export const CartContext =
  createContext<UseCartContextType>(initCartContextState);

interface ChildrenType {
  children: ReactElement | ReactElement[];
}

export const CartProvider = ({ children }: ChildrenType): ReactElement => {
  return (
    <CartContext.Provider value={useCartContext(CartInitialState)}>
      {children}
    </CartContext.Provider>
  );
};
