import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Image } from "../../../../../../ecommerce/models";
import { errorToast, successToast } from "../../../../../../services/toasts";
import { useUpdateImages } from "../../../../../../utils/react-query/querySupplier/queries";
import MultipleFileUploader from "./MultipleFileUploader";

type AmbientImagesProps = {
  images: Image[];
  productId: number;
};

const AmbientImagesForm = ({ images, productId }: AmbientImagesProps) => {
  const [deletedImages, setDeletedImages] = useState<Image[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { mutateAsync: updateImages, isLoading: isUpdatingImages } =
    useUpdateImages();

  const handleDeleteImage = (id: number) => {
    const imageToDelete = images.find((image) => image.id === id);
    if (imageToDelete) {
      setDeletedImages((prevDeletedImages) => [
        ...prevDeletedImages,
        imageToDelete,
      ]);
    }
  };

  const { handleSubmit, control } = useForm({
    defaultValues: {
      ambientImages: [],
    },
  });

  async function onSubmit(data: any) {
    const formDataForRequest = new FormData();

    data.ambientImages.forEach((image: any) => {
      formDataForRequest.append("ambientImages", image);
    });

    // convert to string for compatibility
    deletedImages.forEach((image, index) => {
      formDataForRequest.append(
        `deletedImages[${index}]`,
        JSON.stringify(image)
      );
    });

    try {
      await updateImages({
        productId,
        formDataForRequest,
      });

      setIsButtonDisabled(true);
      setDeletedImages([]);
      return successToast("Imágenes actualizadas correctamente");
    } catch (error) {
      return errorToast(
        "Hubo un problema al actualizar las imágenes. Por favor, inténtalo de nuevo."
      );
    }
  }
  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Controller
          name="ambientImages"
          control={control}
          render={({ onChange }) => (
            <MultipleFileUploader
              images={images}
              fileldChange={onChange}
              isLoading={isUpdatingImages}
              onDeleteImage={handleDeleteImage}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              setDeletedImages={setDeletedImages}
            />
          )}
        />
      </form>
    </div>
  );
};
export default AmbientImagesForm;
