const ChatMessage = ({ text, background }) => {
  // TODO: Improve css style for this Message component
  if (text.includes("https://")) {
    text = text.substring(0, 150) + " ...";
    return (
      <div className={background}>
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          {text}
        </a>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: "700px", width: "100%" }} className={background}>
      <p className="message__text">{text}</p>
    </div>
  );
};

export default ChatMessage;
