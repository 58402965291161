import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../components/Button/ButtonConsultancy.style";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

const PopupVirtualConsultancy = ({ closeModal, role }) => {
  return (
    <Container>
      <h1>¡ Bienvenido a Fliping !</h1>
      <Content>
        <p>
          Bienvenido a la sección donde podrás hacer seguimiento de toda tu
          asesoría virtual.
        </p>
        {role === 1 && (
          <p>
            Para comunicarte con tu profesional puedes escribirle por el chat
            <SpeakerNotesIcon />
          </p>
        )}
        {role === 2 && (
          <p>
            Para comunicarte con tu cliente puedes escribirle por el chat
            <SpeakerNotesIcon />
          </p>
        )}

        {role === 1 && (
          <p>
            Si tu profesional no está conectado, oprime
            {
              <FontAwesomeIcon
                icon="fa-solid fa-hand-back-fist"
                style={{
                  fontSize: "1.5rem",
                  color: "#FDD440",
                }}
              />
            }{" "}
            para notificarle que tú estás en línea y quieres comunicarte con él.
          </p>
        )}
        {role === 2 && (
          <p>
            Si tu cliente no está conectado, oprime
            {
              <FontAwesomeIcon
                icon="fa-solid fa-hand-back-fist"
                style={{
                  fontSize: "1.5rem",
                  color: "#FDD440",
                }}
              />
            }{" "}
            para notificarle que tú estás en línea y quieres comunicarte con él.
          </p>
        )}

        <Button onClick={closeModal} bgColor={"#3498DB"}>
          OK,entiendo
        </Button>
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  & > p {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
`;
const Container = styled.div`
  width: 90%;
  height: auto;
  margin: auto;
  padding: 3rem 0;

  h1 {
    color: #808080;
  }
`;

export default PopupVirtualConsultancy;
