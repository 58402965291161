import { Invoice } from "../types";

type TrackingLinksListProps = {
  invoices: Invoice[];
};

export default function TrackingLinksList({invoices}: TrackingLinksListProps) {
  let urls: string[] = [];
  for (let i = 0; i < invoices.length; i++) {
    if (invoices[i].trackings.length > 0) {
      invoices[i].trackings.map((tracking) => urls.push(tracking.url));
    }
  }
  return (
    <div className="detail-panel-container">
      {urls.map((link, index) => {
        return (
          <div className="detail-panel-row" key={index}>
            <div className="file-details">
              <i className="fa fa-external-link" aria-hidden="true" />
              <a href={link} target="__blank">
                {link}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};
