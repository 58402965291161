import React, { useEffect, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { materialTableES } from "../../services/materialTableES";
import { handleError } from "../../utils/utils";

function BrandsManagement() {
  const [data, setData] = useState();

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    let res = await axios.get("/v1/suppliers/brands");
    setData(res.data);
  };

  return (
    <section className="layout-dashboard-container">
      <MaterialTable
        title={"Marcas"}
        columns={[
          { title: "Nombre", field: "name" },
          {
            title: "Tipo",
            field: "product_type_id",
            lookup: {
              1: "revestimiento",
              2: "decorativo",
            },
          },
        ]}
        data={data}
        editable={{
          onRowAdd: async (newData) => {
            await axios
              .post(`/v1/suppliers/brands/`, newData)
              .then((res) => {
                setData([newData, ...data]);
              })
              .catch((error) => {
                handleError(error);
              });
          },
          onRowUpdate: async (newData, oldData) => {
            let editableFields = {
              name: newData.name,
              product_type_id: newData.product_type_id,
            };
            await axios
              .patch(`/v1/brands/${oldData.id}`, editableFields)
              .then((res) => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
              })
              .catch((error) => {
                handleError(error);
              });
          },
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                axios
                  .delete(`/v1/brands/${oldData.id}`)
                  .then((res) => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                  })
                  .catch((error) => {
                    handleError(error);
                  });
                resolve();
              }, 1000);
            }),
        }}
        loading={data ? false : true}
        localization={materialTableES}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
        }}
      />
    </section>
  );
}

export default BrandsManagement;
