import axios from "axios";
import { Dispatch, useEffect, useMemo, useReducer } from "react";
import { type Product } from "../../../ecommerce/models";
import { useAuth } from "../../../services/auth";
import { REDUCER_ACTION_TYPE } from "../Actions";
import { CartActionType, CartStateType, doInitializeCart } from "../models";
import { cartReducer } from "../Reducer";
import { handleError } from "../../../utils/utils";

const useCartContext = (initCartState: CartStateType) => {
  const [state, dispatch] = useReducer(cartReducer, initCartState);
  const { currentRoleId } = useAuth();
  let cart: Product[] | undefined;

  const getCartFromBackend = async () => {
    const { data } = await axios.get("/v2/cart");
    return data;
  };

  // function to calculate the discount for the cart
  const calculateDiscountForCart = (cart: Product[]) => {
    let totalDiscount = 0;

    cart.forEach((product) => {
      // Calculate the discount factor
      const discountFactor = product?.comission_percentage / 100 / 2;
      // Calculate the discount for the product
      const discount = discountFactor * product.taxed_price * product.quantity;
      totalDiscount += discount;
    });

    return totalDiscount;
  };

  const transformCartDataToProducts = (cartData: any) => {
    return cartData.map((item: any) => {
      return {
        product_id: item.product_id,
        cart_item_id: item.cart_item_id,
        name: item.name,
        taxed_price: item.taxed_price,
        url: item.url,
        min_sale_unit: item.min_sale_unit,
        presentation: item.presentation,
        quantity: item.quantity,
        category: item.category,
        comission_percentage: item.comission_percentage,
        principal_image: item.principal_image,
      };
    });
  };

  const initializeCartFromLocalStorage = (
    dispatch: Dispatch<CartActionType>
  ) => {
    const cartFromLocalStorage = localStorage.getItem("cart");
    if (cartFromLocalStorage) {
      const products: Product[] = JSON.parse(cartFromLocalStorage);
      dispatch(doInitializeCart(products));
    }
  };

  const startInitializingCart = async () => {
    try {
      if (currentRoleId && (currentRoleId === 1 || currentRoleId === 2)) {
        const cartData = await getCartFromBackend();
        const products = transformCartDataToProducts(cartData);

        // Calcular y agregar el descuento a cada producto

        dispatch(doInitializeCart(products));
        localStorage.setItem("cart", JSON.stringify(products));
      } else {
        initializeCartFromLocalStorage(dispatch);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    startInitializingCart();
    // eslint-disable-next-line
  }, [cart]);

  const REDUCER_ACTIONS = useMemo(() => {
    return REDUCER_ACTION_TYPE;
  }, []);

  const totalItems: number = state.cart?.reduce((previousValue, cartItem) => {
    return previousValue + (cartItem.quantity as number);
  }, 0);

  const totalPrice: number = state.cart?.reduce((previousValue, cartItem) => {
    return previousValue + (cartItem.quantity as number) * cartItem.taxed_price;
  }, 0);

  const designerDiscount: number = calculateDiscountForCart(state.cart || []);

  cart = state.cart?.sort((a, b) => {
    const itemA = Math.floor(a.cart_item_id % 10000);
    const itemB = Math.floor(b.cart_item_id % 10000);
    return itemA - itemB;
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return {
    dispatch,
    REDUCER_ACTIONS,
    totalItems,
    totalPrice,
    designerDiscount,
    cart,
  };
};

export default useCartContext;
