import { yupResolver } from "@hookform/resolvers";
import FontPicker from "font-picker-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { ecommercePreferencesSchema } from "../../../../../../services/formValidation";
import { successToast } from "../../../../../../services/toasts";
import { useUpdateEcommercePreferences } from "../../../../../../utils/react-query/querySupplier/queries";
import { handleError } from "../../../../../../utils/utils";
import { type SupplierECommercePreferences } from "../../../../types";
import LogoUploader from "../LogoUploader/LogoUploader";
import { Paper } from "@material-ui/core";
import InputValidationMessage from "../../../../../../components/forms/InputValidationMessage/InputValidationMessage";

type EcommercePreferencesProps = {
  ecommPrefs: SupplierECommercePreferences;
};

const BrandingPreferences = ({ ecommPrefs }: EcommercePreferencesProps) => {
  const [activeFontFamily, setActiveFontFamily] = useState(
    ecommPrefs.company_font_type === null
      ? "Open Sans"
      : ecommPrefs.company_font_type
  );

  const {
    mutateAsync: updateEcommercePreferences,
    isLoading: isUpdatingEcommercePreferences,
  } = useUpdateEcommercePreferences();

  const apiKey = process.env.REACT_APP_GOOGLE_FONTS_API_KEY;

  const { register, handleSubmit, control, watch, errors } = useForm({
    resolver: yupResolver(ecommercePreferencesSchema),
    defaultValues: {
      logoFile: [],
      company_primary_color: ecommPrefs ? ecommPrefs.company_primary_color : "",
      company_secondary_color: ecommPrefs
        ? ecommPrefs.company_secondary_color
        : "",
      company_font_type: ecommPrefs ? ecommPrefs.company_font_type : "",
    },
  });

  // Use the watch method to observe changes to the input values
  const companyPrimaryColor = watch("company_primary_color");
  const companySecondaryColor = watch("company_secondary_color");

  const onSubmit = async (data: any) => {
    try {
      let formData = new FormData();
      formData.append("company_primary_color", data.company_primary_color);
      formData.append("company_secondary_color", data.company_secondary_color);
      formData.append("company_font_type", data.company_font_type);

      if (data.logoFile.length > 0) {
        formData.append("companyLogo", data.logoFile[0]);
      }

      const updatedEcommercePreferences = await updateEcommercePreferences({
        ecommerceData: formData,
      });
      updatedEcommercePreferences &&
        successToast("Preferencias de E-commerce actualizadas exitosamente");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="preferences-container">
      <Paper className="paper-container">
        <h1 className="preferences-heading">Preferencias De Marca</h1>
        <hr className="hr-separator" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <div className="preferences-input-section">
            <h3 className="preferences-subtitle">Logo de la Empresa</h3>
            <p className="preferences-description">
              Sube el logo de tu empresa para que se muestre en tu sitio web.
              Asegúrate de que el logo sea claro y esté en formato PNG
            </p>
            <Controller
              name="logoFile"
              control={control}
              render={({ onChange }) => (
                <LogoUploader
                  fieldChange={onChange}
                  mediaUrl={ecommPrefs?.company_logo?.url}
                />
              )}
            />
          </div>
          <hr className="hr-separator" />
          <div className="preferences-input-section">
            <h3 className="preferences-subtitle">Colores de la Empresa</h3>
            <p className="preferences-description">
              Selecciona los colores primario y secundario que se utilizarán en
              tu sitio web. Estos colores definirán la paleta de tu marca y se
              aplicarán en varios elementos de la interfaz.
            </p>
            <div className="color-content">
              <div>
                <label>Color Primario: </label>
                <input
                  type="color"
                  name="company_primary_color"
                  ref={register}
                  id="company_primary_color"
                />
                <div
                  className="color-picker"
                  onClick={() =>
                    document?.getElementById("company_primary_color")?.click()
                  }
                >
                  <div
                    className="color-picker-square"
                    style={{
                      backgroundColor: companyPrimaryColor,
                    }}
                  />
                  <span>{companyPrimaryColor}</span>
                </div>
              </div>
              <div>
                <label>Color Secundario: </label>
                <input
                  type="color"
                  name="company_secondary_color"
                  ref={register}
                  id="company_secondary_color"
                />
                <div
                  className="color-picker"
                  onClick={() =>
                    document?.getElementById("company_secondary_color")?.click()
                  }
                >
                  <div
                    className="color-picker-square"
                    style={{
                      backgroundColor: companySecondaryColor,
                    }}
                  />
                  <span>{companySecondaryColor}</span>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-separator" />
          <div className="preferences-input-section">
            <h3 className="preferences-subtitle">Tipografía de la Empresa</h3>
            <p className="preferences-description">
              Elige la fuente que se utilizará en tu sitio web. Selecciona una
              tipografía que refleje la personalidad de tu marca y sea legible
              en todos los dispositivos.
            </p>
            <label>Fuente:</label>
            <Controller
              name="company_font_type"
              control={control}
              render={({ onChange, value }) => (
                <FontPicker
                  apiKey={apiKey!}
                  activeFontFamily={value || activeFontFamily}
                  onChange={(nextFont) => {
                    setActiveFontFamily(nextFont.family);
                    onChange(nextFont.family);
                  }}
                  limit={500}
                />
              )}
            />
            <InputValidationMessage
              message={errors?.company_font_type?.message}
            />
          </div>
          <hr className="hr-separator" />
          <CustomButton
            color="secondary"
            type="submit"
            size="medium"
            shape="little-rounded"
            isLoading={isUpdatingEcommercePreferences}
          >
            Guardar cambios
          </CustomButton>
        </form>
      </Paper>
    </div>
  );
};

export default BrandingPreferences;
