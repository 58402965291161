import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  commonStyles: {
    borderRadius: 6,
    height: 24,
    minWidth: 24,
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
    letterSpacing: "0.5px",
    whiteSpace: "nowrap",
    alignItems: "center",
    justifyContent: "center",
  },
  green: {
    color: "rgb(17, 141, 87)",
    backgroundColor: "rgba(34, 197, 94, 0.16)",
  },
  yellow: {
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)",
  },
  red: {
    color: "rgb(183, 29, 24)",
    backgroundColor: "rgba(255, 86, 48, 0.16)",
  },
  grey: {
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgba(145, 158, 171, 0.16)",
  },
}));

interface CustomPaymentChipProps {
  nameStatus: string;
  color: "yellow" | "green" | "red" | "grey";
}

const StatusChip = ({ nameStatus, color }: CustomPaymentChipProps) => {
  const classes = useStyles();
  const chipClass = clsx(classes.commonStyles, classes[color]);

  return <Chip label={nameStatus} className={chipClass} />;
};

export default StatusChip;
