export const REDUCER_ACTION_TYPE = {
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  INCREMENT_ITEM: "INVREMENT_ITEM",
  DECREMENT_ITEM: "DECREMENT_ITEM",
  CLEAR_CART: "CLEAR_CART",
  LOAD_CART: "LOAD_CART",
  ADD_QUANTITY: "ADD_QUANTITY",
  INITIALIZE_CART: "INITIALIZE_CART",
};
