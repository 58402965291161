import { amber, green, red } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import { materialTableES } from "../../services/materialTableES.js";
import { useStyles } from "../../services/muiStyles";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import MaterialTable, { MTableToolbar } from "material-table";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";

import axios from "axios";
import { errorToast, successToast } from "../../services/toasts";
import { handleError } from "../../utils/utils";

function isApprovedIcons(field) {
  switch (field) {
    case true:
      return (
        <CheckCircleIcon style={{ color: green[500] }} titleAccess="Aprobado" />
      );
    case false:
      return <CancelIcon style={{ color: red[500] }} titleAccess="Declinado" />;
    default:
      return (
        <HourglassEmptyIcon
          style={{ color: amber[500] }}
          titleAccess="En proceso..."
        />
      );
  }
}

function DesignerManagement() {
  const classes = useStyles();
  const [approvalScreen, setApprovalScreen] = useState(false);
  const [designers, setDesigners] = useState([]);
  const [detailData, setDetailData] = useState();
  const [filteredDesigners, setFilteredDesigners] = useState([]);
  const [showRegistrationDate, setShowRegistrationDate] = useState(true);
  const calculateRating = (num) => {
    return (num / 5) * 100 + "%";
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await axios.get("/v1/designers");
    setDesigners(res.data);
    setFilteredDesigners(res.data);
  };

  const toggleIsPublic = async (rowData) => {
    let isActive = rowData.is_active !== true ? false : true;

    let copyDesigners = [...designers];
    let index = copyDesigners.indexOf(rowData);
    copyDesigners[index].is_active = !isActive;
    setDesigners(copyDesigners);

    await axios.patch(`/v1/users/${rowData.uuid}`, {
      is_public: !isActive,
    });
  };

  const handleApproval = async (rowData, uuid, isApproved) => {
    try {
      let copyDesigners = [...designers];
      let index = copyDesigners.indexOf(rowData);
      copyDesigners[index].is_approved = isApproved;
      setDesigners(copyDesigners);

      await axios.patch(`/v1/users/${uuid}?emailDesigner=true`, {
        is_approved: isApproved,
      });

      successToast(
        `Has ${isApproved ? "aprobado" : "rechazado"} al diseñador ${
          rowData.first_name
        } ${rowData.last_name} exitosamente`
      );
    } catch (error) {
      errorToast(error.response.data.message);
      handleError(error);
    }
  };

  // Variables and functions for filter buttons
  const setAllDesigners = () => {
    setFilteredDesigners(designers);
  };

  let getPendingApprovalDesigners = designers.filter(
    (designer) => designer.is_approved === null
  );

  let getApprovedDesigners = designers.filter(
    (designer) => designer.is_approved === true
  );

  const setApprovedDesigners = () => {
    setFilteredDesigners(getApprovedDesigners);
  };

  let getRejectedDesigners = designers.filter(
    (designer) => designer.is_approved === false
  );

  const setRejectedDesigners = () => {
    setFilteredDesigners(getRejectedDesigners);
  };

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Chip
            style={{
              color: "#112A36",
              backgroundColor: "#E2CA8A",
              marginRight: 10,
            }}
            label="Todos"
            clickable={true}
            onClick={() => {
              setApprovalScreen(false);
              setAllDesigners();
              setShowRegistrationDate(true);
            }}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#5D777B",
              marginRight: 10,
            }}
            label="Por aprobar"
            avatar={
              <Avatar
                style={{
                  color: "white",
                  border: "1px solid white",
                  backgroundColor: "#5D777B",
                }}
              >
                {getPendingApprovalDesigners.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(true);
              setShowRegistrationDate(false);
            }}
          />
          <Chip
            style={{
              color: "#112A36",
              backgroundColor: "#E2CA8A",
              marginRight: 10,
            }}
            label="Aprobados"
            avatar={
              <Avatar
                style={{
                  color: "#112A36",
                  border: "1px solid white",
                  backgroundColor: "#E2CA8A",
                }}
              >
                {getApprovedDesigners.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(false);
              setApprovedDesigners();
              setShowRegistrationDate(false);
            }}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#AA8684",
              marginRight: 10,
            }}
            label="Rechazados"
            avatar={
              <Avatar
                style={{
                  color: "white",
                  border: "1px solid white",
                  backgroundColor: "#AA8684",
                }}
              >
                {getRejectedDesigners.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(false);
              setRejectedDesigners();
              setShowRegistrationDate(false);
            }}
          />
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      field: "avatar",
      render: (rowData) => (
        <img
          alt="diseñador"
          src={rowData.avatar}
          style={{
            width: 40,
            height: 40,
            objectFit: "cover",
            borderRadius: "100%",
          }}
        />
      ),
      export: false,
    },
    {
      title: "Fecha de Inscripción",
      type: "date",
      field: "created_at",
      hidden: !showRegistrationDate ? true : false,
    },
    {
      title: "Nombres",
      field: "first_name",
      render: (rowData) => `${rowData.first_name}`,
    },
    {
      title: "Apellidos",
      field: "last_name",
      render: (rowData) => `${rowData.last_name}`,
    },
    { title: "Teléfono", field: "phone" },
    { title: "Correo", field: "email" },
    { title: "Ciudad", field: "city" },
    {
      title: "Fecha de solicitud",
      field: "created_at",
      type: "date",
      hidden: approvalScreen ? false : true,
    },
    {
      title: "Solicitud",
      field: "is_approved",
      editable: "never",
      render: function (rowData, i) {
        if (!approvalScreen) {
          return isApprovedIcons(rowData.is_approved);
        } else if (approvalScreen === true && rowData.is_approved === null) {
          return (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                label="Aprobar"
                onClick={() => {
                  handleApproval(rowData, rowData.uuid, true);
                }}
              />
              <Button
                size="small"
                label="Rechazar"
                onClick={() => {
                  handleApproval(rowData, rowData.uuid, false);
                }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Estado",
      field: "is_public",
      width: "0px",
      editable: "never",
      hidden: approvalScreen ? true : false,
      render: function (rowData) {
        let isPublic = rowData.is_public === true ? true : false;
        return (
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={rowData.is_active}
            onChange={(event) => {
              return toggleIsPublic(rowData);
            }}
            name={rowData.uuid}
          />
        );
      },
    },
  ];

  return (
    <section className="layout-dashboard-container">
      <div>
        <Modal
          showModal={detailData ? true : false}
          closeModal={() => setDetailData()}
          contentMaxWidth={"400px"}
        >
          <DesignerDetail data={detailData} />
        </Modal>
        <MaterialTable
          title={approvalScreen ? "Solicitudes pendientes" : "Diseñadores"}
          columns={tableColumns}
          data={
            approvalScreen ? getPendingApprovalDesigners : filteredDesigners
          }
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <TableHeader />
              </div>
            ),
          }}
          localization={materialTableES}
          isLoading={designers.length > 0 ? false : true}
          actions={[
            {
              icon: () => <ZoomInIcon />,
              tooltip: "Detalle",
              onClick: (event, rowData) => {
                setDetailData(rowData);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            actionsCellStyle: {
              paddingRight: 20,
            },
            exportButton: true,
            pageSizeOptions: [5, 10, 50, 100, 300, 500],
          }}
        />
      </div>
    </section>
  );
}

// POP-UP with professional profile data when loupe icon is clicked
const DesignerDetail = ({ data }) => {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información básica</h3>
      <span>
        <i>Nombre.</i> {`${data.first_name} ${data.last_name}`}
      </span>
      <span>
        <i>Correo.</i> {data.email}
      </span>
      <span>
        <i>Cédula.</i> {data.cedula}
      </span>
      <span>
        <i>Celular.</i> {data.phone}
      </span>
      <span>
        <i>Dirección.</i> {data.address}
      </span>
      <span>
        <i>Ciudad.</i> {data.city}
      </span>
      <span>
        <i>Bio.</i> {data.bio}
      </span>
      {data.university_name && (
        <span>
          <i>Institución en la que se graduó:</i> {data.university_name}
        </span>
      )}
      {data.graduation_year && (
        <span>
          <i>Año de graduación:</i> {data.graduation_year}
        </span>
      )}
      {data.socials && (
        <span>
          <i>Instagram:</i> {data.socials.instagram}
        </span>
      )}

      <h3 style={{ marginTop: 10 }}>Cuenta bancaria</h3>
      {data.bank && data.bank ? (
        <div>
          <span>
            <i>Banco:</i> {data.bank.bank_name} <br />
            <i>Titular de la cuenta:</i> {data.bank.account_holder_name} <br />
            {data.bank.is_savings_account ? (
              <i>Cuenta de ahorros</i>
            ) : (
              <i>Cuenta corriente</i>
            )}
            <br />
            <i>Número de la cuenta:</i> {data.bank.account_number} <br />
          </span>
        </div>
      ) : (
        <span>
          <i>Este profesional aún no ha actualizado sus datos bancarios</i>
        </span>
      )}

      <h3 style={{ marginTop: 10 }}>Documentos</h3>
      {data.avatar && (
        <a href={data.avatar} target="__blank">
          Imagen de perfil
        </a>
      )}
      {data.curriculum && (
        <a href={data.curriculum} target="__blank">
          Hoja de vida
        </a>
      )}
      {data.portfolio &&
        data.portfolio.map((image, i) => {
          return (
            <a href={image.url} target="__blank">{`Imagen de portafolio #${
              i + 1
            }`}</a>
          );
        })}
    </div>
  );
};

export default DesignerManagement;
