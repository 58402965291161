import useFetch from "../../../../../ecommerce/hooks/useFetch";
import { Order } from "../types";

export const useFetchOrder = (orderId: number) => {
  const url = `/v2/suppliers/orders/${orderId}`;
  const {
    isLoading,
    apiData: orders,
    apiError: error,
  } = useFetch<Order>({
    method: "GET",
    url,
  });

  return { isLoading, orders, error };
};
