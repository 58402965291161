import { CircularProgress, IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { PostAdd as PostAddIcon } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import useFileUpload from "../../hooks/useFileUpload";
import { File } from "../../privatePages/supplier/pages/PurchasesSupplierTable/types";
import styles from "./DocumentControls.module.scss";

interface DocumentControlsProps {
  fileData: File[];
  orderId: number;
  supplierId?: number;
  userType: "supplier" | "admin" | "customer";
  fileName: "invoice" | "paymentSupport";
}

const DocumentControls = ({
  fileData,
  orderId,
  supplierId,
  userType,
  fileName,
}: DocumentControlsProps) => {
  const { isLoading, handleFileUpload } = useFileUpload(fileName);

  const handleCreateButtonClick = () => {
    if (userType === "supplier") {
      handleFileUpload(`/v2/suppliers/orders/${orderId}/invoice`, "post");
    } else if (userType === "admin") {
      handleFileUpload(`/v2/admin/orders/${orderId}/${supplierId}`, "post");
    } else if (userType === "customer") {
      handleFileUpload(`/v2/admin/clientOrder/${orderId}/invoice`, "post");
    }
  };

  const handleEditButtonClick = (fileId: number) => {
    if (userType === "supplier") {
      handleFileUpload(`/v2/suppliers/orders/${fileId}`, "patch");
    } else if (userType === "admin") {
      handleFileUpload(`/v2/admin/orders/${fileId}`, "patch");
    } else if (userType === "customer") {
      handleFileUpload(`/v2/admin/clientOrder/${fileId}`, "patch");
    }
  };

  return (
    <div className={styles.documentActions}>
      {fileData.length > 0 ? (
        <Tooltip title="Editar Documento" placement="top">
          <IconButton
            aria-label="delete"
            disabled={isLoading}
            color="primary"
            style={{ border: "1px solid #3f51b5", padding: "0.2rem" }}
            onClick={() => handleEditButtonClick(fileData[0].id)}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Agregar Documento" placement="top">
          <IconButton
            aria-label="delete"
            disabled={isLoading}
            color="primary"
            style={{ border: "1px solid #3f51b5", padding: "0.2rem" }}
            onClick={handleCreateButtonClick}
          >
            <PostAddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {fileData.length > 0 ? (
        <a
          className={styles.uploadedText}
          href={fileData[0].url}
          target="_blank"
          rel="noreferrer"
        >
          {isLoading ? <CircularProgress size={13} /> : "Ver documento"}
        </a>
      ) : (
        <p className={styles.notUploadedText}>
          {isLoading ? <CircularProgress size={13} /> : "Sin archivo adjunto"}
        </p>
      )}
    </div>
  );
};

export default DocumentControls;
