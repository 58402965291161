import { REDUCER_ACTION_TYPE } from "../Actions";
import { Product } from "../../../ecommerce/models/product.model";

export type CartActionType =
  | { type: typeof REDUCER_ACTION_TYPE.ADD_ITEM; payload: Product }
  | { type: typeof REDUCER_ACTION_TYPE.REMOVE_ITEM; payload?: Product }
  | { type: typeof REDUCER_ACTION_TYPE.INCREMENT_ITEM; payload?: Product }
  | { type: typeof REDUCER_ACTION_TYPE.DECREMENT_ITEM; payload?: Product }
  | { type: typeof REDUCER_ACTION_TYPE.CLEAR_CART; payload?: undefined }
  | { type: typeof REDUCER_ACTION_TYPE.LOAD_CART; payload?: Product }
  | { type: typeof REDUCER_ACTION_TYPE.INITIALIZE_CART; payload?: Product[] };

export const doAddItem = (item: Product): CartActionType => ({
  type: REDUCER_ACTION_TYPE.ADD_ITEM,
  payload: item,
});

export const doRemoveItem = (item: Product): CartActionType => ({
  type: REDUCER_ACTION_TYPE.REMOVE_ITEM,
  payload: item,
});

export const doIncrementItem = (item: Product): CartActionType => ({
  type: REDUCER_ACTION_TYPE.INCREMENT_ITEM,
  payload: item,
});

export const doDecrementItem = (item: Product): CartActionType => ({
  type: REDUCER_ACTION_TYPE.DECREMENT_ITEM,
  payload: item,
});

export const doClearCart = (): CartActionType => ({
  type: REDUCER_ACTION_TYPE.CLEAR_CART,
});

export const doInitializeCart = (cart: Product[]): CartActionType => ({
  type: REDUCER_ACTION_TYPE.INITIALIZE_CART,
  payload: cart,
});
