import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { Paper, useMediaQuery, useTheme } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { editProductTabs } from "../../../../constants";
import { useGetProductById } from "../../../../utils/react-query/queryEcommerce/queries";

import styles from "./productEditPage.module.scss";
import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import {
  GeneralInfoForm,
  ImagesForm,
  MeasuresForm,
  PackagingMeasuresForm,
  TechnicalSheetForm,
} from "./components";

const ProductEditPage = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState("general");
  const { data: product, isLoading } = useGetProductById(+id);

  // Function to change the active tab
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Paper className={styles.productEditNav}>
        <div className={styles.productEditContent}>
          <Link to="/proveedor/producto/lista">
            <div className={styles.btnBack}>
              <ArrowBackIcon fontSize="small" />
            </div>
          </Link>
          <div className={styles.productEditText}>
            <span>Regresar a la lista</span>
            <h4>Editar Producto</h4>
          </div>
        </div>

        <Tabs
          className={styles.productEditTabs}
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobileOrTablet ? "scrollable" : "standard"}
          scrollButtons="on"
          centered
        >
          {editProductTabs.map((editTab) => (
            <Tab
              key={editTab.id}
              label={editTab.label}
              value={editTab.id}
              style={{ minWidth: "auto", textTransform: "capitalize" }}
            />
          ))}
        </Tabs>
      </Paper>

      <div className="layout-dashboard-container">
        {activeTab === "general" && <GeneralInfoForm product={product} />}
        {activeTab === "measurements" && (
          <MeasuresForm
            dimensions={product.dimensions}
            categoryId={product.category_id}
            ProductId={product.product_id}
          />
        )}
        {activeTab === "packagingMasures" && (
          <PackagingMeasuresForm product={product} />
        )}
        {activeTab === "images" && (
          <ImagesForm
            images={product.ambient_images}
            principalImage={product.principal_image}
            productId={product.product_id}
          />
        )}
        {activeTab === "technicalSheet" && (
          <TechnicalSheetForm
            technicalSheet={product.technical_sheet}
            productId={product.product_id}
          />
        )}
      </div>
    </>
  );
};

export default ProductEditPage;
