import { useState } from "react";
import CustomMaterialTable from "../../../../components/CustomMaterialTable/CustomMaterialTable";
import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import useFetch from "../../../../ecommerce/hooks/useFetch";
import PurchasesTableColumns from "./components/PurchasesTableColumns/PurchasesTableColumns";
import { type Purchase } from "./types";
import Modal from "../../../../components/Modal/Modal";
import BillingInformationDetail from "./components/BilllingInformationDetail/BillingInformationDetail";

const PurchasesSupplierTable = () => {
  const [detailData, setDetailData] = useState<any>();
  const url = "/v2/suppliers/orders";
  const { isLoading, apiData: purchases } = useFetch<Purchase[]>({
    method: "GET",
    url,
  });
  if (isLoading || !purchases) {
    return <LoadingScreen />;
  }

  return (
    <section className="layout-dashboard-container">
      <CustomMaterialTable
        title="Ventas"
        columns={PurchasesTableColumns({ setDetailData, detailData })}
        data={purchases}
      />
      <Modal
        showModal={detailData ? true : false}
        closeModal={() => setDetailData(null)}
      >
        <BillingInformationDetail billingInformation={detailData!} />
      </Modal>
    </section>
  );
};

export default PurchasesSupplierTable;
