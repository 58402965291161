const env = process.env.REACT_APP_ENV || "development";

type Environments = {
  [key: string]: string;
};

const BASE_API_URL_ENVIRONMENTS: Environments = {
  production: "https://r1d-api.herokuapp.com",
  staging: "https://r1d-api-staging.herokuapp.com",
  prod: "https://r1d-api-prod-cee24dd5e0c2.herokuapp.com",
  development: "http://localhost:8080",
};

const BASE_URL_ENVIRONMENTS: Environments = {
  production: "https://app.fliping.co",
  staging: "https://staging.fliping.co",
  prod: "https://prod.fliping.co",
  development: "http://localhost:3000",
};

export const BASE_API_URL = BASE_API_URL_ENVIRONMENTS[env];
export const BASE_URL = BASE_URL_ENVIRONMENTS[env];
