/* Let CRA handle linting for sample app */
import React, { Component } from "react";
import Spinner from "react-spinner";
import classNames from "classnames";
import AccCore from "opentok-accelerator-core";
import axios from "axios";
// import Navbar from "../Navbar";
import { useParams } from "react-router-dom";
import VideoCallIcon from "../../assets/images/backgroundVideocall.png";

// Styles
import "./VideoAPI.css";
import Button from "../Button/Button.js";
import { handleError } from "../../utils/utils";

let otCore;
const otCoreOptions = {
  credentials: {
    apiKey: "",
    sessionId: "",
    token: "",
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, streamId) {
    return {
      publisher: {
        camera: "#cameraPublisherContainer",
        screen: "#screenPublisherContainer",
      },
      subscriber: {
        camera: "#cameraSubscriberContainer",
        screen: "#screenSubscriberContainer",
      },
    }[pubSub][type];
  },
  controlsContainer: "#controls",
  packages: ["textChat", "screenSharing", "annotation"],
  communication: {
    callProperites: null, // Using default
  },
  textChat: {
    name: "",
    // TODO: it is required to pass the users names by parameter
    // in order to show them in the chat
    // eslint-disable-line no-bitwise
    waitingMessage:
      "Los mensajes serán enviados cuando se conecten otros usuarios",
    container: "#chat",
  },
  screenSharing: {
    extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: "append",
      width: "100%",
      height: "100%",
      showControls: false,
      style: {
        buttonDisplayMode: "off",
      },
      videoSource: "window",
      fitMode: "contain", // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: ".App-video-container",
      subscriber: ".App-video-container",
    },
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames("App-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("video-container", {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: !active || !activeCameraSubscribers },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="App-mask">
    <Spinner />
    <div className="message with-spinner">Conectando</div>
  </div>
);

class VideoAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      hangUp: false,
      publishers: null,
      subscribers: null,
      meta: null,
      isLoading: false,
      localAudioEnabled: false,
      localVideoEnabled: false,
      hashid: props.params.hashid,
      role: props.params.role,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.startCallMask = this.startCallMask.bind(this);
  }

  componentDidMount() {
    this.getConsultancy()
      .then((res) => this.fetchVideoData())
      .then((res) => {
        otCore = new AccCore(otCoreOptions);
        otCore.connect().then(() => this.setState({ connected: true }));

        const events = [
          "subscribeToCamera",
          "unsubscribeFromCamera",
          "subscribeToScreen",
          "unsubscribeFromScreen",
          "startScreenShare",
          "endScreenShare",
        ];

        events.forEach((event) =>
          otCore.on(event, ({ publishers, subscribers, meta }) => {
            this.setState({ publishers, subscribers, meta });
          })
        );
      });
  }

  // TODO: Change numeric role values with names for cleaner code.
  componentDidUpdate() {
    // Re-direct to process sheet of the consultancy when call is hung up
    if (this.state.hangUp && this.state.role === "1") {
      window.location.href = `/cliente/compra/asesoria-virtual/${this.state.hashid}`;
    } else if (this.state.hangUp && this.state.role === "2") {
      window.location.href = `/disenador/compra/asesoria-virtual/${this.state.hashid}`;
    }
  }

  fetchVideoData = async () => {
    const { hashid } = this.props.params;
    await axios.get(`/v1/videoapi/room/${hashid}`).then((res) => {
      otCoreOptions.credentials.apiKey = res.data.apiKey;
      otCoreOptions.credentials.sessionId = res.data.sessionId;
      otCoreOptions.credentials.token = res.data.token;
    });
  };

  getConsultancy = async () => {
    const { hashid, role } = this.props.params;
    await axios.get(`/v1/virtual-consultancy/${hashid}`).then((res) => {
      let { client, designer } = res.data.body.info;
      role === "1"
        ? (otCoreOptions.textChat.name = client.first_name)
        : (otCoreOptions.textChat.name = designer.first_name);
    });
  };

  startCall() {
    this.setState({ isLoading: true });
    otCore
      .startCall({ publishVideo: false, publishAudio: false })
      .then(({ publishers, subscribers, meta }) => {
        this.setState({
          publishers,
          subscribers,
          meta,
          active: true,
          isLoading: false,
        });
      })
      .catch((error) => handleError(error));
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }
  startCallMask = (start) => (
    <div className="App-mask">
      <div className="App-logo-video">
        <img src={VideoCallIcon} alt="" />

        <div className="App-mask__content">
          <span>¿Estás listo para la asesoría?</span>
          <p>
            Recuerda tener buena conexión de red,para así poder disfrutar de una
            mejor experiencia.
          </p>
        </div>
        <Button
          style={{ maring: "0" }}
          label="Iniciar videollamada"
          width="15rem"
          bRadius="1rem"
          $isLoading={this.state.isLoading}
          onClick={start}
        />
      </div>
    </div>
  );

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  endCall() {
    otCore.endCall();
    this.setState({ active: false, hangUp: true });
  }

  render() {
    const { connected, active, hangUp } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className="container-video-call">
        <div className="App-main">
          {!connected && connectingMask()}
          {connected &&
            !active &&
            !hangUp &&
            this.startCallMask(this.startCall)}
          <div className="App-video-container">
            <div
              id="cameraPublisherContainer"
              className={cameraPublisherClass}
            />
            <div
              id="screenPublisherContainer"
              className={screenPublisherClass}
            />
            <div
              id="cameraSubscriberContainer"
              className={cameraSubscriberClass}
            />
            <div
              id="screenSubscriberContainer"
              className={screenSubscriberClass}
            />
            <div id="controls" className={controlClass}>
              <div
                className={localAudioClass}
                onClick={this.toggleLocalAudio}
              />
              <div
                className={localVideoClass}
                onClick={this.toggleLocalVideo}
              />
              <div className={localCallClass} onClick={this.endCall} />
            </div>
          </div>
          <div id="chat" className="App-chat-container" />
        </div>
      </div>
    );
  }
}

// export default VideoAPI;
export default (props) => <VideoAPI {...props} params={useParams()} />;
