import { useCallback, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import PlaceholderLogoImage from "../../../../../../assets/svg/profile-placeholder.svg";
import CustomButton from "../../../../../../components/Button/CustomButton";

type ProfileUploaderProps = {
  fieldChange: (files: File[]) => void;
  mediaUrl: string;
};

export const convertFileToUrl = (file: File) => URL.createObjectURL(file);

const LogoUploader = ({ fieldChange, mediaUrl }: ProfileUploaderProps) => {
  const [file, setFile] = useState<File[]>([]);
  const [fileUrl, setFileUrl] = useState<string>(mediaUrl);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFile(acceptedFiles);
      fieldChange(acceptedFiles);
      setFileUrl(convertFileToUrl(acceptedFiles[0]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file, fieldChange]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    noClick: true,
    noKeyboard: true,
  });

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    open();
  };

  return (
    <div {...getRootProps()} className="logo-uploader">
      <input {...getInputProps()} className="logo-uploader__input" />

      <div className="logo-uploader__container">
        <img
          src={fileUrl || PlaceholderLogoImage}
          alt="logo"
          className="logo-uploader__image"
        />
        <CustomButton
          color="outlined"
          shape="little-rounded"
          size="small"
          onClick={handleButtonClick}
        >
          {fileUrl ? "Cambiar Logo" : "Subir Logo"}
        </CustomButton>
      </div>
    </div>
  );
};

export default LogoUploader;
