import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const LayoutTooltip = ({ children, title }) => {
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#232f3e",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      arrow: { color: "white" },
      height: 30,
      display: "flex",
      alignItems: "center",
    },
    arrow: {
      color: "#232f3e",
    },
  }))(Tooltip);

  return (
    <LightTooltip
      title={title}
      placement="right"
      arrow
      interactive
      TransitionComponent={Zoom}
    >
      {children}
    </LightTooltip>
  );
};
export default LayoutTooltip;
