export const REDUCER_ACTION_TYPE = {
  SIDEBAR_OPEN: "SIDEBAR_OPEN",
  SIDEBAR_CLOSE: "SIDEBAR_CLOSE",
  GET_PRODUCTS_BEGIN: "GET_PRODUCTS_BEGIN",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
  GET_FEATURED_PRODUCTS_BEGIN: "GET_FEATURED_PRODUCTS_BEGIN",
  GET_FEATURED_PRODUCTS_SUCCESS: "GET_FEATURED_PRODUCTS_SUCCESS",
  GET_FEATURED_PRODUCTS_ERROR: "GET_FEATURED_PRODUCTS_ERROR",
  GET_SEASONAL_OFFERS_BEGIN: "GET_SEASONAL_OFFERS_BEGIN",
  GET_SEASONAL_OFFERS_SUCCESS: "GET_SEASONAL_OFFERS_SUCCESS",
  GET_SEASONAL_OFFERS_ERROR: "GET_SEASONAL_OFFERS_ERROR",
  GET_SINGLE_PRODUCT_BEGIN: "GET_SINGLE_PRODUCT_BEGIN",
  GET_SINGLE_PRODUCT_SUCCESS: "GET_SINGLE_PRODUCT_SUCCESS",
  GET_SINGLE_PRODUCT_ERROR: "GET_SINGLE_PRODUCT_ERROR",
};
