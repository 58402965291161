export function formatNumberToPrice(number: any) {
  const roundedNumber = Math.round(number);
  const options = {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  return new Intl.NumberFormat("es-CO", options).format(roundedNumber);
}
