import EditIcon from "@material-ui/icons/Edit";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ReceiptIcon from "@material-ui/icons/Receipt";
import VisibilityIcon from "@material-ui/icons/Visibility";

import styled from "styled-components";

const UnorderedList = styled.ul`
  margin-left: 1rem;

  li {
    margin: 0.25rem;
  }
`;

export const stepHelpModalContent: any = {
  visit_date: (
    <div>
      <b>¿Qué es? </b>
      <span>
        Establece la cita entre el profesional y el cliente en el espacio a
        intervenir.
      </span>

      <br />
      <br />

      <b>¿Qué hacer?</b>
      <UnorderedList>
        <li>
          Conociendo la fecha para la visita busca el <EditIcon /> y agéndala
        </li>
        <li>
          Dado el caso que se deba re-agendar, deberás hacer este mismo proceso.
        </li>
        <li>
          Una vez terminada la visita debes ir al estado y cambiarlo de “En
          proceso” a “Completado”.
        </li>
      </UnorderedList>
    </div>
  ),
  design: (
    <div>
      <b>¿Qué es? </b>
      <span>
        Es el ítem que archiva todos los diseños que realices para tu cliente,
        él podrá verlos y aprobarlos cuando desee.
      </span>

      <br />
      <br />

      <b>¿Qué hacer?</b>
      <UnorderedList>
        <li>
          Para cargar un diseño debes dar clic en el <AddPhotoAlternateIcon /> y
          adjuntarlo en formato JPG; este archivo debe llevar el nombre del
          espacio que se va intervenir, ej. habitación principal, sala, estudio,
          etc. Si necesitas varios diseños del mismo espacio debes enumerarlos,
          ej. habitación 2, sala 4, estudio 3, etc.
        </li>
        <li>
          Debes comunicarle a tu cliente que para realizar el presupuesto de
          cada diseño, él debe aprobarlo en el botón “aprobar” al visualizar
          cada diseño desde su hoja de proceso. Debes evitar realizar
          presupuestos de diseños que no se hayan aprobado.
        </li>
        <li>
          Puedes hacer los diseños en Sketchup, montajes fotográficos o
          Moodboards. Nota: Cada espacio a intervenir de un cliente, se cuenta
          como un proyecto diferente, es decir, cada uno tiene diseño,
          presupuesto y pago individual. El estado “En proceso” no cambiará
          hasta que se termine todo el proceso de Remodelación, puesto que
          puedes agregar un nuevo diseño o modificar existentes en cualquier
          etapa del proceso.
        </li>
      </UnorderedList>
    </div>
  ),
  budget: (
    <div>
      <b>¿Qué es? </b>
      <span>
        Es el ítem que archiva todos los presupuesto que realices para tu
        cliente, él podrá verlos y aprobarlos cuando desee.
      </span>

      <br />
      <br />

      <b>¿Qué hacer?</b>
      <UnorderedList>
        <li>
          Para realizar el presupuesto debes dar clic en el ícono
          <ReceiptIcon /> que te llevará al cotizador de Fliping, ahí podrás
          seleccionar todos los productos contemplados en el diseño y dar clic
          al botón crear cotización, allí deberás ingresar las cantidades en m²
          o unds. de cada producto. Al terminar la cotización se cargará
          automáticamente en el ítem de “presupuesto” y tu cliente podrá
          visualizarla y aprobarla.
        </li>
        <li>
          El estado “En proceso” no cambiará hasta que se termine todo el
          proceso de Remodelación, puesto que puedes agregar un nuevo
          presupuesto o modificar existentes en cualquier etapa del proceso.
        </li>
      </UnorderedList>
    </div>
  ),
  payment: (
    <div>
      <b>¿Qué es? </b>
      <span>
        Es el paso donde el cliente realiza los pagos de cada proyecto y puedas
        visualizar la fecha en que se realizó cada uno.
      </span>

      <br />
      <br />

      <b>¿Qué hacer?</b>
      <UnorderedList>
        <li>La persona encargada de coordinar la instalación te contactará.</li>
        <li>
          Deberás cargar el “documento técnico” de cada proyecto pagado, en el
          menor tiempo posible. Éste es necesario para que los instaladores
          tengan claro la especificación del montaje.
        </li>
      </UnorderedList>
    </div>
  ),
  my_orders: (
    <div>
      <b>¿Qué es? </b>
      <span>
        Es la sección donde los proveedores cargan los links de rastreo de cada
        despacho
      </span>

      <br />
      <br />

      <b>¿Qué hacer?</b>
      <UnorderedList>
        <li>
          Para visualizar los links deberás dar clic en el ícono del
          <VisibilityIcon />.
        </li>
        <li>
          Recuérdale a tu cliente que en la entrega de cada despacho es
          necesario contar con alguien que reciba y disponer de un espacio
          adecuado para almacenarlos.
        </li>
        <li>
          Conociendo la fecha de llegada de los productos podrás coordinar una
          fecha de instalación.
        </li>
      </UnorderedList>
    </div>
  ),
  contractors_installing: (
    <div>
      <b>¿Qué es? </b>
      <span>
        Es el ítem donde se encuentra la fecha de instalación y los datos de los
        instaladores y supervisores de la obra.
      </span>

      <br />
      <br />

      <b>¿Qué hacer?</b>
      <UnorderedList>
        <li>
          Cuando todos los productos estén en la dirección indicada, podrás
          acordar una fecha para la instalación entre el cliente y la empresa
          encargada.
        </li>
        <li>
          Debes agendar la fecha acordada para la instalación, dando clic en el
          icono del <EditIcon />.
        </li>
        <li>
          Recuerda a tu cliente que es necesaria su presencia al momento de
          terminar la instalación, para que en su hoja de proceso culmine el
          proyecto dando clic al botón “Recibir a satisfacción” o hacer las
          recomendaciones necesarias.
        </li>
        <li>
          Una vez recibido a satisfacción allí mismo el cliente podrá dar la
          calificación de los instaladores y de ti como profesional.
        </li>
      </UnorderedList>
    </div>
  ),
};
