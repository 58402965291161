import { Remodeling } from "./types";

import remodelingHeaderImage from "../../assets/images/purchase-summary-bg-supermin.jpeg";
import styled from "styled-components";

export default function RemodelingHeaderInfo({
  hashid, client, designer, address
}: Remodeling) {

  return (
    <Container>
      <h2>Hola, {client.first_name} {client.last_name}</h2>
      <p style={{ marginBottom: "8px" }}>Aquí podras encontrar el paso a paso del proceso de remodelacion adelantado por el disenador {designer.first_name} {designer.last_name}.</p>
      <p><b>Dirección de instalación:</b> {address}</p>
      <p><b>Referencia:</b> {hashid}</p>
      <p><b>Estado del proceso:</b> Activo</p>
    </Container>
  );
}

const Container = styled.div`
  padding: 0.5rem 1rem;
  background-image: ${() => `url(${remodelingHeaderImage})`};
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);

  @media (min-width: 768px) {
    padding: 1rem 3.5rem 1rem 3.5rem;
  }
`;
