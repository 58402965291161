import Axios from "axios";
import { handleError } from "../../utils/utils";

export async function getFavoritesProducts() {
  try {
    const favoritesProducts = await Axios.get(`/v2/favorite`);

    if (!favoritesProducts)
      throw Error("No se encontraron productos favoritos");

    return favoritesProducts.data;
  } catch (error) {
    handleError(error);
  }
}

export async function addProductToFavorites(productId: number) {
  try {
    await Axios.post(`/v2/favorite/add/${productId}`);
  } catch (error) {
    handleError(error);
  }
}

export async function removeProductFromFavorites(productId: number) {
  try {
    await Axios.delete(`/v2/favorite/remove/${productId}`);
  } catch (error) {
    handleError(error);
  }
}

export async function getAllProducts(companyName: string) {
  try {
    const products = await Axios.get(`/v2/products/${companyName}/products`);
    return products.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getProductById(productId: number) {
  try {
    const product = await Axios.get(`/v2/products/${productId}`);
    return product.data;
  } catch (error) {
    handleError(error);
  }
}

export async function GetAllCompanyPreferences(companyName: string) {
  try {
    const companyPreferences = await Axios(
      `/v2/suppliers/e-commerce/${companyName}`
    );

    return companyPreferences.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getAllSalesPersonByCompanyName(companyName: string) {
  try {
    const salesPersons = await Axios.get(`/v2/salesperson/${companyName}`);
    return salesPersons.data;
  } catch (error) {
    handleError(error);
  }
}

export async function sendClientContactInfo(contactInfo: IContactInfo) {
  try {
    let response = await Axios.post(`/v2/salesperson/contact`, contactInfo);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}
