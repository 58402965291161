import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";

import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import Button from "../components/Button/Button.js";
import Input from "../components/Input/Input.js";
import PasswordInput from "../components/PasswordInput/PasswordInput.js";
import SplitScreen from "../components/SplitScreen/SplitScreen";
import SuccessBox from "../components/SuccessBox/SuccessBox.js";

import passwordResetRequestImage from "../assets/images/dashboard-mac.jpeg";
import passwordResetImage from "../assets/images/computer-lock.jpeg";
import axios from "axios";
import styled from "styled-components";
import * as yup from "yup";
import { errorToast, warningToast } from "../services/toasts";

function PasswordReset() {
  const [successScreen, setSuccessScreen] = useState(false);
  const [token, setToken] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = token ? passwordValidation : emailValidation;

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { pathname } = useLocation();

  const history = useHistory();
  const params = pathname.split("/");

  useEffect(() => {
    validateToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateToken = async () => {
    if (params.length > 2) {
      let token = params[2];

      let { data } = await axios.get(`/v1/users/password-reset/${token}`);

      if (!data.success) {
        errorToast("El link de recuperación de contraseña ha expirado");
      }

      data.success ? setToken(token) : setToken();
    }
  };

  const handleSendEmail = async (data) => {
    setIsLoading(true);
    try {
      await axios.post("/v1/users/password-reset", data);
      warningToast(
        "Recuerda que el link de recuperación de contraseña expira en 1 hora"
      );
      setSuccessScreen(true);
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;

      setError("email", {
        type: "manual",
        message: requestError ? requestError : error.message,
      });
    }
    setIsLoading(false);
  };

  const handleChangePassword = async (data) => {
    setIsLoading(true);
    try {
      let response = await axios.patch(`/v1/users/password-reset/${token}`, {
        password: data.password,
      });

      setSuccessScreen(true);

      // on success, re-direct user to main page after 5 seconds
      setTimeout(() => {
        history.push("/");
      }, 7000);
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;

      setError("password", {
        type: "manual",
        message: requestError ? requestError : error.message,
      });
    }
    setIsLoading(false);
  };

  if (!token) {
    if (!successScreen) {
      return (
        <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
          <SplitScreen
            fullheight
            logoWithLink
            overlay
            title="Escribe tu correo"
            description="Danos el correo que utilizaste para crear tu cuenta, y te enviaremos los pasos a seguir"
            image={passwordResetImage}
          >
            <Form onSubmit={handleSubmit(handleSendEmail)}>
              <Row>
                <EmailIcon />
                <Input
                  name="email"
                  register={register}
                  placeholder="Tu correo"
                />
              </Row>
              <div>
                {Object.keys(errors).map((errorName) => {
                  return <p className="error">{errors[errorName].message}</p>;
                })}
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  label="Continuar"
                  type="submit"
                  $isLoading={isLoading}
                />
              </div>
            </Form>
          </SplitScreen>
        </div>
      );
    } else {
      return (
        <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
          <SplitScreen
            fullheight
            logoWithLink
            overlay
            title="Revisa tu correo"
            description="Si tu correo es correcto, te hemos enviado las instrucciones para recuperar tu contraseña"
            image={passwordResetImage}
          >
            <SuccessBox />
          </SplitScreen>
          {setTimeout(() => {
            history.push("/login");
          }, 4000)}
        </div>
      );
    }
  }

  if (token) {
    if (!successScreen) {
      return (
        <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
          <SplitScreen
            fullheight
            logoWithLink
            overlay
            title="Cambia tu contraseña"
            description="Escribe tu nueva contraseña"
            image={passwordResetRequestImage}
          >
            <Form onSubmit={handleSubmit(handleChangePassword)}>
              <Row>
                <LockIcon />
                <PasswordInput
                  name="password"
                  register={register}
                  placeholder="Contraseña"
                />
              </Row>

              <Row>
                <EnhancedEncryptionIcon />
                <PasswordInput
                  name="passwordConfirm"
                  register={register}
                  placeholder="Repite tu contraseña"
                />
              </Row>
              <div>
                {Object.keys(errors).map((errorName) => {
                  return <p className="error">{errors[errorName].message}</p>;
                })}
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  label="Continuar"
                  type="submit"
                  $isLoading={isLoading}
                />
              </div>
            </Form>
          </SplitScreen>
        </div>
      );
    } else {
      return (
        <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
          <SplitScreen
            fullheight
            logoWithLink
            overlay
            title="Todo listo"
            description="Haz cambiado tu contraseña exitosamente, serás re-dirigido a nuestra página principal automaticamente"
            image={passwordResetRequestImage}
          >
            <SuccessBox />
          </SplitScreen>
        </div>
      );
    }
  }
}

const emailValidation = yup.object().shape({
  email: yup
    .string()
    .email("Por favor escribe un correo válido")
    .required("Por favor escribe un correo"),
});

const passwordValidation = yup.object().shape({
  password: yup
    .string()
    .min(8, "La contraseña debe tener mínimo 8 caracteres")
    .required("Por favor escribe una contraseña"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("Por favor confirma tu contraseña"),
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default PasswordReset;
