import styles from "./orderHeaderDetail.module.scss";

interface OrderHeaderDetailProps {
  shippingCount: number;
}

const OrderHeaderDetail = ({ shippingCount }: OrderHeaderDetailProps) => {
  return (
    <div className={styles.orderHeaderDetail}>
      <div>
        <h3>{shippingCount} envíos</h3>
      </div>
    </div>
  );
};
export default OrderHeaderDetail;
