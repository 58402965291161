import { OrderList } from "../../components";
import { useState } from "react";

import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import useFetch from "../../../../ecommerce/hooks/useFetch";
import { type Order } from "../../types";
import OrderSearch from "./components/orderSearch/OrderSearch";
import OrderSort from "./components/orderSort/OrderSort";
import styles from "./purchasesPage.module.scss";

const PurchasesPage = () => {
  const url = `/v2/orders/`;
  const { isLoading, apiData: purchases } = useFetch<Order[]>({
    method: "GET",
    url,
  });
  const [searchText, setSearchText] = useState("");
  const [filterType, setFilterType] = useState("all");

  if (isLoading || !purchases) {
    return <LoadingScreen />;
  }

  const emptyPurchases = (
    <div className={styles.noPurchases}>
      <h1>¡TODAVÍA NO TIENES COMPRAS!</h1>
    </div>
  );

  const fullPurchases = (
    <>
      <div className={styles.content}>
        <OrderSearch searchText={searchText} setSearchText={setSearchText} />
        <OrderSort filterType={filterType} setFilterType={setFilterType} />
        <h3>{purchases.length} compras</h3>
      </div>
      <OrderList
        purchases={purchases}
        searchText={searchText}
        filterType={filterType}
      />
    </>
  );

  return (
    <section className="layout-dashboard-container">
      <div className={styles.purchasesPage}>
        <div className={styles.purchasesPage__header}>
          <h2 className={styles.title}>Mis compras</h2>
        </div>
        <div>{purchases.length === 0 ? emptyPurchases : fullPurchases}</div>
      </div>
    </section>
  );
};
export default PurchasesPage;
