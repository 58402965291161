import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import styled from "styled-components";

/**
 * Primary button with styling.
 * Icon prop should be the name of one font-awesome icon.
 * i.e: fa-envelope. https://fontawesome.com/v4.7.0/icons/
 */
function Button({ icon, label, size, onClick, $isLoading, ...props }) {
  return (
    <StyledButton
      size={size}
      onClick={onClick}
      disabled={$isLoading ? true : props.disabled}
      $isLoading={$isLoading}
      {...props}
    >
      {icon && (
        <i
          className={`fa ${icon}`}
          aria-hidden="true"
          style={{ fontSize: "1rem", marginRight: "0.5rem" }}
        />
      )}
      {$isLoading && <CircularProgress size={15} color="inherit" />}
      <span>{label}</span>
    </StyledButton>
  );
}
const StyledButton = styled.button.attrs((props) => ({
  type: props.type,
}))`
  font-family: "Open Sans", sans-serif;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
  padding: ${(props) => {
    if (props.size === "small") return "0.5rem 0.8rem";
    if (props.size === "medium") return "0.5rem 1.5rem";
    if (props.size === "big") return "0.5rem 2rem";
    if (props.size === "extraBig") return "1rem 3rem";
  }};
  box-sizing: border-box;
  background-color: ${(props) =>
    props.primary ? props.backgroundColor : "transparent"};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.bRadius || "4px"};
  text-decoration: none;
  cursor: pointer;
  font-size: ${(props) => {
    if (props.size === "small") return "0.8rem";
    if (props.size === "medium") return "1rem";
    if (props.size === "big") return "1.25rem";
  }};
  font-family: Poppins, sans-serif;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  text-align: center;
  transition: 0.2s;
  position: relative;
  max-height: ${(props) => {
    if (props.size === "small") return "28px";
    if (props.size === "medium") return "32px";
    if (props.size === "big") return "45px";
    if (props.size === "extraBig") return "200px";
  }};
  margin: 2px;
  width: ${(props) => props.width || ""};
  span {
    visibility: ${(props) => (props.$isLoading ? "hidden" : "visible")};
  }

  .MuiCircularProgress-root {
    position: absolute;
    top: 50;
    left: 50;
  }

  &:hover {
    transition: 0.2s;
    filter: brightness(103%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

Button.propTypes = {
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  $isLoading: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

Button.defaultProps = {
  backgroundColor: "#fdd400",
  primary: true,
  $isLoading: false,
  size: "medium",
  color: "black",
};

export default Button;
