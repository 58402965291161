import { useEffect, useState, Fragment } from "react";
import { Remodeling } from "../../pages/RemodelingResume/types";
import { Link } from "react-router-dom";

import Stars from "../Stars/Stars";
import Avatar from "@material-ui/core/Avatar";
import DefaultContent from "../Modal/DefaultContent";
import Modal from "../Modal/Modal";

import axios from "axios";
import moment from "moment";
import styled from "styled-components";

export default function ClientNavbar() {
  const [isShowingRemodelingsModal, setIsShowingRemodelingsModal] =
    useState(false);

  const closeRemodelingsModal = () => {
    setIsShowingRemodelingsModal(false);
  };

  return (
    <Fragment>
      <RemodelingsHistoryModal
        isShowingRemodelingsModal={isShowingRemodelingsModal}
        closeRemodelingsModal={closeRemodelingsModal}
      />
      {/* TODO: change this to be using buttons instead, so we don't have to do the href hack */}
      <a
        // @ts-ignore
        href={`/cliente/dashboard`}
        // href={() => false}
        // onClick={() => setIsShowingRemodelingsModal(true)}
        style={{ cursor: "pointer", color: "#fff" }}
      >
        Dashboard
      </a>
    </Fragment>
  );
}

function RemodelingsHistoryModal({
  isShowingRemodelingsModal,
  closeRemodelingsModal,
}: {
  isShowingRemodelingsModal: boolean;
  closeRemodelingsModal: () => void;
}) {
  const [remodelings, setRemodelings] = useState<Remodeling[]>();

  /**
   * We have to use isMounted to avoid the error 'Can't perform a react state
   * update on an unmounted component' when rendering this component on a test
   *
   * See: https://stackoverflow.com/a/60907638/6206076
   */
  useEffect(() => {
    let isMounted = true;

    axios.get(`/v1/clients/remodelings`).then((res) => {
      if (isMounted) setRemodelings(res.data);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={isShowingRemodelingsModal}
        closeModal={closeRemodelingsModal}
      >
        <DefaultContent
          label="historial"
          heading="Tus remodelaciones"
          showSecondaryButton={false}
          onSubmit={closeRemodelingsModal}
        >
          {remodelings?.map((remodeling) => {
            return (
              <Link
                to={`/compra/${remodeling.hashid}`}
                onClick={closeRemodelingsModal}
                key={remodeling.hashid}
              >
                <RemodelingContainer>
                  <Avatar
                    src={remodeling.designer.avatar}
                    alt="imagen de perfil diseñador"
                  />
                  <span>
                    {remodeling.designer.first_name}{" "}
                    {remodeling.designer.last_name}
                  </span>
                  <span>{remodeling.hashid}</span>
                  <span>
                    {remodeling.designer_rating ? (
                      <Stars averageRating={remodeling.designer_rating} />
                    ) : (
                      "N/A"
                    )}
                  </span>
                  <span>
                    {moment(remodeling.created_at)
                      .toDate()
                      .toLocaleDateString()}
                  </span>
                </RemodelingContainer>
              </Link>
            );
          })}
        </DefaultContent>
      </Modal>
    </Fragment>
  );
}

const RemodelingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
