import { yupResolver } from "@hookform/resolvers";
import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import CustomButton from "../../../../components/Button/CustomButton";
import { salesPersonSchema } from "../../../../services/formValidation";
import SalesPersonAvatar from "../SalesPersonAvatar/SalesPersonAvatar";
import {
  useCreateSalesPerson,
  useGetSalespersonCities,
  useUpdateSalesPerson,
} from "../../../../utils/react-query/querySupplier/queries";
import { useHistory } from "react-router";

type SalesPersonFormProps = {
  action: "create" | "update";
  salesPerson?: SalesPeople;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ebf9fc",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    borderColor: "#ade7f0",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    color: "#006C9C",
  },
  infoText: {
    flexGrow: 1,
    color: "#006C9C",
  },
  closeButton: {
    padding: theme.spacing(0.5),
  },
}));

const SalesPersonForm = ({ action, salesPerson }: SalesPersonFormProps) => {
  const { mutateAsync: createSalesPerson, isLoading: isLoadingCreate } =
    useCreateSalesPerson();
  const { mutateAsync: updateSalesPerson, isLoading: isLoadingUpdate } =
    useUpdateSalesPerson();
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showInfo, setShowInfo] = useState(action === "create");

  const { data: cityOptions, isLoading } = useGetSalespersonCities();

  const formSchema = salesPersonSchema(action);

  const { handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      avatar: salesPerson ? salesPerson.avatar : [],
      first_name: salesPerson ? salesPerson.first_name : "",
      last_name: salesPerson ? salesPerson.last_name : "",
      email: salesPerson ? salesPerson.email : "",
      phone: salesPerson ? salesPerson.phone : "",
      skills_description: salesPerson ? salesPerson.skills_description : "",
      city_name: salesPerson ? salesPerson.city_name : "",
      professional_profile: salesPerson ? salesPerson.professional_profile : "",
      password: "",
      passwordConfirm: "",
    },
  });

  // Reset form when salesPerson changes
  useEffect(() => {
    if (salesPerson) {
      reset({
        avatar: salesPerson.avatar || [],
        first_name: salesPerson.first_name || "",
        last_name: salesPerson.last_name || "",
        email: salesPerson.email || "",
        phone: salesPerson.phone || "",
        skills_description: salesPerson.skills_description || "",
        city_name: salesPerson.city_name || "",
        professional_profile: salesPerson.professional_profile || "",
        password: "",
        passwordConfirm: "",
      });
    }
  }, [salesPerson, reset]);

  async function onSumbit(values: any) {
    let formDataForRequest = new FormData();

    const avatarFile =
      values.avatar && values.avatar.length > 0 ? values.avatar[0] : null;

    if (avatarFile) {
      formDataForRequest.append("avatar", avatarFile);
    }

    for (let key in values) {
      if (key !== "passwordConfirm" && key !== "avatar") {
        formDataForRequest.append(key, values[key]);
      }
    }

    if (salesPerson && action === "update") {
      const updatedSalesPerson = await updateSalesPerson({
        salesPersonId: salesPerson.id!,
        salesPersonData: formDataForRequest,
      });

      if (updatedSalesPerson) return history.push("/proveedor/asesor/lista");
    }

    const newSalesPerson = await createSalesPerson({
      salesPersonData: formDataForRequest,
    });

    if (newSalesPerson) {
      return history.push("/proveedor/asesor/lista");
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSumbit)();
      }}
    >
      <div>
        {showInfo && (
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} />
            <Typography variant="body2" className={classes.infoText}>
              Por favor, guarda tu contraseña en un lugar seguro. Esta es la
              única vez que tendrás acceso a ella. Si la pierdes, solo podrás
              recuperarla mediante el proceso de recuperación de contraseña.
            </Typography>
            <IconButton
              className={classes.closeButton}
              onClick={() => setShowInfo(false)}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        )}
        <div className="salesPersonForm">
          <Grid item xs={12}>
            <Controller
              name="avatar"
              control={control}
              render={({ onChange }) => (
                <SalesPersonAvatar
                  mediaUrl={salesPerson?.avatar || ""}
                  fileldChange={onChange}
                  error={errors}
                />
              )}
            />
          </Grid>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      error={!!errors?.first_name}
                      helperText={
                        errors.first_name ? errors?.first_name?.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label="Apellido"
                      variant="outlined"
                      fullWidth
                      error={!!errors.last_name}
                      helperText={
                        errors.last_name ? errors?.last_name?.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="professional_profile"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label="Perfil Profesional"
                      variant="outlined"
                      fullWidth
                      error={!!errors.professional_profile}
                      helperText={
                        errors.professional_profile
                          ? errors.professional_profile.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label="Celular"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone}
                      helperText={errors.phone ? errors.phone.message : null}
                    />
                  )}
                />
              </Grid>
              {cityOptions && cityOptions.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="city_name"
                    control={control}
                    render={({ ...field }) => (
                      <FormControl fullWidth>
                        <Select
                          defaultInputValue={salesPerson?.city_name}
                          isLoading={isLoading}
                          placeholder="Selecciona una ciudad"
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "56px",
                              minHeight: "56px",
                              borderColor: errors.city_name
                                ? "red"
                                : base.borderColor,
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          {...field}
                          options={cityOptions}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.label);
                          }}
                          value={cityOptions.find(
                            (option: any) => option.value === field.value
                          )}
                        />
                        {errors && (
                          <FormHelperText style={{ color: "red" }}>
                            {errors.city_name?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label="Correo"
                      variant="outlined"
                      fullWidth
                      disabled={action === "update"}
                      error={!!errors?.email}
                      helperText={errors.email ? errors?.email?.message : null}
                    />
                  )}
                />
              </Grid>
              {action === "create" ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ value, onChange, onBlur }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          type={showPassword ? "text" : "password"}
                          label="Contraseña"
                          variant="outlined"
                          fullWidth
                          error={!!errors.password}
                          helperText={
                            errors.password ? errors.password.message : null
                          }
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="passwordConfirm"
                      control={control}
                      render={({ value, onChange, onBlur }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          type={showPasswordConfirm ? "text" : "password"}
                          label="Confirmar Contraseña"
                          variant="outlined"
                          fullWidth
                          error={!!errors.passwordConfirm}
                          helperText={
                            errors.passwordConfirm
                              ? errors.passwordConfirm.message
                              : null
                          }
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowPasswordConfirm(!showPasswordConfirm)
                                  }
                                >
                                  {showPasswordConfirm ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <Controller
                  name="skills_description"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      label="Descripción de habilidades"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      error={!!errors.skills_description}
                      helperText={
                        errors.skills_description
                          ? errors.skills_description.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div className="formSalesPersonButton">
              <CustomButton
                type="submit"
                color="secondary"
                shape="little-rounded"
                size="medium"
                isLoading={isLoadingCreate || isLoadingUpdate}
              >
                {action === "update" ? "Guardar cambios" : "Crear Asesor"}
              </CustomButton>
            </div>
          </Paper>
        </div>
      </div>
    </form>
  );
};

export default SalesPersonForm;
