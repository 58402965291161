import { yupResolver } from "@hookform/resolvers";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PostAddIcon from "@material-ui/icons/PostAdd"; // Bill
import Alert from "@material-ui/lab/Alert";
import MaterialTable, { MTableToolbar } from "material-table";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button.js";
import Modal from "../../components/Modal/Modal";
import fileTypes from "../../services/fileTypes";
import { trackingUrlSchema, validateFile } from "../../services/formValidation";
import { materialTableES } from "../../services/materialTableES";

import axios from "axios";
import { errorToast, successToast } from "../../services/toasts";
import { handleError } from "../../utils/utils";

function SalesManagement() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [isShowingColumns, setIsShowingColumns] = useState(true);
  const [isShowingFinishedRemodelings, setIsShowingFinishedRemodelings] =
    useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [receiptList, setReceiptList] = useState();
  const [isOpenReceipitList, setIsOpenReceiptList] = useState();
  const [activePurchase, setActivePurchase] = useState();
  const [detailData, setDetailData] = useState();

  const userTokens = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getInvoices();
    setIsShowingColumns(false);
    setIsShowingFinishedRemodelings(false);
  }, []);

  const getInvoices = async () => {
    setIsLoading(true);
    let res = await axios.get(`/v1/presential-consultancy/suppliers/invoices`);
    setTimeout(() => {
      setData(res.data.info);
      setIsLoading(false);
    }, 500);
  };

  const getFinishedRemodelings = async () => {
    setIsLoading(true);
    let res = await axios.get(
      `/v1/presential-consultancy/suppliers/invoices?finished=true`
    );

    setTimeout(() => {
      setData(res.data.info);
      setIsLoading(false);
    }, 500);
  };

  const getPaidInvoices = async () => {
    setIsLoading(true);
    let res = await axios.get(
      `/v1/presential-consultancy/suppliers/invoices?paid=true`
    );

    setTimeout(() => {
      setData(res.data.info);
      setIsLoading(false);
    }, 500);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsOpenReceiptList(false);
    getPaidInvoices();
  };

  const handleBillUpload = async (event) => {
    try {
      const file = event.target.files[0];

      // Check if any file was uploaded
      if (file) {
        // PDF validation
        validateFile(file, /pdf/);

        const data = new FormData();
        data.append("file", file);
        data.append("originalname", "Factura Proveedor");

        // Save as a legal doc
        setIsLoading(true);

        await axios.post(
          `/v1/presential-consultancy/${activePurchase}/files/${fileTypes.remodelingLegalDoc}?supplier_receipt=true&invoice_id=${selectedInvoice.id}`,
          data
        );

        let res = await axios.get(
          `/v1/presential-consultancy/suppliers/invoices?paid=true`
        );
        setTimeout(() => {
          setData(res.data);
          setIsLoading(false);
        }, 500);

        successToast("¡Has añadido tu factura exitosamente!");
        getPaidInvoices();
      }
    } catch (error) {
      // Display a red alert of the kind of error
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
      handleError(error);
    }
  };

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Chip
            style={{
              color: "#112A36",
              backgroundColor: "#E2CA8A",
              marginRight: 10,
            }}
            label="Cotizaciones"
            clickable={true}
            onClick={() => {
              setIsShowingColumns(false);
              getInvoices();
              setIsShowingFinishedRemodelings(false);
            }}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#061225",
              marginRight: 10,
            }}
            label="Pagadas"
            clickable={true}
            onClick={() => {
              setIsShowingColumns(true);
              getPaidInvoices();
              setIsShowingFinishedRemodelings(false);
            }}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#AA8684",
              marginRight: 10,
            }}
            label="Terminadas"
            clickable={true}
            onClick={() => {
              setIsShowingColumns(true);
              getFinishedRemodelings();
              setIsShowingFinishedRemodelings(true);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <section className="layout-dashboard-container">
      {userTokens && !userTokens.hasBankInfo && (
        <div>
          <Alert severity="info">
            Aun no has especificado la información bancaria en donde te llegarán
            tus pagos, puedes hacerlo haciendo clic{" "}
            <Link to="configuracion">aquí</Link>
          </Alert>
        </div>
      )}
      <Modal
        showModal={detailData ? true : false}
        closeModal={() => setDetailData()}
        contentMaxWidth={"400px"}
      >
        <ReceiptData data={detailData} />
      </Modal>
      <Modal
        contentPadding={"4rem 4rem 3rem 4rem"}
        closeModal={handleClose}
        showModal={isOpen}
      >
        <AddTrackingUrl handleClose={handleClose} invoice={selectedInvoice} />
      </Modal>
      <Modal
        showModal={isOpenReceipitList}
        closeModal={handleClose}
        contentPadding={"4rem 2rem 3rem 2rem"}
      >
        <RecordOfReceipts handleClose={handleClose} invoice={receiptList} />
      </Modal>
      <input
        onChange={handleBillUpload}
        type="file"
        id="bill-upload"
        className="legal-doc"
      />
      <MaterialTable
        title={"Clientes"}
        columns={[
          { title: "Fecha", type: "date", field: "created_at" },
          {
            title: "Hoja de proceso",
            render: (rowData) => `${rowData.presentialConsultancy.hashid}`,
          },
          {
            title: "Cliente",
            render: (rowData) =>
              `${rowData.presentialConsultancy.client.first_name} ${rowData.presentialConsultancy.client.last_name}`,
          },
          {
            title: "Diseñador",
            render: (rowData) =>
              `${rowData.presentialConsultancy.designer.first_name} ${rowData.presentialConsultancy.designer.last_name}`,
          },
          {
            title: "Ciudad",
            render: (rowData) => rowData.presentialConsultancy.city,
          },
          {
            title: "Datos/Facturación",
            hidden: isShowingColumns ? false : true,
            render: (rowData) => {
              if (isShowingFinishedRemodelings) {
                return <Button size="small" label="Ver datos" disabled />;
              } else {
                return (
                  <Button
                    size="small"
                    label="Ver datos"
                    actions
                    onClick={() => {
                      setDetailData(rowData);
                    }}
                  />
                );
              }
            },
          },
          {
            title: "Cotización",
            render: (rowData) => {
              if (isShowingFinishedRemodelings) {
                return <Button size="small" label="Ver cotización" disabled />;
              } else {
                return (
                  <a href={rowData.url} target="__blank">
                    <Button size="small" label="Ver cotización" />
                  </a>
                );
              }
            },
          },
          {
            title: "Enlaces",
            hidden: isShowingColumns ? false : true,
            render: (rowData) => {
              if (isShowingFinishedRemodelings) {
                return <Button size="small" label="Añadir enlaces" disabled />;
              } else {
                return (
                  <Button
                    size="small"
                    label="Añadir enlaces"
                    onClick={() => {
                      setSelectedInvoice(rowData);
                      setIsOpen(true);
                    }}
                  />
                );
              }
            },
          },
          {
            title: "Facturas",
            hidden: isShowingColumns ? false : true,
            render: (rowData) => {
              if (isShowingFinishedRemodelings) {
                return <Button size="small" label="Ver facturas" disabled />;
              } else {
                return (
                  <Button
                    size="small"
                    label="Ver facturas"
                    onClick={() => {
                      setReceiptList(rowData);
                      setIsOpenReceiptList(true);
                    }}
                  />
                );
              }
            },
          },
        ]}
        data={data}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <TableHeader />
            </div>
          ),
        }}
        actions={[
          {
            hidden: isShowingColumns ? false : true,
            icon: () => <PostAddIcon />,
            tooltip: "Subir factura",
            onClick: (event, rowData) => {
              setActivePurchase(rowData.presentialConsultancy.hashid);
              setSelectedInvoice(rowData);
              document.getElementById("bill-upload").click(); // Upload bill
            },
          },
        ]}
        isLoading={isLoading ? true : false}
        loading={data ? false : true}
        localization={materialTableES}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
        }}
      />
    </section>
  );
}

//TODO: Update list after supplier add an url without close the modal.
function AddTrackingUrl({ handleClose, invoice }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(trackingUrlSchema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      await axios.post(
        `/v1/remodelings/invoices/${invoice.id}/trackings?whatsapp=true`,
        data
      );

      // Update remodeling_process_step "Mis pedidos" to "En proceso"
      await axios.patch(
        `/v1/presential-consultancy/steps/${invoice.presentialConsultancy.steps[4].id}`,
        {
          step_status_id: 2,
        }
      );

      setLoading(false);
      successToast("Has añadido un enlace de seguimiento exitosamente");
      handleClose();
    } catch (error) {
      handleError(error);
      setError("url", {
        type: "manual",
        message: error,
      });
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Agregar nuevo enlace
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="url"
          label="Enlace de seguimiento"
          variant="outlined"
          fullWidth
          inputRef={register}
        />
        {errors.url && <p className="error">{errors.url.message}</p>}
        <Button label="Agregar" type="submit" $isLoading={loading} />
      </form>
      <div style={{ padding: "1rem" }}>
        {invoice.trackings.length > 0 ? (
          <Typography variant="body1" gutterTop>
            Esta cotización tiene {invoice.trackings.length} enlace(s):
          </Typography>
        ) : (
          <p>Esta cotización no tiene enlaces de seguimiento</p>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {invoice.trackings && (
            <TrackingUrlList
              tracking={invoice.trackings}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
    </div>
  );
}

//TODO: Update list after supplier remove an url without close the modal.
const TrackingUrlList = (props) => {
  const onDelete = (id) => {
    try {
      axios.delete(`/v1/remodelings/trackUrl/${id}`).then((res) => {
        props.handleClose();
        successToast("Has eliminado un enlace de seguimiento exitosamente");
      });
    } catch (error) {}
  };

  return (
    <table>
      <tbody>
        {props.tracking.map((track, index) => (
          <tr>
            <th scope="row">{index + 1}.</th>
            <td
              style={{
                overflow: "hidden",
                maxWidth: "12rem",
                padding: "0.7rem",
              }}
            >
              <a href={track.url} target="_blank" rel="noopener noreferrer">
                {track.url}
              </a>
            </td>
            <td>
              <button
                type="button"
                onClick={() => {
                  onDelete(track.id);
                }}
                style={{
                  marginLeft: "10px",
                  background: "red",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <DeleteOutlineIcon style={{ color: "red" }} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// create data receipt and upload it
const ReceiptData = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [url, setUrl] = useState("");

  const handleSaveInvoice = async () => {
    try {
      setIsLoading(true);
      let formData = new FormData();

      const hashid = data.presentialConsultancy.hashid;

      let receiptData = await axios.post(
        `/v1/suppliers/${hashid}/document/receipt`,
        {
          presentialConsultancy: data.presentialConsultancy,
          payment: data.payments,
        }
      );

      // convert base64 to blob
      let blob = await fetch(
        `data:application/pdf;base64,${receiptData.data}`
      ).then((res) => res.blob());

      formData.append(
        "file",
        blob,
        `Datos de factura para proveedor ${hashid}`
      );

      let response = await axios.post(
        `/v1/suppliers/${hashid}/files/${fileTypes.supplierReceiptData}`,
        formData
      );

      let urlFile = response.data.url;
      setUrl(urlFile);
      window.open(urlFile);
      setIsPDF(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información del cliente</h3>
      <span>
        <i>Nombre:</i>{" "}
        {`${data.presentialConsultancy.client.first_name} ${data.presentialConsultancy.client.last_name}`}
      </span>
      <span>
        <i>Teléfono:</i> {data.presentialConsultancy.client.phone}
      </span>
      <span>
        <i>Dirección del proyecto/obra:</i>{" "}
        {data.presentialConsultancy.street_address}
      </span>
      <span>
        <i>Ciudad:</i> {data.presentialConsultancy.city}
      </span>
      <h3 style={{ marginTop: 10 }}>Datos de facturación</h3>

      {data.payments && data.payments[0].receipt_company_id ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <i>Razón Social:</i> {data.payments[0].company_name}
          </span>
          <span>
            <i>NIT:</i> {data.payments[0].NIT}
          </span>
          <span>
            <i>Dirección de la compañía:</i> {data.payments[0].company_address}
          </span>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <i>Nombre:</i> {data.payments[0].first_name}{" "}
            {data.payments[0].last_name}
          </span>
          <span>
            <i>Cédula:</i> {data.payments[0].document_number}
          </span>
        </div>
      )}
      <br />
      {isLoading && (
        <i>Danos unos segundos por favor mientras generamos el PDF</i>
      )}
      {/* TODO: Save the PDF link in this modal for not creating de PDF again. */}
      <div>
        {isPDF ? (
          <span>
            <h4>
              En caso de que no visualices el pdf por favor hacer clic en el
              siguiente enlace:{" "}
            </h4>
            <i>
              <a href={url} target="__blank">
                Datos de la facturación en PDF.
              </a>
            </i>
          </span>
        ) : (
          <Button
            label="Generar pdf"
            onClick={handleSaveInvoice}
            $isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

//TODO: Update list after supplier remove a receipt without close the modal.
function RecordOfReceipts({ handleClose, invoice }) {
  const onDelete = (id) => {
    try {
      axios
        .delete(`/v1/presential-consultancy/supplier-receipt/${id}`)
        .then((res) => {
          handleClose();
          successToast("Has eliminado una factura exitosamente");
        });
    } catch (error) {}
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Historial de facturas de la cotización pagada
      </Typography>

      <div style={{ padding: "1rem" }}>
        {invoice.supplierReceipts.length > 0 ? (
          <Typography variant="body1" gutterTop>
            Esta cotización tiene {invoice.supplierReceipts.length} factura(s):
          </Typography>
        ) : (
          <p>Esta cotización no tiene factura(s)</p>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <table>
            <tbody>
              {invoice.supplierReceipts &&
                invoice.supplierReceipts.map((supplierReceipt, index) => (
                  <tr>
                    <th scope="row">{index + 1}.</th>
                    <td
                      style={{
                        overflow: "hidden",
                        maxWidth: "12rem",
                        padding: "0.7rem",
                      }}
                    >
                      <a
                        href={supplierReceipt.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {supplierReceipt.filename}
                      </a>
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          onDelete(
                            supplierReceipt.presential_consultancy_file_id
                          );
                        }}
                        style={{
                          marginLeft: "10px",
                          background: "red",
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <DeleteOutlineIcon style={{ color: "red" }} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SalesManagement;
