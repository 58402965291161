import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import "./fab.scss";

function Fab() {
  return (
    <div>
      <div className="bg-icon-whatsapp">
        <a href="https://api.whatsapp.com/send?phone=573245882478&text=">
          <WhatsAppIcon
            style={{ color: "white", fontSize: "45", paddingTop: "6" }}
          />
        </a>
      </div>
    </div>
  );
}

export default Fab;
