import { useParams } from "react-router";
import BreadcrumbNav from "../../../../components/BreadcrumbNav/BreadcrumbNav";
import FullScreenLoading from "../../../../components/FullScreenLoading/FullScreenLoading";
import { useGetSalesPersonById } from "../../../../utils/react-query/querySupplier/queries";
import SalesPersonForm from "../../components/SalesPersonForm/SalesPersonForm";

const SalesPersonEdit = () => {
  const { asesorId } = useParams<{ asesorId: string }>();
  const { data: salesPerson, isLoading: isLoadingSalesPerson } =
    useGetSalesPersonById(asesorId || "");

  if (isLoadingSalesPerson || !salesPerson) {
    return <FullScreenLoading />;
  }

  return (
    <section className="layout-dashboard-container">
      <div>
        <div className="header-container">
          <h4 className="header-title">Editar Asesor</h4>
          <BreadcrumbNav
            firstText="Proveedor"
            secondText="asesor"
            secondLink="/proveedor/asesor/lista"
            thirdText={`${salesPerson.first_name} ${salesPerson.last_name}`}
          />
        </div>
        <SalesPersonForm action="update" salesPerson={salesPerson} />
      </div>
    </section>
  );
};

export default SalesPersonEdit;
