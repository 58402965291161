import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    currentText: {
      color: theme.palette.grey[500],
    },
  })
);

interface BreadcrumbNavProps {
  firstText: string;
  secondText: string;
  secondLink: string;
  thirdText: string;
}

const BreadcrumbNav: React.FC<BreadcrumbNavProps> = ({
  firstText,
  secondText,
  secondLink,
  thirdText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography color="textPrimary" className={classes.link}>
          {firstText}
        </Typography>
        <Link to={secondLink} className={classes.link}>
          {secondText}
        </Link>
        <Typography color="textPrimary" className={classes.currentText}>
          {thirdText}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbNav;
