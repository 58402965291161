import styles from "./orderSummarySection.module.scss";

interface OrderSummarySectionProps {
  children?: React.ReactNode;
}

const OrderSummarySection = ({ children }: OrderSummarySectionProps) => {
  return (
    <div>
      <div className={styles.container}>{children}</div>
    </div>
  );
};
export default OrderSummarySection;
