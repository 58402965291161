import axios from "axios";
import { handleError } from "../../utils/utils";

export async function getAllCategories() {
  try {
    const categories = await axios("/v2/categories");
    return categories.data;
  } catch (error) {
    handleError(error);
  }
}
