import { yupResolver } from "@hookform/resolvers";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { clientConfigSchema } from "../../services/formValidation";
import { successToast } from "../../services/toasts";
import Button from "../../components/EcommerceActionButton/Button";

const Config = () => {
  const { register, errors, setError, handleSubmit } = useForm({
    resolver: yupResolver(clientConfigSchema),
  });

  const [client, setClient] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getClient = async () => {
    let { data } = await axios.get(`/v1/clients/`);
    setClient(data);
  };

  // Send data from the form
  const onSubmit = async (data) => {
    try {
      if (data.email !== client.email) {
        await axios.post("/v1/users/check", data.email);
      }
      handleUpdate(data);
    } catch (error) {
      setError("email", {
        type: "manual",
        message: error.response.data.message,
      });
    }
  };

  // Update client data
  const handleUpdate = async (data) => {
    setIsLoading(true);

    let obj = {
      first_name: data.first_name,
      last_name: data.last_name,
      cedula: data.cedula,
      email: data.email,
      phone: data.phone,
    };
    await axios.patch(`/v1/clients/`, obj);
    setIsLoading(false);

    successToast("Has actualizado tus datos exitosamente");
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <section className="layout-dashboard-container">
      <Title>Datos personales</Title>
      <Paper
        style={{ flex: 1, padding: "2rem", width: "100%", marginTop: "1rem" }}
      >
        {client && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <InputContainer>
                <StyledLabel>Nombres</StyledLabel>
                <StyledInput
                  placeholder="Nombres"
                  name="first_name"
                  defaultValue={client.first_name}
                  ref={register}
                />
              </InputContainer>
              <InputContainer>
                <StyledLabel>Apellidos</StyledLabel>
                <StyledInput
                  placeholder="Apellidos"
                  name="last_name"
                  defaultValue={client.last_name}
                  ref={register}
                />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <StyledLabel>Cédula</StyledLabel>
                <StyledInput
                  placeholder="Cédula"
                  name="cedula"
                  defaultValue={client.cedula}
                  ref={register}
                />
              </InputContainer>
              <InputContainer>
                <StyledLabel>Email</StyledLabel>
                <StyledInput
                  placeholder="Email"
                  name="email"
                  defaultValue={client.email}
                  ref={register}
                  disabled
                />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <StyledLabel>Teléfono</StyledLabel>
                <StyledInput
                  placeholder="Teléfono"
                  name="phone"
                  defaultValue={client.phone}
                  ref={register}
                />
              </InputContainer>
            </Row>
            <Row>
              <Button
                color="primary"
                shape="little-rounded"
                size="small"
                $isLoading={isLoading}
              >
                Editar
              </Button>
            </Row>
          </form>
        )}
      </Paper>
    </section>
  );
};

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledLabel = styled.label`
  flex-grow: 1;
  font-family: "Open Sans";
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 300;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  font-family: "Open sans";
  padding: 0.5rem 1rem;
  border: 1px solid lightgray;
`;

export default Config;
