import { Controller, useForm } from "react-hook-form";
import { Image } from "../../../../../../ecommerce/pages/ProductInformation/models/productDescription";
import { useUpdatePrincipalImage } from "../../../../../../utils/react-query/querySupplier/queries";
import SingleFileUploader from "./SingleFileUploader";
import { errorToast, successToast } from "../../../../../../services/toasts";
import { useState } from "react";

type PrincipalImageFormProps = {
  principalImage: Image[];
  productId: number;
};

const PrincipalImageForm = ({
  principalImage,
  productId,
}: PrincipalImageFormProps) => {
  const {
    mutateAsync: updatePrincipalImage,
    isLoading: isUpdatingPrincipalImage,
  } = useUpdatePrincipalImage();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      principalImage: principalImage[0],
    },
  });

  async function onSubmitPrincipalImg(data: any) {
    const formdata = new FormData();

    formdata.append("principalImage", data.principalImage[0]);

    const updatedPrincipalImage = await updatePrincipalImage({
      productId,
      imageId: principalImage.length > 0 ? principalImage[0].id : null,
      imageFile: formdata,
    });
    if (!updatedPrincipalImage) {
      errorToast("Error al actualizar la imagen principal");
    }

    setIsButtonDisabled(true);
    return successToast("Imagen principal actualizada correctamente");
  }

  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmitPrincipalImg)();
        }}
      >
        <Controller
          name="principalImage"
          control={control}
          render={({ onChange }) => (
            <SingleFileUploader
              image={principalImage[0]}
              fileldChange={onChange}
              isLoading={isUpdatingPrincipalImage}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              fileType="image"
            />
          )}
        />
      </form>
    </div>
  );
};

export default PrincipalImageForm;
