import axios from "axios";
import { handleError } from "../utils/utils";
import { errorToast, successToast } from "./toasts";

/*
 * This file is deprecated, will be removed soon
 */

const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://serene-shore-26997.herokuapp.com"
    : "";

export function getRequest(route) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/${route}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function postRequest(route, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/${route}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.response.data);
      // return error.response;
    });
}

export function putRequest(route, data) {
  return axios
    .put(`${API_BASE_URL}/r1d/api/${route}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // throw new Error(error.response.data.message);
      return error;
    });
}

export function deleteRequest(route, data) {
  return axios
    .delete(`${API_BASE_URL}/r1d/api/${route}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET requests

// GET user location through ip-api
// TODO: Check why ip-api is not working on production
export function getIP() {
  return axios
    .get("http://ip-api.com/json")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET client public information for designer
export function getClientCard(id) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/client-card/` + id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET designer public information for admin
export function getDesignerCard(id) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/designer-card/` + id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET client information using auth token
export function getClient() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/client/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET designer information using auth token
export function getDesigner() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/designer/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET /cities (home page and designer's form)
export function getCities() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/cities`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET /public-designers (select designer carousel)
export function getDesigners() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/public-designers`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET /public-designers and filter by user identified city
export async function getDesignersOnCity() {
  var userCity;
  await getIP().then((res) => (userCity = res.city));
  return axios
    .get(`${API_BASE_URL}/r1d/api/public-designers`)
    .then((res) => {
      var designersOnCity = res.data.filter(function (el) {
        return el.city_name === userCity;
      });
      if (designersOnCity.length > 0) {
        return designersOnCity;
      } else {
        return res.data;
      }
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET /purchase-info (client dashboard)
export function getPurchaseInfo(id) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/purchase-info/` + id)
    .then((res) => {
      return res.data;
    });
  // Catch is handled on purchase.js file
}

// GET /purchase (client dashboard)
export function getPurchase(id) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/purchase/` + id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET /steps (client dashboard)
export function getPurchaseSteps(id) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/stepsByPurchaseId/` + id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// GET /payed-purchases for LSH management
export function getPayedPurchases() {
  return (
    axios
      //TODO: Use API_BASE_URL when Heroku side is created
      // .get(`${API_BASE_URL}/r1d/api/payed-purchases/`)
      .get("/v1/remodelings")
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        handleError(error);
      })
  );
}

// GET approved budgets for checkout
export function getApprovedBudgets(hashid) {
  return axios.get(`${API_BASE_URL}/r1d/api/checkout/` + hashid).then((res) => {
    return res.data;
  });
  // catch is handled in checkout.js
}

export function getPasswordReset(id, token) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/password-reset/${id}/${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function getVerifyEmailAcceptOrder(token) {
  return axios
    .get(`${API_BASE_URL}/r1d/api/verify-email-quote/${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function getDesignerProducts() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/designer/products`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function getLSHProducts() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/lsh/products`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function getAdminProducts() {
  return axios
    .get(`${API_BASE_URL}/r1d/api/admin/products`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST requests

export function getAccessoryProducts(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/subproducts`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function createInvoice(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/invoice/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function saveInvoice(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/invoice/save`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function postPasswordReset(id, token, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/password-reset/${id}/${token}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

export function postPasswordResetRequest(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/password-reset-request`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST route for PayU, create reference and payment record
export function postPayUCheckout(hashid, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/payu-checkout/` + hashid, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST route for Wompi, create reference and payment record
export function postWompiCheckout(hashid, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/wompi-checkout/` + hashid, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST check if user already exists
export function postReceipt(receipt_type, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/receipt/${receipt_type}`, data) // Separate receipt_person and company.
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST checkout
export function postUserCheck(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/usercheck/`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST /clients (client signup)
export function postClient(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/clients`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST /purchase (client signup)
export function postPurchase(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/purchase`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// POST /authenticate (client, designer or admin login)
export function postAuth(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/authenticate`, data)
    .then((res) => {
      return res.data;
    });
}

// POST /designer-form
export function postDesignerForm(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/designer-form`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// TODO: Check if this api is still in use, it seems is out-of-date
// POST legal document (LSH, designer)
export function postLegalDoc(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/legal-doc`, data)
    .then((res) => {
      successToast("¡Has añadido tu documento exitosamente!");
      return res.data;
    })
    .catch((error) => {
      errorToast("¡Ups! Un error ha ocurrido, por favor intentalo de nuevo.");
      handleError(error);
    });
}

// POST portfolio image (designer config)
export function postPortfolioImage(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/portfolio-images`, data)
    .then((res) => {
      successToast("¡Has añadido una imagen exitosamente!");
      return res.data;
    })
    .catch((error) => {
      errorToast("¡Ups! Un error ha ocurrido, por favor intentalo de nuevo.");
      handleError(error);
    });
}

// POST handle profile image update
export function profileImageUpdate(data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/profile-image`, data)
    .then((res) => {
      successToast("¡Has cambiado tu imagen de perfil exitosamente!");
      return res.data;
    })
    .catch((error) => {
      errorToast("¡Ups! Un error ha ocurrido, por favor intentalo de nuevo.");
      handleError(error);
    });
}

// POST design approval
export function postDesignApproval(hashid, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/approve-design/` + hashid, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      errorToast("¡Ups! Un error ha ocurrido, por favor intentalo de nuevo.");
      handleError(error);
    });
}

// POST design approval
export function postBudgetApproval(hashid, data) {
  return axios
    .post(`${API_BASE_URL}/r1d/api/approve-budget/` + hashid, data)
    .then((res) => {
      successToast(
        `
		r1d te separa el material aprobado en presupuesto durante 3 días,
		si pasado este tiempo no se realiza el pago los materiales quedaran 
		disponibles nuevamente`
      );
      return res.data;
    })
    .catch((error) => {
      errorToast("¡Ups! Un error ha ocurrido, por favor intentalo de nuevo.");
      handleError(error);
    });
}

// PUT requests

// PUT /product
export function updateProduct(data) {
  return axios
    .put(`${API_BASE_URL}/r1d/api/product/`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// PUT /purchase (client dashboard)
export function editPurchase(hashid, data) {
  return axios
    .put(`${API_BASE_URL}/r1d/api/purchaseByHashid/${hashid}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// PUT /designer
export function updateDesigner(data, id) {
  return axios
    .put(`${API_BASE_URL}/r1d/api/designers/` + id, data)
    .then((res) => {
      setTimeout(() => {
        successToast("¡Has actualizado tu perfil exitosamente!");
        return res.data;
      }, 1000);
    })
    .catch((error) => {
      errorToast("¡Ups! Un error ha ocurrido, por favor intentalo de nuevo.");
      handleError(error);
    });
}

// PUT toggle designer status
export function toggleStatus(id) {
  return axios
    .put(`${API_BASE_URL}/r1d/api/designers/toggle-status/` + id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}

// DELETE requests

// DELETE portfolio image
export function deleteFile(id, id_file_type) {
  return axios
    .delete(`${API_BASE_URL}/r1d/api/file/${id}/${id_file_type}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      handleError(error);
    });
}
