import { Fragment } from "react";
import { History } from "history";
import { Remodeling, Step } from "../types";
import { StatusPill, StatusDot, BeadContainer, Bead, BeadLine } from "./styles";

import DesignsList from "./DesignsList";
import InvoicesList from "./InvoicesList";
import InstallersList from "./InstallersList";
import TrackingLinksList from "./TrackingLinksList";

import InfoIcon from "@material-ui/icons/Info";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ReceiptIcon from "@material-ui/icons/Receipt";

import moment from "moment";
import axios from "axios";

/**
 * TODO: We can improve on the 'data': any prop that's being passed on some
 * components, not all the information is needed so it's causing clutter, and
 * currently it has a type of any which should be changed to specify a type.
 *
 * TODO: Some of the constants defined here are useful globally, move them into
 * the /lib folder (currently named /services).
 */

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

// TODO: Move this to types.ts file
export type StepName =
  | "visit_date"
  | "design"
  | "budget"
  | "payment"
  | "my_orders"
  | "contractors_installing";

export const stepNames: {
  [key in StepName]: string;
} = {
  visit_date: "Fecha de visita",
  design: "Diseño",
  budget: "Presupuesto",
  payment: "Pago",
  my_orders: "Mis pedidos",
  contractors_installing: "Instalación",
};

export const stepStatuses = {
  pending: 1,
  inProgress: 2,
  finished: 3,
};

const colors = {
  grey: "#AFAFAF",
  red: "#D01C24",
  yellow: "#E2C37E",
  green: "#39984A",
};

/**
 * Renders the circle beads at the beginning of the table
 */
const statusBeads = {
  [stepStatuses.pending]: (
    <BeadContainer>
      <BeadLine />
      <Bead color={colors.grey} />
    </BeadContainer>
  ),
  [stepStatuses.inProgress]: (
    <BeadContainer>
      <BeadLine />
      <Bead color={colors.yellow} />
    </BeadContainer>
  ),
  [stepStatuses.finished]: (
    <BeadContainer>
      <BeadLine />
      <Bead color={colors.green} />
    </BeadContainer>
  ),
};

/**
 * Renders the colored dots on the "status" column
 */
const statusPills = {
  [stepStatuses.pending]: (
    <StatusPill>
      <StatusDot color={colors.grey} />
      Pendiente
    </StatusPill>
  ),
  [stepStatuses.inProgress]: (
    <StatusPill>
      <StatusDot color={colors.yellow} />
      <span style={{ textAlign: "left", whiteSpace: "nowrap" }}>
        En proceso
      </span>
    </StatusPill>
  ),
  [stepStatuses.finished]: (
    <StatusPill>
      <StatusDot color={colors.green} />
      Completado
    </StatusPill>
  ),
};

/**
 * Renders the colored dots on the "status" column on mobile
 */
const statusPillsMobile = {
  [stepStatuses.pending]: (
    <StatusPill>
      <StatusDot color={colors.red} />
    </StatusPill>
  ),
  [stepStatuses.inProgress]: (
    <StatusPill>
      <StatusDot color={colors.yellow} />
      <span style={{ textAlign: "left", whiteSpace: "nowrap" }}></span>
    </StatusPill>
  ),
  [stepStatuses.finished]: (
    <StatusPill>
      <StatusDot color={colors.green} />
    </StatusPill>
  ),
};

export const statusTableColumns = (
  handleShowStepHelpModal: (stepName: StepName) => void,
  isSmallScreen: boolean,
  isDesigner: boolean
) => {
  const defaultProps = {
    width: "0px",
    editable: "never",
  };

  const statusColumnMobileStyles = isSmallScreen && {
    headerStyle: {
      textAlign: "center",
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  };

  return [
    {
      ...defaultProps,
      cellStyle: isSmallScreen && {
        paddingLeft: "15px",
      },
      render: ({ step_status_id }: Step) => statusBeads[step_status_id],
    },
    {
      ...defaultProps,
      render: ({ name: stepName }: Step) =>
        isDesigner && (
          <InfoIcon
            onClick={() => handleShowStepHelpModal(stepName)}
            style={{ color: "#AFAFAF", cursor: "pointer" }}
          />
        ),
    },
    {
      title: "Actividad",
      field: "name",
      editable: "never",
      lookup: stepNames,
    },
    {
      title: "Ejecución",
      field: "date",
      type: "date",
      editable: (_: any, { name: stepName }: Step) => stepName !== "my_orders",
      render: ({
        name: stepName,
        step_status_id: number,
        date,
        updated_at,
      }: Step) => {
        // Steps that show last updated or normal date
        const isStepThatShowDate = [
          "visit_date",
          "contractors_installing",
        ].includes(stepName);
        const isStepThatShowLastUpdated = [
          "design",
          "budget",
          "payment",
        ].includes(stepName);

        // Format the date of the step that was last updated, or normal date
        const updatedAtDate = moment(updated_at)
          .toDate()
          .toLocaleDateString("es-ES", DATE_OPTIONS);
        const columnDate = moment(date)
          .toDate()
          .toLocaleDateString("es-ES", DATE_OPTIONS);
        const defaultDate = "-- --";

        // Conditionally show one...
        if (number === stepStatuses.pending) {
          return defaultDate;
        } else if (isStepThatShowDate) {
          return columnDate;
        } else if (isStepThatShowLastUpdated) {
          return updatedAtDate;
        }
      },
    },
    {
      ...statusColumnMobileStyles,
      title: isSmallScreen ? "Est." : "Estado",
      field: "step_status_id",
      editable: (_: any, { name: stepName }: Step) =>
        stepName !== "contractors_installing",
      lookup: {
        1: "Pendiente",
        2: "En proceso...",
        3: "Completado",
      },
      render: ({ step_status_id }: Step) =>
        isSmallScreen
          ? statusPillsMobile[step_status_id]
          : statusPills[step_status_id],
    },
  ];
};

export const statusTableDetail = (
  isClient: boolean,
  isDesigner: boolean,
  isSupplier: boolean,
  data: Remodeling,
  handleDesignApproval: (design_id: number) => Promise<void>,
  handleInvoiceApproval: (invoice_id: number) => Promise<void>
) => {
  const defaultProps = {
    icon: () => <VisibilityIcon />,
    openIcon: () => <VisibilityOffIcon />,
  };

  return [
    ({ name: stepName }: Step) => {
      if (stepName === "design") {
        return {
          ...defaultProps,
          tooltip: "Ver diseños",
          render: () => (
            <DesignsList
              designs={data.designs}
              handleDesignApproval={handleDesignApproval}
              isClient={isClient}
              isDesigner={isDesigner}
            />
          ),
        };
      } else if (stepName === "budget") {
        return {
          ...defaultProps,
          tooltip: "Ver presupuestos",
          render: () => (
            <InvoicesList
              invoices={data.invoices}
              remodeling={data}
              handleInvoiceApproval={handleInvoiceApproval}
              isClient={isClient}
              isDesigner={isDesigner}
            />
          ),
        };
      } else if (stepName === "my_orders") {
        return {
          ...defaultProps,
          tooltip: "Ver detalle",
          render: () => <TrackingLinksList invoices={data.invoices} />,
        };
      } else if (
        stepName === "contractors_installing" &&
        data.installers.length > 0 &&
        !isSupplier
      ) {
        return {
          ...defaultProps,
          tooltip: "Ver detalle",
          render: () => <InstallersList installers={data.installers} />,
        };
      } else {
        return {
          icon: () => <VisibilityIcon style={{ display: "none" }} />,
          render: () => <Fragment></Fragment>,
          disabled: true,
        };
      }
    },
  ];
};

export const statusTableActions = (
  isDesigner: boolean,
  steps: Step[],
  data: Remodeling,
  history: History
) => {
  if (!isDesigner) return;

  return [
    ({ name: stepName }: Step) => {
      if (stepName === "design") {
        return {
          icon: () => <AddPhotoAlternateIcon />,
          tooltip: "Añadir diseño",
          onClick: () => document.getElementById("design_files")?.click(),
        };
      } else if (stepName === "budget") {
        return {
          icon: () => <ReceiptIcon />,
          tooltip: "Añadir cotización",
          onClick: () => {
            /**
             * NOTE: we're stripping down the "data" object to fix a bug where clicking on the
             * button with detail panel open fails to execute "pushState".
             *
             * See https://stackoverflow.com/a/52939423/6206076
             */
            const stepsId = steps.map((step: Step) => {
              return { id: step.id };
            });

            // Data necessary to send to the product management page to create an invoice
            const quotePurchase = {
              client: data.client,
              steps: stepsId,
              hashid: data.hashid,
              address: data.address,
            };

            history.push({
              pathname: "/disenador/productos",
              state: { quotePurchase },
            });
          },
        };
      }
    },
  ];
};

export const statusTableEditable = (
  isDesigner: boolean,
  steps: Step[],
  updateData: () => void
) => {
  const stepsWithoutEditable = ["design", "budget", "payment", "my_orders"];

  if (isDesigner) {
    return {
      isEditHidden: ({ name: stepName }: Step) =>
        stepsWithoutEditable.includes(stepName),
      onRowUpdate: async (newData: Step, oldData: Step) => {
        const stepWasPending = oldData.step_status_id === stepStatuses.pending;
        const isDateUpdated = oldData.date !== newData.date;
        const myOrdersStep = steps[4];

        let stepStatus = newData.step_status_id;

        // If visit or installation steps were pending and the date was updated, it means
        // that the status should be in progress now...
        if (stepWasPending && isDateUpdated) {
          stepStatus = stepStatuses.inProgress;
        }

        await axios.patch(
          `/v1/remodelings/steps/${oldData.id}${
            isDateUpdated && "?whatsapp=true"
          }`,
          {
            step_status_id: stepStatus,
            date: moment(newData.date).format("YYYY-MM-DD HH:mm:ss"),
          }
        );

        // If the installation date changes, update "Mis pedidos" to be completed
        if (isDateUpdated && oldData.name === "contractors_installing") {
          await axios.patch(`/v1/remodelings/steps/${myOrdersStep.id}`, {
            step_status_id: stepStatuses.finished,
          });
        }

        updateData();
      },
    };
  }
};

export const statusTableOptions = {
  actionsColumnIndex: -1,
  detailPanelColumnAlignment: "right",
  toolbar: false,
  paging: false,
  search: false,
  draggable: false,
  sorting: false,
};
