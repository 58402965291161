import { type Location } from "../types";
import { enviaCities } from "../../services/enviaCities";
import { handleError } from "../../utils/utils";

export async function fetchCityOptions() {
  try {
    const options = enviaCities.map((city: Location) => ({
      value: city.code,
      label: city.name,
    }));
    return options;
  } catch (error) {
    handleError(error);
  }
}
