import { FormControl, MenuItem, Select } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import React from "react";
import styles from "./orderSort.module.scss";

type OrderSortProps = {
  filterType: string;
  setFilterType: (filterType: string) => void;
};

const OrderSort = ({ filterType, setFilterType }: OrderSortProps) => {
  const handleFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterType(event.target.value as string);
  };

  return (
    <div className={styles.selectInput}>
      <FormControl variant="outlined">
        <Select
          value={filterType}
          onChange={handleFilter}
          startAdornment={<FilterListIcon className={styles.selectIcon} />}
          className={styles.select}
        >
          <MenuItem value="all">Todas</MenuItem>
          <MenuItem value="this-month">Este mes</MenuItem>
          <MenuItem value="last-month">Mes pasado</MenuItem>
          <MenuItem value="this-year">Este año</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default OrderSort;
