import { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../services/auth";

export const useCurrentProfile = () => {
  const { currentRoleId } = useAuth();
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!currentRoleId) return null;
      try {
        const response = await Axios.get<Profile>("/v1/users/profile");
        setProfile(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProfile();
  }, [currentRoleId, setProfile]);

  return { profile };
};
