import { Tabs } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useParams } from "react-router-dom";

import CustomMaterialTable from "../../../../components/CustomMaterialTable/CustomMaterialTable";
import Tab from "../../../../components/CustomTab/CustomTab";
import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import { orderStatus } from "../../../../data/orderStatus";
import useFetch from "../../../../ecommerce/hooks/useFetch";
import useFilteredData from "../../../../hooks/useFilteredData";
import OrdersAdminTableColumns from "./components/OrdersAdminTableColumns/OrdersAdminTableColumns";

import OrderStatusBadge from "../../../../components/OrderStatusBadge/OrderStatusBadge";
import { type Order, type RouteParams } from "./types";
import CustomButton from "../../../../components/Button/CustomButton";

const OrdersAdminTable = () => {
  const history = useHistory();
  const { purchaseId, supplierId } = useParams<RouteParams>();

  const url = `/v2/admin/suborders/${purchaseId}/${supplierId}`;
  const { isLoading, apiData: orders } = useFetch<Order>({
    method: "GET",
    url,
  });

  const { selectedTab, handleTabChange, filteredData, orderStatusCounts } =
    useFilteredData(orders?.suborders || []);

  if (isLoading || !orders) {
    return <LoadingScreen />;
  }
  return (
    <section className="layout-dashboard-container">
      <CustomButton
        size="small"
        color="outlined"
        shape="little-rounded"
        icon={ArrowBackIosIcon}
        onClick={() => history.goBack()}
      >
        Regresar
      </CustomButton>
      <br />
      <CustomMaterialTable
        title="Ordenes"
        columns={OrdersAdminTableColumns()}
        data={filteredData}
      >
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => handleTabChange(value)}
        >
          <Tab
            label={
              <OrderStatusBadge
                status="En preparacion"
                count={orderStatusCounts.Preparation}
              />
            }
            value={orderStatus.Preparation}
          />
          <Tab
            label={
              <OrderStatusBadge
                status="En transito"
                count={orderStatusCounts.Shipped}
              />
            }
            value={orderStatus.Shipped}
          />
          <Tab
            label={
              <OrderStatusBadge
                status="Entregado"
                count={orderStatusCounts.Delivered}
              />
            }
            value={orderStatus.Delivered}
          />
          <Tab
            label={
              <OrderStatusBadge
                status="Cancelado"
                count={orderStatusCounts.Canceled}
              />
            }
            value={orderStatus.Canceled}
          />
        </Tabs>
      </CustomMaterialTable>
    </section>
  );
};

export default OrdersAdminTable;
