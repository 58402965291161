import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { type CompanyBanner } from "../../privatePages/supplier/types";

export interface CompanyData {
  companyPrimaryColor: string;
  companySecondaryColor: string;
  companyName: string;
  companyFontType: string;
  companyLogo: string | null;
  companyBanner: CompanyBanner[];
}

interface CompanyStoreState extends CompanyData {
  isLoading: boolean;
  setError: (error: string) => void;
  set: (data: Partial<CompanyStoreState>) => void;
}

const useCompanyStore = create<CompanyStoreState>()(
  persist(
    (set, get) => ({
      companyPrimaryColor: "",
      companySecondaryColor: "",
      companyFontType: "",
      companyName: "",
      companyLogo: null,
      companyBanner: [],
      isLoading: true,
      setError: (error: string) => {
        set({
          isLoading: false,
        });
      },
      set: (data: Partial<CompanyStoreState>) => {
        set(data);
      },
    }),
    {
      name: "company",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useCompanyStore;
