import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers";
import { Grid, Paper, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import CustomButton from "../../../../../../components/Button/CustomButton";
import TooltipLargeText from "../../../../../../components/Tooltip/TooltipLargeText";
import InputValidationMessage from "../../../../../../components/forms/InputValidationMessage/InputValidationMessage";
import { lampTypes, lightTypes } from "../../../../../../constants";
import { ProductDescription } from "../../../../../../ecommerce/pages/ProductInformation/models/productDescription";
import { successToast, errorToast } from "../../../../../../services/toasts";
import { useStyles } from "../../../../../../theme/FormStyles";
import { useGetAllCategories } from "../../../../../../utils/react-query/queryEcommerce/queries";
import {
  useGetAllAddresses,
  useGetAllColors,
  useUpdateProductGeneralInfo,
} from "../../../../../../utils/react-query/querySupplier/queries";
import {
  createComissionValues,
  getProductFormSchema,
} from "../../../../../../utils/utils";
import informationFieldsForm from "../../../../data/informationFieldsForm";
import { type Address } from "../../../../types";

type GeneralInfoFormProps = {
  product: ProductDescription;
};

const GeneralInfoForm = ({ product }: GeneralInfoFormProps) => {
  const [categorieInput, setCategorieInput] = useState(product.category_id || 1);
  const classes = useStyles();
  const selectedProductFormSchema = useMemo(
    () => getProductFormSchema(product.category_id),
    [product.category_id]
  );

  const { data: colors } = useGetAllColors();
  const { data: storageAddresses } = useGetAllAddresses();
  const { data: categories } = useGetAllCategories();
  const { mutateAsync: updateProduct, isLoading: isLoadingUpdate } =
    useUpdateProductGeneralInfo();

  let comissionValues = createComissionValues(20, 65);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(selectedProductFormSchema),
    defaultValues: {
      name: product.name,
      brand: product.brand,
      category_id: product.category_id,
      material: product.material,
      min_sale_unit: product.min_sale_unit,
      units_per_package: product.units_per_package,
      price: product.price,
      size: product.size,
      units_available: product.units_available,
      comission_percentage: product.comission_percentage,
      delivery_time: product.delivery_time,
      is_warranty_in_months: product.is_warranty_in_months ? 1 : 0,
      origin: product.origin,
      product_content: product.product_content,
      light_type_id: product.light_type_id,
      lamp_type_id: product.lamp_type_id,
      description: product.description,
      warranty: product.warranty,
      color_id: product.color_id,
      storage_address_id: product.storage_address_id,
      is_unique: product.is_unique ? 1 : 0,
    },
  });

  async function onSubmit(data: any) {
    const updatedProduct = await updateProduct({
      productId: product.product_id,
      productData: data,
    });

    if (!updatedProduct) {
      errorToast("Error al actualizar la información del producto");
    }

    if (updatedProduct) {
      return successToast("Producto actualizado con éxito");
    }
  }

  return (
    <>
      <form
        className="formProductContainer"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Paper className="paperFormContainer">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl fullWidth variant="outlined">
                <TooltipLargeText
                  icon={InfoOutlinedIcon}
                  text={informationFieldsForm.name}
                  color="#F4BF2A"
                  fontSize="1.2rem"
                />
                <InputLabel>Referencia o nombre</InputLabel>
                <OutlinedInput
                  name="name"
                  inputRef={register}
                  label="Referencia o nombre"
                  labelWidth={175}
                />
              </FormControl>
              <InputValidationMessage message={errors?.name?.message} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Marca</InputLabel>
                <OutlinedInput
                  name="brand"
                  inputRef={register}
                  label="Marca"
                  labelWidth={175}
                />
              </FormControl>
              <InputValidationMessage message={errors?.brand?.message} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Material del producto</InputLabel>
                <OutlinedInput
                  name="material"
                  inputRef={register}
                  label="Material del producto"
                  labelWidth={175}
                />
              </FormControl>
              <InputValidationMessage message={errors?.material?.message} />
            </Grid>

            {categories && (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Categoría
                  </InputLabel>
                  <Select
                    onChange={({ target }) => {
                      setCategorieInput(Number(target.value));

                    }}
                    disabled={true}
                    inputRef={register}
                    native
                    label="Categoría"
                    name="category_id"
                  >
                    {categories?.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage
                  message={errors?.category_id?.message}
                />
              </Grid>
            )}

            {product.category_id === 8 || product.category_id === 9 ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <FormControl variant="outlined" fullWidth>
                    <TooltipLargeText
                      icon={InfoOutlinedIcon}
                      text={informationFieldsForm.minimumSellingUnit}
                      color="#F4BF2A"
                      fontSize="1.2rem"
                    />
                    <InputLabel>Unidad minima venta</InputLabel>
                    <OutlinedInput
                      name="min_sale_unit"
                      inputRef={register}
                      label="Unidad minima venta"
                      endAdornment={
                        <InputAdornment position="end">m²</InputAdornment>
                      }
                      labelWidth={175}
                    />
                  </FormControl>
                  <InputValidationMessage
                    message={errors?.min_sale_unit?.message}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Unidades por empaque</InputLabel>
                    <OutlinedInput
                      name="units_per_package"
                      inputRef={register}
                      label="Unidades por empaque"
                      labelWidth={175}
                    />
                  </FormControl>
                  <InputValidationMessage
                    message={errors?.units_per_package?.message}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Precio al público sin IVA</InputLabel>
                <OutlinedInput
                  name="price"
                  inputRef={register}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  labelWidth={180}
                />
              </FormControl>
              <InputValidationMessage message={errors?.price?.message} />
            </Grid>

            {categorieInput === 4 ||
            categorieInput === 8 ||
            categorieInput === 9 ||
            categorieInput === 11 ||
            categorieInput === 12 ? null : (
            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl
                fullWidth
                variant="outlined"
              >
                <TooltipLargeText
                  icon={InfoOutlinedIcon}
                  text={informationFieldsForm.uniqueProduct}
                  color="#F4BF2A"
                  fontSize="1.2rem"
                />

                <InputLabel htmlFor="outlined-age-native-simple">
                  Producto único
                </InputLabel>
                <Select
                  inputRef={register}
                  native
                  id="is_unique"
                  label="Producto único"
                  name="is_unique"
                >
                  <option aria-label="None" value="" />
                  <option value={1}>Si</option>
                  <option value={0}>No</option>
                </Select>
              </FormControl>
            <InputValidationMessage message={errors?.is_unique?.message} />
              </Grid>
          )}

            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <TextField
                name="units_available"
                inputRef={register}
                label="Inventario en unidades"
                variant="outlined"
                fullWidth
              />
              <InputValidationMessage
                message={errors?.units_available?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl variant="outlined" fullWidth>
                <TooltipLargeText
                  icon={InfoOutlinedIcon}
                  text={informationFieldsForm.comission}
                  color="#F4BF2A"
                  fontSize="1.2rem"
                />
                <InputLabel htmlFor="outlined-age-native-simple">
                  Comisión
                </InputLabel>
                <Select
                  inputRef={register}
                  native
                  label="Comisión"
                  name="comission_percentage"
                >
                  {comissionValues.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <InputValidationMessage
                message={errors?.comission_percentage?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.gridMarginTop}>
              <FormControl variant="outlined" fullWidth>
                <TooltipLargeText
                  icon={InfoOutlinedIcon}
                  text={informationFieldsForm.deliveryTime}
                  color="#F4BF2A"
                  fontSize="1.2rem"
                />
                <InputLabel>Tiempo de entrega</InputLabel>
                <OutlinedInput
                  name="delivery_time"
                  inputRef={register}
                  label="Tiempo de entrega"
                  endAdornment={
                    <InputAdornment position="end">días</InputAdornment>
                  }
                  labelWidth={175}
                />
              </FormControl>
              <InputValidationMessage
                message={errors?.delivery_time?.message}
              />
            </Grid>
            {product.category_id === 3 ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Tipo de luz
                    </InputLabel>
                    <Select
                      inputRef={register}
                      native
                      label="Tipos de luz"
                      name="light_type_id"
                    >
                      {lightTypes?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <InputValidationMessage
                    message={errors?.light_type_id?.message}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Tipo de lámpara
                    </InputLabel>
                    <Select
                      inputRef={register}
                      native
                      label="Tipos de lámpara"
                      name="lamp_type_id"
                    >
                      {lampTypes?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <InputValidationMessage
                    message={errors?.lamp_type_id?.message}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={6} sm={3} md={3} className={classes.gridMarginTop}>
              <TextField
                fullWidth
                name="warranty"
                inputRef={register}
                label="Garantía (opcional)"
                variant="outlined"
              />
              <InputValidationMessage message={errors?.warranty?.message} />
            </Grid>
            <Grid item xs={6} sm={3} md={3} className={classes.gridMarginTop}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Garantía en
                </InputLabel>
                <Select
                  inputRef={register}
                  native
                  label="Garantía en"
                  name="is_warranty_in_months"
                >
                  <option value={1}>Meses</option>
                  <option value={0}>Años</option>
                </Select>
              </FormControl>
              <InputValidationMessage
                message={errors?.is_warranty_in_months?.message}
              />
            </Grid>
            {colors && (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl variant="outlined" fullWidth>
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={informationFieldsForm.color}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Color
                  </InputLabel>
                  <Select
                    inputRef={register}
                    native
                    label="Color"
                    name="color_id"
                  >
                    {colors?.map((item: any) => (
                      <option
                        style={{
                          backgroundColor: item.hex,
                          color: item.id === 2 ? "white" : "black",
                        }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage message={errors?.color_id?.message} />
              </Grid>
            )}
            {storageAddresses && (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl variant="outlined" fullWidth>
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={informationFieldsForm.shippingAddress}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Dirección de despacho
                  </InputLabel>
                  <Select
                    inputRef={register}
                    native
                    label="Dirección de despacho"
                    name="storage_address_id"
                  >
                    {storageAddresses.map((item: Address) => (
                      <option key={item.id} value={item.id}>
                        {item.street_address} {item.city_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage
                  message={errors?.storage_address_id?.message}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} className={classes.gridMarginTop}>
              <TextField
                name="description"
                inputRef={register}
                label="Descripción general"
                variant="outlined"
                fullWidth
                multiline
              />
              <InputValidationMessage message={errors?.description?.message} />
            </Grid>
          </Grid>
        </Paper>
        <div className="formProductSaveButton">
          <CustomButton
            type="submit"
            color="secondary"
            shape="little-rounded"
            size="medium"
            isLoading={isLoadingUpdate}
          >
            Guardar Cambios
          </CustomButton>
        </div>
      </form>
    </>
  );
};
export default GeneralInfoForm;
