import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { validateFile } from "../../services/formValidation";
import { materialTableES } from "../../services/materialTableES";

import NoteAddIcon from "@material-ui/icons/NoteAdd"; // Policy
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PostAddIcon from "@material-ui/icons/PostAdd"; // Bill
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import MaterialTable from "material-table";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import DefaultContent from "../../components/Modal/DefaultContent";
import Modal from "../../components/Modal/Modal";

import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import fileTypes from "../../services/fileTypes";
import { errorToast, successToast } from "../../services/toasts";
import { handleError } from "../../utils/utils";

/**
 * LSH employees are able to see the paid
 * purchases on the platform in order to upload legal docs
 */
function PayedPurchaseManagement() {
  const [showAddInstallerModal, setShowAddInstallerModal] = useState(false);
  const [payedPurchases, setPayedPurchases] = useState();
  const [activePurchase, setActivePurchase] = useState();
  const [detailData, setDetailData] = useState();
  const [isLoadingAddInstaller, setIsLoadingAddInstaller] = useState(false);

  const { errors, register, handleSubmit, reset } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await axios.get(`/v1/remodelings?paid=true`);
      setPayedPurchases(res.data);
    } catch (error) {
      throw error;
    }
  };

  const handleBillUpload = async (event) => {
    try {
      const file = event.target.files[0];

      // Check if any file was uploaded
      if (file) {
        // PDF validation
        validateFile(file, /pdf/);

        const data = new FormData();
        data.append("file", file);
        data.append("originalname", "Factura LSH");

        // Save as a legal doc
        await axios.post(
          `/v1/remodelings/${activePurchase}/files/${fileTypes.remodelingLegalDoc}`,
          data
        );

        successToast("¡Has añadido tu factura exitosamente!");
      }
    } catch (error) {
      // Display a red alert of the kind of error
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
      handleError(error);
    }
  };

  const handlePolicyUpload = async (event) => {
    try {
      const file = event.target.files[0];

      // Check if any file was uploaded
      if (file) {
        // PDF validation
        validateFile(file, /pdf/);

        const data = new FormData();
        data.append("file", file);
        data.append("originalname", "Póliza responsabilidad civil");

        // Save as a legal doc
        await axios.post(
          `/v1/remodelings/${activePurchase}/files/${fileTypes.remodelingLegalDoc}`,
          data
        );
        successToast("¡Has añadido tu póliza exitosamente!");
      }
    } catch (error) {
      // Display a red alert of the kind of error
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
      handleError(error);
    }
  };

  const handleAddInstaller = async (data) => {
    try {
      setIsLoadingAddInstaller(true);

      await axios.post(`/v1/remodelings/${activePurchase}/installers`, data);

      successToast("¡Has añadido un instalador exitosamente!");
      reset();
    } catch (error) {
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
      handleError(error);
    }

    setIsLoadingAddInstaller(false);
  };

  return (
    <div>
      <AddInstallerModal
        closeModal={() => setShowAddInstallerModal(false)}
        showAddInstallerModal={showAddInstallerModal}
        handleAddInstaller={(data) => handleAddInstaller(data)}
        isLoading={isLoadingAddInstaller}
        handleSubmit={handleSubmit}
        errors={errors}
        register={register}
      />
      <Modal
        showModal={detailData ? true : false}
        closeModal={() => setDetailData()}
        contentMaxWidth={"400px"}
      >
        <DesignerDetail data={detailData} />
      </Modal>
      <input
        onChange={handleBillUpload}
        type="file"
        id="bill-upload"
        className="legal-doc"
      />
      <input
        onChange={handlePolicyUpload}
        type="file"
        id="policy-upload"
        className="legal-doc"
      />
      <MaterialTable
        title={"Compras pagadas"}
        columns={[
          {
            title: "Fecha de creación",
            field: "created_at",
            type: "date",
            render: (rowData) =>
              moment(rowData.created_at).toDate().toLocaleDateString(),
          },
          {
            title: "Hoja de proceso",
            render: (rowData) => (
              <Link to={`/compra/${rowData.hashid}`} target="_blank">
                {rowData.hashid}
              </Link>
            ),
          },
          {
            title: "Diseñador",
            field: "designer",
            editable: "never",
            render: (rowData) =>
              `${rowData.designer.first_name} ${rowData.designer.last_name}`,
          },
          {
            title: "Cliente",
            field: "client",
            editable: "never",
            render: (rowData) =>
              `${rowData.client.first_name} ${rowData.client.last_name}`,
          },
          {
            title: "Dirección",
            field: "address",
            editable: "never",
            render: (rowData) => `${rowData.address}`,
          },
        ]}
        data={payedPurchases}
        actions={[
          {
            icon: () => <PersonAddIcon />,
            tooltip: "Añadir instalador",
            onClick: (event, rowData) => {
              setActivePurchase(rowData.hashid);
              setShowAddInstallerModal(true);
            },
          },
          {
            icon: () => <ZoomInIcon />,
            tooltip: "Detalle de la compra",
            onClick: (event, rowData) => {
              setDetailData(rowData);
            },
          },
          {
            icon: () => <PostAddIcon />,
            tooltip: "Subir factura",
            onClick: (event, rowData) => {
              setActivePurchase(rowData.hashid);
              document.getElementById("bill-upload").click(); // Upload bill
            },
          },
          {
            icon: () => <NoteAddIcon />,
            tooltip: "Subir póliza",
            onClick: (event, rowData) => {
              setActivePurchase(rowData.hashid);
              document.getElementById("policy-upload").click(); // Upload policy
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        localization={materialTableES}
        isLoading={payedPurchases ? false : true}
      />
    </div>
  );
}

const AddInstallerModal = ({
  showAddInstallerModal,
  handleSubmit,
  errors,
  register,
  closeModal,
  handleAddInstaller,
  isLoading,
}) => {
  return (
    <Fragment>
      <Modal showModal={showAddInstallerModal} closeModal={closeModal}>
        <DefaultContent
          heading="Añadir instalador"
          label="Instaladores"
          showFooter={false}
        >
          <StyledForm onSubmit={handleSubmit(handleAddInstaller)}>
            <Row>
              <Input
                name="full_name"
                placeholder="Nombre completo"
                register={register({ required: true })}
              />
              <Input
                name="cedula"
                placeholder="Cédula"
                register={register({
                  required: true,
                  pattern: /^[0-9]{8,10}$/,
                })}
              />
            </Row>
            {errors.full_name && (
              <p className="error">
                Por favor escribe el nombre completo del instalador
              </p>
            )}
            {errors.cedula && (
              <p className="error">Por favor escribe una cédula válida</p>
            )}
            <Button label="Enviar" $isLoading={isLoading} />
          </StyledForm>
        </DefaultContent>
      </Modal>
    </Fragment>
  );
};

const DesignerDetail = ({ data }) => {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información del proyecto</h3>
      <span>
        <i>Dirección:</i> {data.address}
      </span>
      <span>
        <i>Ciudad:</i> {data.city}
      </span>

      <h3 style={{ marginTop: 10 }}>Información del cliente</h3>
      <span>
        <i>Nombre:</i> {`${data.client.first_name} ${data.client.last_name}`}
      </span>
      <span>
        <i>Correo:</i> {data.client.email}
      </span>
      <span>
        <i>Celular:</i> {data.client.phone}
      </span>

      <h3 style={{ marginTop: 10 }}>Información del diseñador</h3>
      <span>
        <i>Nombre:</i>{" "}
        {`${data.designer.first_name} ${data.designer.last_name}`}
      </span>
      <span>
        <i>Correo:</i> {data.designer.email}
      </span>
      <span>
        <i>Celular:</i> {data.designer.phone}
      </span>
      {/* TODO: Add receipt info */}
    </div>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export default PayedPurchaseManagement;
