import React, { useState, useEffect } from "react";
import Inbox from "../../components/Inbox/Inbox";
import axios from "axios";

function DesignerInbox() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    let response = await axios.get("/v1/users/messages");
    setMessages(response.data);
    setLoading(false);
  };

  return <Inbox notifications={messages} isLoading={loading} />;
}

export default DesignerInbox;
