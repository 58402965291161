import { Document } from "./types";

import moment from "moment";
import styled from "styled-components";

type RemodelingDocumentsProps = {
  legalDocuments: Document[];
  technicalDocuments: Document[];
  isDesigner: boolean;
  isLSH: boolean;
  isShowingLegalDocuments: boolean;
  isShowingTechnicalDocuments: boolean;
  toggleShowLegalDocuments: React.MouseEventHandler<HTMLParagraphElement>;
  toggleShowTechnicalDocuments: React.MouseEventHandler<HTMLParagraphElement>;
};

export default function RemodelingDocuments({
  isDesigner,
  isLSH,
  toggleShowLegalDocuments,
  isShowingLegalDocuments,
  legalDocuments,
  toggleShowTechnicalDocuments,
  isShowingTechnicalDocuments,
  technicalDocuments,
}: RemodelingDocumentsProps) {

  return (
    <Container>
      <Paragraph
        onClick={toggleShowLegalDocuments}
      >
        Documentos legales
      </Paragraph>

      {isShowingLegalDocuments && legalDocuments.map((doc, index) => (
        <DocumentsDetail key={index}>
          <i className="fa fa-file-pdf-o" aria-hidden="true" />
          <a
            href={doc.url}
            target="__blank"
            rel="noopener"
            className="button-see"
          >
            {doc.filename} - {moment(doc.created_at).toDate().toLocaleDateString()}
          </a>
        </DocumentsDetail>
      ))}

      {(isDesigner || isLSH) && (
        <Paragraph
          onClick={toggleShowTechnicalDocuments}
        >
          Documentos técnicos
        </Paragraph>
      )}

      {isShowingTechnicalDocuments && technicalDocuments.map((doc, index) => (
        <DocumentsDetail key={index}>
          <i className="fa fa-file-pdf-o" aria-hidden="true" />
          <a
            href={doc.url}
            target="__blank"
            rel="noopener"
            className="button-see"
          >
            {doc.filename} - {moment(doc.created_at).toDate().toLocaleDateString()}
          </a>
        </DocumentsDetail>
      ))}
    </Container>
  );
}

const Container = styled.div`
  @media (min-width: 768px) {
    margin-left: 2rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const DocumentsDetail = styled.div`
  margin: 1rem 0;

  a {
    color: grey;
  }

  i {
    margin-right: 1rem;
  }
`;

const Paragraph = styled.p`
  @media (max-width: 768px) {
    margin: .5rem 0rem;
  }

  margin: 1rem 0rem;
  cursor: pointer;
  color: grey;
  font-size: 0.9rem;
  text-decoration: underline;
`;
