import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import axios from "axios";

import Paper from "@material-ui/core/Paper";

const COLORS = ["#E2CA8A", "#5D777B", "#AA8684"];
const MONTHS = [
  "Ene.",
  "Feb.",
  "Mar.",
  "Abr.",
  "May.",
  "Jun.",
  "Jul.",
  "Ago.",
  "Sep.",
  "Oct.",
  "Nov.",
  "Dic.",
];
const CURRENT_MONTH = new Date().getMonth();

/*
 * Statistics for dseigner
 */
function Stats() {
  const [stats, setStats] = useState();

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    let { data } = await axios.get(`/v1/designers/stats`);

    data.remodelingsPerMonth.map(
      (month, index) => (month.monthName = MONTHS[index])
    );
    data.revenuePerMonth.map(
      (month, index) => (month.monthName = MONTHS[index])
    );
    setStats(data);
  };

  return (
    <Wrapper>
      {stats && (
        <Fragment>
          <div>
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
                padding: "10px",
                flex: 4,
              }}
            >
              <h3
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: "300",
                  marginLeft: "0.5rem",
                }}
              >
                ¡Ya llevas{" "}
                <span
                  style={{
                    fontSize: "1.17rem",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  {stats.daysSinceRegistration}
                </span>{" "}
                días en la plataforma!{" "}
                <span role="img" aria-label="party-popper">
                  🎉
                </span>
              </h3>
            </Paper>
          </div>

          <StatsRow>
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px",
                paddingTop: "2rem",
                flex: 4,
              }}
            >
              <div style={{ marginLeft: "3.5rem" }}>
                <h4
                  style={{
                    fontSize: "0.75rem",
                    color: "#5a6872",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    margin: "0 0 0.5rem 0",
                  }}
                >
                  Análisis de Solicitudes generales
                </h4>
              </div>
              <div style={{ width: "100%", height: "200px" }}>
                <ResponsiveContainer>
                  <LineChart
                    width={730}
                    height={250}
                    data={stats.remodelingsPerMonth}
                    margin={{ top: 30, right: 30, left: 0, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="4 1 2" />
                    <XAxis dataKey="monthName" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="remodelings"
                      stroke="#3f51b5"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper
              style={{
                display: "flex",
                padding: "2rem",
                margin: "10px",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-around",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h4
                    style={{
                      fontSize: "0.75rem",
                      color: "#5a6872",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      margin: "0 0 0.5rem 0",
                    }}
                  >
                    Solicitudes
                  </h4>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1
                      style={{
                        color: "darkblue",
                      }}
                    >
                      {stats.totalRemodelings}
                    </h1>
                    <h3
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Totales
                    </h3>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1
                      style={{
                        color: "darkblue",
                      }}
                    >
                      {stats.totalPaidRemodelings}
                    </h1>
                    <h3
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Este mes
                    </h3>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1
                      style={{
                        color: "darkblue",
                      }}
                    >
                      {stats.effectivenessPercentage}%
                    </h1>
                    <h3
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Efectividad
                    </h3>
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}></div>
                <Link
                  to="dashboard"
                  style={{ fontFamily: "Open Sans", fontWeight: "300" }}
                >
                  Clic para ver tus remodelaciones
                </Link>
              </div>
            </Paper>
          </StatsRow>

          <StatsRow>
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "2rem",
                margin: "10px",
                flex: 1,
              }}
            >
              <div>
                <div>
                  <h4
                    style={{
                      fontSize: "0.75rem",
                      color: "#5a6872",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      margin: "0 0 0.5rem 0",
                    }}
                  >
                    Utilidades
                  </h4>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1
                      style={{
                        color: "darkblue",
                      }}
                    >
                      $
                      {stats.totalRevenue &&
                        stats.totalRevenue.toLocaleString("ES-es")}
                    </h1>
                    <h3
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Ventas totales
                    </h3>
                  </div>
                  {/* TODO: Make more tests because it is showing wrong data. */}
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <h2
                      style={{
                        color: "#AA8684",
                      }}
                    >
                      $
                      {stats.revenuePerMonth[
                        CURRENT_MONTH
                      ].revenue.toLocaleString("ES-es")}
                    </h2>
                    <h4
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "300",
                        marginLeft: "0.5rem",
                      }}
                    >
                      Este mes
                    </h4>
                  </div> */}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                {stats.productsPercentages && (
                  <PieChart width={100} height={100}>
                    <Tooltip />
                    <Pie
                      data={stats.productsPercentages}
                      dataKey="subtotal"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={50}
                      fill="#8884d8"
                    >
                      {stats.productsPercentages.map((entry, index) => (
                        <Cell key={index} fill={COLORS[index]} />
                      ))}
                    </Pie>
                  </PieChart>
                )}
              </div>
            </Paper>

            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "2rem",
                margin: "10px",
                flex: 4,
              }}
            >
              <div style={{ marginLeft: "3.5rem" }}>
                <h4
                  style={{
                    fontSize: "0.75rem",
                    color: "#5a6872",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    margin: "0 0 0.5rem 0",
                  }}
                >
                  Análisis de ventas
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "200px",
                }}
              >
                <ResponsiveContainer>
                  <LineChart
                    width={730}
                    height={250}
                    data={stats.revenuePerMonth}
                    margin={{ top: 30, right: 30, left: 0, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="4 1 2" />
                    <XAxis dataKey="monthName" />
                    <YAxis
                      tickFormatter={(value) =>
                        new Intl.NumberFormat("en", {
                          notation: "compact",
                          compactDisplay: "short",
                        }).format(value)
                      }
                    />
                    <Tooltip />

                    <Line
                      type="monotone"
                      dataKey="revenue"
                      stroke="#AA8684"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
          </StatsRow>
        </Fragment>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 1.5rem;
`;

const StatsRow = styled.div`
  min-height: 260px;
  display: flex;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export default Stats;
