import {
  ambientImageParagraphs,
  principalImageParagraphs,
} from "../../../../../../constants";
import { Image } from "../../../../../../ecommerce/pages/ProductInformation/models/productDescription";
import AmbientImagesForm from "./AmbientImagesForm";
import styles from "./ImagesForm.module.scss";
import PrincipalImageForm from "./PrincipalImageForm";

type ImageFormProps = {
  images: Image[];
  principalImage: Image[];
  productId: number;
};

const ImagesForm = ({ images, principalImage, productId }: ImageFormProps) => {
  return (
    <div className={styles.imageFormContainer}>
      <div>
        <h3>Imagen Principal</h3>
        <ul>
          {principalImageParagraphs.map((paragraph, index) => (
            <li key={index}>
              <p>{paragraph}</p>
            </li>
          ))}
        </ul>
        <div className={styles.formItem}>
          <PrincipalImageForm
            principalImage={principalImage}
            productId={productId}
          />
        </div>
      </div>

      <div>
        <h3>Imágenes Complementarias</h3>
        <ul>
          {ambientImageParagraphs.map((paragraph, index) => (
            <li key={index}>
              <p>{paragraph}</p>
            </li>
          ))}
        </ul>
        <div className={styles.formItem}>
          <AmbientImagesForm images={images} productId={productId} />
        </div>
      </div>
    </div>
  );
};
export default ImagesForm;
