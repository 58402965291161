export const materialTableES = {
  header: {
    actions: "",
  },
  body: {
    emptyDataSourceMessage: "",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    editRow: {
      deleteText: "¿Estas seguro que deseas eliminar este registro?",
      saveTooltip: "Guardar",
      cancelTooltip: "Cancelar",
    },
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
  pagination: {
    labelRowsSelect: "Filas",
    labelRowsPerPage: "Filas por página",
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    nextAriaLabel: "Siguiente página",
    nextTooltip: "Siguiente página",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
    labelDisplayedRows: "{from}-{to} de {count}",
  },
  toolbar: {
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
    nRowsSelected: "{0} productos seleccionados para crear tú cotización",
  },
};
