import { Fragment } from "react";

import Modal from "../../../components/Modal/Modal";
import DefaultContent from "../../../components/Modal/DefaultContent";

export default function WompiResponseModal({
  closeModal,
  showWompiResponseModal,
  wompiPaymentSuccess,
}) {
  return (
    <Fragment>
      <Modal showModal={showWompiResponseModal} closeModal={closeModal}>
        <DefaultContent
          heading={`Transacción ${
            wompiPaymentSuccess ? "aprobada" : "declinada"
          }`}
          label="Pagos Wompi"
          fontAwesomeHeadingIcon={
            wompiPaymentSuccess ? "check-circle" : "times-circle"
          }
          fontAwesomeHeadingIconColor={wompiPaymentSuccess ? "green" : "red"}
          showFooter={false}
        >
          {wompiPaymentSuccess
            ? `Tu pago ha sido exitoso, en las próximas 24 horas tu profesional
             se pondrá en contacto contigo .`
            : `Ha ocurrido un problema con tu transacción, por favor inténtalo
          de nuevo más tarde o comunícate con nosotros.`}
        </DefaultContent>
      </Modal>
    </Fragment>
  );
}
