import { yupResolver } from "@hookform/resolvers";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { userConfigBankInfoSchema } from "../../services/formValidation";

import Button from "../../components/Button/Button";

import axios from "axios";
import styled from "styled-components";
import { successToast } from "../../services/toasts";
import Warehouses from "./pages/SupplierSettings/components/Warehouses/Warehouses";

function SupplierConfig() {
  const [supplier, setSupplier] = useState();
  const [isLoading, setIsLoading] = useState({
    bankButton: false,
  });

  const userTokens = JSON.parse(localStorage.getItem("user"));
  const getSupplier = async () => {
    let { data } = await axios.get(`/v1/suppliers/profile`);
    setSupplier(data);
  };

  useEffect(() => {
    getSupplier();
  }, []);

  const handleBankAccount = async (data) => {
    setIsLoading({ ...isLoading, bankButton: true });

    if (userTokens.hasBankInfo) {
      await axios.patch(`/v1/users/bank`, data);
      successToast("Has actualizado tus datos exitosamente");
    } else {
      await axios.post(`/v1/users/bank`, data);

      const newTokens = JSON.stringify({
        ...userTokens,
        hasBankInfo: true,
      });
      localStorage.setItem("user", newTokens);

      successToast("Has añadido una cuenta bancaria exitosamente");
    }
    setIsLoading({ ...isLoading, bankButton: false });
  };

  return (
    <div className="layout-dashboard-container">
      <Paper style={{ padding: "2rem" }}>
        <h1
          style={{ fontWeight: "normal", fontSize: "1.5rem", color: "#232f3e" }}
        >
          Editar datos de perfil
        </h1>
        <br />
        <h3 style={{ fontWeight: "normal", color: "#232f3e" }}>
          Por favor envía una solicitud a{" "}
          <a href="mailto:proveedores@fliping.co">proveedores@fliping.co</a>{" "}
          para editar los datos de la organización.
        </h3>
      </Paper>
      <br />
      <Warehouses />
      <br />
      {supplier && (
        <Paper
          style={{
            marginTop: "1.5rem",
            padding: "2rem",
          }}
        >
          <BankForm
            supplierCompanyName={supplier && `${supplier[0].company_name}`}
            bankingInfo={supplier[0].bank ? supplier[0].bank : null}
            hasBankInfo={userTokens && userTokens.hasBankInfo}
            handleBankAccount={handleBankAccount}
            loading={isLoading.bankButton}
          />
        </Paper>
      )}
    </div>
  );
}

function BankForm({
  supplierCompanyName,
  bankingInfo,
  handleBankAccount,
  loading,
}) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(userConfigBankInfoSchema),
  });

  return (
    <form onSubmit={handleSubmit(handleBankAccount)}>
      <h1
        style={{ fontWeight: "normal", fontSize: "1.5rem", color: "#232f3e" }}
      >
        Información bancaria de {supplierCompanyName}
      </h1>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Banco</StyledLabel>
          <StyledSelect
            name="bank_name"
            defaultValue={bankingInfo && bankingInfo.bank_name}
            ref={register}
          >
            <option value="bancolombia">Bancolombia</option>
            <option value="banco de bogota">Banco de Bogotá</option>
            <option value="davivienda">Davivienda</option>
            <option value="bbva">BBVA</option>
            <option value="banco de occidente">Banco de Occidente</option>
            <option value="banco av villas">Banco AV Villas</option>
            <option value="banco caja social">Banco Caja Social</option>
            <option value="colpatria">Scotiabank Colpatria</option>
            <option value="banco falabella">Banco Falabella</option>
            <option value="citi">Citi</option>
            <option value="bancoomeva">Bancoomeva</option>
          </StyledSelect>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Titular de la cuenta (*)</StyledLabel>
          <StyledInput
            placeholder="Titular de la cuenta"
            name="account_holder_name"
            defaultValue={bankingInfo && bankingInfo.account_holder_name}
            ref={register}
          />
        </div>
      </Row>

      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Número de la cuenta</StyledLabel>
          <StyledInput
            placeholder="Número de la cuenta"
            type="text"
            name="account_number"
            ref={register}
            defaultValue={bankingInfo && bankingInfo.account_number}
          />
        </div>
      </Row>
      <Row style={{ marginLeft: "5px" }}>
        <StyledLabel>
          <StyledInput
            type="radio"
            value="1"
            ref={register}
            name="is_savings_account"
            defaultChecked={
              bankingInfo && bankingInfo.is_savings_account ? true : false
            }
          />
          <span style={{ marginLeft: "5px" }}>Cuenta de ahorros</span>
        </StyledLabel>
        <StyledLabel>
          <StyledInput
            type="radio"
            value="0"
            ref={register}
            name="is_savings_account"
            defaultChecked={
              bankingInfo && !bankingInfo.is_savings_account ? true : false
            }
          />
          <span style={{ marginLeft: "5px" }}>Cuenta corriente</span>
        </StyledLabel>
      </Row>

      <i>
        * El titular de la cuenta no puede ser una persona diferente a la razón
        social o al nombre del representante legal de la empresa.
      </i>

      <div>
        {Object.keys(errors)
          .slice(0, 2)
          .map((errorName) => {
            return (
              <p
                key={errorName}
                style={{
                  fontSize: "0.8rem",
                  color: "red",
                  padding: "12px 0 0 10px",
                }}
              >
                {errors[errorName].message}
              </p>
            );
          })}
      </div>
      <Row
        style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "10px" }}
      >
        <Button
          type="submit"
          size="small"
          label={bankingInfo ? "Actualizar" : "Añadir"}
          $isLoading={loading}
        />
      </Row>
    </form>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledInput = styled.input`
  flex-grow: 1;
  font-family: "Open sans";
  padding: 0.4rem 1rem;
`;

const StyledLabel = styled.label`
  flex-grow: 1;
  font-family: "Open Sans";
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 300;
`;

const StyledSelect = styled.select`
  font-family: "Open sans";
  padding: 0.4rem 1rem;
`;

export default SupplierConfig;
