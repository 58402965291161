import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styles from "./orderItemsTable.module.scss";
import { Product } from "../../types";
import { formatNumberToPrice } from "../../../../../../helpers/priceFormatting";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableBody: {
    width: "inherit",
    maxHeight: 400,
    overflow: "auto",
  },
  root: {
    boxShadow: "none",
    border: "none",
  },
});

interface OrderItemsTableProps {
  product: Product;
}

const OrderItemsTable = ({ product }: OrderItemsTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell align="right">Precio Unitario</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {product ? (
            <TableRow>
              <TableCell component="th" scope="row">
                <div className={styles.productCellContainer}>
                  <div>
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className={styles.itemImage}
                    />
                  </div>
                  {product.name}
                </div>
              </TableCell>
              <TableCell align="right">
                {formatNumberToPrice(product.price)}
              </TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">
                {formatNumberToPrice(product.price)}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderItemsTable;
