import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { validateFile } from "../../services/formValidation";
import { materialTableES } from "../../services/materialTableES";

import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PostAddIcon from "@material-ui/icons/PostAdd"; // Bill
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import DefaultContent from "../../components/Modal/DefaultContent";
import Modal from "../../components/Modal/Modal";
import fileTypes from "../../services/fileTypes";
import { errorToast, successToast } from "../../services/toasts";

/**
 * Manage all remodelings processes and filter by the ones that are
 * active, inactive and finished
 *
 * TODO: Add inactive option to filter remodeling processes that haven't
 * had an update
 */
function RemodelingsManagement() {
  // const [hashid, setHashid] = useState();
  const [currentRemodeling, setCurrentRemodeling] = useState();
  const [remodelings, setRemodelings] = useState();
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState();

  const [toPay, setToPay] = useState({
    designer: 0,
    supplier: 0,
    lsh: 0,
    r1d: 0,
  });

  const [filteredBy, setFilterBy] = useState({
    finished: false,
    paid: false,
  });

  useEffect(() => {
    getRemodelings();
  }, []);

  const getRemodelings = async () => {
    let response = await axios.get(`/v1/remodelings`);
    setRemodelings(response.data);
    setFilterBy({ finished: false, paid: false });
  };

  const getFinishedRemodelings = async () => {
    let response = await axios.get(`/v1/remodelings?finished=true`);
    setRemodelings(response.data);

    setFilterBy({ finished: true, paid: false });
  };

  const getPaidRemodelings = async () => {
    let response = await axios.get(`/v1/remodelings?paid=true`);
    setRemodelings(response.data);

    setFilterBy({ finished: false, paid: true });
  };

  const handleShowModal = async (remodeling) => {
    let response = await axios.get(
      `/v1/remodelings/${remodeling.hashid}/invoices?paid=true`
    );
    let paidInvoices = response.data;

    let toPayDesigner = paidInvoices.reduce((accumulator, object) => {
      return accumulator + object.to_pay_designer;
    }, 0);

    let toPaySupplier = paidInvoices.reduce((accumulator, object) => {
      return accumulator + object.to_pay_supplier;
    }, 0);

    let toPayLSH = paidInvoices.reduce((accumulator, object) => {
      return accumulator + object.to_pay_lsh;
    }, 0);

    let toPayR1D = toPayDesigner;

    setCurrentRemodeling(remodeling);

    setShowModal(true);
    setToPay({
      designer: toPayDesigner,
      supplier: toPaySupplier,
      lsh: toPayLSH,
      r1d: toPayDesigner,
    });
  };

  const handleBillUpload = async (event) => {
    try {
      const file = event.target.files[0];

      // Upload a file only if user chooses a valid file.
      if (file) {
        // PDF validation
        validateFile(file, /pdf/);

        const data = new FormData();
        data.append("file", file);
        data.append("originalname", "Factura Fliping");
        await axios.post(
          `/v1/remodelings/${currentRemodeling.hashid}/files/${fileTypes.remodelingLegalDoc}`,
          data
        );

        successToast("¡Has añadido tu factura exitosamente!");
      }
    } catch (error) {
      // Display a red alert of the kind of error
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
    }
  };

  // Function for check box
  const handleCompletePayment = async (rowData) => {
    try {
      // Show checked box if a remodeling has a paid_at date
      let paidByAccountant = rowData.paid_at ? false : true;
      let copyPaidRemodelings = [...remodelings];
      let index = copyPaidRemodelings.indexOf(rowData);
      copyPaidRemodelings[index].paid_at = paidByAccountant;
      setRemodelings(copyPaidRemodelings);

      // Save the current date for paid_at or null for uncheck
      if (paidByAccountant) {
        await axios.patch(`/v1/remodelings/${rowData.hashid}`, {
          paid_at: new Date().toISOString().slice(0, 19).replace("T", " "),
        });
      } else {
        await axios.patch(`/v1/remodelings/${rowData.hashid}`, {
          paid_at: null,
        });
      }
    } catch (error) {
      // Display a red alert of the kind of error
      let requestError = error.response
        ? "Hubo un error. Por favor intentalo más tarde"
        : error;
      errorToast(`${requestError}`);
    }
  };

  return (
    <section className="layout-dashboard-container">
      <div>
        <Modal showModal={showModal} closeModal={() => setShowModal(false)}>
          <DefaultContent
            label="Pagos"
            heading={`Remodelación #${
              currentRemodeling && currentRemodeling.hashid
            }`}
            onSecondaryClick={() => setShowModal(false)}
            onSubmit={() => setShowModal(false)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <table style={{ border: "1px solid grey" }}>
                <tr>
                  <td>
                    Diseñador (
                    {currentRemodeling && currentRemodeling.designer.first_name}{" "}
                    {currentRemodeling && currentRemodeling.designer.last_name})
                  </td>
                  <td>${toPay.designer.toLocaleString("ES-es")}</td>
                </tr>
                <tr>
                  <td>Proveedor</td>
                  <td>${toPay.supplier.toLocaleString("ES-es")}</td>
                </tr>
                <tr>
                  <td>LSH</td>
                  <td>${toPay.lsh.toLocaleString("ES-es")}</td>
                </tr>
                <tr>
                  <td>Fliping</td>
                  <td>${toPay.r1d.toLocaleString("ES-es")}</td>
                </tr>
              </table>
            </div>
          </DefaultContent>
        </Modal>
        <Modal
          showModal={detailData ? true : false}
          closeModal={() => setDetailData()}
        >
          <CustomerDetail data={detailData} />
        </Modal>
        <input
          onChange={handleBillUpload}
          type="file"
          id="bill-upload"
          style={{ visibility: "hidden" }}
        />
        <MaterialTable
          title="Hojas de proceso"
          columns={[
            {
              title: "Fecha",
              field: "created_at",
              type: "date",
            },
            {
              title: "Fecha finalización",
              field: "finished_at",
              type: "date",
              hidden: filteredBy.finished ? false : true,
            },
            {
              title: "Hoja de proceso",
              render: (rowData) => (
                <Link to={`/compra/${rowData.hashid}`} target="_blank">
                  {rowData.hashid}
                </Link>
              ),
            },
            {
              title: "Cliente",
              render: (rowData) =>
                `${rowData.client.first_name} ${rowData.client.last_name}`,
            },
            {
              title: "Profesional",
              render: (rowData) =>
                `${rowData.designer.first_name} ${rowData.designer.last_name}`,
            },
            {
              title: "Ciudad",
              field: "city",
            },
            {
              title: "Consignaciones",
              field: "finished_at",
              hidden: filteredBy.finished ? false : true,
              render: function (rowData) {
                return (
                  <Checkbox
                    checked={rowData.paid_at}
                    name="paidChecked"
                    onChange={() => {
                      handleCompletePayment(rowData);
                    }}
                  />
                );
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    marginBottom: "1rem",
                  }}
                >
                  <div>
                    <Chip
                      style={{
                        color: "#112A36",
                        backgroundColor: "#E2CA8A",
                        marginRight: 10,
                      }}
                      label="Todos"
                      clickable={true}
                      onClick={getRemodelings}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#061225",
                        marginRight: 10,
                      }}
                      label="Pagados"
                      clickable={true}
                      onClick={getPaidRemodelings}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#AA8684",
                        marginRight: 10,
                      }}
                      label="Terminados"
                      clickable={true}
                      onClick={getFinishedRemodelings}
                    />
                  </div>
                </div>
              </div>
            ),
          }}
          actions={[
            (rowData) => {
              return rowData.finished_at
                ? {
                    icon: () => <LocalAtmIcon />,
                    tooltip: "Resumen de pagos",
                    onClick: (event, rowData) => {
                      handleShowModal(rowData);
                    },
                  }
                : null;
            },
            (rowData) => {
              return rowData.finished_at
                ? {
                    icon: () => <PostAddIcon />,
                    tooltip: "Subir factura",
                    onClick: (event, rowData) => {
                      setCurrentRemodeling(rowData);
                      document.getElementById("bill-upload").click(); // Upload bill
                    },
                  }
                : null;
            },
            {
              icon: () => <ZoomInIcon />,
              tooltip: "Detalle",
              onClick: (event, rowData) => {
                setDetailData(rowData);
              },
            },
          ]}
          data={remodelings && remodelings}
          options={{
            actionsColumnIndex: -1,
          }}
          localization={materialTableES}
          isLoading={remodelings ? false : true}
        />
      </div>
    </section>
  );
}

function CustomerDetail({ data }) {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información del cliente</h3>
      <span>
        <i>Nombre: </i> {`${data.client.first_name} ${data.client.last_name}`}
      </span>
      <span>
        <i>Correo: </i> {data.client.email}
      </span>
      <span>
        <i>Teléfono: </i> {data.client.phone}
      </span>
    </div>
  );
}

export default RemodelingsManagement;
