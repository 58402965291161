import styled from "styled-components";
import { Button } from "../../components/Button/ButtonConsultancy.style";

const FilterButton = ({
  allConsultancies,
  setConsultanciesOnHold,
  setConsultanciesApproved,
  setConsultanciesFinished,
  consultancyState,
}) => {
  const ConsultaciesOnHold = () => {
    setConsultanciesOnHold(
      allConsultancies.filter((presential) => presential.is_approved === null)
    );
    consultancyState("OnHold");
  };

  const ConsultaciesApproved = () => {
    setConsultanciesApproved(
      allConsultancies.filter(
        (presential) =>
          presential.is_approved === true &&
          presential.virtualChat !== null &&
          presential.finished_at === null
      )
    );
    consultancyState("Approved");
  };
  const ConsultanciesFinished = () => {
    setConsultanciesFinished(
      allConsultancies.filter((presential) => presential.finished_at !== null)
    );
    consultancyState("Finished");
  };

  return (
    <ContainerButtons>
      <Button bgColor=" rgb(236, 112, 99)" onClick={ConsultaciesOnHold}>
        Por aprobar
      </Button>
      <Button bgColor=" rgb(244, 191, 42)" onClick={ConsultaciesApproved}>
        Aprobadas
      </Button>
      <Button bgColor=" rgb(82, 190, 128)" onClick={ConsultanciesFinished}>
        Terminadas
      </Button>
    </ContainerButtons>
  );
};
const ContainerButtons = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-bottom: 4rem;

  @media screen and (min-width: 481px) {
    justify-content: flex-start;
  }
`;

export default FilterButton;
