import styled from "styled-components";

/**
 */
function Modal({
  showModal,
  closeModal,
  closeIcon = true,
  children,
  ...props
}) {
  return (
    <div>
      {showModal && (
        <Wrapper {...props}>
          <Overlay />
          <ContentWrapper {...props}>
            {closeIcon && (
              <CloseIcon onClick={closeModal} id="closeModal">
                &times;
              </CloseIcon>
            )}
            {children}
          </ContentWrapper>
        </Wrapper>
      )}
    </div>
  );
}

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10;
  border-radius: 16px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // width: 100%;
  // height: 100%;
  overflow: scroll;

  @media (min-width: 768px) {
    overflow: auto;
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`;

const ContentWrapper = styled.div`
  position: relative;
  padding: ${(props) => props.contentPadding};
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: white;
  border-radius: 16px;
  margin: auto;
  z-index: 100;
  max-width: 75%;
  position: relative;
  flex-wrap: wrap;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 100%;

  @media (min-width: 1024px) {
    max-width: ${(props) => props.contentMaxWidth};
    max-height: 90vh;
  }

  @media (min-width: 600px) {
    min-width: ${(props) => props.widthModal || "550px"};
    // max height of modal
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

const CloseIcon = styled.span`
  cursor: pointer;
  text-align: center;
  background-color: #fdd440;
  border-radius: 100px;
  font-size: 24px;
  z-index: inherit;
  position: absolute;
  width: 28px;
  right: 8px;
  top: 8px;
  position: absolute;
  width: 28px;
  right: 16px;
  top: 16px;

  &:hover {
    filter: brightness(103%);
  }
`;

Modal.propTypes = {};

Modal.defaultProps = {
  width: "auto",
  smWidth: "100%",
  closeIcon: true,
  contentPadding: "0",
  contentMaxWidth: "600px",
};

export default Modal;
