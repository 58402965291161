import Axios from "axios";
import {
  Dimensions,
  ProductDescription,
  ProductPackageMeasures,
} from "../ecommerce/pages/ProductInformation/models/productDescription";
import { handleError } from "../utils/utils";

export async function getAllColors() {
  try {
    const colors = await Axios("/v2/products/colors");
    return colors.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getAllAddresses() {
  try {
    const addresses = await Axios("/v2/suppliers/storage-address");
    return addresses.data;
  } catch (error) {
    handleError(error);
  }
}

// Supplier

export async function getAllEcommercePreferences() {
  try {
    const preferences = await Axios("/v2/suppliers/e-commerce");
    return preferences.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateEcommercePreferences(ecommerceData: FormData) {
  try {
    const response = await Axios.patch(
      "/v2/suppliers/e-commerce",
      ecommerceData
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// Get Wompi Public key
export async function getWompiPublicKey() {
  try {
    const response = await Axios.get("/v2/suppliers/wompi-key");
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// Get Wompi Public Key By Supplier
export async function getWompiPublicKeyBySupplier(supplierName: string) {
  try {
    const response = await Axios.get(`/v2/suppliers/wompi-key/${supplierName}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// Update Wompi Public Key
export async function updateWompiPublicKey(wompiKey: string) {
  try {
    const response = await Axios.patch("/v2/suppliers/wompi-key", { wompiKey });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateProductGeneralInfo(
  productId: number,
  productData: ProductDescription
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/products/${productId}/general`,
      productData
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateProductMeasurements(
  productId: number,
  productMeasurements: Dimensions
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/products/${productId}/product-measurements`,
      productMeasurements
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function UpdateProductPackageMeasures(
  productId: number,
  packingPresentationId: number,
  productPackageMeasures: ProductPackageMeasures
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/products/${productId}/packaging-measurements/${packingPresentationId}`,
      productPackageMeasures
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateProductPrincipalImage(
  productId: number,
  imageId: number | null,
  imageFile: FormData
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/products/${productId}/principal-image/${imageId}`,
      imageFile
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateProductImages(
  productId: number,
  formDataForRequest: FormData
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/products/${productId}/ambient-images`,
      formDataForRequest
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateProductTechnicalSheet(
  productId: number,
  technicalSheet: FormData
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/products/${productId}/technical-sheet`,
      technicalSheet
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function addEcommerceBanner(bannerFile: FormData) {
  try {
    const response = await Axios.post(
      `/v2/suppliers/e-commerce/banner`,
      bannerFile
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSalesPerson() {
  try {
    const response = await Axios.get("/v2/suppliers/salespeople");
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSalesPersonById(id: string) {
  try {
    const response = await Axios.get(`/v2/suppliers/salesperson/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function deleteEcommerceBanner(bannerId: number) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/e-commerce/banner/delete/${bannerId}`
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function createSalesPerson(salesPersonData: FormData) {
  try {
    // Extract values ​​from FormData
    const email = salesPersonData.get("email");
    const phone = salesPersonData.get("phone");

    //Check if the email is already registered
    let dataToCheck = {
      email,
      phone,
    };

    // when client tries to register with an existing user
    await Axios.post("/v1/users/check", dataToCheck);

    const response = await Axios.post(
      "/v2/suppliers/salesperson",
      salesPersonData
    );
    return response.data;
  } catch (error: any) {
    const message =
      error.response?.data?.message ||
      "Ocurrió un error al procesar la solicitud. Por favor, intenta nuevamente.";
    handleError(message);
  }
}

export async function updateSalesPerson(
  salesPersonId: number,
  salesPersonData: SalesPerson
) {
  try {
    const response = await Axios.patch(
      `/v2/suppliers/salesperson/${salesPersonId}`,
      salesPersonData
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function deleteSalesPerson(salesPersonId: number) {
  try {
    const response = await Axios.delete(
      `/v2/suppliers/salesperson/${salesPersonId}`
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}
