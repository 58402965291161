export const packageFields = [
  {
    label: "Largo (*)",
    name: "package_length",
    endAdornment: "cm",
  },
  { label: "Ancho (*)", name: "package_width", endAdornment: "cm" },
  { label: "Alto (*)", name: "package_height", endAdornment: "cm" },
  {
    label: "Peso de producto empacado",
    name: "package_weight",
    endAdornment: "Kg",
  },
];

const productSizeAttributes = [
  {
    id: 1,
    category: "decoración",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 2,
    category: "mobiliario",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 3,
    category: "iluminación",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 4,
    category: "oficinas",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 5,
    category: "baños y cocinas",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 6,
    category: "infantil",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 7,
    category: "jardín y exterior",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 8,
    category: "revestimientos",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Espesor",
        name: "thickness",
        endAdornment: "cms",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 9,
    category: "pisos",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Espesor",
        name: "thickness",
        endAdornment: "cms",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 10,
    category: "complementarios",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 11,
    category: "hogar y bienestar",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },

  {
    id: 12,
    category: "mascotas",
    fields: [
      {
        label: "Largo",
        name: "length",
        endAdornment: "mts",
      },
      {
        label: "Ancho",
        name: "width",
        endAdornment: "mts",
      },
      {
        label: "Profundidad",
        name: "depth",
        endAdornment: "mts",
      },
      {
        label: "Altura",
        name: "height",
        endAdornment: "mts",
      },
      {
        label: "Diámetro",
        name: "diameter",
        endAdornment: "mts",
      },
      {
        label: "Peso",
        name: "weight",
        endAdornment: "Kg",
      },
    ],
    packageFields,
  },
];

export default productSizeAttributes;
