import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";

import backgroundImage from "../../assets/images/designer-dashboard-blurred.png";

function MembershipRequired(props) {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const { pathname } = useLocation();
  const { register, handleSubmit, errors } = useForm();
  let params = pathname.split("/");
  let id = params[2];
  let token = params[3];

  useEffect(() => {
    // Here was the re-direction to password reset
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h1>Por favor renueva tu membresia para continuar</h1>
        <p>
          Una vez hagas clic en el botón se te re-dirijira a la pantalla de
          pagos donde puedes cancelar tu membresía con tu medio de pago
          preferido.
        </p>
        <button
          type="submit"
          className="next"
          style={{ backgroundColor: "rgb(26, 69, 148)", color: "#FFF" }}
        >
          Continuar con Wompi
        </button>
        {/* <button type="submit" className="next" style={{ backgroundColor: "#a5c312", color: "#FFF"}}>Continuar con PayU</button> */}
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    background: `url(${backgroundImage}) no-repeat`,
    fontFamily: "Roboto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  card: {
    width: "30%",
    boxShadow: "0 0.0625rem 0.125rem 0 rgba(0,0,0,.15)",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1.25rem",
    borderRadius: "5px",
    backgroundColor: "#F2F2F2",
    padding: "3rem",
    "& h1": {
      color: "#505659",
    },
    "& p": {
      color: "#adb0b1",
    },
  },
  "@media (max-width: 768px)": {
    card: {
      width: "50%",
    },
  },
  "@media (max-width: 650px)": {
    card: {
      width: "100%",
    },
  },
});

export default withRouter(MembershipRequired);
