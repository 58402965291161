import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "./purchaseDetailData.module.scss";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { transformDate } from "../../../../../../utils/dateUtils";
import { formatNumberToPrice } from "../../../../../../helpers/priceFormatting";

import { type Purchase } from "../../../../types";
import { Link } from "@material-ui/core";

interface PurchaseDetailDataProps {
  purchase: Purchase;
}

const PurchaseDetailData = ({ purchase }: PurchaseDetailDataProps) => {
  const { address, files } = purchase;
  const hasInvoice = files.length > 0;

  return (
    <div className={styles.purchasesData}>
      <h2 className={styles.title}>Detalle de compra</h2>
      <div className={styles.purchaseDate}>
        <div>
          <span>Pedido {transformDate(purchase.purchaseDate)}</span>
          <span>Pedido n. {purchase.orderNumber}</span>
        </div>
        <div>
          <Link href={files[0] && files[0].url} target="_blank">
            <CustomButton
              color="outlined"
              shape="little-rounded"
              size="small"
              disabled={!hasInvoice}
            >
              Ver o Imprimir Recibo
            </CustomButton>
          </Link>
          {!hasInvoice && (
            <div className={styles.invoiceInformation}>
              <ErrorOutlineIcon />
              <span>Factura en proceso...</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.purchaseDetails}>
        <div className={styles.addressSection}>
          <h3>Datos de envio</h3>
          <span>Nombre: {address?.name}</span>
          <span>Apellido: {address?.lastname}</span>
          <span>Dirección: {address?.address}</span>
          <span>Ciudad: {address?.city}</span>
          <span>País: Colombia</span>
          <span>Teléfono: {address?.phone}</span>
        </div>
        <div className={styles.orderSummarySection}>
          <h3>Resumen de pedido</h3>
          <hr />
          <div>
            <strong>Total</strong>{" "}
            <strong>{formatNumberToPrice(purchase.total)}</strong>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};
export default PurchaseDetailData;
