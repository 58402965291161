import { useState } from "react";
import { FieldErrors } from "react-hook-form";
import styles from "./selectInput.module.scss";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface SelectInputProps {
  id: string;
  options?: Options;
  register: any;
  errors: FieldErrors;
}

interface Option {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

type Options = Option[];

const SelectInput = ({
  id,
  register,
  errors,
  options = [],
}: SelectInputProps) => {
  const [selectedOption, setSelectedOption] = useState("");
  const handleChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className={styles.selectContainer}>
      <select
        id={id}
        name={id}
        ref={register}
        value={selectedOption}
        onChange={handleChange}
        className={`${styles.selectInput} ${errors[id] ? styles.error : ""}`}
      >
        <option value="" disabled>
          ¿Cómo nos conociste?
        </option>
        {options &&
          options.map((source) => (
            <option value={source.id} key={source.id}>
              {source.name}
            </option>
          ))}
      </select>
      {errors[id] && (
        <div className={styles.errorContent}>
          <ErrorOutlineIcon />
          <span className={styles.errorMessage}>{errors[id].message}</span>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
