import { useState } from "react";
import { Switch } from "@material-ui/core";

import { Supplier } from "../types";

type SwitchCustomProps = {
  handleconfirmPayment: (
    orderId: number,
    supplierId: number,
    isConfirming: boolean
  ) => void;
  rowData: Supplier;
};

const PaymentSwitch = ({
  handleconfirmPayment,
  rowData,
}: SwitchCustomProps) => {
  const [isPaid, setIsPaid] = useState(rowData.is_paid);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsPaid(newValue);
    handleconfirmPayment(rowData.orderId, rowData.supplier_id, newValue);
  };

  return (
    <Switch
      checked={isPaid}
      onChange={handleChange}
      color="primary"
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
};

export default PaymentSwitch;
