import { yupResolver } from "@hookform/resolvers";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Grid, Paper, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import axios from "axios";
import styled from "styled-components";
import * as yup from "yup";

import CustomButton from "../../../../components/Button/CustomButton.tsx";
import DragAndDrop from "../../../../components/DragAndDrop/DragAndDrop.js";
import TooltipLargeText from "../../../../components/Tooltip/TooltipLargeText.jsx";
import {
  ambientImagesSchema,
  coatingAndFlooringAttributesSchema,
  coatingAndFlooringDimensionsSchema,
  commonAttributesSchema,
  homeAndWellnessAttributesSchema,
  lightingAttributesSchema,
  notCoatingAndFlooringAttributesSchema,
  notCoatingAndFlooringDimensionsSchema,
  numDimensionsEntered,
  packageDimensionsSchema,
  petsAttributesSchema,
  principalImageSchema,
} from "../../../../services/formValidation";

import productSizeAttributes from "../../data/fieldsByCategory";

import InputValidationMessage from "../../../../components/forms/InputValidationMessage/InputValidationMessage";
import {
  lampTypes,
  lightTypes,
  originTypes,
  packageTypes,
} from "../../../../constants/index";
import { errorToast, successToast } from "../../../../services/toasts";
import { useStyles } from "../../../../theme/FormStyles";
import { createComissionValues } from "../../../../utils/utils";
import informationFieldsForm from "../../data/informationFieldsForm.js";
import CustomCreatableSelect from "./CustomCreatableSelect";
import BreadcrumbNav from "../../../../components/BreadcrumbNav/BreadcrumbNav";
import { useHistory } from "react-router";

function ProductForm() {
  const history = useHistory();
  const classes = useStyles();
  const [brands, setBrands] = useState();
  const [colors, setColors] = useState();
  const [categories, setCategories] = useState();
  const [categorieInput, setCategorieInput] = useState(1);
  const [isUniqueProduct, setIsUniqueProduct] = useState(0);
  const [categorySelected, setCategorySelected] = useState({});
  const {
    minimumSellingUnit,
    comission,
    name,
    material,
    uniqueProduct,
    deliveryTime,
    color,
    shippingAddress,
    homeAndWellnessMaterial,
    petsMaterial,
    size,
    product_content,
  } = informationFieldsForm;
  const [loading, setLoading] = useState(false);
  const [listLightTypes, setListLightTypes] = useState([]);
  const [listPackageTypes, setListPackageTypes] = useState([]);
  const [storageAddresses, setStorageAddresses] = useState([]);
  // TODO: Improve this applying interfaces with TS and React hooks
  let selectedProductFormSchema;

  if ([8, 9].includes(categorySelected.id)) {
    selectedProductFormSchema = coatingAndFlooringAttributesSchema;
  } else if (categorySelected.id === 3) {
    selectedProductFormSchema = lightingAttributesSchema;
  } else if (categorySelected.id === 11) {
    selectedProductFormSchema = homeAndWellnessAttributesSchema;
  } else if (categorySelected.id === 12) {
    selectedProductFormSchema = petsAttributesSchema;
  } else {
    selectedProductFormSchema = notCoatingAndFlooringAttributesSchema;
  }

  const selectedDimensionsSchema = [8, 9].includes(categorySelected.id)
    ? coatingAndFlooringDimensionsSchema
    : notCoatingAndFlooringDimensionsSchema;
  const { register, handleSubmit, errors, setError, control } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...packageDimensionsSchema.fields,
        ...selectedProductFormSchema.fields,
        ...commonAttributesSchema.fields,
        ...selectedDimensionsSchema.fields,
        ...principalImageSchema.fields,
        ...ambientImagesSchema.fields,
      })
    ),
  });
  let comissionValues = createComissionValues(20, 65);
  useEffect(() => {
    getData();
  }, []);

  const onChangeIsUniqueProduct = ({ target }) => {
    setIsUniqueProduct(Number(target.value));
  };

  // find category by name to render fields of dimensions
  const findByCategory = (category) => {
    let categorySelected = productSizeAttributes.find((item) => {
      return item.id === category;
    });

    setCategorySelected(categorySelected ? categorySelected : { fields: [] });
  };

  useEffect(() => {
    findByCategory(Number(categorieInput));
  }, [categorieInput]);

  const getData = async (data) => {
    let brands = await axios.get("/v2/products/brands");
    let categories = await axios.get("/v2/categories");
    let colors = await axios.get("/v2/products/colors");
    let storageAddress = await axios.get("/v2/suppliers/storage-address");
    setBrands(brands.data);
    setColors(colors.data);
    setCategories(categories.data);
    setListLightTypes(lightTypes);
    setListPackageTypes(packageTypes);
    setStorageAddresses(storageAddress.data);
  };

  const onSubmit = async (data) => {
    if (isUniqueProduct === 1) {
      data = { ...data, units_available: 1 };
    }
    // validate data.warranty is null or undefined change to 0
    if (data.warranty === null || data.warranty === undefined) {
      data = { ...data, warranty: 0 };
    }

    if (
      data.is_warranty_in_months === null ||
      data.is_warranty_in_months === undefined
    ) {
      data = { ...data, is_warranty_in_months: 0 };
    }

    try {
      setLoading(true);
      // Validates the number of entered dimensions for a product.
      const { width, height, length, depth, thickness, diameter } = data;

      const DimensionCount = numDimensionsEntered(
        categorySelected.id,
        width,
        height,
        length,
        depth,
        thickness,
        diameter
      );

      // Validates that the minimum number of dimensions for a product is 2.
      if (DimensionCount < 2) {
        // Display error message indicating that values must be entered in at least two fields.
        setError("product_dimensions", {
          type: "manual",
          message:
            "Debes ingresar valores en al menos dos campos relacionados con las dimensiones",
        });
      }
      let formDataForRequest = new FormData();

      // append product cover image
      data.cover.forEach((image) => {
        formDataForRequest.append("cover", image);
      });

      // append product ambient images
      data.ambient.forEach((image) => {
        formDataForRequest.append("ambient", image);
      });

      // append product technical sheet
      if (data.sheet) {
        data.sheet.forEach((image) => {
          formDataForRequest.append("sheet", image);
        });
      }

      // convert object with designer info to form data
      for (let key in data) {
        if (key !== "cover" && key !== "sheet" && key !== "ambient") {
          formDataForRequest.append(key, data[key]);
        }
      }

      await axios.post("/v2/suppliers/products", formDataForRequest);
      history.push("/proveedor/producto/lista");
      successToast("Producto creado exitosamente");
    } catch (error) {
      errorToast("Producto no pudo ser creado, por favor intenta nuevamente");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="layout-dashboard-container">
      <div className="header-container">
        <h4 className="header-title">Crear un nuevo producto</h4>
        <BreadcrumbNav
          firstText="Proveedor"
          secondText="Producto"
          secondLink="/proveedor/producto/lista"
          thirdText={"Nuevo producto"}
        />
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Paper className="paperFormContainer">
            <Typography variant="h6" className={classes.headingTextForm}>
              Información general
            </Typography>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className={classes.gridContainerForm}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.category_id?.message}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Categoría
                  </InputLabel>
                  <Select
                    onChange={({ target }) => {
                      setCategorieInput(Number(target.value));
                    }}
                    inputRef={register}
                    native
                    label="Categoría"
                    name="category_id"
                    id="category"
                  >
                    <>
                      <option aria-label="None" value="" />
                      {categories?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </>
                  </Select>
                </FormControl>
                <InputValidationMessage
                  message={errors?.category_id?.message}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.name?.message}
                  helperText="Incorrect entry."
                >
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={name}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel>Referencia o nombre</InputLabel>
                  <OutlinedInput
                    id="name"
                    name="name"
                    inputRef={register}
                    label="Referencia o nombre"
                    labelWidth={175}
                  />
                </FormControl>
                <InputValidationMessage message={errors?.name?.message} />
              </Grid>
              {brands && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <Controller
                    name="brand"
                    control={control}
                    render={({ onChange }) => (
                      <CustomCreatableSelect
                        options={brands}
                        placeholder="Elegir o crear marca"
                        errors={errors}
                        id="brand"
                        onChange={onChange}
                      />
                    )}
                  />
                  <InputValidationMessage message={errors?.brand?.message} />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.material?.message}
                >
                  {categorySelected.id === 11 ? (
                    <TooltipLargeText
                      icon={InfoOutlinedIcon}
                      text={homeAndWellnessMaterial}
                      color="#F4BF2A"
                      fontSize="1.2rem"
                    />
                  ) : categorySelected.id === 12 ? (
                    <TooltipLargeText
                      icon={InfoOutlinedIcon}
                      text={petsMaterial}
                      color="#F4BF2A"
                      fontSize="1.2rem"
                    />
                  ) : (
                    <TooltipLargeText
                      icon={InfoOutlinedIcon}
                      text={material}
                      color="#F4BF2A"
                      fontSize="1.2rem"
                    />
                  )}
                  <InputLabel>Material del producto</InputLabel>
                  <OutlinedInput
                    id="material"
                    name="material"
                    inputRef={register}
                    label="Material del producto"
                    labelWidth={175}
                  />
                </FormControl>
                <InputValidationMessage message={errors?.material?.message} />
              </Grid>
              {categorySelected.id === 8 || categorySelected.id === 9 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.gridMarginTop}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors?.min_sale_unit?.message}
                    >
                      <TooltipLargeText
                        icon={InfoOutlinedIcon}
                        text={minimumSellingUnit}
                        color="#F4BF2A"
                        fontSize="1.2rem"
                      />
                      <InputLabel>Unidad minima venta</InputLabel>
                      <OutlinedInput
                        id="min_sale_unit"
                        name="min_sale_unit"
                        inputRef={register}
                        label="Unidad minima venta"
                        endAdornment={
                          <InputAdornment position="end">m²</InputAdornment>
                        }
                        labelWidth={175}
                      />
                    </FormControl>
                    <InputValidationMessage
                      message={errors?.min_sale_unit?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.gridMarginTop}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors?.units_per_package?.message}
                    >
                      <InputLabel>Unidades por empaque</InputLabel>
                      <OutlinedInput
                        id="units_per_package"
                        name="units_per_package"
                        inputRef={register}
                        label="Unidades por empaque"
                        labelWidth={175}
                      />
                    </FormControl>
                    <InputValidationMessage
                      message={errors?.units_per_package?.message}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.price?.message}
                >
                  <InputLabel>Precio al público sin IVA</InputLabel>
                  <OutlinedInput
                    id="price"
                    name="price"
                    inputRef={register}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    labelWidth={180}
                  />
                </FormControl>
                <InputValidationMessage message={errors?.price?.message} />
              </Grid>
              {categorySelected.id === 11 ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors?.size?.message}
                  >
                    <TooltipLargeText
                      icon={InfoOutlinedIcon}
                      text={size}
                      color="#F4BF2A"
                      fontSize="1.2rem"
                    />
                    <InputLabel>Talla (opcional)</InputLabel>
                    <OutlinedInput
                      id="size"
                      name="size"
                      inputRef={register}
                      label="Talla (opcional)"
                      labelWidth={300}
                    />
                  </FormControl>
                  <InputValidationMessage message={errors?.size?.message} />
                </Grid>
              ) : null}
              {categorySelected.id === 4 ||
              categorySelected.id === 8 ||
              categorySelected.id === 9 ||
              categorySelected.id === 11 ||
              categorySelected.id === 12 ? null : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors?.is_unique?.message}
                  >
                    <TooltipLargeText
                      icon={InfoOutlinedIcon}
                      text={uniqueProduct}
                      color="#F4BF2A"
                      fontSize="1.2rem"
                    />
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Producto único
                    </InputLabel>
                    <Select
                      onChange={onChangeIsUniqueProduct}
                      inputRef={register}
                      native
                      id="is_unique"
                      label="Producto único"
                      name="is_unique"
                    >
                      <option aria-label="None" value="" />
                      <option value={1}>Si</option>
                      <option value={0}>No</option>
                    </Select>
                  </FormControl>
                  <InputValidationMessage
                    message={errors?.is_unique?.message}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors?.storage_address_id?.message}
                >
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={shippingAddress}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Dirección de despacho
                  </InputLabel>
                  <Select
                    inputRef={register}
                    native
                    id="storage_address_id"
                    label="Dirección de despacho"
                    name="storage_address_id"
                  >
                    <option aria-label="None" value="" />
                    {storageAddresses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.street_address} {item.city_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage
                  message={errors?.storage_address_id?.message}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors?.comission_percentage?.message}
                >
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={comission}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Comisión
                  </InputLabel>
                  <Select
                    id="comission_percentage"
                    inputRef={register}
                    native
                    label="Comisión"
                    name="comission_percentage"
                  >
                    <option aria-label="None" value="" />
                    {comissionValues.map((item) => (
                      <option key={item.id} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage
                  message={errors?.comission_percentage?.message}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors?.delivery_time?.message}
                >
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={deliveryTime}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel>Tiempo de entrega</InputLabel>
                  <OutlinedInput
                    id="delivery_time"
                    name="delivery_time"
                    inputRef={register}
                    label="Tiempo de entrega"
                    endAdornment={
                      <InputAdornment position="end">días</InputAdornment>
                    }
                    labelWidth={175}
                  />
                </FormControl>
                <InputValidationMessage
                  message={errors?.delivery_time?.message}
                />
              </Grid>
              {categorySelected.id === 3 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.gridMarginTop}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!errors?.light_type_id?.message}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Tipo de luz
                      </InputLabel>
                      <Select
                        inputRef={register}
                        native
                        id="light_type_id"
                        label="Tipos de luz"
                        name="light_type_id"
                      >
                        <option aria-label="None" value="" />
                        {listLightTypes?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <InputValidationMessage
                      message={errors?.light_type_id?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.gridMarginTop}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!errors?.lamp_type_id?.message}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Tipo de lámpara
                      </InputLabel>
                      <Select
                        id="lamp_type_id"
                        inputRef={register}
                        native
                        label="Tipos de lámpara"
                        name="lamp_type_id"
                      >
                        <option aria-label="None" value="" />
                        {lampTypes?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <InputValidationMessage
                      message={errors?.lamp_type_id?.message}
                    />
                  </Grid>
                </>
              ) : null}
              {categorySelected.id === 11 || categorySelected.id === 12 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.gridMarginTop}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!errors?.origin?.message}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Origen
                      </InputLabel>
                      <Select
                        id="origin"
                        inputRef={register}
                        native
                        label="Origen"
                        name="origin"
                      >
                        <option aria-label="None" value="" />
                        {originTypes.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <InputValidationMessage message={errors?.origin?.message} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.gridMarginTop}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!errors?.product_content?.message}
                    >
                      <TooltipLargeText
                        icon={InfoOutlinedIcon}
                        text={product_content}
                        color="#F4BF2A"
                        fontSize="1.2rem"
                      />
                      <InputLabel>Contenido del producto</InputLabel>
                      <OutlinedInput
                        id="product_content"
                        name="product_content"
                        inputRef={register}
                        label="Contenido del producto"
                        labelWidth={175}
                      />
                    </FormControl>
                    <InputValidationMessage
                      message={errors?.product_content?.message}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors?.color_id?.message}
                >
                  <TooltipLargeText
                    icon={InfoOutlinedIcon}
                    text={color}
                    color="#F4BF2A"
                    fontSize="1.2rem"
                  />
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Color
                  </InputLabel>
                  <Select
                    inputRef={register}
                    native
                    label="Color"
                    id="color_id"
                    name="color_id"
                  >
                    <option aria-label="None" value="" />
                    {colors?.map((item) => (
                      <option
                        style={{
                          backgroundColor: item.hex,
                          color: item.id === 2 ? "white" : "black",
                        }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage message={errors?.color_id?.message} />
              </Grid>
              {isUniqueProduct === 0 ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.gridMarginTop}
                >
                  <TextField
                    id="units_available"
                    name="units_available"
                    inputRef={register}
                    label="Inventario en unidades"
                    variant="outlined"
                    error={!!errors?.units_available?.message}
                    fullWidth
                  />
                  <InputValidationMessage
                    message={errors?.units_available?.message}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.gridMarginTop}
              >
                <TextField
                  name="warranty"
                  inputRef={register}
                  label="Garantía"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.warranty?.message}
                  InputProps={{
                    endAdornment: (
                      <Select
                        style={{ marginLeft: "37%" }}
                        id="is_warranty_in_months"
                        name="is_warranty_in_months"
                        variant="standard"
                        defaultValue={"Select"}
                        onChange={(e) => {
                          register({
                            name: "is_warranty_in_months",
                            value: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={"Select"} disabled divider>
                          Seleccionar
                        </MenuItem>
                        <MenuItem value={1}>Meses</MenuItem>
                        <MenuItem value={0}>Años</MenuItem>
                      </Select>
                    ),
                  }}
                />
                <InputValidationMessage message={errors?.warranty?.message} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.gridMarginTop}
              >
                <TextField
                  id="description"
                  name="description"
                  inputRef={register}
                  label="Descripción general"
                  variant="outlined"
                  error={!!errors?.description?.message}
                  rows={4}
                  fullWidth
                  multiline
                />
                <InputValidationMessage
                  message={errors?.description?.message}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className="paperFormContainer">
            <Typography variant="h6" className={classes.headingTextForm}>
              Dimensiones del producto.
            </Typography>
            <div
              style={{
                display: "flex",
                marginTop: "0.5rem",
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>
                (*) Por favor solo ingresar metros como unidad de medida.
                Realizar conversión a metros si es necesario.
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "0.5rem",
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>
                (*) Solo diligenciar las que correspondan a tu producto
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "0.5rem",
                marginBottom: "1rem",
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>
                <strong>Nota</strong> : Por favor, utiliza un punto en vez de
                una coma para valores decimales. (Ejemplo: 1.65 para decimales y
                56 para enteros.)
              </span>
            </div>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className={classes.gridContainerForm}
            >
              {categorySelected?.fields?.map(
                ({ label, name, endAdornment }) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={name}
                      className={classes.gridMarginTop}
                    >
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={errors?.[name]?.message}
                      >
                        <InputLabel>{` ${label} (*) `}</InputLabel>
                        <OutlinedInput
                          id={name}
                          name={name}
                          inputRef={register}
                          label={` ${label} (*) `}
                          endAdornment={
                            <InputAdornment position="end">
                              {endAdornment}
                            </InputAdornment>
                          }
                          labelWidth={110}
                        />
                      </FormControl>
                      <InputValidationMessage
                        message={errors?.[name]?.message}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
            <br />
            <InputValidationMessage
              message={errors?.product_dimensions?.message}
            />
          </Paper>
          {/* specifications of a package  */}
          <Paper className="paperFormContainer">
            <Typography variant="h6" className={classes.headingTextForm}>
              Dimensiones del empaque.
            </Typography>
            <div
              style={{
                display: "flex",
                marginTop: "0.5rem",
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>
                (*) Por favor solo ingresar centímetros como unidad de medida.
                Realizar conversión a centímetros si es necesario.
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "0.5rem",
                marginBottom: "1rem",
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>
                <strong>Nota</strong> : utiliza un punto en lugar de una coma.
                (Ejemplo: 1.65 para valores decimales y 56 para valores
                enteros.)
              </span>
            </div>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className={classes.gridContainerForm}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.gridMarginTop}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors?.packing_presentation_id?.message}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Tipo
                  </InputLabel>
                  <Select
                    inputRef={register}
                    native
                    label="Tipo"
                    name="packing_presentation_id"
                  >
                    <option aria-label="None" value="" />
                    {listPackageTypes?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <InputValidationMessage
                  message={errors?.packing_presentation_id?.message}
                />
              </Grid>
              {categorySelected?.packageFields?.map(
                ({ label, name, endAdornment }) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      key={name}
                      className={classes.gridMarginTop}
                    >
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={errors?.[name]?.message}
                      >
                        <InputLabel>{` ${label} `}</InputLabel>
                        <OutlinedInput
                          id={name}
                          name={name}
                          inputRef={register}
                          label={` ${label} `}
                          endAdornment={
                            <InputAdornment position="end">
                              {endAdornment}
                            </InputAdornment>
                          }
                          labelWidth={110}
                        />
                      </FormControl>
                      <InputValidationMessage
                        message={errors?.[name]?.message}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
            <br />
          </Paper>
          {/* Upload documents and images  */}
          <Paper className="paperFormContainer">
            <Typography variant="h6" className={classes.headingTextForm}>
              Imagenes y documentos
            </Typography>
            <FileInputContainer>
              <IndividualFileInputContainer>
                <InputLabel
                  style={{
                    zIndex: "0",
                    marginTop: "1rem",
                    textAlign: "center",
                  }}
                  className={classes.fileInputLabel}
                >
                  Imagen principal
                </InputLabel>
                <div>
                  <span className={classes.imageUploadInstructions}>
                    Sube la imagen principal: debe ser un primer <br /> plano
                    del producto.
                  </span>
                </div>
                <Controller
                  name="cover"
                  control={control}
                  render={({ onChange }) => (
                    <DragAndDrop
                      acceptedFiles="image/*"
                      maxFiles={1}
                      onChange={onChange}
                      errors={errors}
                      name="cover"
                    />
                  )}
                  maxFiles={1}
                />
              </IndividualFileInputContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel
                  style={{
                    zIndex: "0",
                    marginTop: "1rem",
                    textAlign: "center",
                  }}
                  className={classes.fileInputLabel}
                >
                  Imágenes de producto ambientado
                </InputLabel>
                <span className={classes.imageUploadInstructions}>
                  Máximo 5 imágenes adicionales: muestra <br /> diferentes
                  ángulos o ambientes.
                </span>
                <Controller
                  name="ambient"
                  control={control}
                  render={({ onChange }) => (
                    <DragAndDrop
                      multipleEnabled
                      acceptedFiles="image/*"
                      maxFiles={5}
                      onChange={onChange}
                      errors={errors}
                      name="ambient"
                    />
                  )}
                />
              </div>
              <IndividualFileInputContainer>
                <InputLabel
                  style={{
                    zIndex: "0",
                    marginTop: "1rem",
                    textAlign: "center",
                  }}
                  className={classes.fileInputLabel}
                >
                  Ficha técnica
                </InputLabel>
                <span className={classes.imageUploadInstructions}>
                  Sube la ficha técnica: especificaciones y <br /> detalles del
                  producto.
                </span>
                <Controller
                  name="sheet"
                  control={control}
                  render={({ onChange }) => (
                    <DragAndDrop
                      acceptedFiles="image/*,.pdf"
                      maxFiles={1}
                      onChange={onChange}
                      errors={errors}
                      name="sheet"
                    />
                  )}
                />
              </IndividualFileInputContainer>
            </FileInputContainer>
            <div style={{ textAlign: "center", width: "100%" }}>
              <InputValidationMessage message={errors?.code?.message} />
            </div>
          </Paper>
          <Row>
            <ButtonContainer>
              <CustomButton
                type="submit"
                color="secondary"
                shape="little-rounded"
                size="medium"
                isLoading={loading}
                disabled={loading}
              >
                Crear producto
              </CustomButton>
            </ButtonContainer>
          </Row>
        </div>
      </form>
    </section>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.8rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const IndividualFileInputContainer = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
`;

const FileInputContainer = styled.div`
  padding: 0.1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 3rem;

  @media (min-width: 62rem) {
    padding-bottom: 1.5rem;
  }
`;

export default ProductForm;
