import { useParams } from "react-router";

import { Tabs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CustomMaterialTable from "../../../../components/CustomMaterialTable/CustomMaterialTable";
import Tab from "../../../../components/CustomTab/CustomTab";
import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import { orderStatus } from "../../../../data/orderStatus";
import CustomBreadCrumb from "../../../../ecommerce/components/CustomBreadCrumb/CustomBreadCrumb";
import SalesTableColumns from "./components/salesTableColumns/SalesTableColumns";
import useFilteredData from "../../../../hooks/useFilteredData";

import { useFetchOrder } from "./hooks/useFetchOrder";
import { type RouteParams } from "./types";
import OrderStatusBadge from "../../../../components/OrderStatusBadge/OrderStatusBadge";

const OrdersSupplierTable = () => {
  const guiaEmbalajeLink =
    "https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/Envia_GuiaEmbalaje.pdf";

  const { id } = useParams<RouteParams>();
  const orderId = Number(id);

  const { isLoading, orders } = useFetchOrder(orderId);
  const { selectedTab, handleTabChange, filteredData, orderStatusCounts } =
    useFilteredData(orders?.suborders || []);
  if (isLoading || !orders) {
    return <LoadingScreen />;
  }

  return (
    <section className="layout-dashboard-container">
      <Alert severity="info">
        <a href={guiaEmbalajeLink} target="__blank">
          Guía de embalaje
        </a>
      </Alert>
      <br />
      <CustomBreadCrumb orderId={orders.orderNumber} userRole="supplier" />
      <CustomMaterialTable
        title="Ordenes"
        columns={SalesTableColumns()}
        data={filteredData}
      >
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => handleTabChange(value)}
        >
          <Tab
            label={
              <OrderStatusBadge
                status="En preparacion"
                count={orderStatusCounts.Preparation}
              />
            }
            value={orderStatus.Preparation}
          />
          <Tab
            label={
              <OrderStatusBadge
                status="En transito"
                count={orderStatusCounts.Shipped}
              />
            }
            value={orderStatus.Shipped}
          />
          <Tab
            label={
              <OrderStatusBadge
                status="Entregado"
                count={orderStatusCounts.Delivered}
              />
            }
            value={orderStatus.Delivered}
          />
          <Tab
            label={
              <OrderStatusBadge
                status="Cancelado"
                count={orderStatusCounts.Canceled}
              />
            }
            value={orderStatus.Canceled}
          />
        </Tabs>
      </CustomMaterialTable>
    </section>
  );
};
export default OrdersSupplierTable;
