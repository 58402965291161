import { yupResolver } from "@hookform/resolvers";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import { FieldError, useForm } from "react-hook-form";

import CustomButton from "../../../../../../components/Button/CustomButton";
import InputValidationMessage from "../../../../../../components/forms/InputValidationMessage/InputValidationMessage";
import { type Dimensions } from "../../../../../../ecommerce/pages/ProductInformation/models/productDescription";
import {
  coatingAndFlooringDimensionsSchema,
  notCoatingAndFlooringDimensionsSchema,
} from "../../../../../../services/formValidation";
import { errorToast, successToast } from "../../../../../../services/toasts";
import { useStyles } from "../../../../../../theme/FormStyles";
import { useUpdateProductMeasurements } from "../../../../../../utils/react-query/querySupplier/queries";
import productSizeAttributes from "../../../../data/fieldsByCategory";
import styles from "./measuresForm.module.scss";

type MeasuresFormProps = {
  dimensions: Dimensions;
  categoryId: number;
  ProductId: number;
};

interface ErrorsIndexSignature {
  [key: string]: FieldError | undefined;
}

const MeasuresForm = ({
  dimensions,
  categoryId,
  ProductId,
}: MeasuresFormProps) => {
  const { mutateAsync: updateProductDimensions, isLoading: isLoadingUpdate } =
    useUpdateProductMeasurements();
  const classes = useStyles();
  const selectedDimensionsSchema = [8, 9].includes(categoryId)
    ? coatingAndFlooringDimensionsSchema
    : notCoatingAndFlooringDimensionsSchema;

  // find the fields for the category
  const fields = productSizeAttributes.find((field) => field.id === categoryId);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(selectedDimensionsSchema),
    defaultValues: {
      width: dimensions.width,
      height: dimensions.height,
      length: dimensions.length,
      depth: dimensions.depth,
      thickness: dimensions.thickness,
      diameter: dimensions.diameter,
      weight: dimensions.weight,
    },
  });

  async function onSubmit(data: any) {
    const updatedProductDimensions = await updateProductDimensions({
      productId: ProductId,
      productMeasurements: data,
    });

    if (!updatedProductDimensions) {
      errorToast("No se pudo actualizar las medidas del producto");
    }

    return successToast("Medidas del producto actualizadas correctamente");
  }

  return (
    <>
      <div className={styles.measuresFormInfo}>
        <p>
          (*) Por favor solo ingresar metros como unidad de medida. Realizar
          conversión a metros si es necesario.
        </p>
        <p>(*) Solo diligenciar las que correspondan a tu producto</p>
        <p>
          <strong>Nota</strong> : Por favor, utiliza un punto en vez de una coma
          para valores decimales. (Ejemplo: 1.65 para decimales y 56 para
          enteros.)
        </p>
      </div>
      <form
        className="formProductContainer"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Paper className="paperFormContainer">
          <Grid container alignItems="center" spacing={2}>
            {fields?.fields?.map(({ label, name, endAdornment }) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={name}
                  className={classes.gridMarginTop}
                >
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{` ${label} (*) `}</InputLabel>
                    <OutlinedInput
                      name={name}
                      inputRef={register}
                      label={` ${label} (*) `}
                      endAdornment={
                        <InputAdornment position="end">
                          {endAdornment}
                        </InputAdornment>
                      }
                      labelWidth={175}
                    />
                  </FormControl>
                  <InputValidationMessage
                    message={(errors as ErrorsIndexSignature)[name]?.message}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
        <div className="formProductSaveButton">
          <CustomButton
            type="submit"
            color="secondary"
            shape="little-rounded"
            size="medium"
            isLoading={isLoadingUpdate}
          >
            Guardar Cambios
          </CustomButton>
        </div>
      </form>
    </>
  );
};
export default MeasuresForm;
