import styles from "./showError.module.scss";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
interface ShowErrorProps {
  error: string;
}

const ShowError = ({ error }: ShowErrorProps) => {
  return (
    <div className={styles.showError}>
      <ErrorOutlineIcon />
      <span className={styles.errorMessage}>{error}</span>
    </div>
  );
};
export default ShowError;
