import Axios from "axios";
import {
  createContext,
  ReactElement,
  useReducer,
  useEffect,
  useContext,
} from "react";
import filterReducer, { FilterInitialState } from "../Reducer/FilterReducer";
import { useProductsContext } from "../../Products/context/ProductContext";
import { REDUCER_ACTION_TYPE } from "../Actions/filterActions";
import { type ProductPaginationData } from "../../../ecommerce/pages/Products/models/ProductPaginationData";
import { type Filter } from "../types";

const initFilterContextState = {
  ...FilterInitialState,
  setGridView: () => {},
  setListView: () => {},
  updateSort: (e: any) => {},
  updateFiltersPrice: (e: any) => {},
  updateFiltersColor: (e: any) => {},
  updateFiltersBrand: (e: any) => {},
  updateFiltersMaterial: (e: any) => {},
  updateCurrentPage: (newPage: number) => {},
  updateTotalItems: (newTotalItems: number) => {},
  resetAllFilters: () => {},
  updateTotalPages: (totalPages: number) => {},
  updatePagination: (newPagination: any) => {},
  clearFilters: () => {},
  getProductsByFilters: (
    categoryId: number,
    filters: Filter,
    currentPage: number,
    itemsPerPage: number,
    sort: string
  ) => {},
};

const FilterContext = createContext(initFilterContextState);

type ChildrenType = {
  children: ReactElement | ReactElement[];
};

export const FilterProvider = ({ children }: ChildrenType) => {
  const { products } = useProductsContext();
  const [state, dispatch] = useReducer(filterReducer, FilterInitialState);

  useEffect(() => {
    dispatch({ type: REDUCER_ACTION_TYPE.LOAD_PRODUCTS, payload: products });
  }, [products]);

  const setGridView = () => {
    dispatch({ type: REDUCER_ACTION_TYPE.SET_GRIDVIEW });
  };

  const setListView = () => {
    dispatch({ type: REDUCER_ACTION_TYPE.SET_LISTVIEW });
  };

  const updateSort = (e: any) => {
    const value = e.target.value;
    dispatch({ type: REDUCER_ACTION_TYPE.UPDATE_SORT, payload: value });

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_CURRENT_PAGE,
      payload: 1,
    });
  };

  const getProductsByFilters = async (
    categoryId: number,
    filters: Filter,
    currentPage: number,
    itemsPerPage: number,
    sort: string
  ) => {
    const queryParams = new URLSearchParams({
      currentPage: currentPage.toString(),
      itemsPerPage: itemsPerPage.toString(),
      brand: filters.brand.join(","),
      color: filters.color.join(","),
      material: filters.material.join(","),
      max_price: filters.price.toString(),
      sort: sort,
    });
    const url = `/v2/products/category/${categoryId}?${queryParams.toString()}`;
    try {
      dispatch({ type: REDUCER_ACTION_TYPE.GET_ALL_PRODUCTS_BEGIN });
      const response = await Axios.get<ProductPaginationData | null>(url);
      const { items, pagination } = response?.data as ProductPaginationData;
      updatePagination(pagination);

      dispatch({
        type: REDUCER_ACTION_TYPE.GET_ALL_PRODUCTS_SUCCESS,
        payload: items,
      });

      return response?.data as ProductPaginationData;
    } catch (error) {
      dispatch({ type: REDUCER_ACTION_TYPE.GET_ALL_PRODUCTS_ERROR });
    }
  };

  const updateFiltersPrice = (e: any) => {
    let name = e.target.name;
    let value = Number(e.target.value);

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_PRICE,
      payload: { name, value },
    });
  };

  const updateFiltersColor = (value: any) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_COLOR,
      payload: { value },
    });

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_CURRENT_PAGE,
      payload: 1,
    });
  };

  const updateFiltersBrand = (e: any) => {
    let value = e.target.name;

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_BRAND,
      payload: { value },
    });

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_CURRENT_PAGE,
      payload: 1,
    });
  };

  const updateFiltersMaterial = (e: any) => {
    let value = e.target.name;

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_MATERIAL,
      payload: { value },
    });

    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_CURRENT_PAGE,
      payload: 1,
    });
  };

  const updateCurrentPage = (newPage: number) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_CURRENT_PAGE,
      payload: newPage,
    });
  };

  const updateTotalItems = (newTotalItems: number) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_TOTAL_ITEMS,
      payload: newTotalItems,
    });
  };

  const updateTotalPages = (totalPages: number) => ({
    type: REDUCER_ACTION_TYPE.UPDATE_TOTAL_PAGES,
    payload: totalPages,
  });

  const updatePagination = (newPagination: any) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.UPDATE_PAGINATION,
      payload: newPagination,
    });
  };
  const resetAllFilters = () => {
    dispatch({ type: REDUCER_ACTION_TYPE.RESET_FILTERS });
  };
  const clearFilters = () => {
    dispatch({ type: REDUCER_ACTION_TYPE.CLEAR_FILTERS });
  };

  return (
    <FilterContext.Provider
      value={{
        ...state,
        setGridView,
        setListView,
        updateSort,
        updateFiltersColor,
        updateFiltersMaterial,
        updateFiltersBrand,
        updateFiltersPrice,
        updateCurrentPage,
        updateTotalItems,
        resetAllFilters,
        clearFilters,
        updateTotalPages,
        updatePagination,
        getProductsByFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
