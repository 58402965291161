import React from "react";
import Button from "../../components/button.js";
import SuccessBox from "../../components/SuccessBox/SuccessBox.js";
import { getAccessoryProducts } from "../../services/api.js";
import { createUseStyles } from "react-jss";
import { createInvoice, saveInvoice } from "../../services/api.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory, useLocation, Link } from "react-router-dom";

function Quoter() {
  let data = useLocation();
  let products = data.state ? data.state.products : [];
  let quotePurchase = data.state ? data.state.quotePurchase : null;
  const history = useHistory();
  const classes = useStyles();
  const [brands, setBrands] = React.useState();
  const [previewScreen, setPreviewScreen] = React.useState(false);
  const [successScreen, setSuccessScreen] = React.useState(false);
  const [invoice, setInvoice] = React.useState();
  const [m2, setM2] = React.useState(new Array(products.length).fill(1));
  const [units, setUnits] = React.useState(new Array(products.length).fill(1));
  const [invoiceFilename, setInvoiceFilename] = React.useState("");

  React.useEffect(() => {
    // If the user attemps to directly access the quoter without selecting products,
    // re-direct them to the dashboard
    if (products.length === 0 || !quotePurchase) {
      history.push("/disenador/dashboard");
    }

    // Set initial values for m²
    for (let i = 0; i < products.length; i++) {
      setM2((oldM2) => {
        const newM2 = [...oldM2];
        newM2[i] = products[i].unidad_minima_m2;
        return newM2;
      });
    }

    // Get brands
    getAccessoryProducts(products).then((res) => {
      setBrands(res);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Generate a preview PDF before saving the budget to the db
  const generatePDF = () => {
    // Preserve the total
    quotePurchase.purchaseTotal = subtotal * 0.19 + subtotal;
    let unifyComplementaries = [];

    // Build an object with only complementary products
    brands.map((item) =>
      item.complementarios.map((a) => unifyComplementaries.push(a))
    );

    createInvoice({
      purchase: {
        ref: quotePurchase.hashid,
        address: quotePurchase.purchase_address,
      },
      client: {
        name: quotePurchase.client_name,
        email: quotePurchase.client_email,
        phone: quotePurchase.client_phone,
      },
      products: [...products, ...unifyComplementaries],
    }).then((res) => {
      setInvoice(res); // PDF base64 data
    });
    setPreviewScreen(true);
  };

  // Save budget pdf to db
  const handleBudgetSubmit = () => {
    let info = quotePurchase;

    let unifyComplementaries = [];

    // Build an object with only complementary products
    brands.map((item) =>
      item.complementarios.map((a) => unifyComplementaries.push(a))
    );

    saveInvoice({
      buffer: invoice,
      purchaseId: info.id_purchase,
      designerId: info.id_designer,
      budgetName: invoiceFilename,
      purchaseTotal: info.purchaseTotal,
      products: [...products, ...unifyComplementaries],
    }).then((res) => {
      // Success message noting that the budget has been saved
      setSuccessScreen(true);
      setPreviewScreen(false);
    });
  };

  // Update user selected m² for products, and calculate units based on that
  const updateUnits = (neededM2, min, max, i) => {
    let neededProduct = parseInt(neededM2);

    // Prevent out of range values
    if (neededProduct > 0 && neededProduct <= max && neededProduct > min) {
      setM2((oldM2) => {
        const newM2 = [...oldM2];
        newM2[i] = neededM2;
        return newM2;
      });

      setUnits((oldUnits) => {
        const newUnits = [...oldUnits];
        // Calculate units depending on m²
        newUnits[i] = Math.ceil(neededM2 / products[i].unidad_minima_m2);
        return newUnits;
      });
    }
  };

  // Sum produts price and installation cost
  let subtotal = 0;
  for (let i = 0; i < units.length; i++) {
    subtotal += units[i] * products[i].precio;
    subtotal += m2[i] * products[i].precio_instalacion;
  }

  if (previewScreen) {
    return (
      <Paper>
        <div className={classes.container}>
          <div style={{ marginBottom: "1rem" }}>
            <h6
              style={{
                marginBottom: "1rem",
                fontFamily: "Roboto",
                fontSize: "1.25rem",
                fontWeight: "500",
                letterSpacing: "0.0075em",
              }}
            >
              Cotizador
            </h6>

            <div style={{ marginBottom: "0.5rem" }}>
              <label
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  marginRight: "1rem",
                }}
              >
                Añade un nombre a esta cotización:
              </label>
              <input
                type="text"
                onChange={(e) => setInvoiceFilename(e.target.value)}
              />
            </div>

            <p
              style={{
                fontFamily: "Roboto",
                marginBottom: "0.5rem",
                fontSize: "14px",
              }}
            >
              <i
                class="fa fa-info-circle"
                aria-hidden="true"
                style={{ color: "#232f3e", marginRight: "0.25rem" }}
              ></i>
              Antes de subir la cotización a la hoja de proceso para
              visualización del cliente, por favor revisa que los contenidos
              sean correctos y añade un nombre
            </p>
          </div>

          {invoice ? (
            <iframe
              title="Cotización"
              src={`data:application/pdf;base64,${invoice}`}
              className={classes.iframe}
            ></iframe>
          ) : (
            <div>Cargando PDF...</div>
          )}

          <div className={classes.footer2}>
            <Button onClick={() => setPreviewScreen(false)}>Volver</Button>
            <Button
              disabled={invoiceFilename ? false : true}
              onClick={handleBudgetSubmit}
            >
              Subir cotización
            </Button>
          </div>
        </div>
      </Paper>
    );
  } else if (successScreen) {
    return (
      <Paper style={{ padding: "3rem" }}>
        <SuccessBox
          title="¡Has subido tú cotización exitosamente!"
          text="Ahora tu cliente podra revisarla en su hoja de proceso."
        >
          <Link
            style={{ color: "grey", marginTop: "1rem" }}
            to="/disenador/productos/"
          >
            Volver a productos
          </Link>
        </SuccessBox>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.container}>
        <div style={{ marginBottom: "1rem" }}>
          <h6
            style={{
              marginBottom: "1rem",
              fontFamily: "Roboto",
              fontSize: "1.25rem",
              fontWeight: "500",
              letterSpacing: "0.0075em",
            }}
          >
            Cotizador
          </h6>
          <p
            style={{
              fontFamily: "Roboto",
              marginBottom: "0.5rem",
              fontSize: "16px",
            }}
          >
            Especifica la cantidad de unidades que deseas para cada producto
          </p>
          <p
            style={{
              fontFamily: "Roboto",
              marginBottom: "0.5rem",
              fontSize: "14px",
            }}
          >
            <i
              class="fa fa-info-circle"
              aria-hidden="true"
              style={{ color: "#232f3e", marginRight: "0.25rem" }}
            ></i>
            Recuerda que después de realizada la cotización deberás enviarla lo
            antes posible al cliente, ya que los materiales se separaran por 3
            días y no se podrán volver a separar
          </p>
        </div>

        <div className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ padding: "6px 24px 6px 16px" }}>
                  Marca
                </TableCell>
                <TableCell>Referencia</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Unidades</TableCell>
                <TableCell>Precio</TableCell>
                <TableCell>Instalación</TableCell>
                <TableCell>Disponibilidad</TableCell>
                <TableCell>Subtotal</TableCell>
              </TableRow>
            </TableHead>
            {products &&
              products.map((product, i) => {
                // Add units to overall products object
                products[i].unidades_seleccionadas = units[i];
                products[i].m2_seleccionados = m2[i];

                let minSelection = product.unidad_minima_m2;
                let maxSelection =
                  product.unidad_minima_m2 * product.disponibilidad;

                return (
                  <TableRow>
                    <TableCell>
                      <img src={product.imagen} alt="imagen producto" />
                    </TableCell>
                    <TableCell>{product.marca}</TableCell>
                    <TableCell>{product.referencia}</TableCell>
                    <TableCell>
                      <input
                        type="number"
                        value={m2[i]}
                        onChange={(e) =>
                          updateUnits(
                            e.target.value,
                            minSelection,
                            maxSelection,
                            i
                          )
                        }
                        min={minSelection}
                        max={maxSelection}
                      />
                      {product.tipo === "revestimientos" ? "m2" : "unidades"}
                    </TableCell>
                    <TableCell>{units[i]}</TableCell>
                    <TableCell>${product.precio.toLocaleString()}</TableCell>
                    <TableCell>
                      ${(m2[i] * product.precio_instalacion).toLocaleString()}
                    </TableCell>
                    <TableCell>{product.disponibilidad - units[i]}</TableCell>
                    <TableCell>
                      ${(units[i] * product.precio).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            {brands &&
              brands.map((subproduct) => {
                // Array of the indexes that represent a single brand. Ex: [0, 1]
                let uniqueBrandIndexes = products.reduce(
                  (a, e, i) =>
                    e.id_marca === subproduct.id_marca ? a.concat(i) : a,
                  []
                );

                // Set the m² for the current subproduct to 0
                let subproductM2 = 0;

                // Sum the m² of all the products that belong to a same brand
                for (let i = 0; i < uniqueBrandIndexes.length; i++) {
                  subproductM2 += Number(m2[uniqueBrandIndexes[i]]);
                }

                return (
                  <>
                    {/* <th colspan="9" style={{ textAlign: "left", margin: "10px" }}>Complementarios para productos {subproduct.marca}</th> */}
                    {subproduct.complementarios.map(
                      (prod, induniqueBrandIndexes) => {
                        // Calculate the units
                        let subproductUnits = Math.ceil(
                          Number(subproductM2) / Number(prod.unidad_minima_m2)
                        );

                        // Add units to overall brands object
                        subproduct.complementarios[
                          induniqueBrandIndexes
                        ].unidades_seleccionadas = subproductUnits;
                        // FIXME: This works right now, but it doesn't really represent m² for subproducts
                        subproduct.complementarios[
                          induniqueBrandIndexes
                        ].m2_seleccionados = 0;

                        // Sum to subtotal the price of the accessories
                        subtotal += subproductUnits * prod.precio;
                        return (
                          <TableRow>
                            <TableCell>
                              <img src={prod.imagen} alt="imagen accesorio" />
                            </TableCell>
                            <TableCell>{prod.marca}</TableCell>
                            <TableCell>{prod.referencia}</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>{subproductUnits}</TableCell>
                            <TableCell>
                              ${prod.precio.toLocaleString()}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                              {prod.disponibilidad - subproductUnits}
                            </TableCell>
                            <TableCell>
                              $
                              {(subproductUnits * prod.precio).toLocaleString()}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </>
                );
              })}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: "1.2rem" }}>Subtotal</TableCell>
              <TableCell style={{ fontSize: "1.2rem" }}>
                ${subtotal.toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: "1.2rem" }}>IVA</TableCell>
              <TableCell style={{ fontSize: "1.2rem" }}>
                ${(subtotal * 0.19).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: "1.2rem" }}>Total</TableCell>
              <TableCell style={{ fontSize: "1.2rem" }}>
                ${(subtotal * 0.19 + subtotal).toLocaleString()}
              </TableCell>
            </TableRow>
          </Table>
        </div>
        <div className={classes.footer1}>
          <div style={{ display: "flex" }}>
            <Button
              onClick={() =>
                history.push({
                  pathname: "productos",
                  state: { quotePurchase: quotePurchase },
                })
              }
            >
              Volver
            </Button>
            <Button onClick={generatePDF}>Revisar cotización</Button>
          </div>
          {/* <div className={classes.totalsContainer}> */}
          {/*   <p>Subtotal: ${subtotal.toLocaleString()}</p> */}
          {/*   <p>IVA: ${(subtotal * 0.19).toLocaleString()}</p> */}
          {/*   <p>Gran total: ${((subtotal * 0.19) + subtotal).toLocaleString()}</p> */}
          {/* </div> */}
        </div>
      </Paper>
    );
  }
}

const useStyles = createUseStyles({
  container: {
    padding: "2rem",
    fontSize: 16,
    "& h1": {
      color: "#232f3e",
      textAlign: "left",
    },
    "& h2": {
      fontWeight: 500,
      lineHeight: 1.8,
      color: "#232f3e",
    },
    "& p": {
      fontFamily: "Open Sans",
    },
    "& img": {
      backgroundSize: "cover",
      width: 50,
      height: 50,
      borderRadius: 100,
    },
  },
  footer1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footer2: {
    display: "flex",
    alignItems: "center",
  },
  totalsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    "& p": {
      fontFamily: "Roboto",
    },
    "& span": {
      color: "grey",
      fontSize: "1.2rem",
      lineHeight: 1.8,
    },
    "& h2": {
      color: "#232f3e",
    },
  },
  tableContainer: {
    overflowX: "auto",
  },
  table: {
    borderCollapse: "collapse",
    borderSpacing: "0",
    marginRight: 40,
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    "& th": {
      color: "#232f3e",
      fontSize: 18,
      borderBottom: "1px solid #bebebe",
      padding: "10px 5px",
    },
    "& td": {
      borderBottom: "1px solid #e4e4e4",
      textAlign: "center",
      verticalAlign: "center",
      padding: "5px 0",
      "& input": {
        fontSize: 18,
        border: "none",
        borderBottom: "1px solid #232f3e",
        width: "100%",
        textAlign: "right",
      },
    },
  },
  subproductRow: {
    paddingLeft: 5,
    "& img": {
      backgroundSize: "cover",
      marginLeft: 20,
      width: 50,
      height: 50,
      borderRadius: 100,
    },
  },
  iframe: {
    marginTop: 10,
    minHeight: "60vh",
    width: "100%",
  },
});

export default Quoter;
