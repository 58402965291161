import { useHistory } from "react-router";
import Axios from "axios";

import { Column } from "material-table";

import ActionLink from "../../../../../components/ActionLink/ActionLink";
import CustomLink from "../../../../../components/CustomLink/CustomLink";
import DocumentControls from "../../../../../components/DocumentControls/DocumentControls";
import { successToast } from "../../../../../services/toasts";

import { type Supplier } from "../types";
import PaymentSwitch from "./PaymentSwitch";

const SuppliersTableColumns = () => {
  const history = useHistory();
  const handleconfirmPayment = async (
    orderId: number,
    supplierId: number,
    isConfirming: boolean
  ) => {
    const response = await Axios.patch(
      `/v2/admin/orderItem/${orderId}/${supplierId}`
    );
    if (response.status === 200) {
      if (isConfirming) {
        successToast("El pago se confirmó con éxito");
      } else {
        successToast("La confirmación de pago se canceló con éxito");
      }
    }

    setTimeout(() => {
      history.go(0);
    }, 1000);
  };

  const tableColumns: Array<Column<Supplier>> = [
    {
      title: "Nombre del proveedor",
      type: "string",
      align: "left",
      render: (rowData) => (
        <CustomLink
          to={`/admin/ventas/${rowData.orderId}/proveedores/${rowData.supplier_id}/ordenes`}
        >
          {rowData.supplier_name}
        </CustomLink>
      ),
    },
    {
      title: "Factura del Proveedor",
      render: (rowData) => {
        if (rowData.supplierFiles.length === 0) {
          return <p>Aún no hay Factura</p>;
        } else {
          return (
            <ActionLink
              href={rowData.supplierFiles[0].url}
              text="Ver soporte"
            />
          );
        }
      },
    },
    {
      title: "Soporte para proveedor",
      render: (rowData) => (
        <DocumentControls
          fileData={rowData.adminFiles}
          orderId={rowData.orderId}
          userType="admin"
          fileName="paymentSupport"
          supplierId={rowData.supplier_id}
        />
      ),
    },
    {
      title: "Pago",
      align: "left",
      render: (rowData) => (
        <PaymentSwitch
          handleconfirmPayment={handleconfirmPayment}
          rowData={rowData}
        />
      ),
    },
  ];
  return tableColumns;
};

export default SuppliersTableColumns;
