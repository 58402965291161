import { Typography } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      marginBottom: "1rem",
      marginLeft: "0.5rem",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "0",
      },
    },
  })
);

export interface PropsTypeBreadcrumb {
  category?: string | undefined;
  orderId: string;
  nameProduct?: string;
  userRole: "admin" | "supplier";
}

const CustomBreadCrumb = ({ orderId, userRole }: PropsTypeBreadcrumb) => {
  const classes = useStyles();
  const salesLink =
    userRole === "admin" ? "/admin/ventas/" : "/proveedor/ventas/";
  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href={salesLink}>
          ventas
        </Link>

        {orderId && <Typography color="textPrimary">{orderId}</Typography>}
      </Breadcrumbs>
    </div>
  );
};
export default CustomBreadCrumb;
