import { useState, useEffect, Fragment } from "react";
import { Remodeling, RemodelingRating } from "./types";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../../services/auth";
import { roles } from "../../services/auth";

import { StepName } from "./RemodelingStatusTable/StatusTable";
import RemodelingStatusTable from "./RemodelingStatusTable";
import Rating from "@material-ui/lab/Rating";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button/Button";
import CollapseList from "../../components/CollapseList";

import StepHelpModal from "./RemodelingResumeModals/StepHelpModal";
import WompiResponseModal from "./RemodelingResumeModals/WompiResponseModal";
import RemodelingRatingModal from "./RemodelingResumeModals/RemodelingRatingModal";
import RemodelingStaticInfo, {
  REMODELING_STATIC_INFO_CONTENT,
} from "./RemodelingStaticInfo";
import RemodelingHeaderInfo from "./RemodelingHeaderInfo";
import RemodelingDocuments from "./RemodelingDocuments";

import axios from "axios";
import moment from "moment";
import jwt from "jwt-decode";
import fileTypes from "../../services/fileTypes";

/**
 * Has all the information regarding a remodeling process: steps, designs,
 * invoices, etc...
 *
 * This is the place where the client spends most of his time.
 *
 * TODO: refractor the word 'purchase' to 'remodeling'
 */
function RemodelingResume() {
  const history = useHistory();
  const { hashid } = useParams<{ hashid: string }>();
  const { pathname } = useLocation();
  // @ts-ignore
  const { authToken } = useAuth<{ authToken: string }>();

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const isBigScreen = useMediaQuery({ minWidth: 768 });

  // Remodeling data
  const [purchase, setPurchase] = useState<Remodeling>();

  // Modals
  const [isShowingRatingModal, setIsShowingRatingModal] = useState(false);
  const [isShowingWompiResponseModal, setIsShowingWompiResponseModal] =
    useState(false);
  const [isShowingStepHelpModal, setIsShowingStepHelpModal] = useState(false);
  const [isRatingConfirmationChecked, setIsRatingConfirmationChecked] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Rating object and default values for when the modal shows up
  const [rating, setRating] = useState<RemodelingRating>({
    designer_rating: 5,
    installers_rating: 5,
    comments: "",
  });

  const [isShowingLegalDocuments, setIsShowingLegalDocuments] = useState(false);
  const [isShowingTechnicalDocuments, setIsShowingTechnicalDocuments] =
    useState(false);
  const [isSuccessfulPaymentWompi, setIsSuccessfulPaymentWompi] =
    useState(false);
  const [selectedStepHelpName, setSelectedStepHelpName] =
    useState<StepName>("visit_date");

  // TODO: In prod if the user is not authenticated this results on a
  // re-direct to the main page, but we might want to improve this in the
  // future
  const decodedToken: any = authToken && jwt(authToken);
  const isClient = decodedToken.role_id === roles.client;
  const isDesigner = decodedToken.role_id === roles.designer;
  const isLSH = decodedToken.role_id === roles.lsh;

  // Enable rating button for client when the installation step is completed
  const enableRating = purchase && purchase.steps[5].step_status_id !== 2;

  useEffect(() => {
    getData();
    handleWompiReturn();
  }, [hashid]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    let response = await axios.get(`/v1/remodelings/${hashid}`);
    setPurchase(response.data);
  };

  const handleWompiReturn = async () => {
    // If user returns from Wompi payment website
    if (pathname.split("/").includes("wompi")) {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);

      // Get wompi reference id from url
      let wompiReference = urlParams.get("id");

      // Verify that the transaction is valid
      let response = await axios.get(
        `/v1/remodelings/payments/wompi/${wompiReference}`
      );
      let paymentStatus = response.data.status;

      // If response from Wompi
      if (response) {
        // Show modal with information about payment response
        if (paymentStatus === "APPROVED") {
          setIsSuccessfulPaymentWompi(true);
          setIsShowingWompiResponseModal(true);
        } else if (paymentStatus === "DECLINED") {
          setIsSuccessfulPaymentWompi(false);
          setIsShowingWompiResponseModal(true);
        }
      }
      history.push(`/compra/${hashid}`);
    }
  };

  const handleFinishRemodeling = async () => {
    if (!purchase) return;

    setIsLoading(true);

    const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    await axios.patch(`/v1/remodelings/${hashid}?email=true`, {
      finished_at: currentDate,
    });
    purchase.finished_at = currentDate;

    setIsShowingRatingModal(true);

    // update all steps to completed
    for (let i = 0; i < purchase.steps.length; i++) {
      // update loaded data
      purchase.steps[i].step_status_id = 3;

      // persist in database
      axios.patch(`/v1/remodelings/steps/${purchase.steps[i].id}`, {
        step_status_id: 3,
      });
    }

    // create equivalent document and upload it
    let formData = new FormData();

    let paidInvoices = await axios.get(
      `/v1/remodelings/${hashid}/invoices?paid=true`
    );

    let equivalentDoc = await axios.post(
      `/v1/remodelings/${hashid}/document/equivalent`,
      {
        remodeling: purchase,
        paidInvoices: paidInvoices.data,
      }
    );

    // convert base64 to blob
    let blob = await fetch(
      `data:application/pdf;base64,${equivalentDoc.data}`
    ).then((res) => res.blob());

    formData.append(
      "file",
      blob,
      `Documento equivalente remodelación ${hashid}`
    );

    await axios.post(
      `/v1/remodelings/${hashid}/files/${fileTypes.remodelingLegalDoc}`,
      formData
    );

    handleSubmitRating();
    setIsLoading(false);
  };

  const handleSubmitRating = async () => {
    if (!purchase || !rating) return;

    // Persist rating in database
    await axios.patch(`/v1/remodelings/${hashid}`, rating);

    // Save rating locally
    purchase.designer_rating = rating.designer_rating;

    // Close rating modal
    setIsShowingRatingModal(false);
  };

  const handleShowStepHelpModal = (stepName: StepName) => {
    setSelectedStepHelpName(stepName);
    setIsShowingStepHelpModal(true);
  };

  return (
    <Fragment>
      <Navbar />
      <div className="order-container">
        <div className="order">
          <div className="main">
            <StepHelpModal
              isShowingStepHelpModal={isShowingStepHelpModal}
              closeStepHelpModal={() => setIsShowingStepHelpModal(false)}
              stepName={selectedStepHelpName}
            />
            <RemodelingRatingModal
              isVirtualConsultancy={false}
              isShowingRatingModal={isShowingRatingModal}
              closeRatingModal={() => {
                setIsShowingRatingModal(false);
                setIsRatingConfirmationChecked(false);
              }}
              handleFinishRemodeling={handleFinishRemodeling}
              rating={rating}
              setRating={(rating) => setRating(rating)}
              isRatingConfirmationChecked={isRatingConfirmationChecked}
              setIsRatingConfirmationChecked={(isRatingConfirmationChecked) =>
                setIsRatingConfirmationChecked(isRatingConfirmationChecked)
              }
              isLoading={isLoading}
            />
            <WompiResponseModal
              closeModal={() => setIsShowingWompiResponseModal(false)}
              showWompiResponseModal={isShowingWompiResponseModal}
              wompiPaymentSuccess={isSuccessfulPaymentWompi}
            />
            {purchase && <RemodelingHeaderInfo {...purchase} />}
            {purchase && (
              <div className="status-info">
                <RemodelingStatusTable
                  data={purchase}
                  updateData={() => getData()}
                  decodedToken={decodedToken}
                  handleShowStepHelpModal={handleShowStepHelpModal}
                  isSmallScreen={isSmallScreen}
                  // @ts-ignore
                  isDesigner={isDesigner}
                />
                {purchase.finished_at ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0.5rem",
                    }}
                  >
                    {purchase.designer_rating && (
                      <Rating value={purchase.designer_rating} readOnly />
                    )}
                    <span style={{ marginLeft: "0.5rem" }}>
                      Proyecto culminado el{" "}
                      {moment(purchase.finished_at)
                        .toDate()
                        .toLocaleDateString()}
                    </span>
                  </div>
                ) : (
                  <Fragment>
                    {isClient && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/*@ts-ignore*/}
                        <Button
                          disabled={enableRating}
                          style={{ margin: "0.5rem" }}
                          onClick={() => setIsShowingRatingModal(true)}
                          label="Recibo a satisfacción"
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            )}
            {purchase && (
              <RemodelingDocuments
                toggleShowLegalDocuments={() =>
                  setIsShowingLegalDocuments(!isShowingLegalDocuments)
                }
                isShowingLegalDocuments={isShowingLegalDocuments}
                legalDocuments={purchase.legal_documents}
                toggleShowTechnicalDocuments={() =>
                  setIsShowingTechnicalDocuments(!isShowingTechnicalDocuments)
                }
                isShowingTechnicalDocuments={isShowingTechnicalDocuments}
                technicalDocuments={purchase.technical_documents}
                isDesigner={isDesigner}
                isLSH={isLSH}
              />
            )}
            {isSmallScreen && (
              <CollapseList items={REMODELING_STATIC_INFO_CONTENT} />
            )}
          </div>
          {isBigScreen && (
            <div className="info">
              <RemodelingStaticInfo />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default RemodelingResume;
