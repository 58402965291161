import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Button from "@material-ui/core/Button";
import axios from "axios";
import moment from "moment";
import Rating from "@material-ui/lab/Rating";
import CircularProgress from "@material-ui/core/CircularProgress";

import { successToast } from "../../services/toasts";
import { SRLWrapper } from "simple-react-lightbox";
import DescriptionIcon from "@material-ui/icons/Description";
import RemodelingRatingModal from "../../pages/RemodelingResume/RemodelingResumeModals/RemodelingRatingModal";
import fileTypes from "../../services/fileTypes";

function VirtualConsultancyStatus({ hashid, role, purchase }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSharedFile, setIsLoadingSharedFile] = useState(false);
  const [virtualDesigns, setVirtualDesigns] = useState([]);
  const [sharedFiles, setSharedFiles] = useState([]);
  const [legalDocs, setLegalDocs] = useState([]);
  const [refreshDocs, setRefreshDocs] = useState(false);

  // Modals
  const [isShowingRatingModal, setIsShowingRatingModal] = useState(false);
  const [isRatingConfirmationChecked, setIsRatingConfirmationChecked] =
    useState(false);

  // Rating object and default values for when the modal shows up
  const [rating, setRating] = useState({
    designer_rating: 5,
    comments: "",
  });

  const elements = virtualDesigns.map((item) => ({ src: item.url }));

  const IMAGES = [];

  const handleDesignFileUpload = async (event) => {
    if (!event.target.files) return;

    setIsLoading(true);

    const designFile = new FormData();
    const file = event.target.files[0];
    designFile.append("file", file);

    const insert = await axios.post(
      `/v1/virtual-consultancy/${hashid}/files/${fileTypes.virtualConsultancyDesign}?whatsapp=true`,
      designFile
    );

    insert.status === 201 && successToast("Has subido un diseño exitosamente");

    await getDesigns();

    setIsLoading(false);
  };

  const handleSharedFileUpload = async (event) => {
    if (!event.target.files) return;

    setIsLoadingSharedFile(true);

    const sharedFile = new FormData();
    const file = event.target.files[0];
    sharedFile.append("file", file);

    const insert = await axios.post(
      `/v1/virtual-consultancy/${hashid}/files/${fileTypes.consultancySharedFile}?whatsapp=true`,
      sharedFile
    );

    insert.status === 201 && successToast("Has subido un archivo exitosamente");

    await getSharedFiles();

    setIsLoadingSharedFile(false);
  };

  const getDesigns = async () => {
    let response = await axios.get(`/v1/virtual-consultancy/${hashid}`);
    await setVirtualDesigns(response.data.body.info.designs);
  };

  const getSharedFiles = async () => {
    let response = await axios.get(
      `/v1/virtual-consultancy/shared-file/${hashid}`
    );
    await setSharedFiles(response.data.body.info);
  };

  const getlegalDocs = async () => {
    let response = await axios.get(
      `/v1/virtual-consultancy/legal-docs/${hashid}`
    );
    setLegalDocs(response.data.body.info);
  };

  const handleDesignApproval = async (designId) => {
    let response = await axios.patch(
      `/v1/virtual-consultancy/${designId}/approve-file`
    );

    if (response) {
      await getDesigns();
      successToast("“Has aprobado tu diseño exitosamente”");
    }
  };

  // Function when client submit the "Recibo a satisfacción" button
  const handleFinishRemodeling = async () => {
    if (!purchase) return;

    setIsLoading(true);

    const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    // set the finish_at date
    await axios.patch(`/v1/virtual-consultancy/${hashid}/finish?email=true`, {
      finishedAt: currentDate,
      client_uuid: purchase.client.uuid,
      designer_uuid: purchase.designer.uuid,
    });

    purchase.finished_at = currentDate;

    setIsShowingRatingModal(true);

    // create equivalent document and upload it
    let formData = new FormData();

    let equivalentDocData = {};

    // Get receipt data either of a peron or company
    let receipt = await axios.get(
      `/v1/virtual-consultancy/receipt/${purchase.payment.uuid}`
    );

    let receiptType = receipt.data.body.info.type;

    let virtualConsultancyData = {
      hashid: purchase.hashid,
      to_pay_designer: purchase.to_pay_designer,
    };

    let professionalData = {
      uuid: purchase.designer.uuid,
      first_name: purchase.designer.first_name,
      last_name: purchase.designer.last_name,
      email: purchase.designer.email,
      cedula: purchase.designer.cedula,
      address: purchase.designer.address,
    };

    // Create equivalentDocData either for person or company
    if (receiptType === "receiptPerson") {
      equivalentDocData = {
        professional: professionalData,
        client: {
          type: receiptType,
          first_name: receipt.data.body.info.firstName,
          last_name: receipt.data.body.info.lastName,
          email: receipt.data.body.info.email,
          cedula: receipt.data.body.info.documentNumber,
          phone: purchase.client.phone,
          address: receipt.data.body.info.address,
          city: receipt.data.body.info.city,
        },
        virtualConsultancyData,
      };
    } else {
      equivalentDocData = {
        professional: professionalData,
        client: {
          company_name: receipt.data.body.info.companyName,
          nit: receipt.data.body.info.nit,
          company_address: receipt.data.body.info.companyAddress,
        },
        virtualConsultancyData,
      };
    }

    let equivalentDoc = await axios.post(
      `/v1/virtual-consultancy/document/equivalent`,
      {
        equivalentDocData,
      }
    );

    // convert base64 to blob
    let blob = await fetch(
      `data:application/pdf;base64,${equivalentDoc.data.body.info.pdfBuffer}`
    ).then((res) => res.blob());

    formData.append(
      "file",
      blob,
      `Documento equivalente asesoría virtual ${hashid}`
    );

    // Save equivalent document as consultancy legal doc
    await axios.post(
      `/v1/virtual-consultancy/${hashid}/files/${fileTypes.consultancyLegalDoc}`,
      formData
    );

    // Send rating
    handleSubmitRating();
    setIsLoading(false);
  };

  const handleSubmitRating = async () => {
    if (!purchase || !rating) return;

    // Persist rating in database
    await axios.patch(
      `/v1/virtual-consultancy/${hashid}/submit-rating`,
      rating
    );

    // Save rating locally
    purchase.designer_rating = rating.designer_rating;

    // Update legal docs list
    setRefreshDocs(true);

    // Close rating modal
    setIsShowingRatingModal(false);
  };

  useEffect(() => {
    getDesigns();
    getSharedFiles();
    getlegalDocs();
    // eslint-disable-next-line
  }, []);

  // Refresh legal docs when client submit designer rating
  useEffect(() => {
    if (!refreshDocs) return;

    getlegalDocs();
    // eslint-disable-next-line
  }, [refreshDocs]);

  return (
    <Container>
      <RemodelingRatingModal
        isVirtualConsultancy={true}
        isShowingRatingModal={isShowingRatingModal}
        closeRatingModal={() => {
          setIsShowingRatingModal(false);
          setIsRatingConfirmationChecked(false);
        }}
        handleFinishRemodeling={handleFinishRemodeling}
        rating={rating}
        setRating={(rating) => setRating(rating)}
        isRatingConfirmationChecked={isRatingConfirmationChecked}
        setIsRatingConfirmationChecked={(isRatingConfirmationChecked) =>
          setIsRatingConfirmationChecked(isRatingConfirmationChecked)
        }
        isLoading={isLoading}
      />

      {/* designs */}
      {!isLoading ? (
        <Accordion style={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                role === 1 ? (
                  <CropOriginalIcon style={{ marginRight: "1rem" }} />
                ) : (
                  <Input
                    type="file"
                    id="design_files"
                    accept="image/*, application/pdf"
                    onChange={handleDesignFileUpload}
                  />
                )
              }
              label=" Mis Diseños"
            />
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {virtualDesigns &&
                virtualDesigns.map((design, index) => (
                  <>
                    <ContentDesign>
                      {/* TODO: Look for another better way to remove long code of filename */}
                      {/* Remove long code from filename */}
                      <ContentText>
                        <span>{design.filename.slice(14)}</span>
                      </ContentText>
                      {/* TODO: Make the container-buttons responsive */}
                      <ContentButtons>
                        {role === 1 &&
                          (design.is_approved === true ? (
                            <Button
                              style={{
                                backgroundColor: "#39b54a",
                                color: "white",
                                width: "5rem",
                                borderRadius: "0.5rem",
                                textTransform: "capitalize",
                              }}
                              disabled
                            >
                              Aprobado
                            </Button>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: "gold",
                                color: "black",
                                width: "5rem",
                                borderRadius: "0.5rem",
                                textTransform: "capitalize",
                              }}
                              size="small"
                              onClick={() => handleDesignApproval(design.id)}
                            >
                              Aprobar
                            </Button>
                          ))}

                        {role === 2 && design.is_approved === true && (
                          <Button
                            style={{
                              backgroundColor: "#39b54a",
                              color: "white",
                              width: "5rem",
                              borderRadius: "0.5rem",
                              textTransform: "capitalize",
                            }}
                            disabled
                          >
                            Aprobado
                          </Button>
                        )}
                        <ContentButtons>
                          <a
                            style={{ textDecoration: "none" }}
                            href={design.url}
                            target="__blank"
                          >
                            <Button
                              style={{
                                backgroundColor: "gold",
                                width: "2rem",
                                borderRadius: "0.5rem",
                                textTransform: "capitalize",
                              }}
                            >
                              Ver
                            </Button>
                          </a>
                        </ContentButtons>
                      </ContentButtons>
                    </ContentDesign>
                    <hr
                      style={{ margin: "1rem 0", border: "1px solid #cfcdcd" }}
                    />
                  </>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <CircularProgress style={{ fill: "green" }} />
      )}

      {/* client photos */}
      {!isLoadingSharedFile ? (
        <Accordion style={{ marginTop: "1rem", width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                role === 2 ? (
                  <CropOriginalIcon style={{ marginRight: "1rem" }} />
                ) : (
                  <Input
                    type="file"
                    id="design_files"
                    accept="image/*, application/pdf"
                    onChange={handleSharedFileUpload}
                  />
                )
              }
              label="Archivos compartidos"
            />
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {sharedFiles &&
                sharedFiles.map((sharedFile, index) => (
                  <>
                    <ContentDesign>
                      {/* TODO: Look for another better way to remove long code of filename */}
                      {/* Remove long code from filename */}
                      <ContentText>
                        <span>{sharedFile.filename.slice(14)}</span>
                      </ContentText>
                      {/* TODO: Make the container-buttons responsive */}
                      <ContentButtons>
                        <a
                          style={{ textDecoration: "none" }}
                          href={sharedFile.url}
                          target="__blank"
                        >
                          <Button
                            style={{
                              backgroundColor: "gold",
                              width: "2rem",
                              borderRadius: "0.5rem",
                              textTransform: "capitalize",
                            }}
                          >
                            Ver
                          </Button>
                        </a>
                      </ContentButtons>
                    </ContentDesign>
                    <hr
                      style={{ margin: "1rem 0", border: "1px solid #cfcdcd" }}
                    />
                  </>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <CircularProgress style={{ fill: "green" }} />
      )}

      {/* Files */}
      <Accordion style={{ margin: "1rem 0", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<DescriptionIcon style={{ marginRight: "1rem" }} />}
            label=" Mis Facturas"
          />
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {legalDocs &&
              legalDocs.map((doc, index) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <a
                      href={doc.url}
                      target="__blank"
                      rel="noopener"
                      className="button-see"
                    >
                      {/* TODO: Look for another better way to remove long code of filename */}
                      {/* Remove long code from filename */}
                      <span key={index}>{doc.filename.slice(14)}</span>
                    </a>
                  </div>
                  <hr
                    style={{ margin: "1rem 0", border: "1px solid #cfcdcd" }}
                  />
                </div>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>

      <SRLWrapper elements={elements} />

      {/* Recibo a satisfacción button and star rating with finished date */}
      {role === 1 || role === 5 ? (
        purchase.finished_at ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
            {purchase.professional_rating && (
              <Rating value={purchase.professional_rating} readOnly />
            )}
            <span style={{ marginLeft: "0.5rem" }}>
              Proyecto culminado el{" "}
              {moment(purchase.finished_at).toDate().toLocaleDateString()}
            </span>
          </div>
        ) : (
          <Button
            style={{
              backgroundColor: "gold",
              borderRadius: "0.2rem",
              textTransform: "capitalize",
            }}
            onClick={() => {
              setIsShowingRatingModal(true);
            }}
          >
            Recibo a satisfacción
          </Button>
        )
      ) : null}
    </Container>
  );
}

export default VirtualConsultancyStatus;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 3px;
  overflow: hidden;
  text-align: center;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &:before {
    content: "+";
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid #999;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    padding: 5px 8px;
    outline: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }

  &:hover::before {
    border-color: black;
  }
`;

const ContentDesign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const ContentText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;

const ContentButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;
