import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0;
  width: fit-content;
  background-color: ${(props) => props.bgColor};
  height: 40px;
  outline: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 1rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-position: center;
  transition: background 0.8s;

  &:hover {
    filter: brightness(103%);
    background-image: radial-gradient(
      circle,
      transparent 1%,
      ${(props) => props.bgColor} 1%
    );
    background-position: center;
    background-size: 15000%;
  }

  &:active {
    transition: background 0s;
    background-color: rgba(255, 255, 255, 0.1);
    background-size: 100%;
  }
`;
