import { useState } from "react";
import { Token } from "../pages/RemodelingResume/types";
import { createContext, useContext } from "react";

import axios from "axios";
import jwt from "jwt-decode";
import {
  categoriesAdmin,
  categoriesClient,
  categoriesDesigner,
  categoriesLSH,
  categoriesSupplier,
} from "../privatePages/layout/data/layoutCategories";

export type UserContext =
  | {
      authToken: string;
      setAuthToken: (token: string) => void;
      currentRoleId: number;
      isClient: boolean;
      isDesigner: boolean;
      isSupplier: boolean;
      isUserAccessGranted: boolean;
    }
  | undefined;

export const AuthContext = createContext<UserContext>(undefined);

// TODO: check if the changes made the page load slower
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const LOCAL_STORAGE_TOKEN = localStorage.getItem("token");
  const EXISTING_TOKEN = LOCAL_STORAGE_TOKEN && JSON.parse(LOCAL_STORAGE_TOKEN);

  const [authToken, setAuthToken] = useState(
    EXISTING_TOKEN ? EXISTING_TOKEN : ""
  );

  if (LOCAL_STORAGE_TOKEN) {
    axios.defaults.headers.common["Authorization"] = EXISTING_TOKEN;
  }

  const setToken = (token: string) => {
    localStorage.setItem("token", JSON.stringify(token));
    setAuthToken(token);
  };

  const decodedJwt: Token = authToken && jwt(authToken);

  const isClient = decodedJwt.role_id === roles.client;
  const isDesigner = decodedJwt.role_id === roles.designer;
  const isSupplier = decodedJwt.role_id === roles.supplier;
  const currentRoleId = decodedJwt.role_id;
  const isUserAccessGranted =
    isClient || isDesigner || !currentRoleId ? true : false;

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken: setToken,
        // TODO: refractor to currentUserRoleId
        currentRoleId,
        isClient,
        isDesigner,
        isSupplier,
        isUserAccessGranted,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export type Role = "client" | "designer" | "supplier" | "lsh" | "admin";

export const roles: {
  [key in Role]: number;
} = {
  client: 1,
  designer: 2,
  supplier: 3,
  lsh: 4,
  admin: 5,
};

export const AccessLevels = {
  // any allows any user that has an account
  any: [roles.client, roles.designer, roles.lsh, roles.admin, roles.supplier],
  client: [roles.client],
  designer: [roles.designer],
  supplier: [roles.supplier],
  admin: [roles.admin],
  lsh: [roles.lsh],
};

export const isLoggedIn = {
  loggedRol: JSON.parse(localStorage.getItem("user") || "{}").rol,
};

export const getCategoriesByRole = (roleId: number) => {
  switch (roleId) {
    case roles.designer:
      return categoriesDesigner;
    case roles.supplier:
      return categoriesSupplier;
    case roles.lsh:
      return categoriesLSH;
    case roles.admin:
      return categoriesAdmin;
    case roles.client:
      return categoriesClient;
    default:
      return [];
  }
};
