import { useEffect, useState } from "react";
import VirtualConsultancyHeaderInfo from "./virtualConsultancyHeaderInfo";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";

import jwt from "jwt-decode";
import { useAuth } from "../../services/auth";
import { roles } from "../../services/auth";

import styled from "styled-components";
import Chat from "../../components/Chat/Chat";
import axios from "axios";
import Modal from "../../components/modal";
import PopupVirtualConsultancy from "../../components/Popup/PopupVirtualConsultancy";

function VirtualConsultancyResume() {
  const { authToken } = useAuth();
  const { hashid } = useParams();
  //TODO: Look if Token applies
  const decodedToken = authToken && jwt(authToken);
  const isClient = decodedToken.role_id === roles.client;
  const isProfessional = decodedToken.role_id === roles.designer;
  const isAdmin = decodedToken.role_id === roles.admin;
  const [purchase, setPurchase] = useState(null);
  const [showChat, setShowChat] = useState(false);

  // Enable rating button for client when the installation step is completed
  // const enableRating = purchase && purchase.steps[5].step_status_id !== 2;

  useEffect(() => {
    getData();
  }, [hashid]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    let response = await axios.get(`/v1/virtual-consultancy/${hashid}`);
    await setPurchase(response.data.body.info);
  };

  const handleShowChat = () => {
    setShowChat((currShownChat) => !currShownChat);
  };

  // TODO: Change this logic with the real one
  let role_number = 0;
  if (isClient) {
    role_number = 1;
  }

  if (isProfessional) {
    role_number = 2;
  }

  if (isAdmin) {
    role_number = 5;
  }

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container>
      <Navbar />

      <Modal closeModal={handleClose} showModal={open} width={"50rem"}>
        <PopupVirtualConsultancy closeModal={handleClose} role={role_number} />
      </Modal>

      <Section>
        {purchase && (
          <VirtualConsultancyHeaderInfo
            role={role_number}
            purchase={purchase}
            ShownChat={showChat}
            handleShowChat={handleShowChat}
            hashid={hashid}
          />
        )}

        {purchase && (
          <Chat
            role={role_number}
            clientChatId={purchase.virtualChat.client_uuid}
            professionalChatId={purchase.virtualChat.professional_uuid}
            roomId={purchase.virtualChat.room_uuid}
            handleShowChat={handleShowChat}
            showChat={showChat}
            purchase={purchase}
          />
        )}
      </Section>
    </Container>
  );
}

export default VirtualConsultancyResume;

const Container = styled.div`
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  @media screen and (min-width: 961px) {
  }
`;
