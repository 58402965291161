import { Fragment } from "react";
import { CollapseItem } from "../../components/CollapseList/CollapseList";

export const REMODELING_STATIC_INFO_CONTENT: CollapseItem[] = [
  {
    title: "¿Qué debes saber de tu orden?",
    children: (
      <Fragment>
        <p>
          Fliping es la primera plataforma que permite adquirir productos para
          transformar espacios, con una asesoría profesional personalizada sin
          un costo.
        </p>
        <p>
          Cada producto y cada profesional cargado en nuestra plataforma ha
          pasado por un estricto proceso de selección, para garantizar que tu
          remodelación sea la mejor.
        </p>
      </Fragment>
    ),
  },
  {
    title: "Recuerda",
    children: (
      <Fragment>
        <p>
          Ésta es tu hoja de proceso, acá encuentras los 7 pasos para la
          ejecución de tu remodelación, el último paso es la calificación del
          profesional y de los instaladores, lo podrás visualizar cuantas veces
          quieras desde el enlace enviado a tu correo y whatsapp.
        </p>
        <p>
          El diseñador no podrá hacer ningún cobro en todo el proceso de tu
          remodelación, cualquier pago se deberá hacer mediante la plataforma.
        </p>
        <p>
          El diseñador no podrá ofrecer o vender ningún producto que no se
          encuentre en nuestra plataforma en el proceso solicitado.
        </p>
        <p>
          Podrás contratar los servicios profesionales de nuestros Arquitectos o
          Diseñadores para proyectos futuros o fuera de tu proceso de
          remodelación solicitado.
        </p>
      </Fragment>
    ),
  },
];

const SOCIAL_MEDIA_SOURCES = [
  {
    icon: "fa fa-instagram",
    link: "#",
  },
  {
    icon: "fa fa-facebook",
    link: "#",
  },
  {
    icon: "fa fa-twitter",
    link: "#",
  },
];

export default function PQRS() {
  return (
    <Fragment>
      {REMODELING_STATIC_INFO_CONTENT.map((item, index) => (
        <Fragment key={index}>
          <h1>{item.title}</h1>
          {item.children}
        </Fragment>
      ))}
      <div className="social-icons">
        {SOCIAL_MEDIA_SOURCES.map((item, index) => (
          <a key={index} href={item.link} target="_blank" rel="noreferrer">
            <i className={item.icon} aria-hidden="true"></i>
          </a>
        ))}
      </div>
    </Fragment>
  );
}
