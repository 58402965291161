import { useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

interface UseFetchOptions {
  method: AxiosRequestConfig["method"];
  url: string;
  body?: any;
  queryParams?: Record<string, any>;
  headers?: Record<string, string>;
}

const useFetch = <T>({
  method,
  url,
  body,
  queryParams,
  headers,
}: UseFetchOptions) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState<T | null>(null);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios({
          method: method,
          url: url,
          params: queryParams,
          data: body,
        });
        const data: T = await response?.data;

        setApiData(data);
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.message ||
          error?.response?.data ||
          error?.message;
        setApiError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, apiData, apiError };
};

export default useFetch;
