import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * Stars component that displays a rating from zero to five
 */
function Stars({ averageRating, ...props }) {
  const percent = (num) => {
    return (num / 5) * 100 + "%";
  };

  return (
    <Wrapper {...props}>
      <Upper ratingPercent={percent(averageRating)}>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span>★</span>
      </Upper>
      <Lower>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span>★</span>
      </Lower>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: inline-block;
  unicodebidi: bidi-override;
  color: ${(props) =>
    props.emptyStarColor ? props.emptyStarColor : "#f7f7f7"};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1.25rem")};
  width: auto;
  margin: 0;
  position: relative;
  padding: 0;
`;

const Upper = styled.div`
  color: #fdd400;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  overflow: hidden;
  width: ${(props) => props.ratingPercent};
`;

const Lower = styled.div`
  padding: 0;
  display: flex;
  z-index: 0;
`;

Stars.propTypes = {
  emptyStarColor: PropTypes.string,
};

Stars.defaultProps = {
  emptyStarColor: "#f7f7f7",
};

export default Stars;
