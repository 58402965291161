import { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useMeasure } from "react-use";
import { ListContainer, Title, ItemContainer } from "./styles";

export type CollapseItem = {
  title: string;
  children: JSX.Element;
};

type CollapseListProps = {
  items: CollapseItem[];
};

export function CollapseListItem({ title, children }: CollapseItem) {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, { height: viewHeight }] = useMeasure<HTMLDivElement>();

  const props = useSpring({
    height: isOpen ? viewHeight : 0,
    opacity: isOpen ? 1 : 0,
    y: isOpen ? 5 : 0
  });

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ItemContainer onClick={toggleOpen}>
      <Title>{isOpen ? "-" : "+"} {title}</Title>
      <animated.div
        style={{
          ...props,
          overflow: "hidden"
        }}
      >
        {isOpen && <div ref={ref}>{children}</div>}
      </animated.div>
    </ItemContainer>
  );
}

export default function CollapseList({ items }: CollapseListProps) {
  return (
    <ListContainer>
      {items?.map((item: CollapseItem, index) => (
        <CollapseListItem key={index} title={item.title}>
          {item.children}
        </CollapseListItem>
      ))}
    </ListContainer>
  );
}
