import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { designerFormSchema } from "../../services/formValidation";
import { yupResolver } from "@hookform/resolvers";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";

import SplitScreen from "../../components/SplitScreen/SplitScreen";
import PasswordInput from "../../components/PasswordInput/PasswordInput.js";
import Button from "../../components/Button/Button.js";
import Input from "../../components/Input/Input.js";
import Select from "../../components/Select/Select.js";

import styled from "styled-components";
import axios from "axios";
import teamImage from "../../assets/images/team.jpg";
import CreatableSelect from "react-select/creatable";

function DesignerFormStep1() {
  const history = useHistory();
  const data = useLocation();
  const formData = data.state ? data.state.formData : [];
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(designerFormSchema),
  });
  const [cities, setCities] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await axios.post("/v1/users/check", data);

      history.push({
        pathname: "/disenador/paso-2",
        state: { formData: data },
      });
    } catch (error) {
      setError("email", {
        type: "manual",
        message: error.response.data.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    axios.get("/v1/cities/").then((res) => setCities(res.data));
    axios
      .get("/v1/designers/professions")
      .then((res) => setProfessions(res.data));
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "21rem",
      marginLeft: "0.5rem",
      marginBottom: "0.5rem",
      outline: "none",
      fontSize: "0.8rem",
      fontWeight: "500",
      backgroundColor: "inherit",
      border: "none",
      borderBottom: "1.25px solid black",
      transition: "all 300ms ease 0s",
      color: "grey",
      "-ms-box-sizing": "content-box",
      "-moz-box-sizing": "content-box",
      "-webkit-box-sizing": "content-box",
      boxSizing: "content-box",
      fontfamily: "Open Sans",
    }),
    option: (provided) => ({
      ...provided,
      color: "grey",
      fontfamily: "Open Sans",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "grey",
      fontfamily: "Open Sans",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontfamily: "Open Sans",
    }),

    "&:focus": {
      borderBottom: "1.25px solid #fdd440",
      transition: "all 300ms ease 0s",
    },
  };

  return (
    <SplitScreen
      fullheight
      logoWithLink
      overlay
      image={teamImage}
      title="Haz parte de nuestro equipo"
    >
      {cities.length > 0 && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Input
              name="first_name"
              register={register}
              placeholder="Nombre"
              defaultValue={formData.first_name}
            />
            <Input
              name="last_name"
              register={register}
              placeholder="Apellido"
              defaultValue={formData.last_name}
            />
          </Row>
          <Row>
            <Input
              name="phone"
              register={register}
              placeholder="Celular"
              defaultValue={formData.phone}
            />
            <Input
              name="email"
              type="email"
              register={register}
              placeholder="Correo"
              defaultValue={formData.email}
            />
          </Row>
          <Row style={{ width: "23.1rem" }}>
            <Input
              name="cedula"
              register={register}
              placeholder="Cédula"
              defaultValue={formData.cedula}
            />
          </Row>
          <Row>
            <CreatableSelect
              options={
                cities &&
                cities.map((city) => {
                  return { value: city.id, label: city.name };
                })
              }
              placeholder="Ciudad"
              styles={customStyles}
              isClearable
              name="city_name"
              label="Ciudad"
              onChange={(e) =>
                e && register({ name: "city_name", value: e.label })
              }
              formatCreateLabel={(inputValue) =>
                `Crear nueva ciudad: ${inputValue}`
              }
            />
          </Row>
          <Row>
            <Input
              name="street_address"
              register={register}
              placeholder="Dirección"
              defaultValue={formData.street_address}
            />
            {professions && (
              <Select name="profession_id" register={register}>
                <option
                  hidden
                  disabled
                  selected={formData.profession_id ? false : true}
                >
                  Profesión
                </option>
                {professions.map((profession) => (
                  <option
                    value={profession.id}
                    key={profession.id}
                    selected={
                      formData.profession_id === profession.id ? true : false
                    }
                  >
                    {profession.name}
                  </option>
                ))}
              </Select>
            )}
          </Row>
          <Row>
            <PasswordInput
              name="password"
              register={register}
              placeholder="Contraseña"
              autoComplete="new-password"
              defaultValue={formData.password}
            />
            <PasswordInput
              name="passwordConfirm"
              register={register}
              placeholder="Repite tu contraseña"
              defaultValue={formData.passwordConfirm}
            />
          </Row>
          <Row>
            <TextArea
              name="bio"
              ref={register}
              placeholder="Escribe sobre ti para que tus clientes te conozcan mejor (ej: estilo de diseño, tu experiencia, materiales a usar)"
              rows="5"
              maxLength="500"
              defaultValue={formData.bio}
            />
          </Row>

          <p
            style={{
              fontSize: "13.3px",
              marginTop: "5px",
              color: "gray",
              margin: "1rem",
            }}
          >
            Por favor recuerda que solicitamos a nuestros diseñadores disponer
            de una laptop o tablet
          </p>
          <div>
            {Object.keys(errors)
              .slice(0, 2)
              .map((errorName) => {
                return <p className="error">{errors[errorName].message}</p>;
              })}
          </div>
          <div style={{ display: "flex" }}>
            <Button label="Continuar" type="submit" $isLoading={loading} />
          </div>
        </Form>
      )}
    </SplitScreen>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  margin: 1rem 0.5rem;
  padding: 0.25rem 1rem;
  background-color: inherit;
  border: none;
  border-bottom: 1.25px solid black;
  transition: all 300ms ease 0s;
  font-family: Roboto;

  &:focus {
    border-bottom: 1.25px solid #fdd440;
    transition: all 300ms ease 0s;
  }
`;

export default withRouter(DesignerFormStep1);
