import { useRef, useState } from "react";

import "./drop-file-input.css";

import uploadImg from "../../assets/images/cloud-upload-regular-240.png";
import pdfLogo from "../../assets/images/file-pdf-solid-240.png";
import InputValidationMessage from "../forms/InputValidationMessage/InputValidationMessage";

const DropFileInput = ({
  multipleEnabled = false,
  acceptedFiles,
  maxFiles,
  onChange,
  errors = {},
  name,
}) => {
  const [fileList, setFileList] = useState([]);
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    e.preventDefault();
    const newFiles = [...e.target.files];
    if (newFiles.length > 0) {
      const updatedList = [...fileList, ...newFiles];
      setFileList(updatedList);
      onChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onChange(updatedList.length === 0 ? null : updatedList);
  };

  return (
    <>
      <div className="box-drag-drop">
        <div
          ref={wrapperRef}
          className={`drop-file-input ${
            errors?.[name]?.message ? "drop-file-input-error" : ""
          }`}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="drop-file-input__label">
            <img src={uploadImg} alt="" />
            <p>Arrastra o selecciona tus archivos aquí</p>
          </div>

          <input
            type="file"
            onChange={onFileDrop}
            multiple={multipleEnabled}
            accept={acceptedFiles}
            disabled={fileList.length >= maxFiles}
          />
        </div>
        <InputValidationMessage message={errors?.[name]?.message} />
        {fileList?.length > 0 ? (
          <div className="drop-file-preview">
            <p className="drop-file-preview__title">Archivos cargados</p>
            {fileList.map((item, index) => (
              <div key={index} className="drop-file-preview__item">
                <img
                  src={
                    item.type === "application/pdf"
                      ? pdfLogo
                      : URL.createObjectURL(item)
                  }
                  alt={`Uploaded file ${item.name}`}
                />
                <div className="drop-file-preview__item__info">
                  <div className="item-name">{item.name}</div>
                  <p>{item.size} Bytes</p>
                </div>
                <span
                  className="drop-file-preview__item__del"
                  onClick={() => fileRemove(item)}
                >
                  <b>X</b>
                </span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DropFileInput;
