import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { supplierFormSchema } from "../../services/formValidation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import SplitScreen from "../../components/SplitScreen/SplitScreen";
import suppliersImage from "../../assets/images/suppliers.jpg";
import styled from "styled-components";
import Button from "../../components/Button/Button.js";
import Input from "../../components/Input/Input.js";
import PasswordInput from "../../components/PasswordInput/PasswordInput.js";
// import Select from "../../components/Select/Select.js";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import CreatableSelect from "react-select/creatable";

function SupplierFormStep1() {
  const history = useHistory();
  const data = useLocation();
  const formData = data.state ? data.state.formData : [];
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(supplierFormSchema),
  });
  const [cities, setCities] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await axios.post("/v1/users/check", {
        email: data.company_email,
        phone: data.company_phone,
      });
      history.push({
        pathname: "/proveedor/paso-2",
        state: { formData: data },
      });
    } catch (error) {
      setError("email", {
        type: "manual",
        message: error.response.data.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    axios.get("/v1/cities/").then((res) => setCities(res.data));
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "9.5rem",
      marginLeft: "0.5rem",
      marginBottom: "0.5rem",
      outline: "none",
      fontSize: "0.8rem",
      fontWeight: "500",
      backgroundColor: "inherit",
      border: "none",
      borderBottom: "1.25px solid black",
      transition: "all 300ms ease 0s",
      color: "grey",
      "-ms-box-sizing": "content-box",
      "-moz-box-sizing": "content-box",
      "-webkit-box-sizing": "content-box",
      boxSizing: "content-box",
      fontfamily: "Open Sans",
    }),
    option: (provided) => ({
      ...provided,
      color: "grey",
      fontfamily: "Open Sans",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "grey",
      fontfamily: "Open Sans",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontfamily: "Open Sans",
    }),

    "&:focus": {
      borderBottom: "1.25px solid #fdd440",
      transition: "all 300ms ease 0s",
    },
  };

  return (
    <SplitScreen
      fullheight
      logoWithLink
      overlay
      image={suppliersImage}
      title="Sé uno de nuestros proveedores"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4 style={{ margin: "0 0.5rem", color: "gray" }}>
          Información de la empresa
        </h4>
        <Row>
          <Input
            name="company_name"
            register={register}
            placeholder="Razón social"
            defaultValue={formData.company_name}
          />
          <Input
            name="company_phone"
            register={register}
            placeholder="Celular"
            defaultValue={formData.company_phone}
          />
        </Row>
        <Row>
          <Input
            name="company_email"
            type="email"
            register={register}
            placeholder="Correo"
            defaultValue={formData.company_email}
          />
          <Input
            maxLength={9}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            name="NIT"
            register={register}
            placeholder="N.I.T (primeros 9 dígitos)"
            defaultValue={formData.NIT}
          />
        </Row>
        <Row>
          <Input
            name="street_address"
            register={register}
            placeholder="Dirección de oficina/facturación"
            defaultValue={formData.street_address}
          />
          <CreatableSelect
            options={
              cities &&
              cities.map((city) => {
                return { value: city.id, label: city.name };
              })
            }
            placeholder="Ciudad"
            styles={customStyles}
            isClearable
            name="city_name"
            label="Ciudad"
            onChange={(e) =>
              e && register({ name: "city_name", value: e.label })
            }
            formatCreateLabel={(inputValue) =>
              `Crear nueva ciudad: ${inputValue}`
            }
          />
        </Row>
        <Row>
          <Input
            name="warehouse1_address"
            register={register}
            placeholder="Dirección despacho 1"
            defaultValue={formData.warehouse1_address}
          />
          <CreatableSelect
            options={
              cities &&
              cities.map((city) => {
                return { value: city.id, label: city.name };
              })
            }
            placeholder="Ciudad"
            styles={customStyles}
            isClearable
            name="city_name"
            label="Ciudad"
            onChange={(e) =>
              e && register({ name: "warehouse1_city_name", value: e.label })
            }
            formatCreateLabel={(inputValue) =>
              `Crear nueva ciudad: ${inputValue}`
            }
          />
        </Row>
        <Row>
          <Input
            name="warehouse2_address"
            register={register}
            placeholder="Dirección despacho 2 (opcional)"
            defaultValue={formData.warehouse2_address}
          />
          <CreatableSelect
            options={
              cities &&
              cities.map((city) => {
                return { value: city.id, label: city.name };
              })
            }
            placeholder="Ciudad"
            styles={customStyles}
            isClearable
            name="city_name"
            label="Ciudad"
            onChange={(e) =>
              e && register({ name: "warehouse2_city_name", value: e.label })
            }
            formatCreateLabel={(inputValue) =>
              `Crear nueva ciudad: ${inputValue}`
            }
          />
        </Row>
        <Row>
          <Input
            name="warehouse3_address"
            register={register}
            placeholder="Dirección despacho 3 (opcional)"
            defaultValue={formData.warehouse3_address}
          />
          <CreatableSelect
            options={
              cities &&
              cities.map((city) => {
                return { value: city.id, label: city.name };
              })
            }
            placeholder="Ciudad"
            styles={customStyles}
            isClearable
            name="city_name"
            label="Ciudad"
            onChange={(e) =>
              e && register({ name: "warehouse3_city_name", value: e.label })
            }
            formatCreateLabel={(inputValue) =>
              `Crear nueva ciudad: ${inputValue}`
            }
          />
        </Row>
        <Row>
          <PasswordInput
            name="password"
            register={register}
            placeholder="Contraseña"
            autoComplete="new-password"
            defaultValue={formData.password}
          />
          <PasswordInput
            name="passwordConfirm"
            register={register}
            placeholder="Repite tu contraseña"
            defaultValue={formData.passwordConfirm}
          />
        </Row>
        <Row>
          <TextArea
            name="company_description"
            ref={register}
            placeholder="Describe brevemente tu empresa y los productos (recomendamos agregar un link del catálogo de tus productos para conocerte mejor). También añade tu web y/o cuenta de instagram"
            rows="5"
            maxLength="500"
            defaultValue={formData.description}
          />
        </Row>
        <h4 style={{ margin: "0 0.5rem", color: "gray" }}>
          Representante legal
        </h4>
        <Row>
          <Input
            name="legal_rep_first_name"
            register={register}
            placeholder="Nombre"
            defaultValue={formData.legal_rep_first_name}
          />
          <Input
            name="legal_rep_last_name"
            register={register}
            placeholder="Apellido"
            defaultValue={formData.legal_rep_last_name}
          />
        </Row>
        <Row>
          <Input
            name="legal_rep_phone"
            register={register}
            placeholder="Celular"
            defaultValue={formData.legal_rep_phone}
          />
          <Input
            name="legal_rep_cedula"
            register={register}
            placeholder="Cédula"
            defaultValue={formData.legal_rep_cedula}
          />
        </Row>
        <div>
          {Object.keys(errors)
            .slice(0, 2)
            .map((errorName) => {
              return <p className="error">{errors[errorName].message}</p>;
            })}
        </div>
        <div style={{ display: "flex" }}>
          <Button label="Continuar" type="submit" $isLoading={loading} />
        </div>
      </Form>
    </SplitScreen>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 25rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  margin: 1rem 0.5rem;
  padding: 0.25rem 1rem;
  background-color: inherit;
  border: none;
  border-bottom: 1.25px solid black;
  transition: all 300ms ease 0s;
  font-family: Roboto;

  &:focus {
    border-bottom: 1.25px solid #fdd440;
    transition: all 300ms ease 0s;
  }
`;

export default withRouter(SupplierFormStep1);
