import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      fontSize: "1.25rem",
      fontFamily: "Open sans",
      cursor: "pointer",
    },
    checkbox: {
      color: "#000",
      "&$checked": {
        color: "#000",
      },
    },
    switchBase: {
      color: "#ffffff",
      "&$checked": {
        color: "#fdd400",
      },
      "&$checked + $track": {
        backgroundColor: "#fdd400",
      },
    },
    checked: {},
    track: {},
    icons: {
      color: "#232f3e",
    },
    root: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      width: "fit-content",
    },
    drawerPaper: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    buttonProgress: {
      color: "#232f3e",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    appBar: {
      zIndex: 9,
      background: "#ffffff",
      transition: theme.transitions.create(["widthA", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: "100%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 8,
    },
    hide: {
      display: "none",
    },
    small: {
      width: "32px",
      height: "32px",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      zIndex: 1,
    },
    drawerOpen: {
      zIndex: 1,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      zIndex: 1,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    navlink: {
      color: "#637381",
      textDecoration: "none",
    },
    navlinkActive: {
      background: "rgba(35, 47, 62, 0.08)",
      color: "#232f3e",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 2),
      ...theme.mixins.toolbar,
    },
    content: {
      minHeight: "100vh",
      position: "relative",
      flexGrow: 1,
      overflowX: "auto",
    },
    logoImage: {
      maxHeight: "100%",
      height: "100%",
      width: "auto",
      objectFit: "contain",
    },
    logoContainer: {
      height: "40px",
      width: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    darkBlue: {
      color: theme.palette.getContrastText("#232f3e"),
      backgroundColor: "#232f3e",
    },
    subMenuList: {
      paddingLeft: 12,
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 39,
        width: 2,
        backgroundColor: "#d4d4d4",
      },
    },
    subMenuLink: {
      padding: 0,
      paddingRight: "0 !important",
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        width: 18,
        height: 1,
        backgroundColor: "#d4d4d4",
      },
    },
  })
);
