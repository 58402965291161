import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Typography, makeStyles, withStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteConfirmationDialog from "../../../../components/DeleteConfirmationDialog/DeleteConfirmationDialog";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    paddingInline: "5px",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  menuItemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    textDecoration: "none",
    gap: "0.5rem",
    color: "inherit",
  },
  menuItemText: {
    marginLeft: theme.spacing(1),
  },
  deleteColor: {
    color: "rgb(255, 86, 48)",
  },
}));

type ItemActionsMenuProps = {
  itemId: number;
  editRoute: string;
  onDelete: (itemId: number) => void;
};

export default function ItemActionsMenu({
  itemId,
  editRoute,
  onDelete,
}: ItemActionsMenuProps) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const open = Boolean(anchorElement);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const onDeleteItem = () => {
    onDelete(itemId);
    setAnchorElement(null);
    setDeleteDialogOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAnchorElement(null);
  };

  return (
    <>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        title="Confirmar eliminación"
        contentText="¿Estás seguro de que deseas eliminar este ítem?"
        cancelText="No, Cancelar"
        confirmText="Sí, eliminar ítem"
        onConfirm={onDeleteItem}
      />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="fade-menu"
        anchorEl={anchorElement}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem>
          <Link to={editRoute} className={classes.menuItemContainer}>
            <EditIcon />
            <Typography variant="body2" className={classes.menuItemText}>
              Editar
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteDialog}>
          <DeleteIcon className={classes.deleteColor} />
          <Typography
            variant="body2"
            className={classes.menuItemText + " " + classes.deleteColor}
          >
            Eliminar
          </Typography>
        </MenuItem>
      </StyledMenu>
    </>
  );
}
