import { Column } from "material-table";

import ActionLink from "../../../../../../components/ActionLink/ActionLink";
import StatusChip from "../../../../../../components/StatusChip/StatusChip";
import CustomLink from "../../../../../../components/CustomLink/CustomLink";
import DocumentControls from "../../../../../../components/DocumentControls/DocumentControls";

import { type Purchase } from "../../types";
import CustomButton from "../../../../../../components/Button/CustomButton";

type PurchasesTableColumnsProps = {
  setDetailData: (value: any) => void;
  detailData: any;
};

const PurchasesTableColumns = ({
  setDetailData,
  detailData,
}: PurchasesTableColumnsProps) => {
  const tableColumns: Array<Column<Purchase>> = [
    {
      title: "Nro. de compra",
      type: "numeric",
      align: "left",
      render: (rowData) => (
        <CustomLink to={`/proveedor/ordenes/${rowData.id}`}>
          {rowData.orderNumber}
        </CustomLink>
      ),
    },
    {
      title: "Fecha",
      field: "purchaseDate",
      type: "date",
    },
    {
      title: "Datos de facturación",
      render: (rowData) => (
        <div>
          <CustomButton
            shape="little-rounded"
            color="outlined"
            size="small"
            className="btn btn-primary"
            onClick={() => setDetailData(rowData.billingInformation)}
          >
            Ver detalle
          </CustomButton>
        </div>
      ),
    },
    {
      title: "Factura para Fliping",
      render: (rowData) => (
        <DocumentControls
          fileData={rowData.supplierFiles}
          orderId={rowData.id}
          userType="supplier"
          fileName="invoice"
        />
      ),
    },
    {
      title: "Soporte de pago",
      field: "trackUrl",
      render: (rowData) => {
        if (rowData.adminFiles.length === 0) {
          return <p>Aún no hay soporte</p>;
        } else {
          return (
            <ActionLink href={rowData.adminFiles[0].url} text="Ver soporte" />
          );
        }
      },
    },
    {
      title: "Pagado",
      render: (rowData) => (
        <>
          {rowData.is_paid ? (
            <StatusChip nameStatus="pagado" color="green" />
          ) : (
            <StatusChip nameStatus="pendiente" color="yellow" />
          )}
        </>
      ),
    },
  ];

  return tableColumns;
};
export default PurchasesTableColumns;
