import ClientRegisterCards from "../../components/ClientRegisterCard/ClientRegisterCards";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar";
import "./ConsultancySections/ConsultancySections.css";

function HomeRegister() {
  return (
    <>
      <Navbar />
      <section>
        <div className="register__background">
          <div className="container__form">
            <ClientRegisterCards />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default HomeRegister;
