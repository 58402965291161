import { FieldErrors } from "react-hook-form";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import styles from "./InputForm.module.scss";
import { FocusEvent, useState } from "react";
import React, { forwardRef, Ref } from "react";

interface InputFormProps {
  id: string;
  name: string;
  label: string;
  type?: string;
  disabled?: boolean;
  register: any;
  placeholder?: string;
  errors: FieldErrors;
  icon?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  ref?: Ref<HTMLInputElement>;
}

const InputForm = forwardRef(
  (
    {
      id,
      icon: Icon,
      type = "text",
      disabled,
      register,
      placeholder,
      errors,
      label,
      name,
      onChange,
      onFocus,
      onBlur,
    }: InputFormProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const [readonly, setReadonly] = useState(true);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      setReadonly(false);
      onFocus && onFocus(event);
    };

    return (
      <>
        <div className={`${styles.inputContainer} `}>
          <div className={styles.inputContent}>
            {Icon && <Icon className={styles.inputIcon} />}
            <input
              readOnly={readonly}
              onFocus={handleFocus}
              className={`${errors[name] ? styles.error : ""} ${styles.input}`}
              id={id}
              placeholder={placeholder}
              type={
                type === "password"
                  ? showPassword
                    ? "text"
                    : "password"
                  : type
              }
              name={name}
              ref={register}
              onChange={onChange}
              onBlur={onBlur}
            />
            {type === "password" && (
              <div
                className={styles.passwordIcon}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </div>
            )}
          </div>
          {errors[name] && (
            <div className={styles.errorContent}>
              <ErrorOutlineIcon />
              <span className={styles.errorMessage}>
                {errors[name].message}
              </span>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default InputForm;
