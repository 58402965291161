import { useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const CardConsultancy = ({ hashid, designer, spaces, isPaid, isApproved }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickCard = () => {
    if (isPaid) {
      history.push(`/cliente/compra/asesoria-virtual/${hashid}`);
    } else {
      return;
    }
  };

  return (
    <Card isApproved={isApproved} onClick={isApproved ? handleClickCard : null}>
      <CardData>
        <Modal
          closeModal={handleClose}
          showModal={open}
          contentMaxWidth="1000px"
          contentMaxHeight="500px"
        >
          <ContentModal>
            <h2>
              Total a pagar: <span>{`COP $${spaces * 749000}`}</span>
            </h2>
          </ContentModal>
        </Modal>
        <Data>
          <span>Código</span>
          <span>{hashid}</span>
        </Data>

        <Data>
          <span>Diseñador</span>
          <span>{designer}</span>
        </Data>
        <Data>
          <span>Espacios</span>
          <span>{`${spaces}`}</span>
        </Data>
        {isPaid && (
          <Data>
            <span>Estado</span>
            <IconCircle>
              <StatePoint isApproved={isApproved}></StatePoint>
              <span>{isApproved ? "Activo" : "En espera"}</span>
            </IconCircle>
          </Data>
        )}
        <Data>
          <span>Total a pagar:</span>
          <span>${parseInt(spaces * 749000).toLocaleString("es-CO")}</span>
        </Data>
      </CardData>
      {!isPaid && (
        <Link
          style={{ textDecoration: "none" }}
          to={{
            pathname: `/cliente/compra/asesoria-virtual/checkout/${hashid}`,
          }}
        >
          <Button
            style={{ background: "#F4D03F", color: "#fff", margin: "0 auto" }}
            label={"Pagar"}
          />
        </Link>
      )}
    </Card>
  );
};

export default CardConsultancy;

const Card = styled.div`
  width: 100%;
  border: 1.5px solid rgb(229, 231, 233);
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: 0.5s;
  ${(props) =>
    props.isApproved
      ? "&:hover { cursor: pointer; box-shadow:5px 5px 10px #afafaf,-5px -5px 10px #ffffff; transform: scale(1.01) ;}"
      : ""}
`;

const CardData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1.5rem;
  }
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & span:nth-child(1) {
    font-weight: bold;
    font-size: 0.9rem;
  }
  & span:nth-child(2) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 321px) {
    & span:nth-child(1) {
      font-weight: bold;
      font-size: 1rem;
    }
    & span:nth-child(2) {
      font-size: 0.9rem;
    }
  }

  @media screen and (min-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentModal = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > h2 span {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.55);
    font-weight: 400;
  }
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const StatePoint = styled.div`
  text-align: center;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 100%;
  background-color: ${(props) => (props.isApproved ? "#00ff00" : " #fdd440")};
`;
