import styles from "./checkboxInput.module.scss";

interface CheckboxInputProps {
  handleTermsAndCond: () => void;
}
const CheckboxInput = ({ handleTermsAndCond }: CheckboxInputProps) => {
  return (
    <div className={styles.checkboxContainer}>
      <input
        type="checkbox"
        id="termsCheckbox"
        className={styles.checkbox}
        onChange={handleTermsAndCond}
      />
      <label htmlFor="termsCheckbox" className={styles.checkboxLabel}>
        Acepto los{" "}
        <a
          href="https://r1d-assets.s3.us-east-2.amazonaws.com/TerminosYCondicionesClientes.pdf"
          target="__blank"
          className={styles.termsLink}
        >
          Términos y Condiciones
        </a>
      </label>
    </div>
  );
};
export default CheckboxInput;
