import CreatableSelect from "react-select/creatable";

interface OptionType {
  label: string;
  value: string;
}

interface CustomCreatableSelectProps {
  options: OptionType[];
  placeholder: string;
  errors: { brand?: string };
  id: string;
  onChange: (value: string | null) => void;
}

const CustomCreatableSelect = ({
  options,
  placeholder,
  errors,
  id,
  onChange,
}: CustomCreatableSelectProps) => {
  return (
    <CreatableSelect
      isClearable
      options={options.map((item: any) => ({
        value: item.name,
        label: item.name,
      }))}
      placeholder={placeholder}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: "100%",
          height: "3.5rem",
          borderColor: errors.brand ? "red" : baseStyles.borderColor,
          ":hover": {
            borderColor: errors.brand ? "red" : "#000000",
          },
        }),
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            fontSize: "16px",
            color: errors.brand ? "#e74c3c" : defaultStyles.color,
          };
        },
        menu: (base) => ({
          ...base,
          zIndex: 10000,
        }),
      }}
      id={id}
      onChange={(e) => {
        onChange(e ? e.value : null);
      }}
    />
  );
};
export default CustomCreatableSelect;
