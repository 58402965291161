import { Slide, toast } from "react-toastify";

export const successToast = (text: string) =>
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
    theme: "light",
  });

export const errorToast = (text: string) =>
  toast.error(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
    theme: "light",
  });

export const warningToast = (text: string) =>
  toast.warn(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
    theme: "light",
  });

export const infoToast = (text: string) =>
  toast.info(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
    theme: "light",
  });
