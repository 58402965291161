import React, { useState, useEffect, Fragment } from "react";
import { materialTableES } from "../../services/materialTableES";

import Modal from "../../components/Modal/Modal";
import ProductModal from "../../components/ProductModal/ProductModal";
import MaterialTable from "material-table";

import axios from "axios";

/**
 * LSH employees are able to set a product
 * installation price on this component
 */
function ProductsManagement() {
  const [products, setProducts] = useState();
  const [productModal, setProductModal] = useState(false);
  const [productDetail, setProductDetail] = useState();

  const handleClose = (event) => {
    setProductModal(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  // Get the products that are pending to set an installation price
  const getProducts = async () => {
    let response = await axios.get(
      `/v1/products?type=decorativo&type=revestimiento&installationPrice=false`
    );
    setProducts(response.data);
  };

  return (
    <Fragment>
      <Modal
        showModal={productModal}
        closeModal={handleClose}
        contentMaxWidth="1000px"
        contentMaxHeight="500px"
      >
        <ProductModal product={productDetail} />
      </Modal>
      <MaterialTable
        title="Productos con precio de instalación pendientes"
        columns={[
          {
            width: "0px",
            field: "image",
            render: (rowData) => (
              <img
                alt="producto"
                src={rowData.image}
                style={{
                  width: 40,
                  height: 40,
                  objectFit: "cover",
                  borderRadius: "100%",
                }}
              />
            ),
            editable: "never",
          },
          { title: "Proveedor", field: "company_name" },
          {
            title: "Marca",
            render: (rowData) => rowData.brand.name,
            customFilterAndSearch: (term, rowData) =>
              (
                rowData.brand.name +
                rowData.description +
                rowData.comission_percentage / 2
              )
                .toLowerCase()
                .indexOf(term.toLowerCase()) !== -1,
            editable: "never",
          },
          { title: "Referencia", field: "name", editable: "never" },
          {
            title: "Unidad de Venta",
            field: "presentation",
            editable: "never",
          },
          {
            title: "UNV de Empaque",
            field: "min_sale_unit",
            type: "numeric",
            editable: "never",
          },
          {
            title: "Precio",
            field: "price",
            type: "currency",
            editable: "never",
            currencySetting: {
              currencyCode: "COP",
              locale: "col",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          },
          {
            title: "Utilidad",
            field: "comission_percentage",
            type: "numeric",
            editable: "never",
          },
          {
            title: "Unidades en Inventario",
            field: "units_available",
            type: "numeric",
            editable: "never",
          },
        ]}
        data={products}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            await axios
              .patch(`/v1/products/${oldData.id}`, {
                installation_price: newData.installation_price,
              })
              .then((res) => {
                const dataUpdate = [...products];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setProducts([...dataUpdate]);
              })
              .catch((e) => {
                throw e;
              });
          },
        }}
        onRowClick={(evt, selectedRow) => {
          setProductModal(true);
          setProductDetail(selectedRow);
        }}
        options={{
          padding: "dense",
          actionsColumnIndex: -1,
          clickable: true,
        }}
        localization={materialTableES}
        isLoading={products ? false : true}
      />
    </Fragment>
  );
}

export default ProductsManagement;
