import React, { useState } from "react";
import styled from "styled-components";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

/**
 */
function PasswordInput({ placeholder, register, ...props }) {
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);
  // let focus = true;

  return (
    <>
      <StyledLabel>
        <StyledPasswordInput
          type={show ? "text" : "password"}
          placeholder={placeholder}
          placeholderTextColor="grey"
          ref={register}
          onFocus={() => setFocus(true)}
          //onBlur={() => setFocus(false)}
          {...props}
        />
        {focus ? (
          show ? (
            <VisibilityOffIcon onClick={() => setShow(false)} />
          ) : (
            <VisibilityIcon onClick={() => setShow(true)} />
          )
        ) : (
          ""
        )}
      </StyledLabel>
    </>
  );
}

const StyledLabel = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
`;

const StyledPasswordInput = styled.input`
  width: 90%;
  outline: none;
  background: none;
  border: none;
  color: black;
  padding: 0.5rem 0.8rem;
`;

PasswordInput.defaultProps = {};

export default PasswordInput;
