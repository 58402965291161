import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import { amber, green, red } from "@material-ui/core/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import { materialTableES } from "../../services/materialTableES.js";
import { useStyles } from "../../services/muiStyles";
import { errorToast, successToast } from "../../services/toasts";

function isApprovedIcons(field) {
  switch (field) {
    case true:
      return (
        <CheckCircleIcon style={{ color: green[500] }} titleAccess="Aprobado" />
      );
    case false:
      return <CancelIcon style={{ color: red[500] }} titleAccess="Declinado" />;
    default:
      return (
        <HourglassEmptyIcon
          style={{ color: amber[500] }}
          titleAccess="En proceso..."
        />
      );
  }
}

function SupplierManagement() {
  const classes = useStyles();
  const [approvalScreen, setApprovalScreen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [detailData, setDetailData] = useState();
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await axios.get("/v1/suppliers");
    setSuppliers(res.data);
    setFilteredSuppliers(res.data);
  };

  const toggleIsPublic = async (rowData) => {
    let isPublic = rowData.is_public !== true ? false : true;

    let copyDesigners = [...suppliers];
    let index = copyDesigners.indexOf(rowData);
    copyDesigners[index].is_public = !isPublic;
    setSuppliers(copyDesigners);

    await axios.patch(`/v1/users/${rowData.uuid}`, {
      is_public: !isPublic,
    });

    await axios.patch(`/v1/products/public_status/${rowData.uuid}`, {
      is_public: !isPublic,
    });
  };

  const handleApproval = async (rowData, uuid, isApproved) => {
    try {
      let copyDesigners = [...suppliers];
      let index = copyDesigners.indexOf(rowData);
      copyDesigners[index].is_approved = isApproved;
      setSuppliers(copyDesigners);

      await axios.patch(`/v1/users/${uuid}?emailSupplier=true`, {
        is_approved: isApproved,
      });

      successToast(
        `Has ${isApproved ? "aprobado" : "rechazado"} al proveedor ${
          rowData.company_name
        } exitosamente`
      );
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  // Functions for filter buttons
  const setAllSuppliers = () => {
    setFilteredSuppliers(suppliers);
  };

  let getPendingApprovalSuppliers = suppliers.filter(
    (supplier) => supplier.is_approved === null
  );

  let getApprovedSuppliers = suppliers.filter(
    (supplier) => supplier.is_approved === true
  );

  const setApprovedSuppliers = () => {
    setFilteredSuppliers(getApprovedSuppliers);
  };

  let getRejectedSuppliers = suppliers.filter(
    (supplier) => supplier.is_approved === false
  );

  const setRejectedSuppliers = () => {
    setFilteredSuppliers(getRejectedSuppliers);
  };

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Chip
            style={{
              color: "#112A36",
              backgroundColor: "#E2CA8A",
              marginRight: 10,
            }}
            label="Todos"
            clickable={true}
            onClick={() => {
              setApprovalScreen(false);
              setAllSuppliers();
            }}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#5D777B",
              marginRight: 10,
            }}
            label="Por aprobar"
            avatar={
              <Avatar
                style={{
                  color: "white",
                  border: "1px solid white",
                  backgroundColor: "#5D777B",
                }}
              >
                {getPendingApprovalSuppliers.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(true);
            }}
          />
          <Chip
            style={{
              color: "#112A36",
              backgroundColor: "#E2CA8A",
              marginRight: 10,
            }}
            label="Aprobados"
            avatar={
              <Avatar
                style={{
                  color: "#112A36",
                  border: "1px solid white",
                  backgroundColor: "#E2CA8A",
                }}
              >
                {getApprovedSuppliers.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(false);
              setApprovedSuppliers();
            }}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#AA8684",
              marginRight: 10,
            }}
            label="Rechazados"
            avatar={
              <Avatar
                style={{
                  color: "white",
                  border: "1px solid white",
                  backgroundColor: "#AA8684",
                }}
              >
                {getRejectedSuppliers.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(false);
              setRejectedSuppliers();
            }}
          />
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      field: "avatar",
      render: (rowData) => (
        <Avatar className={classes.darkBlue}>{rowData.company_name[0]}</Avatar>
      ),
      export: false,
    },
    { title: "Nombre", field: "company_name" },
    { title: "Teléfono", field: "phone" },
    { title: "Correo", field: "email" },
    { title: "Ciudad", field: "city" },
    {
      title: "Fecha de solicitud",
      field: "created_at",
      type: "date",
      hidden: approvalScreen ? false : true,
    },
    {
      title: "Solicitud",
      field: "is_approved",
      editable: "never",
      render: function (rowData, i) {
        if (!approvalScreen) {
          return isApprovedIcons(rowData.is_approved);
        } else if (approvalScreen === true && rowData.is_approved === null) {
          return (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                label="Aprobar"
                onClick={() => {
                  handleApproval(rowData, rowData.uuid, true);
                }}
              />
              <Button
                size="small"
                label="Rechazar"
                onClick={() => {
                  handleApproval(rowData, rowData.uuid, false);
                }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Estado",
      field: "is_public",
      width: "0px",
      editable: "never",
      render: function (rowData) {
        let isPublic = rowData.is_public === true ? true : false;
        return (
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={rowData.is_public}
            onChange={(event) => {
              return toggleIsPublic(rowData);
            }}
            name={rowData.uuid}
          />
        );
      },
    },
  ];

  return (
    <section className="layout-dashboard-container">
      <div>
        <Modal
          showModal={detailData ? true : false}
          closeModal={() => setDetailData()}
        >
          <SupplierDetail data={detailData} />
        </Modal>
        <MaterialTable
          title={approvalScreen ? "Solicitudes pendientes" : "Proveedores"}
          columns={tableColumns}
          data={
            approvalScreen ? getPendingApprovalSuppliers : filteredSuppliers
          }
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <TableHeader />
              </div>
            ),
          }}
          actions={[
            {
              icon: () => <ZoomInIcon />,
              tooltip: "Detalle",
              onClick: (event, rowData) => {
                setDetailData(rowData);
              },
            },
          ]}
          localization={materialTableES}
          isLoading={suppliers.length > 0 ? false : true}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            actionsCellStyle: {
              paddingRight: 20,
            },
            exportButton: true,
            pageSizeOptions: [5, 10, 50, 100, 300, 500],
          }}
        />
      </div>
    </section>
  );
}

const SupplierDetail = ({ data }) => {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información básica</h3>
      <span>
        <i>Empresa:</i> {data.company_name}
      </span>
      <span>
        <i>NIT:</i> {data.NIT}
      </span>
      <span>
        <i>Información:</i> {data.company_description}
      </span>

      <h3 style={{ marginTop: 10 }}>Información para E-Commerce</h3>
      <span style={{ display: "flex", gap: "1rem" }}>
        <i>Color primario:</i>
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: data.company_primary_color,
            border: "1px solid black",
            borderRadius: 100,
          }}
        ></div>
      </span>
      <span style={{ display: "flex", gap: "1rem" }}>
        <i>Color secundario:</i>
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: data.company_secondary_color,
            border: "1px solid black",
            borderRadius: 100,
          }}
        ></div>
      </span>
      <span>
        <i>Tipo de fuente:</i> {data.company_font_type}
      </span>
      {data.logo[0] && (
        <a href={data.logo[0].url} target="__blank">
          Logo
        </a>
      )}
      {data.banner[0] && (
        <a
          href={data.banner[0].url}
          target="__blank"
          style={{ marginBottom: 10 }}
        >
          Banner
        </a>
      )}

      <h3 style={{ marginTop: 10 }}>Representante legal</h3>
      <span>
        <i>Nombre:</i>{" "}
        {`${data.legal_rep_first_name} ${data.legal_rep_last_name}`}
      </span>
      <span>
        <i>Celular:</i> {data.legal_rep_phone}
      </span>

      <h3 style={{ marginTop: 10 }}>Contacto principal</h3>
      <span>
        <i>Nombre:</i> {data.contact_first_name} {data.contact_last_name}
      </span>
      <span>
        <i>Correo:</i> {data.contact_email}
      </span>
      <span>
        <i>Celular:</i> {data.contact_phone}
      </span>

      <h3 style={{ marginTop: 10 }}>Cuenta bancaria</h3>
      {data.bank && data.bank ? (
        <div>
          <span>
            <i>Banco:</i> {data.bank.bank_name} <br />
            <i>Titular de la cuenta:</i> {data.bank.account_holder_name} <br />
            {data.bank.is_savings_account ? (
              <i>Cuenta de ahorros</i>
            ) : (
              <i>Cuenta corriente</i>
            )}
            <br />
            <i>Número de la cuenta:</i> {data.bank.account_number} <br />
          </span>
        </div>
      ) : (
        <span>
          <i>Este proveedor aún no ha actualizado sus datos bancarios</i>
        </span>
      )}

      <h3 style={{ marginTop: 10 }}>Documentos</h3>
      {data.RUT[0] && (
        <a href={data.RUT[0].url} target="__blank">
          RUT
        </a>
      )}
      {data.chamber_of_commerce && (
        <a href={data.chamber_of_commerce} target="__blank">
          Cámara de comercio
        </a>
      )}
    </div>
  );
};

export default SupplierManagement;
