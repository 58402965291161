import { CircularProgress } from "@material-ui/core";

import styles from "./FullScreenLoading.module.scss";

const FullScreenLoading = () => {
  return (
    <div className={styles.fullScreenLoading}>
      <CircularProgress size={60} />
    </div>
  );
};

export default FullScreenLoading;
