import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../components/Button/Button";
import { successToast } from "../../services/toasts";

const DesignerVirtualConsultancyCard = ({
  date,
  hashid,
  virtualConsultancyId,
  client,
  designer,
  to_pay,
  openCard,
  Approved = false,
}) => {
  const history = useHistory();
  const [chatData, setChatData] = useState({
    clientId: client.uuid,
    clientNames: `${client.first_name} ${client.last_name}`,
    designerNames: `${designer.first_name} ${designer.last_name}`,
  });

  const handleClickCard = () => {
    history.push(`/disenador/compra/asesoria-virtual/${hashid}`);
  };

  const handleApproval = async () => {
    try {
      await handleChatConfiguration();
      // Approve a virtual consultancy

      await axios.patch(`/v1/virtual-consultancy/${hashid}?whatsapp=true`, {
        is_approved: true,
      });

      successToast(
        `Has aprobado a tu cliente ${client.first_name} ${client.last_name} exitosamente`
      );
      setInterval(() => window.location.reload(), 400);
    } catch (error) {
      throw error;
    }
  };

  const handleChatConfiguration = async () => {
    try {
      // Configure chat users
      let consultancyUsers = await axios.post(
        "/v1/virtual-consultancy/chat-users",
        chatData
      );

      let users = [];

      users.push(consultancyUsers.data.body.info.clientId);
      users.push(consultancyUsers.data.body.info.professionalId);

      // Create a new unique chat
      let newChat = await axios.post(
        "/v1/virtual-consultancy/new-virtual-chat",
        {
          virtualConsultancyId,
          users,
        }
      );

      return newChat;
    } catch (error) {
      throw error;
    }
  };
  return (
    <Card isOpenCard={openCard} onClick={openCard ? handleClickCard : null}>
      <CardData>
        <Data>
          <span>Fecha</span>
          <span>{date}</span>
        </Data>

        <Data>
          <span>Hoja de proceso</span>
          <span>{hashid}</span>
        </Data>
        <Data>
          <span>Pago</span>
          <span>${parseInt(to_pay).toLocaleString("es-CO")} </span>
        </Data>
        <Data>
          <span>Cliente</span>
          <span>{`${client.first_name}  ${client.last_name}`}</span>
        </Data>
        {Approved && (
          <Data>
            <div>
              <Button size="small" label="Aprobar" onClick={handleApproval} />
            </div>
          </Data>
        )}
      </CardData>
    </Card>
  );
};

export default DesignerVirtualConsultancyCard;

const Card = styled.div`
  width: 100%;
  border: 1.5px solid rgb(229, 231, 233);
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: all 0.5s;
  cursor: ${(props) => (props.isOpenCard ? "pointer" : "normal")};
  &:hover {
    box-shadow: ${(props) =>
      props.isOpenCard ? "3px 3px 6px rgba(0, 0, 0, 0.1)" : "none"};
    transform: ${(props) => (props.isOpenCard ? " scale(1.01)" : "none")};
  }
`;

const CardData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: 769px) {
    flex-direction: row-reverse;
    justify-content: space-around;
    margin-bottom: 1.5rem;
  }
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & span:nth-child(1) {
    font-weight: bold;
    font-size: 0.9rem;
  }
  & span:nth-child(2) {
    font-size: 0.8rem;
  }

  & button {
    font-weight: 1px;
    color: #fff;
  }

  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }

  @media screen and (min-width: 321px) {
    & span:nth-child(1) {
      font-weight: bold;
      font-size: 1rem;
    }
    & span:nth-child(2) {
      font-size: 0.9rem;
    }
  }

  @media screen and (min-width: 769px) {
    flex-direction: column;
    align-items: center;

    & div {
      margin: 0;
    }
  }
`;
