import { yupResolver } from "@hookform/resolvers";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  designerConfigSchema,
  userConfigBankInfoSchema,
  validateFile,
} from "../../services/formValidation";

import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import Button from "../../components/Button/Button";
import Stars from "../../components/Stars/Stars";

import axios from "axios";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import fileTypes from "../../services/fileTypes";
import { errorToast, successToast } from "../../services/toasts";
import { handleError } from "../../utils/utils";

/**
 * Has all the configuration options for a designer
 */
function DesignerConfig() {
  const [cities, setCities] = useState();
  const [designer, setDesigner] = useState();
  const [isLoading, setIsLoading] = useState({
    bankButton: false,
    avatarButton: false,
    profileButton: false,
    portfolioButton: false,
  });

  const userTokens = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getDesigner();
    getCities();
  }, []);

  const getDesigner = async () => {
    let { data } = await axios.get(`/v1/designers/profile`);
    setDesigner(data);
  };

  const getCities = async () => {
    let { data } = await axios.get(`/v1/cities`);
    setCities(data);
  };

  const handleChangeAvatar = async (event) => {
    try {
      setIsLoading({ ...isLoading, avatarButton: true });

      let avatar = event.target.files[0];
      let requestFormData = new FormData();

      // validate that's an image
      validateFile(avatar, /jpeg|png|jpg/);

      requestFormData.append("avatar", avatar);

      let { data } = await axios.patch("/v1/designers/avatar", requestFormData);

      // update localStorage
      localStorage.setItem(
        "user",
        JSON.stringify({ ...userTokens, avatar: data.url })
      );

      // we could avoid having to reload the page by having all the localStorage
      // object on state, but for the time being I'll leave it like this
      window.location.reload();

      successToast("Has actualizado tu avatar exitosamente");
      setIsLoading({ ...isLoading, avatarButton: false });
    } catch (error) {
      errorToast(error.message);
      handleError(error);
    }
  };

  const handleAddPortfolioImage = async (event) => {
    try {
      if (designer.portfolio.length >= 4) {
        throw new Error(
          "Has alcanzado el límite de imágenes, puedes eliminar alguna para subir más"
        );
      }
      setIsLoading({ ...isLoading, portfolioButton: true });

      let file = event.target.files[0];
      let requestFormData = new FormData();

      // validate that's an image
      validateFile(file, /jpeg|png|jpg/);

      requestFormData.append("file", file);

      let { data } = await axios.post(
        `/v1/users/files/${fileTypes.portfolioImage}`,
        requestFormData
      );

      // add on state
      let portfolioCopy = [...designer.portfolio];
      portfolioCopy.push({
        id: data.id,
        url: data.url,
        filename: data.filename,
      });

      setDesigner({ ...designer, portfolio: portfolioCopy });

      successToast("Has añadido una imagen a tu portafolio exitosamente");
      setIsLoading({ ...isLoading, portfolioButton: false });
    } catch (error) {
      errorToast(error.message);
      handleError(error);
    }
  };

  const handleDeletePortfolioImage = async (image, index) => {
    try {
      if (designer.portfolio.length === 1) {
        throw new Error("Debes tener al menos una imagen en tu portafolio");
      }
      setIsLoading({ ...isLoading, portfolioButton: true });

      await axios.delete(`/v1/users/files/${image.id}`);

      // delete on state
      let portfolioCopy = [...designer.portfolio];
      portfolioCopy.splice(index, 1);

      setDesigner({ ...designer, portfolio: portfolioCopy });

      // reset input
      document.getElementById("portfolio-file-input").value = "";

      successToast("Has eliminado una imagen de tu portafolio exitosamente");
      setIsLoading({ ...isLoading, portfolioButton: false });
    } catch (error) {
      errorToast(error.message);
      handleError(error);
    }
  };

  const handleUpdate = async (data) => {
    setIsLoading({ ...isLoading, profileButton: true });

    let obj = {
      user: {
        email: data.email,
        phone: data.phone,
      },
      address: {
        street_address: data.street_address,
        city_id: data.city_id,
      },
      city_name: data.city_name,
      designer: {
        bio: data.bio,
      },
      socials: {
        instagram: `https://www.instagram.com/${data.instagram}`,
      },
    };
    await axios.patch(`/v1/users`, obj);
    setIsLoading({ ...isLoading, profileButton: false });
    successToast("Has actualizado tus datos exitosamente");
  };

  const handleBankAccount = async (data) => {
    setIsLoading({ ...isLoading, bankButton: true });

    if (userTokens.hasBankInfo) {
      await axios.patch(`/v1/users/bank`, data);
      successToast("Has actualizado tus datos exitosamente");
    } else {
      await axios.post(`/v1/users/bank`, data);

      const newTokens = JSON.stringify({
        ...userTokens,
        hasBankInfo: true,
      });
      localStorage.setItem("user", newTokens);

      successToast("Has añadido una cuenta bancaria exitosamente");
    }
    setIsLoading({ ...isLoading, bankButton: false });
  };

  const handleToggleIsPublic = async () => {
    await axios.patch(`/v1/users`, {
      user: {
        is_public: !designer.is_public,
      },
    });

    setDesigner({ ...designer, is_public: !designer.is_public });
  };

  return (
    <Body style={{ width: "100%" }}>
      <input
        onChange={handleChangeAvatar}
        type="file"
        id="avatar-file-input"
        style={{ display: "none" }}
      />
      <input
        onChange={handleAddPortfolioImage}
        type="file"
        id="portfolio-file-input"
        style={{ display: "none" }}
      />
      {cities && designer && (
        <Fragment>
          <Paper
            style={{ flex: 1, padding: "2rem", width: "100%", margin: ".5rem" }}
          >
            <Header>
              <AvatarContainer>
                {isLoading.avatarButton ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <CircularProgress size={25} color="inherit" />
                  </div>
                ) : (
                  <Avatar
                    src={userTokens && userTokens.avatar}
                    alt="imagen de perfil diseñador"
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      document.getElementById("avatar-file-input").click();
                    }}
                  />
                )}
              </AvatarContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1rem",
                }}
              >
                <h2 style={{ fontFamily: "Open Sans" }}>
                  {designer.first_name} {designer.last_name}
                </h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Stars averageRating={designer.average_rating} />
                  <p style={{ fontSize: "0.6rem", marginLeft: "4px" }}>
                    ({designer.number_of_ratings})
                  </p>
                </div>
              </div>
            </Header>
            <ProfileForm
              designer={designer}
              cities={cities}
              loading={isLoading.profileButton}
              handleUpdate={(data) => handleUpdate(data)}
            />

            {designer && designer.is_active && designer.is_public && (
              <Alert severity="success">
                Tu perfil se encuentra habilitado. Recuerda que al apagarlo ya
                no estarás visible para nuestros clientes.
              </Alert>
            )}

            {designer && designer.is_active && !designer.is_public && (
              <Alert severity="error">
                Tu perfil se encuentra inhabilitado. Recuerda que no estarás
                visible para nuestros clientes hasta que lo actives de nuevo.
              </Alert>
            )}

            {designer && !designer.is_active && (
              <Alert severity="error">
                Tu membresía ha expirado por lo que no estarás visible para
                nuestros clientes. Para activarla de nuevo, por favor ponerse en
                contacto con atención al profesional: 324 5882478
              </Alert>
            )}
            <Row>
              <div>
                <StyledLabel>Estado de tu perfil</StyledLabel>
                {designer.is_active ? (
                  <Switch
                    defaultChecked={
                      designer && designer.is_public
                        ? designer.is_public
                        : false
                    }
                    onChange={handleToggleIsPublic}
                  />
                ) : (
                  <div>
                    <Switch defaultChecked={false} disabled />
                  </div>
                )}
              </div>
            </Row>
          </Paper>

          <PortfolioContainer>
            <Paper
              style={{
                flex: 1,
                padding: "2rem",
                width: "100%",
                margin: ".5rem",
                height: "100%",
              }}
            >
              <h3 style={{ fontFamily: "Open Sans" }}>
                Imágenes de portafolio
              </h3>
              <div
                id="portfolio-images-container"
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  overflow: "scroll",
                }}
              >
                {designer &&
                  designer.portfolio.map((image, index) => (
                    <div style={{ position: "relative", marginRight: "10px" }}>
                      <img
                        alt="avatar"
                        src={image.url}
                        width={100}
                        height={100}
                        style={{ objectFit: "cover" }}
                      />
                      <DeleteIcon
                        onClick={() => handleDeletePortfolioImage(image, index)}
                      >
                        &times;
                      </DeleteIcon>
                    </div>
                  ))}
              </div>

              <Button
                size="small"
                label="Añadir"
                onClick={() => {
                  document.getElementById("portfolio-file-input").click();
                }}
                $isLoading={isLoading.portfolioButton}
              />
            </Paper>
            <Paper
              style={{
                flex: 1,
                padding: "2rem",
                width: "100%",
                margin: ".5rem",
                height: "100%",
              }}
            >
              <BankForm
                designerName={
                  designer && `${designer.first_name} ${designer.last_name}`
                }
                bankingInfo={designer.bank ? designer.bank : null}
                hasBankInfo={userTokens && userTokens.hasBankInfo}
                handleBankAccount={handleBankAccount}
                loading={isLoading.bankButton}
              />
            </Paper>
          </PortfolioContainer>
        </Fragment>
      )}
    </Body>
  );
}

function ProfileForm({ designer, cities, loading, handleUpdate }) {
  const { register, errors, setError, handleSubmit } = useForm({
    resolver: yupResolver(designerConfigSchema),
  });

  const [country, setCountry] = useState([]);
  const [selectCountry, setSelectCountry] = useState("");
  const [state, setState] = useState([]);
  const [selectState, setSelectState] = useState("");
  const [citye, setCitye] = useState([]);
  const [selectCitye, setSelectCitye] = useState("");
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJyMWQuZW5naW5lZXJpbmcuYXJlYUBnbWFpbC5jb20iLCJhcGlfdG9rZW4iOiJqOGtKNm1tRExvY1FLRUQxeFdGVHdkRDNrTnlSNmR6TVBaZ1N6YlMtWnJHdVBhb3VDMTFwMXF2QlNZQVM3QmpxMzVJIn0sImV4cCI6MTY1MzA4NDkyM30.lLK-SGJyYYSq9aI7U1mwOtAFp9g5jpWyPEXzsVglgK8";

  /* TODO:the auth_token is updated every 24 hours, in case the api does not do it, it has to be done manually through a setTimeOut or another option. */
  const refreshAuthToken = async (token) =>
    await axios
      .get("https://www.universal-tutorial.com/api/getaccesstoken", {
        headers: {
          Accept: "application/json",
          "api-token":
            "j8kJ6mmDLocQKED1xWFTwdD3kNyR6dzMPZgSzbS-ZrGuPaouC11p1qvBSYAS7Bjq35I",
          "user-email": "r1d.engineering.area@gmail.com",
        },
      })
      .then((res) => {
        token = res.data.auth_token;
      })
      .catch((error) => {
        console.error(error);
      });

  useEffect(() => {
    const getCountrys = async () => {
      await axios
        .get("https://www.universal-tutorial.com/api/countries/", {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setCountry(res.data);
        })
        .catch((error) => {
          throw error;
        });
    };

    getCountrys();
  }, []);

  useEffect(() => {
    const getstates = async () => {
      await axios
        .get(`https://www.universal-tutorial.com/api/states/${selectCountry}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setState(res.data);
        })
        .catch((error) => {
          throw error;
        });
    };

    getstates();
  }, [selectCountry]);

  useEffect(() => {
    const getstates = async () => {
      await axios
        .get(`https://www.universal-tutorial.com/api/cities/${selectState}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setCitye(res.data);
        })
        .catch((error) => {
          throw error;
        });
    };

    getstates();
  }, [selectState]);

  const Nationalities = [
    { value: "Colombia", label: "Colombia" },
    { value: "USA", label: "USA" },
    { value: "Alemania", label: "Alemania" },
    { value: "España", label: "España" },
    { value: "Brasil", label: "Brasil" },
    { value: "Francia", label: "Francia" },
  ];

  const Languajes = [
    { value: "Español", label: "Español" },
    { value: "Inglés", label: "Inglés" },
    { value: "Aleman", label: "Aleman" },
    { value: "Portugues", label: "Portugues" },
    { value: "Frances", label: "Frances" },
    { value: "Italiano", label: "Italiano" },
  ];

  let countrys = country.map((country) => {
    return { value: country.country_name, label: country.country_name };
  });

  let states = state.map((state) => {
    return { value: state.state_name, label: state.state_name };
  });

  let cityes = citye.map((citye) => {
    return { value: citye.city_name, label: citye.city_name };
  });
  const getCities = (e) => {
    setSelectCountry(e.value);
  };

  const onSubmit = async (data) => {
    try {
      if (data.email !== designer.email) {
        await axios.post("/v1/users/check", data);
      }
      handleUpdate(data);
    } catch (error) {
      setError("email", {
        type: "manual",
        message: error.response.data.message,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <InputContainer>
          <StyledLabel>Email</StyledLabel>
          <StyledInput
            placeholder="Email"
            name="email"
            defaultValue={designer.email}
            ref={register}
          />
        </InputContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
          }}
        >
          <StyledLabel>Celular</StyledLabel>
          <StyledInput
            placeholder="Celular"
            name="phone"
            defaultValue={designer.phone}
            ref={register}
          />
        </div>
      </Row>

      {/* TODO: Uncomment this code when international virtual consultancy will be implemented*/}

      {/* <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Pais</StyledLabel>
          <Select
            onChange={(e) => {
              setSelectCountry(e.value);
            }}
            width="5px"
            placeholder="Seleccionar opción"
            name="city"
            options={countrys}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Municipio/Estado</StyledLabel>
          <Select
            onChange={(e) => {
              setSelectState(e.value);
            }}
            width="5px"
            placeholder="Seleccionar opción"
            name="city"
            options={states}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Ciudad</StyledLabel>
          <Select
            onChange={(e) => {
              setSelectCitye(e.value);
            }}
            width="5px"
            placeholder="Seleccionar opción"
            name="city"
            options={cityes}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Nacionalidad</StyledLabel>
          <Select
            placeholder="Seleccionar opción"
            name="colors"
            options={Nationalities}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Idiomas</StyledLabel>
          <Select
            placeholder=" Seleccionar idiomas"
            name="colors"
            isMulti
            options={Languajes}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </Row> */}

      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Dirección</StyledLabel>
          <StyledInput
            placeholder="Dirección"
            name="street_address"
            defaultValue={designer.address}
            ref={register}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Instagram</StyledLabel>
          <StyledInput
            placeholder="Instagram"
            name="instagram"
            defaultValue={
              designer.socials
                ? designer.socials.instagram
                  ? designer.socials.instagram.slice(26)
                  : designer.socials.instagram
                : designer.socials
            }
            ref={register}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        ></div>
      </Row>

      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Ciudad</StyledLabel>
          <CreatableSelect
            options={
              cities &&
              cities.map((city) => {
                return { value: city.id, label: city.name };
              })
            }
            placeholder="Ciudad"
            isClearable
            defaultValue={{ value: designer.city_id, label: designer.city }}
            name="city_name"
            label="Ciudad"
            onChange={(e) =>
              e && register({ name: "city_name", value: e.label })
            }
            formatCreateLabel={(inputValue) =>
              `Crear nueva ciudad: ${inputValue}`
            }
          />
        </div>
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          margin: "0 0.5rem",
          flex: 1,
        }}
      >
        <StyledLabel>Define tu estilo</StyledLabel>
        <StyledTextArea
          placeholder="Aspectos relevantes que describan mejor tu estilo"
          defaultValue={designer.bio}
          name="bio"
          ref={register}
        ></StyledTextArea>
      </div>

      <div>
        {Object.keys(errors)
          .slice(0, 2)
          .map((errorName) => {
            return (
              <p
                key={errorName}
                style={{
                  fontSize: "0.8rem",
                  color: "red",
                  padding: "12px 0 0 10px",
                }}
              >
                {errors[errorName].message}
              </p>
            );
          })}
      </div>
      <Row
        style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "10px" }}
      >
        <Button
          type="submit"
          size="small"
          label="Editar"
          $isLoading={loading}
        />
      </Row>
    </form>
  );
}

function BankForm({ designerName, bankingInfo, handleBankAccount, loading }) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(userConfigBankInfoSchema),
  });

  return (
    <form onSubmit={handleSubmit(handleBankAccount)}>
      <h3 style={{ fontFamily: "Open Sans", marginLeft: "5px" }}>
        Información bancaria
      </h3>

      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Banco</StyledLabel>
          <StyledSelect
            name="bank_name"
            defaultValue={bankingInfo && bankingInfo.bank_name}
            ref={register}
          >
            <option value="bancolombia">Bancolombia</option>
            <option value="banco de bogota">Banco de Bogotá</option>
            <option value="davivienda">Davivienda</option>
            <option value="bbva">BBVA</option>
            <option value="banco de occidente">Banco de Occidente</option>
            <option value="banco av villas">Banco AV Villas</option>
            <option value="banco caja social">Banco Caja Social</option>
            <option value="colpatria">Scotiabank Colpatria</option>
            <option value="banco falabella">Banco Falabella</option>
            <option value="citi">Citi</option>
            <option value="bancoomeva">Bancoomeva</option>
          </StyledSelect>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Titular de la cuenta</StyledLabel>
          <StyledInput
            placeholder="Titular de la cuenta"
            name="account_holder_name"
            defaultValue={designerName && designerName}
            ref={register}
            disabled={true}
          />
        </div>
      </Row>

      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: "0 0.5rem",
            flex: 1,
          }}
        >
          <StyledLabel>Número de la cuenta</StyledLabel>
          <StyledInput
            placeholder="Número de la cuenta"
            type="text"
            name="account_number"
            ref={register}
            defaultValue={bankingInfo && bankingInfo.account_number}
          />
        </div>
      </Row>

      <Row style={{ marginLeft: "5px" }}>
        <StyledLabel>
          <StyledInput
            type="radio"
            value="1"
            ref={register}
            name="is_savings_account"
            defaultChecked={
              bankingInfo && bankingInfo.is_savings_account ? true : false
            }
          />
          <span style={{ marginLeft: "5px" }}>Cuenta de ahorros</span>
        </StyledLabel>
        <StyledLabel>
          <StyledInput
            type="radio"
            value="0"
            ref={register}
            name="is_savings_account"
            defaultChecked={
              bankingInfo && !bankingInfo.is_savings_account ? true : false
            }
          />
          <span style={{ marginLeft: "5px" }}>Cuenta corriente</span>
        </StyledLabel>
      </Row>

      <i>
        * El titular de la cuenta no puede ser una persona diferente al
        diseñador
      </i>

      <div>
        {Object.keys(errors)
          .slice(0, 2)
          .map((errorName) => {
            return (
              <p
                key={errorName}
                style={{
                  fontSize: "0.8rem",
                  color: "red",
                  padding: "12px 0 0 10px",
                }}
              >
                {errors[errorName].message}
              </p>
            );
          })}
      </div>
      <Row
        style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "10px" }}
      >
        <Button
          type="submit"
          size="small"
          label={bankingInfo ? "Actualizar" : "Añadir"}
          $isLoading={loading}
        />
      </Row>
    </form>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledInput = styled.input`
  flex-grow: 1;
  font-family: "Open sans";
  padding: 0.5rem 1rem;
  border: 1px solid lightgray;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0.5rem;
`;

const StyledLabel = styled.label`
  flex-grow: 1;
  font-family: "Open Sans";
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 300;
`;

const StyledSelect = styled.select`
  font-family: "Open sans";
  padding: 0.25rem 1rem;
  max-height: 2rem;
`;

const StyledTextArea = styled.textarea`
  font-family: "Open sans";
  padding: 0.25rem 1rem;
  border: 1px solid lightgray;
`;

const PortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  #portfolio-images-container::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    width: auto;
  }
`;

const DeleteIcon = styled.span`
  color: white;
  cursor: pointer;
  text-align: center;
  background-color: lightcoral;
  border-radius: 100px;
  font-size: 18px;
  position: absolute;
  width: 24px;
  right: 5px;
  top: 5px;
`;

const AvatarContainer = styled.div`
  img {
    transition: 0.5s ease;
  }

  &:hover {
    img {
      transition: 0.5s ease;
      opacity: 0.75;
    }
  }
`;

export default DesignerConfig;
