import { useEffect, useState } from "react";
import { materialTableES } from "../../services/materialTableES";
import { useStyles } from "../../services/muiStyles";

import MaterialTable from "material-table";
import CustomButton from "../../components/Button/CustomButton";
import Modal from "../../components/Modal/Modal";
import ProductModal from "../../components/ProductModal/ProductModal";

import axios from "axios";
import { errorToast, successToast } from "../../services/toasts";

/**
 * Admin is able to revise and approve a product, after the a approval
 * the product will be public and available for all
 */
//TODO: Update this function name to ReviewProducts
function ProductsManagement() {
  const classes = useStyles();
  const [products, setProducts] = useState();
  const [productModal, setProductModal] = useState(false);
  const [productDetail, setProductDetail] = useState();

  useEffect(() => {
    getProducts();
  }, []);

  // Get the products that aren't already approved
  const getProducts = async () => {
    let response = await axios.get(`/v2/admin/products?status=pending`);
    setProducts(response.data);
  };

  const handleClose = (event) => {
    setProductModal(false);
  };

  const handleApproval = async (rowData, isApproved) => {
    try {
      let copyProducts = [...products];
      let index = copyProducts.indexOf(rowData);
      copyProducts.splice(index, 1);
      setProducts(copyProducts);

      await axios.patch(`/v2/admin/products/${rowData.product_id}/approval`, {
        is_approved: isApproved,
      });

      successToast(
        `Has ${isApproved ? "aprobado" : "declinado"} el producto exitosamente`
      );
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  return (
    <section className="layout-dashboard-container">
      <Modal
        showModal={productModal}
        closeModal={handleClose}
        contentMaxWidth="1000px"
        contentMaxHeight="500px"
      >
        <ProductModal product={productDetail} />
      </Modal>
      <MaterialTable
        title="Productos pendientes de aprobación"
        columns={[
          {
            title: "Imagen",
            width: "0px",
            field: "image",
            render: (rowData) => {
              if (!rowData?.principal_image[0]?.url) {
                return null;
              }
              return (
                // TODO: Optimize the alt name
                <img
                  alt="producto"
                  src={rowData?.principal_image[0].url}
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                />
              );
            },
            editable: "never",
          },
          { title: "Proveedor", field: "company_name" },
          {
            title: "Marca",
            render: (rowData) => rowData.brand,
            customFilterAndSearch: (term, rowData) =>
              (
                rowData.brand +
                rowData.description +
                rowData.comission_percentage / 2
              )
                .toLowerCase()
                .indexOf(term.toLowerCase()) !== -1,
            editable: "never",
          },
          { title: "Referencia", field: "name", editable: "never" },
          {
            title: "MUV",
            field: "min_sale_unit",
            type: "numeric",
            editable: "never",
          },
          { title: "Unidades por empaque", field: "units_per_package" },
          {
            title: "Presentación",
            field: "presentation",
            editable: "never",
            render: (rowData) => rowData.presentation || "No aplica",
          },
          {
            title: "Precio",
            field: "taxed_price",
            type: "currency",
            editable: "never",
            currencySetting: {
              currencyCode: "COP",
              locale: "col",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          },
          {
            title: "Unidades en Inventario",
            field: "units_available",
            type: "numeric",
            editable: "never",
          },
        ]}
        data={products}
        onRowClick={(event, selectedRow) => {
          event.stopPropagation();
          setProductModal(true);
          setProductDetail(selectedRow);
        }}
        options={{
          padding: "dense",
          clickable: true,
          actionsCellStyle: {
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        actions={[
          {
            icon: () => (
              <CustomButton size="small" color="success" shape="little-rounded">
                Aprobar
              </CustomButton>
            ),
            tooltip: "Aprobar",
            onClick: (event, rowData) => {
              handleApproval(rowData, true);
            },
          },
          {
            icon: () => (
              <CustomButton size="small" shape="little-rounded" color="danger">
                Declinar
              </CustomButton>
            ),
            tooltip: "Declinar",
            onClick: (event, rowData) => {
              handleApproval(rowData, false);
            },
          },
        ]}
        localization={materialTableES}
        isLoading={products ? false : true}
      />
    </section>
  );
}

export default ProductsManagement;
