import { doClearCart, useCart } from "../../Cart";

const useUserStore = () => {
  const { dispatch } = useCart();
  const startLogout = () => {
    localStorage.clear();
    dispatch(doClearCart());
    window.location.reload();
  };
  return { startLogout };
};
export default useUserStore;
