import { Fragment } from "react";
import { Design } from "../types";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

import Button from "../../../components/Button/Button";

type DesignsListProps = {
  designs: Design[];
  isClient: boolean;
  isDesigner: boolean;
  handleDesignApproval: (id: number) => void;
};

export default function DesignsList({
  isClient,
  isDesigner,
  designs,
  handleDesignApproval,
}: DesignsListProps) {
  const { openLightbox } = useLightbox();
  const elements = designs.map((item) => ({ src: item.url }));

  return (
    <Fragment>
      <div className="detail-panel-container">
        {designs.map((design, index) => {
          return (
            <div className="detail-panel-row" key={index}>
              <div className="file-details">
                <i className="fa fa-file-image-o" aria-hidden="true" />
                <p>{design.filename}</p>
              </div>
              <div className="button-container" style={{ display: "flex" }}>
                {/* @ts-ignore */}
                <Button
                  style={{ marginRight: "0.25rem" }}
                  size="small"
                  label="Ver"
                  onClick={() => {
                    openLightbox(index);
                  }}
                />
                {isClient &&
                  (design.is_approved === true ? (
                    // @ts-ignore
                    <Button
                      size="small"
                      label="Aprobado"
                      color="white"
                      backgroundColor="#39b54a"
                      disabled
                    />
                  ) : (
                    // @ts-ignore
                    <Button
                      size="small"
                      label="Aprobar"
                      onClick={() => handleDesignApproval(design.id)}
                    />
                  ))}
                {isDesigner && design.is_approved === true && (
                  // @ts-ignore
                  <Button
                    size="small"
                    label="Aprobado"
                    color="white"
                    backgroundColor="#39b54a"
                    disabled
                  />
                )}
              </div>
            </div>
          );
        })}
        <SRLWrapper elements={elements} />
      </div>
    </Fragment>
  );
}
