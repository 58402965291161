import styles from "./orderInfoData.module.scss";
interface OrderInfoDataProps {
  icon: React.ElementType;
  title: string;
  children: React.ReactNode;
}
const OrderInfoData = ({ icon: Icon, title, children }: OrderInfoDataProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon />
      </div>
      <div className={styles.content}>
        <h5>{title}</h5>
        {children}
      </div>
    </div>
  );
};
export default OrderInfoData;
