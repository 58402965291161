import { orderStatus } from "../data/orderStatus";
import { type Suborder } from "../privatePages/supplier/pages/salesManager/types";

const filterDataByStatus = (data: Suborder[], selectedTab: string) => {
  if (!data) return [];

  //Function to map the states using getStatusText
  const mapStatus = (item: Suborder) => {
    return {
      ...item,
      status: getStatusText(item.status),
    };
  };

  // Map states before filtering based on selected state
  const transformedData = data.map(mapStatus);

  if (selectedTab === orderStatus.Preparation) {
    return transformedData.filter(
      (item: Suborder) => item.status === "En preparación"
    );
  } else if (selectedTab === orderStatus.Shipped) {
    return transformedData.filter(
      (item: Suborder) => item.status === "En tránsito"
    );
  } else if (selectedTab === orderStatus.Delivered) {
    return transformedData.filter(
      (item: Suborder) => item.status === "Entregado"
    );
  } else if (selectedTab === orderStatus.Canceled) {
    return transformedData.filter(
      (item: Suborder) => item.status === "Cancelado"
    );
  } else {
    return data;
  }
};

export const getStatusText = (status: string) => {
  switch (status) {
    case "Created":
      return "En preparación";
    case "Shipped":
      return "En tránsito";
    case "Delivered":
      return "Entregado";
    case "Canceled":
      return "Cancelado";
    default:
      return "En preparación";
  }
};

export default filterDataByStatus;
