import axios from "axios";
import { useEffect, useState } from "react";

const useFetchData = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await axios.get(url, {
          signal: abortController.signal,
        });
        setData(result.data);
      } catch (error) {
        setErrorStatus(error);
      }
      setIsLoading(false);
    };
    fetchData();

    return () => abortController.abort();
    // eslint-disable-next-line
  }, [url]);

  return {
    data,
    isLoading,
    errorStatus,
  };
};
export default useFetchData;
