export const enviaCities = [
  {
    name: "ABEJORRAL",
    state_code: "AN",
    code: "05002000",
  },
  {
    name: "ABREGO",
    state_code: "NS",
    code: "54003000",
  },
  {
    name: "ABRIAQUI",
    state_code: "AN",
    code: "05004000",
  },
  {
    name: "ACACIAS",
    state_code: "ME",
    code: "50006000",
  },
  {
    name: "ACANDI",
    state_code: "CH",
    code: "27006000",
  },
  {
    name: "ACAPULCO",
    state_code: "SN",
    code: "68307007",
  },
  {
    name: "ACEVEDO",
    state_code: "HU",
    code: "41006000",
  },
  {
    name: "ACHI",
    state_code: "BL",
    code: "13006000",
  },
  {
    name: "AGRADO",
    state_code: "HU",
    code: "41013000",
  },
  {
    name: "AGUA DE DIOS",
    state_code: "CN",
    code: "25001000",
  },
  {
    name: "AGUACHICA",
    state_code: "CE",
    code: "20011000",
  },
  {
    name: "AGUADA",
    state_code: "SN",
    code: "68013000",
  },
  {
    name: "AGUADAS",
    state_code: "CL",
    code: "17013000",
  },
  {
    name: "AGUAZUL",
    state_code: "CS",
    code: "85010000",
  },
  {
    name: "AGUSTIN CODAZZI",
    state_code: "CE",
    code: "20013000",
  },
  {
    name: "AIPE",
    state_code: "HU",
    code: "41016000",
  },
  {
    name: "ALBAN ",
    state_code: "NA",
    code: "52019000",
  },
  {
    name: "ALBAN",
    state_code: "CN",
    code: "25019000",
  },
  {
    name: "ALBANIA ",
    state_code: "CA",
    code: "18029000",
  },
  {
    name: "ALBANIA",
    state_code: "LG",
    code: "44035000",
  },
  {
    name: "ALBANIA",
    state_code: "SN",
    code: "68020000",
  },
  {
    name: "ALCALA",
    state_code: "VC",
    code: "76020000",
  },
  {
    name: "ALDANA",
    state_code: "NA",
    code: "52022000",
  },
  {
    name: "ALEJANDRIA",
    state_code: "AN",
    code: "05021000",
  },
  {
    name: "ALGARROBO",
    state_code: "MA",
    code: "47030000",
  },
  {
    name: "ALGECIRAS",
    state_code: "HU",
    code: "41020000",
  },
  {
    name: "ALMAGUER",
    state_code: "CU",
    code: "19022000",
  },
  {
    name: "ALMEIDA",
    state_code: "BY",
    code: "15022000",
  },
  {
    name: "ALPUJARRA",
    state_code: "TO",
    code: "73024000",
  },
  {
    name: "ALTAMIRA ",
    state_code: "HU",
    code: "41026000",
  },
  {
    name: "ALTO BAUDO",
    state_code: "CH",
    code: "27025000",
  },
  {
    name: "ALTOS DEL ROSARIO",
    state_code: "BL",
    code: "13030000",
  },
  {
    name: "ALVARADO",
    state_code: "TO",
    code: "73026000",
  },
  {
    name: "AMAGA",
    state_code: "AN",
    code: "05030000",
  },
  {
    name: "AMALFI",
    state_code: "AN",
    code: "05031000",
  },
  {
    name: "AMBALEMA ",
    state_code: "TO",
    code: "73030000",
  },
  {
    name: "ANAPOIMA",
    state_code: "CN",
    code: "25035000",
  },
  {
    name: "ANCUYA",
    state_code: "NA",
    code: "52036000",
  },
  {
    name: "ANDALUCIA",
    state_code: "VC",
    code: "76036000",
  },
  {
    name: "ANDES",
    state_code: "AN",
    code: "05034000",
  },
  {
    name: "ANGELOPOLIS",
    state_code: "AN",
    code: "05036000",
  },
  {
    name: "ANGOSTURA",
    state_code: "AN",
    code: "05038000",
  },
  {
    name: "ANOLAIMA",
    state_code: "CN",
    code: "25040000",
  },
  {
    name: "ANORI",
    state_code: "AN",
    code: "05040000",
  },
  {
    name: "ANSERMA",
    state_code: "CL",
    code: "17042000",
  },
  {
    name: "ANSERMANUEVO",
    state_code: "VC",
    code: "76041000",
  },
  {
    name: "ANZA",
    state_code: "AN",
    code: "05044000",
  },
  {
    name: "ANZOATEGUI",
    state_code: "TO",
    code: "73043000",
  },
  {
    name: "APARTADO ",
    state_code: "AN",
    code: "05045000",
  },
  {
    name: "APIA",
    state_code: "RI",
    code: "66045000",
  },
  {
    name: "APULO",
    state_code: "CN",
    code: "25599000",
  },
  {
    name: "AQUITANIA",
    state_code: "BY",
    code: "15047000",
  },
  {
    name: "ARACATACA",
    state_code: "MA",
    code: "47053000",
  },
  {
    name: "ARANZAZU",
    state_code: "CL",
    code: "17050000",
  },
  {
    name: "ARATOCA",
    state_code: "SN",
    code: "68051000",
  },
  {
    name: "ARAUCA ",
    state_code: "AR",
    code: "81001000",
  },
  {
    name: "ARAUQUITA",
    state_code: "AR",
    code: "81065000",
  },
  {
    name: "ARBELAEZ",
    state_code: "CN",
    code: "25053000",
  },
  {
    name: "ARBOLEDA ",
    state_code: "NA",
    code: "52051000",
  },
  {
    name: "ARBOLEDAS ",
    state_code: "NS",
    code: "54051000",
  },
  {
    name: "ARBOLETES",
    state_code: "AN",
    code: "05051000",
  },
  {
    name: "ARCABUCO",
    state_code: "BY",
    code: "15051000",
  },
  {
    name: "ARENAL",
    state_code: "BL",
    code: "13042000",
  },
  {
    name: "ARGELIA ",
    state_code: "AN",
    code: "05055000",
  },
  {
    name: "ARGELIA",
    state_code: "VC",
    code: "76054000",
  },
  {
    name: "ARGELIA ",
    state_code: "CU",
    code: "19050000",
  },
  {
    name: "ARIGUANI",
    state_code: "MA",
    code: "47058000",
  },
  {
    name: "ARJONA",
    state_code: "BL",
    code: "13052000",
  },
  {
    name: "ARMENIA ",
    state_code: "AN",
    code: "05059000",
  },
  {
    name: "ARMENIA",
    state_code: "QU",
    code: "63001000",
  },
  {
    name: "ARMERO",
    state_code: "TO",
    code: "73055000",
  },
  {
    name: "ARROYOHONDO",
    state_code: "BL",
    code: "13062000",
  },
  {
    name: "ASTREA",
    state_code: "CE",
    code: "20032000",
  },
  {
    name: "ATACO",
    state_code: "TO",
    code: "73067000",
  },
  {
    name: "ATRATO",
    state_code: "CH",
    code: "27050000",
  },
  {
    name: "AYAPEL",
    state_code: "CO",
    code: "23068000",
  },
  {
    name: "BAGADO",
    state_code: "CH",
    code: "27073000",
  },
  {
    name: "BAHIA SOLANO",
    state_code: "CH",
    code: "27075000",
  },
  {
    name: "BAJO BAUDO",
    state_code: "CH",
    code: "27077000",
  },
  {
    name: "BALBOA ",
    state_code: "CU",
    code: "19075000",
  },
  {
    name: "BALBOA ",
    state_code: "RI",
    code: "66075000",
  },
  {
    name: "BARANOA",
    state_code: "AT",
    code: "08078000",
  },
  {
    name: "BARAYA ",
    state_code: "HU",
    code: "41078000",
  },
  {
    name: "BARBACOAS ",
    state_code: "NA",
    code: "52079000",
  },
  {
    name: "BARBOSA ",
    state_code: "SN",
    code: "68077000",
  },
  {
    name: "BARBOSA ",
    state_code: "AN",
    code: "05079000",
  },
  {
    name: "BARICHARA",
    state_code: "SN",
    code: "68079000",
  },
  {
    name: "BARRACAS",
    state_code: "LG",
    code: "44078000",
  },
  {
    name: "BARRANCA DE UPIA",
    state_code: "ME",
    code: "50110000",
  },
  {
    name: "BARRANCABERMEJA",
    state_code: "SN",
    code: "68081000",
  },
  {
    name: "BARRANCO DE LOBA",
    state_code: "BL",
    code: "13074000",
  },
  {
    name: "BARRANCO MINAS",
    state_code: "GU",
    code: "94343000",
  },
  {
    name: "BARRANQUILLA",
    state_code: "AT",
    code: "08001000",
  },
  {
    name: "BECERRIL",
    state_code: "CE",
    code: "20045000",
  },
  {
    name: "BELALCAZAR",
    state_code: "CL",
    code: "17088000",
  },
  {
    name: "BELEN ",
    state_code: "BY",
    code: "15087000",
  },
  {
    name: "BELEN ",
    state_code: "NA",
    code: "52083000",
  },
  {
    name: "BELEN DE LOS ANDAQUIES",
    state_code: "CA",
    code: "18094000",
  },
  {
    name: "BELEN DE UMBRIA",
    state_code: "RI",
    code: "66088000",
  },
  {
    name: "BELLO",
    state_code: "AN",
    code: "05088000",
  },
  {
    name: "BELMIRA",
    state_code: "AN",
    code: "05086000",
  },
  {
    name: "BELTRAN",
    state_code: "CN",
    code: "25086000",
  },
  {
    name: "BERBEO",
    state_code: "BY",
    code: "15090000",
  },
  {
    name: "BETANIA ",
    state_code: "AN",
    code: "05091000",
  },
  {
    name: "BETEITIVA",
    state_code: "BY",
    code: "15092000",
  },
  {
    name: "BETULIA ",
    state_code: "AN",
    code: "05093000",
  },
  {
    name: "BETULIA",
    state_code: "SN",
    code: "68092000",
  },
  {
    name: "BITUIMA",
    state_code: "CN",
    code: "25095000",
  },
  {
    name: "BOAVITA",
    state_code: "BY",
    code: "15097000",
  },
  {
    name: "BOCHALEMA",
    state_code: "NS",
    code: "54099000",
  },
  {
    name: "BOGOTA",
    state_code: "CN",
    code: "11001000",
  },
  {
    name: "BOJACA",
    state_code: "CN",
    code: "25099000",
  },
  {
    name: "BOJAYA",
    state_code: "CH",
    code: "27099000",
  },
  {
    name: "BOLIVAR ",
    state_code: "VC",
    code: "76100000",
  },
  {
    name: "BOLIVAR ",
    state_code: "CU",
    code: "19100000",
  },
  {
    name: "BOLIVAR ",
    state_code: "SN",
    code: "68101000",
  },
  {
    name: "BOSCONIA",
    state_code: "CE",
    code: "20060000",
  },
  {
    name: "BOYACA",
    state_code: "BY",
    code: "15104000",
  },
  {
    name: "BRICENO ",
    state_code: "AN",
    code: "05107000",
  },
  {
    name: "BRICENO",
    state_code: "BY",
    code: "15106000",
  },
  {
    name: "BUCARAMANGA",
    state_code: "SN",
    code: "68001000",
  },
  {
    name: "BUCARASICA",
    state_code: "NS",
    code: "54109000",
  },
  {
    name: "BUENAVENTURA",
    state_code: "VC",
    code: "76109000",
  },
  {
    name: "BUENAVISTA",
    state_code: "SU",
    code: "70110000",
  },
  {
    name: "BUENAVISTA",
    state_code: "BY",
    code: "15109000",
  },
  {
    name: "BUENAVISTA ",
    state_code: "CO",
    code: "23079000",
  },
  {
    name: "BUENAVISTA ",
    state_code: "QU",
    code: "63111000",
  },
  {
    name: "BUENOS AIRES ",
    state_code: "CU",
    code: "19110000",
  },
  {
    name: "BUESACO",
    state_code: "NA",
    code: "52110000",
  },
  {
    name: "BUGA",
    state_code: "VC",
    code: "76111000",
  },
  {
    name: "BUGALAGRANDE",
    state_code: "VC",
    code: "76113000",
  },
  {
    name: "BURITICA",
    state_code: "AN",
    code: "05113000",
  },
  {
    name: "BUSBANZA",
    state_code: "BY",
    code: "15114000",
  },
  {
    name: "CABRERA",
    state_code: "CN",
    code: "25120000",
  },
  {
    name: "CABRERA",
    state_code: "SN",
    code: "68121000",
  },
  {
    name: "CABUYARO",
    state_code: "ME",
    code: "50124000",
  },
  {
    name: "CACAHUAL",
    state_code: "GU",
    code: "94886000",
  },
  {
    name: "CACERES ",
    state_code: "AN",
    code: "05120000",
  },
  {
    name: "CACHIPAY ",
    state_code: "CN",
    code: "25123000",
  },
  {
    name: "CACHIRA",
    state_code: "NS",
    code: "54128000",
  },
  {
    name: "CACOTA",
    state_code: "NS",
    code: "54125000",
  },
  {
    name: "CAICEDO",
    state_code: "AN",
    code: "05125000",
  },
  {
    name: "CAICEDONIA",
    state_code: "VC",
    code: "76122000",
  },
  {
    name: "CAIMITO",
    state_code: "SU",
    code: "70124000",
  },
  {
    name: "CAJAMARCA ",
    state_code: "TO",
    code: "73124000",
  },
  {
    name: "CAJIBIO",
    state_code: "CU",
    code: "19130000",
  },
  {
    name: "CAJICA",
    state_code: "CN",
    code: "25126000",
  },
  {
    name: "CALAMAR",
    state_code: "BL",
    code: "13140000",
  },
  {
    name: "CALAMAR",
    state_code: "GA",
    code: "95015000",
  },
  {
    name: "CALARCA",
    state_code: "QU",
    code: "63130000",
  },
  {
    name: "CALDAS ",
    state_code: "AN",
    code: "05129000",
  },
  {
    name: "CALDAS",
    state_code: "BY",
    code: "15131000",
  },
  {
    name: "CALDONO",
    state_code: "CU",
    code: "19137000",
  },
  {
    name: "CALI",
    state_code: "VC",
    code: "76001000",
  },
  {
    name: "CALIFORNIA",
    state_code: "SN",
    code: "68132000",
  },
  {
    name: "CALIMA",
    state_code: "VC",
    code: "76126000",
  },
  {
    name: "CALOTO",
    state_code: "CU",
    code: "19142000",
  },
  {
    name: "CAMPAMENTO",
    state_code: "AN",
    code: "05134000",
  },
  {
    name: "CAMPO DE LA CRUZ",
    state_code: "AT",
    code: "08137000",
  },
  {
    name: "CAMPOALEGRE ",
    state_code: "HU",
    code: "41132000",
  },
  {
    name: "CAMPOHERMOSO",
    state_code: "BY",
    code: "15135000",
  },
  {
    name: "CANALETE",
    state_code: "CO",
    code: "23090000",
  },
  {
    name: "CANASGORDAS",
    state_code: "AN",
    code: "05138000",
  },
  {
    name: "CANDELARIA ",
    state_code: "AT",
    code: "08141000",
  },
  {
    name: "CANDELARIA ",
    state_code: "VC",
    code: "76130000",
  },
  {
    name: "CANTAGALLO",
    state_code: "BL",
    code: "13160000",
  },
  {
    name: "CAPARRAPI",
    state_code: "CN",
    code: "25148000",
  },
  {
    name: "CAPITANEJO",
    state_code: "SN",
    code: "68147000",
  },
  {
    name: "CAQUEZA",
    state_code: "CN",
    code: "25151000",
  },
  {
    name: "CARACOLI ",
    state_code: "AN",
    code: "05142000",
  },
  {
    name: "CARAMANTA",
    state_code: "AN",
    code: "05145000",
  },
  {
    name: "CARCASI",
    state_code: "SN",
    code: "68152000",
  },
  {
    name: "CAREPA",
    state_code: "AN",
    code: "05147000",
  },
  {
    name: "CARMEN DE APICALA",
    state_code: "TO",
    code: "73148000",
  },
  {
    name: "CARMEN DE CARUPA",
    state_code: "CN",
    code: "25154000",
  },
  {
    name: "CARMEN DEL DARIEN",
    state_code: "CH",
    code: "27150000",
  },
  {
    name: "CAROLINA",
    state_code: "AN",
    code: "05150000",
  },
  {
    name: "CARTAGENA",
    state_code: "BL",
    code: "13001000",
  },
  {
    name: "CARTAGENA DEL CHAIRA",
    state_code: "CA",
    code: "18150000",
  },
  {
    name: "CARTAGO",
    state_code: "VC",
    code: "76147000",
  },
  {
    name: "CARURU",
    state_code: "VA",
    code: "97161000",
  },
  {
    name: "CASABIANCA",
    state_code: "TO",
    code: "73152000",
  },
  {
    name: "CASTILLA LA NUEVA",
    state_code: "ME",
    code: "50150000",
  },
  {
    name: "CAUCASIA",
    state_code: "AN",
    code: "05154000",
  },
  {
    name: "CEPITA",
    state_code: "SN",
    code: "68160000",
  },
  {
    name: "CERETE",
    state_code: "CO",
    code: "23162000",
  },
  {
    name: "CERINZA",
    state_code: "BY",
    code: "15162000",
  },
  {
    name: "CERRITO",
    state_code: "SN",
    code: "68162000",
  },
  {
    name: "CERRO SAN ANTONIO",
    state_code: "MA",
    code: "47161000",
  },
  {
    name: "CERTEGUI",
    state_code: "CH",
    code: "27160000",
  },
  {
    name: "CHACHAGUI",
    state_code: "NA",
    code: "52240000",
  },
  {
    name: "CHAGUANI",
    state_code: "CN",
    code: "25168000",
  },
  {
    name: "CHALAN",
    state_code: "SU",
    code: "70230000",
  },
  {
    name: "CHAMEZA",
    state_code: "CS",
    code: "85015000",
  },
  {
    name: "CHAPARRAL",
    state_code: "TO",
    code: "73168000",
  },
  {
    name: "CHARALA",
    state_code: "SN",
    code: "68167000",
  },
  {
    name: "CHARTA",
    state_code: "SN",
    code: "68169000",
  },
  {
    name: "CHIA",
    state_code: "CN",
    code: "25175000",
  },
  {
    name: "CHIBOLO",
    state_code: "MA",
    code: "47170000",
  },
  {
    name: "CHIGORODO ",
    state_code: "AN",
    code: "05172000",
  },
  {
    name: "CHIMA",
    state_code: "CO",
    code: "23168000",
  },
  {
    name: "CHIMA",
    state_code: "SN",
    code: "68176000",
  },
  {
    name: "CHIMICHAGUA",
    state_code: "CE",
    code: "20175000",
  },
  {
    name: "CHINACOTA",
    state_code: "NS",
    code: "54172000",
  },
  {
    name: "CHINAVITA",
    state_code: "BY",
    code: "15172000",
  },
  {
    name: "CHINCHINA",
    state_code: "CL",
    code: "17174000",
  },
  {
    name: "CHINU",
    state_code: "CO",
    code: "23182000",
  },
  {
    name: "CHIPAQUE",
    state_code: "CN",
    code: "25178000",
  },
  {
    name: "CHIPATA",
    state_code: "SN",
    code: "68179000",
  },
  {
    name: "CHIQUINQUIRA",
    state_code: "BY",
    code: "15176000",
  },
  {
    name: "CHIQUIZA",
    state_code: "BY",
    code: "15232000",
  },
  {
    name: "CHIRIGUANA",
    state_code: "CE",
    code: "20178000",
  },
  {
    name: "CHISCAS",
    state_code: "BY",
    code: "15180000",
  },
  {
    name: "CHITA",
    state_code: "BY",
    code: "15183000",
  },
  {
    name: "CHITAGA",
    state_code: "NS",
    code: "54174000",
  },
  {
    name: "CHITARAQUE",
    state_code: "BY",
    code: "15185000",
  },
  {
    name: "CHIVATA",
    state_code: "BY",
    code: "15187000",
  },
  {
    name: "CHIVOR",
    state_code: "BY",
    code: "15236000",
  },
  {
    name: "CHOACHI",
    state_code: "CN",
    code: "25181000",
  },
  {
    name: "CHOCONTA",
    state_code: "CN",
    code: "25183000",
  },
  {
    name: "CICUCO",
    state_code: "BL",
    code: "13188000",
  },
  {
    name: "CIENAGA",
    state_code: "MA",
    code: "47189000",
  },
  {
    name: "CIENAGA DE ORO",
    state_code: "CO",
    code: "23189000",
  },
  {
    name: "CIENEGA",
    state_code: "BY",
    code: "15189000",
  },
  {
    name: "CIMITARRA",
    state_code: "SN",
    code: "68190000",
  },
  {
    name: "CIRCASIA",
    state_code: "QU",
    code: "63190000",
  },
  {
    name: "CISNEROS ",
    state_code: "AN",
    code: "05190000",
  },
  {
    name: "CIUDAD BOLIVAR",
    state_code: "AN",
    code: "05101000",
  },
  {
    name: "CLEMENCIA",
    state_code: "BL",
    code: "13222000",
  },
  {
    name: "COCORNA",
    state_code: "AN",
    code: "05197000",
  },
  {
    name: "COELLO",
    state_code: "TO",
    code: "73200000",
  },
  {
    name: "COGUA",
    state_code: "CN",
    code: "25200000",
  },
  {
    name: "COLOMBIA ",
    state_code: "HU",
    code: "41206000",
  },
  {
    name: "COLON",
    state_code: "NA",
    code: "52203000",
  },
  {
    name: "COLON",
    state_code: "PU",
    code: "86219000",
  },
  {
    name: "COLOSO",
    state_code: "SU",
    code: "70204000",
  },
  {
    name: "COMBITA",
    state_code: "BY",
    code: "15204000",
  },
  {
    name: "CONCEPCION",
    state_code: "AN",
    code: "05206000",
  },
  {
    name: "CONCEPCION",
    state_code: "SN",
    code: "68207000",
  },
  {
    name: "CONCORDIA",
    state_code: "MA",
    code: "52090000",
  },
  {
    name: "CONCORDIA ",
    state_code: "AN",
    code: "05209000",
  },
  {
    name: "CONDOTO",
    state_code: "CH",
    code: "27205000",
  },
  {
    name: "CONFINES",
    state_code: "SN",
    code: "68209000",
  },
  {
    name: "CONSACA",
    state_code: "NA",
    code: "52207000",
  },
  {
    name: "CONTADERO",
    state_code: "NA",
    code: "52210000",
  },
  {
    name: "CONTRATACION",
    state_code: "SN",
    code: "68211000",
  },
  {
    name: "CONVENCION",
    state_code: "NS",
    code: "54206000",
  },
  {
    name: "COPACABANA",
    state_code: "AN",
    code: "05212000",
  },
  {
    name: "COPER",
    state_code: "BY",
    code: "15212000",
  },
  {
    name: "CORDOBA",
    state_code: "BL",
    code: "13212000",
  },
  {
    name: "CORDOBA ",
    state_code: "NA",
    code: "52215000",
  },
  {
    name: "CORDOBA ",
    state_code: "QU",
    code: "63212000",
  },
  {
    name: "CORINTO ",
    state_code: "CU",
    code: "19212000",
  },
  {
    name: "COROMORO",
    state_code: "SN",
    code: "68217000",
  },
  {
    name: "COROZAL",
    state_code: "SU",
    code: "70215000",
  },
  {
    name: "CORRALES",
    state_code: "BY",
    code: "15215000",
  },
  {
    name: "COTA",
    state_code: "CN",
    code: "25214000",
  },
  {
    name: "COTORRA",
    state_code: "CO",
    code: "23079007",
  },
  {
    name: "COTORRA",
    state_code: "CO",
    code: "23300000",
  },
  {
    name: "COVARACHIA",
    state_code: "BY",
    code: "15218000",
  },
  {
    name: "COVENAS",
    state_code: "SU",
    code: "70221000",
  },
  {
    name: "COYAIMA",
    state_code: "TO",
    code: "73217000",
  },
  {
    name: "CRAVO NORTE",
    state_code: "AR",
    code: "81220000",
  },
  {
    name: "CUASPUD",
    state_code: "NA",
    code: "52224000",
  },
  {
    name: "CUBARA",
    state_code: "BY",
    code: "15223000",
  },
  {
    name: "CUBARRAL",
    state_code: "ME",
    code: "50223000",
  },
  {
    name: "CUCAITA",
    state_code: "BY",
    code: "15224000",
  },
  {
    name: "CUCUNUBA",
    state_code: "CN",
    code: "25224000",
  },
  {
    name: "CUCUTA",
    state_code: "NS",
    code: "54001000",
  },
  {
    name: "CUCUTILLA",
    state_code: "NS",
    code: "54223000",
  },
  {
    name: "CUITIVA",
    state_code: "BY",
    code: "15226000",
  },
  {
    name: "CUMARAL",
    state_code: "ME",
    code: "50226000",
  },
  {
    name: "CUMARIBO",
    state_code: "VI",
    code: "99773000",
  },
  {
    name: "CUMBAL",
    state_code: "NA",
    code: "52227000",
  },
  {
    name: "CUMBITARA",
    state_code: "NA",
    code: "52233000",
  },
  {
    name: "CUNDAY",
    state_code: "TO",
    code: "73226000",
  },
  {
    name: "CURILLO",
    state_code: "CA",
    code: "18205000",
  },
  {
    name: "CURITI",
    state_code: "SN",
    code: "68229000",
  },
  {
    name: "CURUMANI",
    state_code: "CE",
    code: "20228000",
  },
  {
    name: "DABEIBA",
    state_code: "AN",
    code: "05234000",
  },
  {
    name: "DAGUA",
    state_code: "VC",
    code: "76233000",
  },
  {
    name: "DIBULLA",
    state_code: "LG",
    code: "44090000",
  },
  {
    name: "DISTRACCION",
    state_code: "LG",
    code: "44098000",
  },
  {
    name: "DOLORES ",
    state_code: "TO",
    code: "73236000",
  },
  {
    name: "DON MATIAS",
    state_code: "AN",
    code: "05237000",
  },
  {
    name: "DOSQUEBRADAS ",
    state_code: "RI",
    code: "66170000",
  },
  {
    name: "DUITAMA",
    state_code: "BY",
    code: "15238000",
  },
  {
    name: "DURANIA",
    state_code: "NS",
    code: "54239000",
  },
  {
    name: "EBEJICO",
    state_code: "AN",
    code: "05240000",
  },
  {
    name: "EL AGUILA",
    state_code: "VC",
    code: "76243000",
  },
  {
    name: "EL BAGRE ",
    state_code: "AN",
    code: "05250000",
  },
  {
    name: "EL BANCO",
    state_code: "MA",
    code: "47245000",
  },
  {
    name: "EL BORDO",
    state_code: "CU",
    code: "19532000",
  },
  {
    name: "EL CAIRO ",
    state_code: "VC",
    code: "76246000",
  },
  {
    name: "EL CALVARIO",
    state_code: "ME",
    code: "50245000",
  },
  {
    name: "EL CANTON DEL SAN PABLO",
    state_code: "CH",
    code: "27135000",
  },
  {
    name: "EL CARMEN ",
    state_code: "NS",
    code: "54245000",
  },
  {
    name: "EL CARMEN DE ATRATO",
    state_code: "CH",
    code: "27245000",
  },
  {
    name: "EL CARMEN DE BOLIVAR",
    state_code: "BL",
    code: "13244000",
  },
  {
    name: "EL CARMEN DE CHUCURI",
    state_code: "SN",
    code: "68235000",
  },
  {
    name: "EL CARMEN DE VIBORAL",
    state_code: "AN",
    code: "05148000",
  },
  {
    name: "EL CASTILLO",
    state_code: "ME",
    code: "50251000",
  },
  {
    name: "EL CERRITO",
    state_code: "VC",
    code: "76248000",
  },
  {
    name: "EL CHARCO ",
    state_code: "NA",
    code: "52250000",
  },
  {
    name: "EL COCUY",
    state_code: "BY",
    code: "15244000",
  },
  {
    name: "EL COLEGIO",
    state_code: "CN",
    code: "25245000",
  },
  {
    name: "EL COPEY",
    state_code: "CE",
    code: "20238000",
  },
  {
    name: "EL DONCELLO",
    state_code: "CA",
    code: "18247000",
  },
  {
    name: "EL DORADO",
    state_code: "ME",
    code: "50270000",
  },
  {
    name: "EL DOVIO",
    state_code: "VC",
    code: "76250000",
  },
  {
    name: "EL ENCANTO",
    state_code: "AM",
    code: "91263000",
  },
  {
    name: "EL ESPINO",
    state_code: "BY",
    code: "15248000",
  },
  {
    name: "EL GUACAMAYO",
    state_code: "SN",
    code: "68245000",
  },
  {
    name: "EL GUAMO",
    state_code: "BL",
    code: "13248000",
  },
  {
    name: "EL LITORAL DEL SAN JUAN",
    state_code: "CH",
    code: "27250000",
  },
  {
    name: "EL MOLINO",
    state_code: "LG",
    code: "44110000",
  },
  {
    name: "EL PASO",
    state_code: "CE",
    code: "20250000",
  },
  {
    name: "EL PAUJIL",
    state_code: "CA",
    code: "18256000",
  },
  {
    name: "EL PENOL",
    state_code: "NA",
    code: "52254000",
  },
  {
    name: "EL PENON",
    state_code: "SN",
    code: "68250000",
  },
  {
    name: "EL PENON",
    state_code: "BL",
    code: "13268000",
  },
  {
    name: "EL PENON",
    state_code: "CN",
    code: "25258000",
  },
  {
    name: "EL PINON",
    state_code: "MA",
    code: "47258000",
  },
  {
    name: "EL PLAYON",
    state_code: "SN",
    code: "68255000",
  },
  {
    name: "EL RETEN",
    state_code: "MA",
    code: "47268000",
  },
  {
    name: "EL RETORNO",
    state_code: "GA",
    code: "95025000",
  },
  {
    name: "EL ROBLE",
    state_code: "SU",
    code: "70233000",
  },
  {
    name: "EL ROSAL",
    state_code: "CN",
    code: "25260000",
  },
  {
    name: "EL ROSARIO",
    state_code: "NA",
    code: "52256000",
  },
  {
    name: "EL SANTUARIO",
    state_code: "AN",
    code: "05697000",
  },
  {
    name: "EL TABLON DE GOMEZ",
    state_code: "NA",
    code: "52258000",
  },
  {
    name: "EL TAMBO",
    state_code: "CU",
    code: "19256000",
  },
  {
    name: "EL TAMBO",
    state_code: "NA",
    code: "52260000",
  },
  {
    name: "EL TARRA",
    state_code: "NS",
    code: "54003018",
  },
  {
    name: "EL TARRA",
    state_code: "NS",
    code: "54250000",
  },
  {
    name: "EL ZULIA",
    state_code: "NS",
    code: "54261000",
  },
  {
    name: "ELIAS",
    state_code: "HU",
    code: "41244000",
  },
  {
    name: "ENCINO",
    state_code: "SN",
    code: "68264000",
  },
  {
    name: "ENCISO",
    state_code: "SN",
    code: "68266000",
  },
  {
    name: "ENTRERRIOS",
    state_code: "AN",
    code: "05264000",
  },
  {
    name: "ENVIGADO",
    state_code: "AN",
    code: "05266000",
  },
  {
    name: "ESPINAL",
    state_code: "TO",
    code: "73268000",
  },
  {
    name: "FACATATIVA",
    state_code: "CN",
    code: "25269000",
  },
  {
    name: "FALAN",
    state_code: "TO",
    code: "73270000",
  },
  {
    name: "FILADELFIA",
    state_code: "CL",
    code: "17272000",
  },
  {
    name: "FILANDIA",
    state_code: "QU",
    code: "63272000",
  },
  {
    name: "FIRAVITOBA",
    state_code: "BY",
    code: "15272000",
  },
  {
    name: "FLANDES",
    state_code: "TO",
    code: "73275000",
  },
  {
    name: "FLORENCIA",
    state_code: "CU",
    code: "19290000",
  },
  {
    name: "FLORENCIA",
    state_code: "CA",
    code: "18001000",
  },
  {
    name: "FLORESTA",
    state_code: "BY",
    code: "15276000",
  },
  {
    name: "FLORIAN",
    state_code: "SN",
    code: "68271000",
  },
  {
    name: "FLORIDA",
    state_code: "VC",
    code: "76275000",
  },
  {
    name: "FLORIDABLANCA",
    state_code: "SN",
    code: "68276000",
  },
  {
    name: "FOMEQUE",
    state_code: "CN",
    code: "25279000",
  },
  {
    name: "FONSECA",
    state_code: "LG",
    code: "44279000",
  },
  {
    name: "FORTUL",
    state_code: "AR",
    code: "81300000",
  },
  {
    name: "FOSCA",
    state_code: "CN",
    code: "25281000",
  },
  {
    name: "FRANCISCO PIZARRO",
    state_code: "NA",
    code: "52520000",
  },
  {
    name: "FREDONIA",
    state_code: "AN",
    code: "05282000",
  },
  {
    name: "FRESNO",
    state_code: "TO",
    code: "73283000",
  },
  {
    name: "FRONTINO",
    state_code: "AN",
    code: "05284000",
  },
  {
    name: "FUENTE DE ORO",
    state_code: "ME",
    code: "50287000",
  },
  {
    name: "FUNDACION",
    state_code: "MA",
    code: "47288000",
  },
  {
    name: "FUNES",
    state_code: "NA",
    code: "52287000",
  },
  {
    name: "FUNZA",
    state_code: "CN",
    code: "25286000",
  },
  {
    name: "FUQUENE",
    state_code: "CN",
    code: "25288000",
  },
  {
    name: "FUSAGASUGA",
    state_code: "CN",
    code: "25290000",
  },
  {
    name: "GACHALA",
    state_code: "CN",
    code: "25293000",
  },
  {
    name: "GACHANCIPA",
    state_code: "CN",
    code: "25295000",
  },
  {
    name: "GACHANTIVA",
    state_code: "BY",
    code: "15293000",
  },
  {
    name: "GACHETA",
    state_code: "CN",
    code: "25297000",
  },
  {
    name: "GALAN",
    state_code: "SN",
    code: "68296000",
  },
  {
    name: "GALAPA",
    state_code: "AT",
    code: "08296000",
  },
  {
    name: "GALERAS",
    state_code: "SU",
    code: "70235000",
  },
  {
    name: "GAMA",
    state_code: "CN",
    code: "25299000",
  },
  {
    name: "GAMARRA",
    state_code: "CE",
    code: "20295000",
  },
  {
    name: "GAMBITA",
    state_code: "SN",
    code: "68298000",
  },
  {
    name: "GAMEZA",
    state_code: "BY",
    code: "15296000",
  },
  {
    name: "GARAGOA",
    state_code: "BY",
    code: "15299000",
  },
  {
    name: "GARZON",
    state_code: "HU",
    code: "41298000",
  },
  {
    name: "GENOVA",
    state_code: "QU",
    code: "63302000",
  },
  {
    name: "GIGANTE",
    state_code: "HU",
    code: "41306000",
  },
  {
    name: "GINEBRA",
    state_code: "VC",
    code: "76306000",
  },
  {
    name: "GIRALDO",
    state_code: "AN",
    code: "05306000",
  },
  {
    name: "GIRARDOT",
    state_code: "CN",
    code: "25307000",
  },
  {
    name: "GIRARDOTA",
    state_code: "AN",
    code: "05308000",
  },
  {
    name: "GIRON",
    state_code: "SN",
    code: "68307000",
  },
  {
    name: "GOMEZ PLATA",
    state_code: "AN",
    code: "05310000",
  },
  {
    name: "GONZALEZ",
    state_code: "CE",
    code: "20310000",
  },
  {
    name: "GRAMALOTE",
    state_code: "NS",
    code: "54313000",
  },
  {
    name: "GRANADA",
    state_code: "CN",
    code: "25312000",
  },
  {
    name: "GRANADA",
    state_code: "ME",
    code: "50313000",
  },
  {
    name: "GRANADA",
    state_code: "AN",
    code: "05313000",
  },
  {
    name: "GUACA",
    state_code: "SN",
    code: "68318000",
  },
  {
    name: "GUACAMAYAS",
    state_code: "BY",
    code: "15317000",
  },
  {
    name: "GUACARI",
    state_code: "VC",
    code: "76318000",
  },
  {
    name: "GUACHENE",
    state_code: "CU",
    code: "19300000",
  },
  {
    name: "GUACHETA",
    state_code: "CN",
    code: "25317000",
  },
  {
    name: "GUACHUCAL",
    state_code: "NA",
    code: "52317000",
  },
  {
    name: "GUADALUPE",
    state_code: "HU",
    code: "41319000",
  },
  {
    name: "GUADALUPE",
    state_code: "SN",
    code: "68320000",
  },
  {
    name: "GUADALUPE",
    state_code: "AN",
    code: "05315000",
  },
  {
    name: "GUADUAS",
    state_code: "CN",
    code: "25320000",
  },
  {
    name: "GUAITARILLA",
    state_code: "NA",
    code: "52320000",
  },
  {
    name: "GUALMATAN",
    state_code: "NA",
    code: "52323000",
  },
  {
    name: "GUAMAL",
    state_code: "MA",
    code: "47318000",
  },
  {
    name: "GUAMAL",
    state_code: "ME",
    code: "50318000",
  },
  {
    name: "GUAMO",
    state_code: "TO",
    code: "73319000",
  },
  {
    name: "GUAPI",
    state_code: "CU",
    code: "19318000",
  },
  {
    name: "GUAPOTA",
    state_code: "SN",
    code: "68322000",
  },
  {
    name: "GUARANDA",
    state_code: "SU",
    code: "70265000",
  },
  {
    name: "GUARNE ",
    state_code: "AN",
    code: "05318000",
  },
  {
    name: "GUASCA",
    state_code: "CN",
    code: "25322000",
  },
  {
    name: "GUATAPE",
    state_code: "AN",
    code: "05321000",
  },
  {
    name: "GUATAQUI",
    state_code: "CN",
    code: "25324000",
  },
  {
    name: "GUATAVITA",
    state_code: "CN",
    code: "25326000",
  },
  {
    name: "GUATEQUE",
    state_code: "BY",
    code: "15322000",
  },
  {
    name: "GUATICA",
    state_code: "RI",
    code: "66318000",
  },
  {
    name: "GUAVATA",
    state_code: "SN",
    code: "68324000",
  },
  {
    name: "GUAYABAL DE SIQUIMA",
    state_code: "CN",
    code: "25328000",
  },
  {
    name: "GUAYABETAL",
    state_code: "CN",
    code: "25335000",
  },
  {
    name: "GUAYATA",
    state_code: "BY",
    code: "15325000",
  },
  {
    name: "GUEPSA",
    state_code: "SN",
    code: "68327000",
  },
  {
    name: "GUICAN",
    state_code: "BY",
    code: "15332000",
  },
  {
    name: "GUTIERREZ",
    state_code: "CN",
    code: "25339000",
  },
  {
    name: "HACARI",
    state_code: "NS",
    code: "54344000",
  },
  {
    name: "HATILLO DE LOBA",
    state_code: "BL",
    code: "13300000",
  },
  {
    name: "HATO",
    state_code: "SN",
    code: "68344000",
  },
  {
    name: "HATO COROZAL",
    state_code: "CS",
    code: "85125000",
  },
  {
    name: "HATONUEVO",
    state_code: "LG",
    code: "44378000",
  },
  {
    name: "HELICONIA",
    state_code: "AN",
    code: "05347000",
  },
  {
    name: "HERRAN",
    state_code: "NS",
    code: "54347000",
  },
  {
    name: "HERVEO",
    state_code: "TO",
    code: "73347000",
  },
  {
    name: "HISPANIA",
    state_code: "AN",
    code: "05353000",
  },
  {
    name: "HOBO",
    state_code: "HU",
    code: "41349000",
  },
  {
    name: "HONDA",
    state_code: "TO",
    code: "73349000",
  },
  {
    name: "IBAGUE",
    state_code: "TO",
    code: "73001000",
  },
  {
    name: "ICONONZO",
    state_code: "TO",
    code: "73352000",
  },
  {
    name: "ILES",
    state_code: "NA",
    code: "52352000",
  },
  {
    name: "IMUES",
    state_code: "NA",
    code: "52354000",
  },
  {
    name: "INIRIDA",
    state_code: "GU",
    code: "94001000",
  },
  {
    name: "INZA",
    state_code: "CU",
    code: "19355000",
  },
  {
    name: "IPIALES",
    state_code: "NA",
    code: "52356000",
  },
  {
    name: "IQUIRA",
    state_code: "HU",
    code: "41357000",
  },
  {
    name: "ISNOS",
    state_code: "HU",
    code: "41359000",
  },
  {
    name: "ISTMINA",
    state_code: "CH",
    code: "27361000",
  },
  {
    name: "ITAGUI",
    state_code: "AN",
    code: "05360000",
  },
  {
    name: "ITUANGO",
    state_code: "AN",
    code: "05361000",
  },
  {
    name: "IZA",
    state_code: "BY",
    code: "15362000",
  },
  {
    name: "JAMBALO",
    state_code: "CU",
    code: "19364000",
  },
  {
    name: "JAMUNDI",
    state_code: "VC",
    code: "76364000",
  },
  {
    name: "JARDIN",
    state_code: "AN",
    code: "05364000",
  },
  {
    name: "JENESANO",
    state_code: "BY",
    code: "15367000",
  },
  {
    name: "JERICO ",
    state_code: "AN",
    code: "05368000",
  },
  {
    name: "JERICO ",
    state_code: "BY",
    code: "15368000",
  },
  {
    name: "JERUSALEN",
    state_code: "CN",
    code: "25368000",
  },
  {
    name: "JESUS MARIA",
    state_code: "SN",
    code: "68368000",
  },
  {
    name: "JORDAN",
    state_code: "SN",
    code: "68370000",
  },
  {
    name: "JUAN DE ACOSTA",
    state_code: "AT",
    code: "08372000",
  },
  {
    name: "JUNIN ",
    state_code: "CN",
    code: "25372000",
  },
  {
    name: "JURADO",
    state_code: "CH",
    code: "27372000",
  },
  {
    name: "LA APARTADA",
    state_code: "CO",
    code: "23350000",
  },
  {
    name: "LA ARGENTINA ",
    state_code: "HU",
    code: "41378000",
  },
  {
    name: "LA BELLEZA",
    state_code: "SN",
    code: "68377000",
  },
  {
    name: "LA CALERA",
    state_code: "CN",
    code: "25377000",
  },
  {
    name: "LA CAPILLA",
    state_code: "BY",
    code: "15380000",
  },
  {
    name: "LA CEJA ",
    state_code: "AN",
    code: "05376000",
  },
  {
    name: "LA CELIA",
    state_code: "RI",
    code: "66383000",
  },
  {
    name: "LA CHORRERA",
    state_code: "AM",
    code: "91405000",
  },
  {
    name: "LA CRUZ ",
    state_code: "NA",
    code: "52378000",
  },
  {
    name: "LA CUMBRE ",
    state_code: "VC",
    code: "76377000",
  },
  {
    name: "LA DORADA",
    state_code: "CL",
    code: "17380000",
  },
  {
    name: "LA ESPERANZA",
    state_code: "NS",
    code: "54385000",
  },
  {
    name: "LA ESTRELLA ",
    state_code: "AN",
    code: "05380000",
  },
  {
    name: "LA FLORIDA ",
    state_code: "NA",
    code: "52381000",
  },
  {
    name: "LA GLORIA ",
    state_code: "CE",
    code: "20383000",
  },
  {
    name: "LA GUADALUPE",
    state_code: "GU",
    code: "94885000",
  },
  {
    name: "LA JAGUA DE IBIRICO",
    state_code: "CE",
    code: "20400000",
  },
  {
    name: "LA JAGUA DEL PILAR",
    state_code: "LG",
    code: "44420000",
  },
  {
    name: "LA LLANADA",
    state_code: "CN",
    code: "25181002",
  },
  {
    name: "LA LLANADA",
    state_code: "NA",
    code: "52385000",
  },
  {
    name: "LA MACARENA",
    state_code: "ME",
    code: "50350000",
  },
  {
    name: "LA MERCED",
    state_code: "CL",
    code: "17388000",
  },
  {
    name: "LA MESA ",
    state_code: "CN",
    code: "25386000",
  },
  {
    name: "LA MONTANITA",
    state_code: "CA",
    code: "18410000",
  },
  {
    name: "LA PALMA ",
    state_code: "CN",
    code: "25394000",
  },
  {
    name: "LA PAZ",
    state_code: "CE",
    code: "20621000",
  },
  {
    name: "LA PAZ ",
    state_code: "SN",
    code: "68397000",
  },
  {
    name: "LA PEDRERA",
    state_code: "AM",
    code: "91407000",
  },
  {
    name: "LA PENA",
    state_code: "CN",
    code: "25398000",
  },
  {
    name: "LA PINTADA",
    state_code: "AN",
    code: "05390000",
  },
  {
    name: "LA PINTADA",
    state_code: "AN",
    code: "05390000",
  },
  {
    name: "LA PLATA",
    state_code: "HU",
    code: "41396000",
  },
  {
    name: "LA PLAYA",
    state_code: "NS",
    code: "54398000",
  },
  {
    name: "LA PRIMAVERA ",
    state_code: "VI",
    code: "99524000",
  },
  {
    name: "LA SALINA",
    state_code: "CS",
    code: "85136000",
  },
  {
    name: "LA SIERRA ",
    state_code: "CU",
    code: "19392000",
  },
  {
    name: "LA TEBAIDA",
    state_code: "QU",
    code: "63401000",
  },
  {
    name: "LA TOLA",
    state_code: "NA",
    code: "52390000",
  },
  {
    name: "LA UNION ",
    state_code: "NA",
    code: "52399000",
  },
  {
    name: "LA UNION ",
    state_code: "AN",
    code: "05400000",
  },
  {
    name: "LA UNION ",
    state_code: "SU",
    code: "70400000",
  },
  {
    name: "LA UNION ",
    state_code: "VC",
    code: "76400000",
  },
  {
    name: "LA UVITA",
    state_code: "BY",
    code: "15403000",
  },
  {
    name: "LA VEGA ",
    state_code: "CN",
    code: "25402000",
  },
  {
    name: "LA VEGA ",
    state_code: "CU",
    code: "19397000",
  },
  {
    name: "LA VICTORIA",
    state_code: "AM",
    code: "91430000",
  },
  {
    name: "LA VICTORIA",
    state_code: "BY",
    code: "15401000",
  },
  {
    name: "LA VICTORIA ",
    state_code: "VC",
    code: "76403000",
  },
  {
    name: "LA VIRGINIA ",
    state_code: "RI",
    code: "66400000",
  },
  {
    name: "LABATECA",
    state_code: "NS",
    code: "54377000",
  },
  {
    name: "LABRANZAGRANDE",
    state_code: "BY",
    code: "15377000",
  },
  {
    name: "LANDAZURI",
    state_code: "SN",
    code: "68385000",
  },
  {
    name: "LEBRIJA",
    state_code: "SN",
    code: "68406000",
  },
  {
    name: "LEGUIZAMO",
    state_code: "PU",
    code: "86573000",
  },
  {
    name: "LEIVA",
    state_code: "NA",
    code: "52405000",
  },
  {
    name: "LEJANIAS",
    state_code: "ME",
    code: "50400000",
  },
  {
    name: "LENGUAZAQUE",
    state_code: "CN",
    code: "25407000",
  },
  {
    name: "LERIDA",
    state_code: "TO",
    code: "73408000",
  },
  {
    name: "LETICIA",
    state_code: "AM",
    code: "91001000",
  },
  {
    name: "LIBANO ",
    state_code: "TO",
    code: "73411000",
  },
  {
    name: "LIBORINA",
    state_code: "AN",
    code: "05411000",
  },
  {
    name: "LINARES",
    state_code: "NA",
    code: "52411000",
  },
  {
    name: "LLORO",
    state_code: "CH",
    code: "27413000",
  },
  {
    name: "LOPEZ",
    state_code: "CU",
    code: "19821003",
  },
  {
    name: "LORICA",
    state_code: "CO",
    code: "23417000",
  },
  {
    name: "LOS ANDES",
    state_code: "NA",
    code: "52418000",
  },
  {
    name: "LOS CORDOBAS",
    state_code: "CO",
    code: "23419000",
  },
  {
    name: "LOS CORDOBAS",
    state_code: "CO",
    code: "23466034",
  },
  {
    name: "LOS PALMITOS",
    state_code: "SU",
    code: "70418000",
  },
  {
    name: "LOS PATIOS ",
    state_code: "NS",
    code: "54405000",
  },
  {
    name: "LOS SANTOS",
    state_code: "SN",
    code: "68418000",
  },
  {
    name: "LOURDES",
    state_code: "NS",
    code: "54418000",
  },
  {
    name: "LURUACO",
    state_code: "AT",
    code: "08421000",
  },
  {
    name: "MACANAL",
    state_code: "BY",
    code: "15425000",
  },
  {
    name: "MACARAVITA",
    state_code: "SN",
    code: "68425000",
  },
  {
    name: "MACEO",
    state_code: "AN",
    code: "05425000",
  },
  {
    name: "MACHETA",
    state_code: "CN",
    code: "25426000",
  },
  {
    name: "MADRID",
    state_code: "CN",
    code: "25430000",
  },
  {
    name: "MAGANGUE",
    state_code: "BL",
    code: "13430000",
  },
  {
    name: "MAGUI",
    state_code: "NA",
    code: "52427000",
  },
  {
    name: "MAHATES",
    state_code: "BL",
    code: "13433000",
  },
  {
    name: "MAICAO",
    state_code: "LG",
    code: "44430000",
  },
  {
    name: "MAJAGUAL ",
    state_code: "SU",
    code: "70429000",
  },
  {
    name: "MALAGA",
    state_code: "SN",
    code: "68432000",
  },
  {
    name: "MALAMBO ",
    state_code: "AT",
    code: "08433000",
  },
  {
    name: "MALLAMA",
    state_code: "NA",
    code: "52435000",
  },
  {
    name: "MANATI",
    state_code: "AT",
    code: "08436000",
  },
  {
    name: "MANAURE",
    state_code: "CE",
    code: "20443000",
  },
  {
    name: "MANAURE",
    state_code: "LG",
    code: "44560000",
  },
  {
    name: "MANI",
    state_code: "CS",
    code: "85139000",
  },
  {
    name: "MANIZALES",
    state_code: "CL",
    code: "17001000",
  },
  {
    name: "MANTA",
    state_code: "CN",
    code: "25436000",
  },
  {
    name: "MANZANARES",
    state_code: "CL",
    code: "17433000",
  },
  {
    name: "MAPIRIPAN",
    state_code: "ME",
    code: "50325000",
  },
  {
    name: "MAPIRIPANA",
    state_code: "GU",
    code: "94663000",
  },
  {
    name: "MARGARITA",
    state_code: "BL",
    code: "13440000",
  },
  {
    name: "MARIA LA BAJA",
    state_code: "BL",
    code: "13442000",
  },
  {
    name: "MARINILLA",
    state_code: "AN",
    code: "05440000",
  },
  {
    name: "MARIPI",
    state_code: "BY",
    code: "15442000",
  },
  {
    name: "MARIQUITA",
    state_code: "TO",
    code: "73443000",
  },
  {
    name: "MARMATO",
    state_code: "CL",
    code: "17442000",
  },
  {
    name: "MARQUETALIA",
    state_code: "CL",
    code: "17444000",
  },
  {
    name: "MARSELLA ",
    state_code: "RI",
    code: "66440000",
  },
  {
    name: "MARULANDA",
    state_code: "CL",
    code: "17446000",
  },
  {
    name: "MATANZA",
    state_code: "SN",
    code: "68444000",
  },
  {
    name: "MEDELLIN",
    state_code: "AN",
    code: "05001000",
  },
  {
    name: "MEDINA",
    state_code: "CN",
    code: "25438000",
  },
  {
    name: "MEDIO ATRATO",
    state_code: "CH",
    code: "27425000",
  },
  {
    name: "MEDIO BAUDO",
    state_code: "CH",
    code: "27430000",
  },
  {
    name: "MEDIO SAN JUAN",
    state_code: "CH",
    code: "27450000",
  },
  {
    name: "MELGAR",
    state_code: "TO",
    code: "73449000",
  },
  {
    name: "MERCADERES",
    state_code: "CU",
    code: "19450000",
  },
  {
    name: "MESETAS",
    state_code: "ME",
    code: "50330000",
  },
  {
    name: "MILAN",
    state_code: "CA",
    code: "18460000",
  },
  {
    name: "MIRAFLORES",
    state_code: "BY",
    code: "15455000",
  },
  {
    name: "MIRAFLORES ",
    state_code: "GA",
    code: "95200000",
  },
  {
    name: "MIRANDA",
    state_code: "CU",
    code: "19455000",
  },
  {
    name: "MIRITI PARANA",
    state_code: "AM",
    code: "91460000",
  },
  {
    name: "MISTRATO",
    state_code: "RI",
    code: "66456000",
  },
  {
    name: "MITU",
    state_code: "VA",
    code: "97001000",
  },
  {
    name: "MOCOA",
    state_code: "PU",
    code: "86001000",
  },
  {
    name: "MOGOTES",
    state_code: "SN",
    code: "68464000",
  },
  {
    name: "MOLAGAVITA",
    state_code: "SN",
    code: "68468000",
  },
  {
    name: "MOMIL",
    state_code: "CO",
    code: "23464000",
  },
  {
    name: "MOMPOS",
    state_code: "BL",
    code: "13468000",
  },
  {
    name: "MONGUA",
    state_code: "BY",
    code: "15464000",
  },
  {
    name: "MONGUI",
    state_code: "BY",
    code: "15466000",
  },
  {
    name: "MONIQUIRA",
    state_code: "BY",
    code: "15469000",
  },
  {
    name: "MONITOS",
    state_code: "CO",
    code: "23500000",
  },
  {
    name: "MONTEBELLO ",
    state_code: "AN",
    code: "05467000",
  },
  {
    name: "MONTECRISTO",
    state_code: "BL",
    code: "13458000",
  },
  {
    name: "MONTELIBANO",
    state_code: "CO",
    code: "23466000",
  },
  {
    name: "MONTENEGRO",
    state_code: "QU",
    code: "63470000",
  },
  {
    name: "MONTERIA",
    state_code: "CO",
    code: "23001000",
  },
  {
    name: "MONTERREY",
    state_code: "CS",
    code: "85162000",
  },
  {
    name: "MORALES",
    state_code: "CU",
    code: "19473000",
  },
  {
    name: "MORALES",
    state_code: "BL",
    code: "13473000",
  },
  {
    name: "MORELIA ",
    state_code: "CA",
    code: "18479000",
  },
  {
    name: "MORICHAL",
    state_code: "GU",
    code: "94888000",
  },
  {
    name: "MORROA",
    state_code: "SU",
    code: "70473000",
  },
  {
    name: "MOSQUERA ",
    state_code: "CN",
    code: "25473000",
  },
  {
    name: "MOSQUERA ",
    state_code: "NA",
    code: "52473000",
  },
  {
    name: "MOTAVITA",
    state_code: "BY",
    code: "15476000",
  },
  {
    name: "MURILLO ",
    state_code: "TO",
    code: "73461000",
  },
  {
    name: "MURINDO",
    state_code: "AN",
    code: "05475000",
  },
  {
    name: "MUTATA",
    state_code: "AN",
    code: "05480000",
  },
  {
    name: "MUTISCUA",
    state_code: "NS",
    code: "54480000",
  },
  {
    name: "MUZO",
    state_code: "BY",
    code: "15480000",
  },
  {
    name: "NARINO ",
    state_code: "AN",
    code: "05483000",
  },
  {
    name: "NARINO ",
    state_code: "CN",
    code: "25483000",
  },
  {
    name: "NARINO ",
    state_code: "NA",
    code: "52001006",
  },
  {
    name: "NATAGA",
    state_code: "HU",
    code: "41483000",
  },
  {
    name: "NATAGAIMA",
    state_code: "TO",
    code: "73483000",
  },
  {
    name: "NECHI",
    state_code: "AN",
    code: "05495000",
  },
  {
    name: "NECOCLI",
    state_code: "AN",
    code: "05490000",
  },
  {
    name: "NEIRA",
    state_code: "CL",
    code: "17486000",
  },
  {
    name: "NEIVA",
    state_code: "HU",
    code: "41001000",
  },
  {
    name: "NEMOCON",
    state_code: "CN",
    code: "25486000",
  },
  {
    name: "NILO",
    state_code: "CN",
    code: "25488000",
  },
  {
    name: "NIMAIMA",
    state_code: "CN",
    code: "25489000",
  },
  {
    name: "NOBSA",
    state_code: "BY",
    code: "15491000",
  },
  {
    name: "NOCAIMA",
    state_code: "CN",
    code: "25491000",
  },
  {
    name: "NORCASIA",
    state_code: "CL",
    code: "17495000",
  },
  {
    name: "NOROSI",
    state_code: "BL",
    code: "13490000",
  },
  {
    name: "NOVITA",
    state_code: "CH",
    code: "27491000",
  },
  {
    name: "NUEVA GRANADA",
    state_code: "MA",
    code: "47460000",
  },
  {
    name: "NUEVO COLON",
    state_code: "BY",
    code: "15494000",
  },
  {
    name: "NUNCHIA",
    state_code: "CS",
    code: "85225000",
  },
  {
    name: "NUQUI",
    state_code: "CH",
    code: "27495000",
  },
  {
    name: "OBANDO",
    state_code: "VC",
    code: "76497000",
  },
  {
    name: "OCAMONTE",
    state_code: "SN",
    code: "68498000",
  },
  {
    name: "OCANA",
    state_code: "NS",
    code: "54498000",
  },
  {
    name: "OIBA",
    state_code: "SN",
    code: "68500000",
  },
  {
    name: "OICATA",
    state_code: "BY",
    code: "15500000",
  },
  {
    name: "OLAYA",
    state_code: "AN",
    code: "05501000",
  },
  {
    name: "OLAYA HERRERA ",
    state_code: "NA",
    code: "52079012",
  },
  {
    name: "ONZAGA",
    state_code: "SN",
    code: "68502000",
  },
  {
    name: "OPORAPA",
    state_code: "HU",
    code: "41503000",
  },
  {
    name: "ORITO",
    state_code: "PU",
    code: "86320000",
  },
  {
    name: "OROCUE",
    state_code: "CS",
    code: "85230000",
  },
  {
    name: "ORTEGA ",
    state_code: "TO",
    code: "73504000",
  },
  {
    name: "OSPINA",
    state_code: "NA",
    code: "52506000",
  },
  {
    name: "OTANCHE",
    state_code: "BY",
    code: "15507000",
  },
  {
    name: "OVEJAS",
    state_code: "SU",
    code: "70508000",
  },
  {
    name: "PACHAVITA",
    state_code: "BY",
    code: "15511000",
  },
  {
    name: "PACHO",
    state_code: "CN",
    code: "25513000",
  },
  {
    name: "PACOA",
    state_code: "VA",
    code: "97511000",
  },
  {
    name: "PACORA",
    state_code: "CL",
    code: "17513000",
  },
  {
    name: "PADILLA ",
    state_code: "CU",
    code: "19513000",
  },
  {
    name: "PAEZ ",
    state_code: "CU",
    code: "19517000",
  },
  {
    name: "PAEZ",
    state_code: "BY",
    code: "19517000",
  },
  {
    name: "PAICOL",
    state_code: "HU",
    code: "41518000",
  },
  {
    name: "PAILITAS",
    state_code: "CE",
    code: "20517000",
  },
  {
    name: "PAIME",
    state_code: "CN",
    code: "25518000",
  },
  {
    name: "PAIPA",
    state_code: "BY",
    code: "15516000",
  },
  {
    name: "PAJARITO",
    state_code: "BY",
    code: "15518000",
  },
  {
    name: "PALERMO",
    state_code: "HU",
    code: "41524000",
  },
  {
    name: "PALESTINA",
    state_code: "HU",
    code: "41530000",
  },
  {
    name: "PALESTINA",
    state_code: "CL",
    code: "17524000",
  },
  {
    name: "PALMAR",
    state_code: "SN",
    code: "68522000",
  },
  {
    name: "PALMAR DE VARELA",
    state_code: "AT",
    code: "08520000",
  },
  {
    name: "PALMAS DEL SOCORRO",
    state_code: "SN",
    code: "68524000",
  },
  {
    name: "PALMIRA",
    state_code: "VC",
    code: "76520000",
  },
  {
    name: "PALMITO",
    state_code: "SU",
    code: "70523000",
  },
  {
    name: "PALOCABILDO ",
    state_code: "TO",
    code: "73520000",
  },
  {
    name: "PAMPLONA ",
    state_code: "NS",
    code: "54518000",
  },
  {
    name: "PAMPLONITA",
    state_code: "NS",
    code: "54520000",
  },
  {
    name: "PANA PANA",
    state_code: "GU",
    code: "94887000",
  },
  {
    name: "PANDI",
    state_code: "CN",
    code: "25524000",
  },
  {
    name: "PANQUEBA",
    state_code: "BY",
    code: "15522000",
  },
  {
    name: "PAPUNAUA",
    state_code: "VA",
    code: "97777000",
  },
  {
    name: "PARAMO",
    state_code: "SN",
    code: "68533000",
  },
  {
    name: "PARATEBUENO",
    state_code: "CN",
    code: "25530000",
  },
  {
    name: "PASCA",
    state_code: "CN",
    code: "25535000",
  },
  {
    name: "PASTO",
    state_code: "NA",
    code: "52001000",
  },
  {
    name: "PATIA",
    state_code: "CU",
    code: "19532008",
  },
  {
    name: "PAUNA",
    state_code: "BY",
    code: "15531000",
  },
  {
    name: "PAYA",
    state_code: "BY",
    code: "15533000",
  },
  {
    name: "PAZ DE ARIPORO",
    state_code: "CS",
    code: "85250000",
  },
  {
    name: "PAZ DE RIO",
    state_code: "BY",
    code: "15537000",
  },
  {
    name: "PEDRAZA",
    state_code: "MA",
    code: "47541000",
  },
  {
    name: "PELAYA",
    state_code: "CE",
    code: "20550000",
  },
  {
    name: "PENOL",
    state_code: "AN",
    code: "05541000",
  },
  {
    name: "PENSILVANIA",
    state_code: "CL",
    code: "17541000",
  },
  {
    name: "PEQUE",
    state_code: "AN",
    code: "05543000",
  },
  {
    name: "PEREIRA",
    state_code: "RI",
    code: "66001000",
  },
  {
    name: "PESCA",
    state_code: "BY",
    code: "15542000",
  },
  {
    name: "PIAMONTE",
    state_code: "CU",
    code: "19533000",
  },
  {
    name: "PIEDECUESTA",
    state_code: "SN",
    code: "68547000",
  },
  {
    name: "PIEDRAS",
    state_code: "TO",
    code: "73547000",
  },
  {
    name: "PIENDAMO",
    state_code: "CU",
    code: "19548000",
  },
  {
    name: "PIJAO",
    state_code: "QU",
    code: "63548000",
  },
  {
    name: "PIJINO DEL CARMEN",
    state_code: "MA",
    code: "47545000",
  },
  {
    name: "PINCHOTE",
    state_code: "SN",
    code: "68549000",
  },
  {
    name: "PINILLOS",
    state_code: "BL",
    code: "13549000",
  },
  {
    name: "PIOJO",
    state_code: "AT",
    code: "08549000",
  },
  {
    name: "PISBA",
    state_code: "BY",
    code: "15550000",
  },
  {
    name: "PITAL",
    state_code: "HU",
    code: "41548000",
  },
  {
    name: "PITALITO",
    state_code: "HU",
    code: "41551000",
  },
  {
    name: "PIVIJAY",
    state_code: "MA",
    code: "47551000",
  },
  {
    name: "PLANADAS ",
    state_code: "TO",
    code: "73555000",
  },
  {
    name: "PLANETA RICA",
    state_code: "CO",
    code: "23555000",
  },
  {
    name: "PLATO",
    state_code: "MA",
    code: "47555000",
  },
  {
    name: "POLICARPA",
    state_code: "NA",
    code: "52540000",
  },
  {
    name: "POLONUEVO",
    state_code: "AT",
    code: "08558000",
  },
  {
    name: "PONEDERA",
    state_code: "AT",
    code: "08560000",
  },
  {
    name: "POPAYAN",
    state_code: "CU",
    code: "19001000",
  },
  {
    name: "PORE ",
    state_code: "CS",
    code: "85263000",
  },
  {
    name: "POTOSI ",
    state_code: "NA",
    code: "52560000",
  },
  {
    name: "PRADERA",
    state_code: "VC",
    code: "76563000",
  },
  {
    name: "PRADO ",
    state_code: "TO",
    code: "73563000",
  },
  {
    name: "PROVIDENCIA ",
    state_code: "NA",
    code: "52565000",
  },
  {
    name: "PROVIDENCIA",
    state_code: "SA",
    code: "88564000",
  },
  {
    name: "PUEBLO BELLO ",
    state_code: "CE",
    code: "20570000",
  },
  {
    name: "PUEBLO NUEVO ",
    state_code: "CO",
    code: "23570000",
  },
  {
    name: "PUEBLO RICO ",
    state_code: "RI",
    code: "66572000",
  },
  {
    name: "PUEBLORRICO",
    state_code: "AN",
    code: "05576000",
  },
  {
    name: "PUEBLOVIEJO",
    state_code: "MA",
    code: "47570000",
  },
  {
    name: "PUENTE NACIONAL",
    state_code: "SN",
    code: "68572000",
  },
  {
    name: "PUERRES",
    state_code: "NA",
    code: "52573000",
  },
  {
    name: "PUERTO ALEGRIA",
    state_code: "AM",
    code: "91530000",
  },
  {
    name: "PUERTO ARICA",
    state_code: "AM",
    code: "91536000",
  },
  {
    name: "PUERTO ASIS",
    state_code: "PU",
    code: "86568000",
  },
  {
    name: "PUERTO BERRIO",
    state_code: "AN",
    code: "05579000",
  },
  {
    name: "PUERTO BOYACA",
    state_code: "BY",
    code: "15572000",
  },
  {
    name: "PUERTO CAICEDO",
    state_code: "PU",
    code: "86569000",
  },
  {
    name: "PUERTO CARRENO",
    state_code: "VI",
    code: "99001000",
  },
  {
    name: "PUERTO COLOMBIA ",
    state_code: "AT",
    code: "08573000",
  },
  {
    name: "PUERTO COLOMBIA ",
    state_code: "GU",
    code: "94884000",
  },
  {
    name: "PUERTO CONCORDIA",
    state_code: "ME",
    code: "50450000",
  },
  {
    name: "PUERTO ESCONDIDO",
    state_code: "CO",
    code: "23574000",
  },
  {
    name: "PUERTO GAITAN ",
    state_code: "ME",
    code: "50568000",
  },
  {
    name: "PUERTO GUZMAN",
    state_code: "PU",
    code: "86571000",
  },
  {
    name: "PUERTO LIBERTADOR",
    state_code: "CO",
    code: "23580000",
  },
  {
    name: "PUERTO LLERAS ",
    state_code: "ME",
    code: "50577000",
  },
  {
    name: "PUERTO LOPEZ",
    state_code: "ME",
    code: "50573000",
  },
  {
    name: "PUERTO NARE",
    state_code: "AN",
    code: "05585000",
  },
  {
    name: "PUERTO NARINO",
    state_code: "AM",
    code: "91540000",
  },
  {
    name: "PUERTO PARRA",
    state_code: "SN",
    code: "68573000",
  },
  {
    name: "PUERTO RICO ",
    state_code: "CA",
    code: "18592000",
  },
  {
    name: "PUERTO RICO ",
    state_code: "ME",
    code: "50590000",
  },
  {
    name: "PUERTO RONDON",
    state_code: "AR",
    code: "81591000",
  },
  {
    name: "PUERTO SALGAR",
    state_code: "CN",
    code: "25572000",
  },
  {
    name: "PUERTO SANTANDER ",
    state_code: "NS",
    code: "54553000",
  },
  {
    name: "PUERTO SANTANDER",
    state_code: "AM",
    code: "91669000",
  },
  {
    name: "PUERTO TEJADA",
    state_code: "CU",
    code: "19573000",
  },
  {
    name: "PUERTO TRIUNFO",
    state_code: "AN",
    code: "05591000",
  },
  {
    name: "PUERTO WILCHES",
    state_code: "SN",
    code: "68575000",
  },
  {
    name: "PULI",
    state_code: "CN",
    code: "25580000",
  },
  {
    name: "PUPIALES",
    state_code: "NA",
    code: "52585000",
  },
  {
    name: "PURACE",
    state_code: "CU",
    code: "19585004",
  },
  {
    name: "PURIFICACION",
    state_code: "TO",
    code: "73585000",
  },
  {
    name: "PURISIMA",
    state_code: "CO",
    code: "23586000",
  },
  {
    name: "QUEBRADANEGRA ",
    state_code: "CN",
    code: "25592000",
  },
  {
    name: "QUETAME",
    state_code: "CN",
    code: "25594000",
  },
  {
    name: "QUIBDO",
    state_code: "CH",
    code: "27001000",
  },
  {
    name: "QUIMBAYA",
    state_code: "QU",
    code: "63594000",
  },
  {
    name: "QUINCHIA",
    state_code: "RI",
    code: "66594000",
  },
  {
    name: "QUIPAMA",
    state_code: "BY",
    code: "15580000",
  },
  {
    name: "QUIPILE",
    state_code: "CN",
    code: "25596000",
  },
  {
    name: "RAGONVALIA",
    state_code: "NS",
    code: "54599000",
  },
  {
    name: "RAMIRIQUI",
    state_code: "BY",
    code: "15599000",
  },
  {
    name: "RAQUIRA",
    state_code: "BY",
    code: "15600000",
  },
  {
    name: "RECETOR",
    state_code: "CS",
    code: "85279000",
  },
  {
    name: "REGIDOR",
    state_code: "BL",
    code: "13580000",
  },
  {
    name: "REMEDIOS",
    state_code: "AN",
    code: "05604000",
  },
  {
    name: "REMOLINO",
    state_code: "MA",
    code: "47605000",
  },
  {
    name: "REPELON",
    state_code: "AT",
    code: "08606000",
  },
  {
    name: "RESTREPO ",
    state_code: "ME",
    code: "50606000",
  },
  {
    name: "RESTREPO ",
    state_code: "VC",
    code: "76606000",
  },
  {
    name: "RETIRO",
    state_code: "AN",
    code: "05607000",
  },
  {
    name: "RICAURTE ",
    state_code: "CN",
    code: "25612000",
  },
  {
    name: "RICAURTE ",
    state_code: "NA",
    code: "52612000",
  },
  {
    name: "RIO BLANCO ",
    state_code: "TO",
    code: "73616000",
  },
  {
    name: "RIO DE ORO ",
    state_code: "CE",
    code: "20614000",
  },
  {
    name: "RIO IRO",
    state_code: "CH",
    code: "27580000",
  },
  {
    name: "RIO QUITO",
    state_code: "CH",
    code: "27600000",
  },
  {
    name: "RIO VIEJO",
    state_code: "BL",
    code: "13600000",
  },
  {
    name: "RIOFRIO ",
    state_code: "VC",
    code: "76616000",
  },
  {
    name: "RIOHACHA",
    state_code: "LG",
    code: "44001000",
  },
  {
    name: "RIONEGRO ",
    state_code: "SN",
    code: "68615000",
  },
  {
    name: "RIONEGRO ",
    state_code: "AN",
    code: "05615000",
  },
  {
    name: "RIOSUCIO",
    state_code: "CH",
    code: "27615000",
  },
  {
    name: "RIOSUCIO",
    state_code: "CL",
    code: "17614000",
  },
  {
    name: "RISARALDA",
    state_code: "CL",
    code: "17616000",
  },
  {
    name: "RIVERA ",
    state_code: "HU",
    code: "41615000",
  },
  {
    name: "ROBERTO PAYAN",
    state_code: "NA",
    code: "52250006",
  },
  {
    name: "ROLDANILLO",
    state_code: "VC",
    code: "76622000",
  },
  {
    name: "RONCESVALLES",
    state_code: "TO",
    code: "73622000",
  },
  {
    name: "RONDON",
    state_code: "BY",
    code: "15621000",
  },
  {
    name: "ROSAS",
    state_code: "CU",
    code: "19622000",
  },
  {
    name: "ROVIRA ",
    state_code: "TO",
    code: "73624000",
  },
  {
    name: "SABANA DE TORRES",
    state_code: "SN",
    code: "68655000",
  },
  {
    name: "SABANAGRANDE",
    state_code: "AT",
    code: "08634000",
  },
  {
    name: "SABANALARGA ",
    state_code: "AN",
    code: "05628000",
  },
  {
    name: "SABANALARGA ",
    state_code: "AT",
    code: "08638000",
  },
  {
    name: "SABANALARGA ",
    state_code: "CS",
    code: "85300000",
  },
  {
    name: "SABANAS DE SAN ANGEL",
    state_code: "MA",
    code: "47660000",
  },
  {
    name: "SABANETA ",
    state_code: "AN",
    code: "05631000",
  },
  {
    name: "SABOYA",
    state_code: "BY",
    code: "15632000",
  },
  {
    name: "SACAMA",
    state_code: "CS",
    code: "85315000",
  },
  {
    name: "SACHICA",
    state_code: "BY",
    code: "15638000",
  },
  {
    name: "SAHAGUN",
    state_code: "CO",
    code: "23660000",
  },
  {
    name: "SALADOBLANCO",
    state_code: "HU",
    code: "41660000",
  },
  {
    name: "SALAMINA",
    state_code: "MA",
    code: "17653000",
  },
  {
    name: "SALAMINA",
    state_code: "CL",
    code: "17653000",
  },
  {
    name: "SALAZAR ",
    state_code: "NS",
    code: "54660000",
  },
  {
    name: "SALDANA",
    state_code: "TO",
    code: "73671000",
  },
  {
    name: "SALENTO",
    state_code: "QU",
    code: "63690000",
  },
  {
    name: "SALGAR ",
    state_code: "AN",
    code: "05642000",
  },
  {
    name: "SAMACA",
    state_code: "BY",
    code: "15646000",
  },
  {
    name: "SAMANA",
    state_code: "CL",
    code: "17662000",
  },
  {
    name: "SAMANIEGO",
    state_code: "NA",
    code: "52678000",
  },
  {
    name: "SAMPUES",
    state_code: "SU",
    code: "70670000",
  },
  {
    name: "SAN AGUSTIN ",
    state_code: "HU",
    code: "41668000",
  },
  {
    name: "SAN ALBERTO ",
    state_code: "CE",
    code: "20710000",
  },
  {
    name: "SAN ANDRES",
    state_code: "SA",
    code: "88001000",
  },
  {
    name: "SAN ANDRES ",
    state_code: "SN",
    code: "68669000",
  },
  {
    name: "SAN ANDRES DE CUERQUIA",
    state_code: "AN",
    code: "05647000",
  },
  {
    name: "SAN ANDRES DE SOTAVENTO",
    state_code: "CO",
    code: "23670000",
  },
  {
    name: "SAN ANDRES DE TUMACO",
    state_code: "NA",
    code: "52835000",
  },
  {
    name: "SAN ANTERO",
    state_code: "CO",
    code: "23672000",
  },
  {
    name: "SAN ANTONIO ",
    state_code: "TO",
    code: "73675000",
  },
  {
    name: "SAN ANTONIO DEL TEQUENDAMA",
    state_code: "CN",
    code: "25645000",
  },
  {
    name: "SAN BENITO ",
    state_code: "SN",
    code: "68673000",
  },
  {
    name: "SAN BENITO ABAD",
    state_code: "SU",
    code: "70678000",
  },
  {
    name: "SAN BERNARDO ",
    state_code: "CN",
    code: "25649000",
  },
  {
    name: "SAN BERNARDO ",
    state_code: "NA",
    code: "52685000",
  },
  {
    name: "SAN CALIXTO ",
    state_code: "NS",
    code: "54670000",
  },
  {
    name: "SAN CARLOS ",
    state_code: "CO",
    code: "23678000",
  },
  {
    name: "SAN CARLOS ",
    state_code: "AN",
    code: "05649000",
  },
  {
    name: "SAN CARLOS DE GUAROA",
    state_code: "ME",
    code: "50680000",
  },
  {
    name: "SAN CAYETANO ",
    state_code: "CN",
    code: "25653000",
  },
  {
    name: "SAN CAYETANO ",
    state_code: "NS",
    code: "54673000",
  },
  {
    name: "SAN CRISTOBAL",
    state_code: "BL",
    code: "13620000",
  },
  {
    name: "SAN DIEGO ",
    state_code: "CE",
    code: "20750000",
  },
  {
    name: "SAN EDUARDO",
    state_code: "BY",
    code: "15660000",
  },
  {
    name: "SAN ESTANISLAO",
    state_code: "BL",
    code: "13647000",
  },
  {
    name: "SAN FELIPE",
    state_code: "GU",
    code: "94883000",
  },
  {
    name: "SAN FERNANDO",
    state_code: "BL",
    code: "13650000",
  },
  {
    name: "SAN FRANCISCO ",
    state_code: "AN",
    code: "05209004",
  },
  {
    name: "SAN FRANCISCO ",
    state_code: "CN",
    code: "25658000",
  },
  {
    name: "SAN FRANCISCO",
    state_code: "PU",
    code: "86755000",
  },
  {
    name: "SAN GIL",
    state_code: "SN",
    code: "68679000",
  },
  {
    name: "SAN JACINTO",
    state_code: "BL",
    code: "13654000",
  },
  {
    name: "SAN JACINTO DEL CAUCA",
    state_code: "BL",
    code: "13655000",
  },
  {
    name: "SAN JERONIMO ",
    state_code: "AN",
    code: "05656000",
  },
  {
    name: "SAN JOAQUIN",
    state_code: "SN",
    code: "68682000",
  },
  {
    name: "SAN JOSE",
    state_code: "CL",
    code: "17665000",
  },
  {
    name: "SAN JOSE DE LA MONTANA",
    state_code: "AN",
    code: "05658000",
  },
  {
    name: "SAN JOSE DE MIRANDA",
    state_code: "SN",
    code: "68684000",
  },
  {
    name: "SAN JOSE DE PARE",
    state_code: "BY",
    code: "15664000",
  },
  {
    name: "SAN JOSE DEL FRAGUA",
    state_code: "CA",
    code: "18610000",
  },
  {
    name: "SAN JOSE DEL GUAVIARE",
    state_code: "GA",
    code: "95001000",
  },
  {
    name: "SAN JOSE DEL PALMAR",
    state_code: "CH",
    code: "27660000",
  },
  {
    name: "SAN JUAN DE ARAMA",
    state_code: "ME",
    code: "50683000",
  },
  {
    name: "SAN JUAN DE BETULIA",
    state_code: "SU",
    code: "70702000",
  },
  {
    name: "SAN JUAN DE RIO SECO",
    state_code: "CN",
    code: "25662000",
  },
  {
    name: "SAN JUAN DE URABA",
    state_code: "AN",
    code: "05659000",
  },
  {
    name: "SAN JUAN DEL CESAR",
    state_code: "LG",
    code: "44650000",
  },
  {
    name: "SAN JUAN NEPOMUCENO",
    state_code: "BL",
    code: "13657000",
  },
  {
    name: "SAN JUANITO ",
    state_code: "ME",
    code: "50686000",
  },
  {
    name: "SAN LORENZO ",
    state_code: "NA",
    code: "52687000",
  },
  {
    name: "SAN LUIS ",
    state_code: "AN",
    code: "05660000",
  },
  {
    name: "SAN LUIS ",
    state_code: "TO",
    code: "73678000",
  },
  {
    name: "SAN LUIS DE GACENO",
    state_code: "BY",
    code: "15667000",
  },
  {
    name: "SAN LUIS DE PALENQUE",
    state_code: "CS",
    code: "85325000",
  },
  {
    name: "SAN LUIS DE SINCE",
    state_code: "SU",
    code: "70742000",
  },
  {
    name: "SAN MARCOS ",
    state_code: "SU",
    code: "70708000",
  },
  {
    name: "SAN MARTIN ",
    state_code: "CE",
    code: "20770000",
  },
  {
    name: "SAN MARTIN ",
    state_code: "ME",
    code: "50689000",
  },
  {
    name: "SAN MARTIN DE LOBA",
    state_code: "BL",
    code: "13667000",
  },
  {
    name: "SAN MATEO",
    state_code: "BY",
    code: "15673000",
  },
  {
    name: "SAN MIGUEL",
    state_code: "SN",
    code: "68686000",
  },
  {
    name: "SAN MIGUEL",
    state_code: "PU",
    code: "86757001",
  },
  {
    name: "SAN MIGUEL DE SEMA",
    state_code: "BY",
    code: "15676000",
  },
  {
    name: "SAN ONOFRE",
    state_code: "SU",
    code: "70713000",
  },
  {
    name: "SAN PABLO ",
    state_code: "NA",
    code: "52693000",
  },
  {
    name: "SAN PABLO",
    state_code: "BL",
    code: "13670000",
  },
  {
    name: "SAN PABLO DE BORBUR",
    state_code: "BY",
    code: "15681000",
  },
  {
    name: "SAN PEDRO  ",
    state_code: "AN",
    code: "05664000",
  },
  {
    name: "SAN PEDRO",
    state_code: "SU",
    code: "70717000",
  },
  {
    name: "SAN PEDRO ",
    state_code: "VC",
    code: "76403007",
  },
  {
    name: "SAN PEDRO DE CARTAGO",
    state_code: "NA",
    code: "52694000",
  },
  {
    name: "SAN PEDRO DE URABA",
    state_code: "AN",
    code: "05665000",
  },
  {
    name: "SAN PELAYO",
    state_code: "CO",
    code: "23686000",
  },
  {
    name: "SAN RAFAEL ",
    state_code: "AN",
    code: "05667000",
  },
  {
    name: "SAN ROQUE ",
    state_code: "AN",
    code: "05670000",
  },
  {
    name: "SAN SEBASTIAN ",
    state_code: "CU",
    code: "19693000",
  },
  {
    name: "SAN SEBASTIAN DE BUENAVISTA",
    state_code: "MA",
    code: "47692000",
  },
  {
    name: "SAN VICENTE",
    state_code: "AN",
    code: "05674000",
  },
  {
    name: "SAN VICENTE DE CHUCURI",
    state_code: "SN",
    code: "68689000",
  },
  {
    name: "SAN VICENTE DEL CAGUAN",
    state_code: "CA",
    code: "18753000",
  },
  {
    name: "SAN ZENON",
    state_code: "MA",
    code: "47703000",
  },
  {
    name: "SANDONA",
    state_code: "NA",
    code: "52683000",
  },
  {
    name: "SANTA ANA",
    state_code: "MA",
    code: "47707000",
  },
  {
    name: "SANTA BARBARA ",
    state_code: "NA",
    code: "52683004",
  },
  {
    name: "SANTA BARBARA ",
    state_code: "AN",
    code: "05679000",
  },
  {
    name: "SANTA BARBARA",
    state_code: "SN",
    code: "68705000",
  },
  {
    name: "SANTA BARBARA DE PINTO",
    state_code: "MA",
    code: "47720000",
  },
  {
    name: "SANTA CATALINA",
    state_code: "BL",
    code: "13673000",
  },
  {
    name: "SANTA ELENA",
    state_code: "VC",
    code: "76248009",
  },
  {
    name: "SANTA FE DE ANTIOQUIA",
    state_code: "AN",
    code: "05042000",
  },
  {
    name: "SANTA HELENA DEL OPON",
    state_code: "SN",
    code: "68720000",
  },
  {
    name: "SANTA ISABEL ",
    state_code: "TO",
    code: "73686000",
  },
  {
    name: "SANTA LUCIA",
    state_code: "AT",
    code: "08675000",
  },
  {
    name: "SANTA MARIA",
    state_code: "BY",
    code: "15690000",
  },
  {
    name: "SANTA MARIA ",
    state_code: "HU",
    code: "41676000",
  },
  {
    name: "SANTA MARTA",
    state_code: "MA",
    code: "47001000",
  },
  {
    name: "SANTA ROSA ",
    state_code: "CU",
    code: "19701000",
  },
  {
    name: "SANTA ROSA",
    state_code: "BL",
    code: "13683000",
  },
  {
    name: "SANTA ROSA DE CABAL",
    state_code: "RI",
    code: "66682000",
  },
  {
    name: "SANTA ROSA DE OSOS",
    state_code: "AN",
    code: "05686000",
  },
  {
    name: "SANTA ROSA DE VITERBO",
    state_code: "BY",
    code: "15693000",
  },
  {
    name: "SANTA ROSA DEL SUR",
    state_code: "BL",
    code: "13688000",
  },
  {
    name: "SANTA ROSALIA",
    state_code: "VI",
    code: "99624000",
  },
  {
    name: "SANTA SOFIA",
    state_code: "BY",
    code: "15696000",
  },
  {
    name: "SANTACRUZ",
    state_code: "NA",
    code: "52699000",
  },
  {
    name: "SANTAFE DE ANTIOQUIA",
    state_code: "AN",
    code: "05042000",
  },
  {
    name: "SANTANA",
    state_code: "BY",
    code: "15686000",
  },
  {
    name: "SANTANDER DE QUILICHAO",
    state_code: "CU",
    code: "19698000",
  },
  {
    name: "SANTIAGO",
    state_code: "NS",
    code: "54680000",
  },
  {
    name: "SANTIAGO",
    state_code: "PU",
    code: "86760000",
  },
  {
    name: "SANTIAGO DE TOLU",
    state_code: "SU",
    code: "70820000",
  },
  {
    name: "SANTO DOMINGO ",
    state_code: "AN",
    code: "05040004",
  },
  {
    name: "SANTO TOMAS",
    state_code: "AT",
    code: "08685000",
  },
  {
    name: "SANTUARIO ",
    state_code: "RI",
    code: "66687000",
  },
  {
    name: "SAPUYES",
    state_code: "NA",
    code: "52720000",
  },
  {
    name: "SARAVENA",
    state_code: "AR",
    code: "81736000",
  },
  {
    name: "SARDINATA ",
    state_code: "NS",
    code: "54720000",
  },
  {
    name: "SASAIMA",
    state_code: "CN",
    code: "25718000",
  },
  {
    name: "SATIVANORTE",
    state_code: "BY",
    code: "15720000",
  },
  {
    name: "SATIVASUR",
    state_code: "BY",
    code: "15723000",
  },
  {
    name: "SEGOVIA ",
    state_code: "AN",
    code: "05736000",
  },
  {
    name: "SESQUILE",
    state_code: "CN",
    code: "25736000",
  },
  {
    name: "SEVILLA",
    state_code: "VC",
    code: "76736000",
  },
  {
    name: "SIACHOQUE",
    state_code: "BY",
    code: "15740000",
  },
  {
    name: "SIBATE",
    state_code: "CN",
    code: "25740000",
  },
  {
    name: "SIBUNDOY",
    state_code: "PU",
    code: "86749000",
  },
  {
    name: "SILOS",
    state_code: "NS",
    code: "54743000",
  },
  {
    name: "SILVANIA",
    state_code: "CN",
    code: "25743000",
  },
  {
    name: "SILVIA",
    state_code: "CU",
    code: "19743000",
  },
  {
    name: "SIMACOTA",
    state_code: "SN",
    code: "68745000",
  },
  {
    name: "SIMIJACA",
    state_code: "CN",
    code: "25745000",
  },
  {
    name: "SIMITI",
    state_code: "BL",
    code: "13744000",
  },
  {
    name: "SINCELEJO",
    state_code: "SU",
    code: "70001000",
  },
  {
    name: "SIPI",
    state_code: "CH",
    code: "27745000",
  },
  {
    name: "SITIONUEVO",
    state_code: "MA",
    code: "47745000",
  },
  {
    name: "SOACHA",
    state_code: "CN",
    code: "25754000",
  },
  {
    name: "SOATA",
    state_code: "BY",
    code: "15753000",
  },
  {
    name: "SOCHA",
    state_code: "BY",
    code: "15757000",
  },
  {
    name: "SOCORRO",
    state_code: "SN",
    code: "68755000",
  },
  {
    name: "SOCOTA",
    state_code: "BY",
    code: "15755000",
  },
  {
    name: "SOGAMOSO",
    state_code: "BY",
    code: "15759000",
  },
  {
    name: "SOLANO",
    state_code: "CA",
    code: "18756000",
  },
  {
    name: "SOLEDAD ",
    state_code: "AT",
    code: "08758000",
  },
  {
    name: "SOLITA",
    state_code: "CA",
    code: "18785000",
  },
  {
    name: "SOMONDOCO",
    state_code: "BY",
    code: "15761000",
  },
  {
    name: "SONSON",
    state_code: "AN",
    code: "05756000",
  },
  {
    name: "SOPETRAN",
    state_code: "AN",
    code: "05761000",
  },
  {
    name: "SOPLAVIENTO",
    state_code: "BL",
    code: "13760000",
  },
  {
    name: "SOPO",
    state_code: "CN",
    code: "25758000",
  },
  {
    name: "SORA",
    state_code: "BY",
    code: "15762000",
  },
  {
    name: "SORACA",
    state_code: "BY",
    code: "15764000",
  },
  {
    name: "SOTAQUIRA",
    state_code: "BY",
    code: "15763000",
  },
  {
    name: "SOTARA",
    state_code: "CU",
    code: "19760000",
  },
  {
    name: "SUAITA",
    state_code: "SN",
    code: "68770000",
  },
  {
    name: "SUAN",
    state_code: "AT",
    code: "08770000",
  },
  {
    name: "SUAREZ",
    state_code: "CU",
    code: "19780000",
  },
  {
    name: "SUAREZ",
    state_code: "TO",
    code: "73770000",
  },
  {
    name: "SUAZA",
    state_code: "HU",
    code: "41770000",
  },
  {
    name: "SUBACHOQUE",
    state_code: "CN",
    code: "25769000",
  },
  {
    name: "SUCRE ",
    state_code: "CU",
    code: "19785000",
  },
  {
    name: "SUCRE ",
    state_code: "SN",
    code: "68773000",
  },
  {
    name: "SUCRE ",
    state_code: "SU",
    code: "70771000",
  },
  {
    name: "SUESCA",
    state_code: "CN",
    code: "25772000",
  },
  {
    name: "SUPATA",
    state_code: "CN",
    code: "25777000",
  },
  {
    name: "SUPIA",
    state_code: "CL",
    code: "17777000",
  },
  {
    name: "SURATA",
    state_code: "SN",
    code: "68780000",
  },
  {
    name: "SUSA",
    state_code: "CN",
    code: "25779000",
  },
  {
    name: "SUSACON",
    state_code: "BY",
    code: "15774000",
  },
  {
    name: "SUTAMARCHAN",
    state_code: "BY",
    code: "15776000",
  },
  {
    name: "SUTATAUSA",
    state_code: "CN",
    code: "25781000",
  },
  {
    name: "SUTATENZA",
    state_code: "BY",
    code: "15778000",
  },
  {
    name: "TABIO",
    state_code: "CN",
    code: "25785000",
  },
  {
    name: "TADO",
    state_code: "CH",
    code: "27787000",
  },
  {
    name: "TALAIGUA NUEVO",
    state_code: "BL",
    code: "13780000",
  },
  {
    name: "TAMALAMEQUE",
    state_code: "CE",
    code: "20787000",
  },
  {
    name: "TAMARA",
    state_code: "CS",
    code: "85400000",
  },
  {
    name: "TAME",
    state_code: "AR",
    code: "81794000",
  },
  {
    name: "TAMESIS",
    state_code: "AN",
    code: "05789000",
  },
  {
    name: "TAMINANGO",
    state_code: "NA",
    code: "52786000",
  },
  {
    name: "TANGUA",
    state_code: "NA",
    code: "52788000",
  },
  {
    name: "TARAIRA",
    state_code: "VA",
    code: "97666000",
  },
  {
    name: "TARAPACA",
    state_code: "AM",
    code: "91798000",
  },
  {
    name: "TARAZA",
    state_code: "AN",
    code: "05790000",
  },
  {
    name: "TARQUI",
    state_code: "HU",
    code: "41791000",
  },
  {
    name: "TARSO",
    state_code: "AN",
    code: "05792000",
  },
  {
    name: "TASCO",
    state_code: "BY",
    code: "15790000",
  },
  {
    name: "TAURAMENA",
    state_code: "CS",
    code: "85410000",
  },
  {
    name: "TAUSA",
    state_code: "CN",
    code: "25793000",
  },
  {
    name: "TELLO",
    state_code: "HU",
    code: "41799000",
  },
  {
    name: "TENA",
    state_code: "CN",
    code: "25797000",
  },
  {
    name: "TENERIFE",
    state_code: "MA",
    code: "47798000",
  },
  {
    name: "TENJO",
    state_code: "CN",
    code: "25799000",
  },
  {
    name: "TENZA",
    state_code: "BY",
    code: "15798000",
  },
  {
    name: "TEORAMA",
    state_code: "NS",
    code: "54800000",
  },
  {
    name: "TERUEL",
    state_code: "HU",
    code: "41801000",
  },
  {
    name: "TESALIA",
    state_code: "HU",
    code: "41797000",
  },
  {
    name: "TIBACUY",
    state_code: "CN",
    code: "25805000",
  },
  {
    name: "TIBANA",
    state_code: "BY",
    code: "15804000",
  },
  {
    name: "TIBASOSA",
    state_code: "BY",
    code: "15806000",
  },
  {
    name: "TIBIRITA",
    state_code: "CN",
    code: "25807000",
  },
  {
    name: "TIBU",
    state_code: "NS",
    code: "54810000",
  },
  {
    name: "TIERRALTA",
    state_code: "CO",
    code: "23807000",
  },
  {
    name: "TIMANA",
    state_code: "HU",
    code: "41807000",
  },
  {
    name: "TIMBIO",
    state_code: "CU",
    code: "19807000",
  },
  {
    name: "TIMBIQUI",
    state_code: "CU",
    code: "19809000",
  },
  {
    name: "TINJACA",
    state_code: "BY",
    code: "15808000",
  },
  {
    name: "TIPACOQUE",
    state_code: "BY",
    code: "15810000",
  },
  {
    name: "TIQUISIO",
    state_code: "BL",
    code: "13810000",
  },
  {
    name: "TITIRIBI",
    state_code: "AN",
    code: "05809000",
  },
  {
    name: "TOCA",
    state_code: "BY",
    code: "15814000",
  },
  {
    name: "TOCAIMA",
    state_code: "CN",
    code: "25815000",
  },
  {
    name: "TOCANCIPA",
    state_code: "CN",
    code: "25817000",
  },
  {
    name: "TOGUI",
    state_code: "BY",
    code: "15816000",
  },
  {
    name: "TOLU VIEJO",
    state_code: "SU",
    code: "70823000",
  },
  {
    name: "TONA",
    state_code: "SN",
    code: "68820000",
  },
  {
    name: "TOPAGA",
    state_code: "BY",
    code: "15820000",
  },
  {
    name: "TOPAIPI",
    state_code: "CN",
    code: "25823000",
  },
  {
    name: "TORIBIO",
    state_code: "CU",
    code: "19821000",
  },
  {
    name: "TORO",
    state_code: "VC",
    code: "76823000",
  },
  {
    name: "TOTA",
    state_code: "BY",
    code: "15822000",
  },
  {
    name: "TOTORO",
    state_code: "CU",
    code: "19824000",
  },
  {
    name: "TRINIDAD",
    state_code: "CS",
    code: "84430000",
  },
  {
    name: "TRUJILLO",
    state_code: "VC",
    code: "76828000",
  },
  {
    name: "TUBARA",
    state_code: "AT",
    code: "08832000",
  },
  {
    name: "TUCHIN",
    state_code: "CO",
    code: "23670011",
  },
  {
    name: "TULUA",
    state_code: "VC",
    code: "76834000",
  },
  {
    name: "TUMACO",
    state_code: "NA",
    code: "52835000",
  },
  {
    name: "TUNJA",
    state_code: "BY",
    code: "15001000",
  },
  {
    name: "TUNUNGUA",
    state_code: "BY",
    code: "15832000",
  },
  {
    name: "TUQUERRES",
    state_code: "NA",
    code: "52838000",
  },
  {
    name: "TURBACO",
    state_code: "BL",
    code: "13836000",
  },
  {
    name: "TURBANA",
    state_code: "BL",
    code: "13838000",
  },
  {
    name: "TURBO",
    state_code: "AN",
    code: "05837000",
  },
  {
    name: "TURMEQUE",
    state_code: "BY",
    code: "15835000",
  },
  {
    name: "TUTA",
    state_code: "BY",
    code: "15837000",
  },
  {
    name: "TUTAZA",
    state_code: "BY",
    code: "15839000",
  },
  {
    name: "UBALA",
    state_code: "CN",
    code: "25839000",
  },
  {
    name: "UBAQUE",
    state_code: "CN",
    code: "25841000",
  },
  {
    name: "UBATE",
    state_code: "CN",
    code: "25843000",
  },
  {
    name: "ULLOA",
    state_code: "VC",
    code: "76845000",
  },
  {
    name: "UMBITA",
    state_code: "BY",
    code: "15842000",
  },
  {
    name: "UNE",
    state_code: "CN",
    code: "25845000",
  },
  {
    name: "UNGUIA",
    state_code: "CH",
    code: "27800000",
  },
  {
    name: "UNION PANAMERICANA",
    state_code: "CH",
    code: "27810000",
  },
  {
    name: "URAMITA",
    state_code: "AN",
    code: "05842000",
  },
  {
    name: "URIBE",
    state_code: "ME",
    code: "50370000",
  },
  {
    name: "URIBIA",
    state_code: "LG",
    code: "44847000",
  },
  {
    name: "URRAO",
    state_code: "AN",
    code: "05847000",
  },
  {
    name: "URUMITA",
    state_code: "LG",
    code: "44855000",
  },
  {
    name: "USIACURI",
    state_code: "AT",
    code: "08849000",
  },
  {
    name: "UTICA",
    state_code: "CN",
    code: "25851000",
  },
  {
    name: "VALDIVIA",
    state_code: "AN",
    code: "05854000",
  },
  {
    name: "VALENCIA ",
    state_code: "CO",
    code: "23855000",
  },
  {
    name: "VALLE DE SAN JOSE",
    state_code: "SN",
    code: "68855000",
  },
  {
    name: "VALLE DE SAN JUAN",
    state_code: "TO",
    code: "73854000",
  },
  {
    name: "VALLE DEL GUAMUEZ",
    state_code: "PU",
    code: "86865000",
  },
  {
    name: "VALLEDUPAR",
    state_code: "CE",
    code: "20001000",
  },
  {
    name: "VALPARAISO ",
    state_code: "AN",
    code: "05856000",
  },
  {
    name: "VALPARAISO ",
    state_code: "CA",
    code: "18860000",
  },
  {
    name: "VEGACHI",
    state_code: "AN",
    code: "05858000",
  },
  {
    name: "VELEZ",
    state_code: "SN",
    code: "68861000",
  },
  {
    name: "VENADILLO ",
    state_code: "TO",
    code: "73861000",
  },
  {
    name: "VENECIA ",
    state_code: "AN",
    code: "05861000",
  },
  {
    name: "VENECIA ",
    state_code: "CN",
    code: "25518004",
  },
  {
    name: "VENTAQUEMADA",
    state_code: "BY",
    code: "15861000",
  },
  {
    name: "VERGARA",
    state_code: "CN",
    code: "25862000",
  },
  {
    name: "VERSALLES ",
    state_code: "VC",
    code: "76863000",
  },
  {
    name: "VETAS",
    state_code: "SN",
    code: "68867000",
  },
  {
    name: "VIANI",
    state_code: "CN",
    code: "25867000",
  },
  {
    name: "VICTORIA",
    state_code: "CL",
    code: "17867000",
  },
  {
    name: "VIGIA DEL FUERTE",
    state_code: "AN",
    code: "05873000",
  },
  {
    name: "VIJES",
    state_code: "VC",
    code: "76869000",
  },
  {
    name: "VILLA CARO",
    state_code: "NS",
    code: "54871000",
  },
  {
    name: "VILLA DE LEYVA",
    state_code: "BY",
    code: "15407000",
  },
  {
    name: "VILLA DEL ROSARIO",
    state_code: "NS",
    code: "54874000",
  },
  {
    name: "VILLA RICA ",
    state_code: "CU",
    code: "19845000",
  },
  {
    name: "VILLAGARZON",
    state_code: "PU",
    code: "86885000",
  },
  {
    name: "VILLAGOMEZ",
    state_code: "CN",
    code: "25871000",
  },
  {
    name: "VILLAHERMOSA ",
    state_code: "TO",
    code: "73870000",
  },
  {
    name: "VILLAMARIA",
    state_code: "CL",
    code: "17873000",
  },
  {
    name: "VILLANUEVA",
    state_code: "BL",
    code: "13873000",
  },
  {
    name: "VILLANUEVA",
    state_code: "SN",
    code: "68872000",
  },
  {
    name: "VILLANUEVA ",
    state_code: "CS",
    code: "85440000",
  },
  {
    name: "VILLANUEVA ",
    state_code: "LG",
    code: "44874000",
  },
  {
    name: "VILLAPINZON",
    state_code: "CN",
    code: "25873000",
  },
  {
    name: "VILLARRICA ",
    state_code: "TO",
    code: "73873000",
  },
  {
    name: "VILLAVICENCIO",
    state_code: "ME",
    code: "50001000",
  },
  {
    name: "VILLAVIEJA",
    state_code: "HU",
    code: "41872000",
  },
  {
    name: "VILLETA",
    state_code: "CN",
    code: "25875000",
  },
  {
    name: "VIOTA",
    state_code: "CN",
    code: "25878000",
  },
  {
    name: "VIRACACHA",
    state_code: "BY",
    code: "15879000",
  },
  {
    name: "VISTA HERMOSA ",
    state_code: "ME",
    code: "50711000",
  },
  {
    name: "VITERBO",
    state_code: "CL",
    code: "17877000",
  },
  {
    name: "YACOPI",
    state_code: "CN",
    code: "25885000",
  },
  {
    name: "YACUANQUER",
    state_code: "NA",
    code: "52885000",
  },
  {
    name: "YAGUARA",
    state_code: "HU",
    code: "41885000",
  },
  {
    name: "YALI",
    state_code: "AN",
    code: "05885000",
  },
  {
    name: "YARUMAL ",
    state_code: "AN",
    code: "05887000",
  },
  {
    name: "YAVARATE",
    state_code: "VA",
    code: "97889000",
  },
  {
    name: "YOLOMBO",
    state_code: "AN",
    code: "05890000",
  },
  {
    name: "YONDO",
    state_code: "AN",
    code: "05893000",
  },
  {
    name: "YOPAL",
    state_code: "CS",
    code: "85001000",
  },
  {
    name: "YOTOCO",
    state_code: "VC",
    code: "76890000",
  },
  {
    name: "YUMBO",
    state_code: "VC",
    code: "76892000",
  },
  {
    name: "ZAMBRANO",
    state_code: "BL",
    code: "13894000",
  },
  {
    name: "ZAPATOCA",
    state_code: "SN",
    code: "68895000",
  },
  {
    name: "ZAPAYAN",
    state_code: "MA",
    code: "47960000",
  },
  {
    name: "ZARAGOZA ",
    state_code: "AN",
    code: "05895000",
  },
  {
    name: "ZARZAL",
    state_code: "VC",
    code: "76895000",
  },
  {
    name: "ZETAQUIRA",
    state_code: "BY",
    code: "15897000",
  },
  {
    name: "ZIPACON",
    state_code: "CN",
    code: "25898000",
  },
  {
    name: "ZIPAQUIRA",
    state_code: "CN",
    code: "25899000",
  },
  {
    name: "ZONA BANANERA",
    state_code: "MA",
    code: "47980000",
  },
];
