import { yupResolver } from "@hookform/resolvers";
import { amber, green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  decorativeFormSchema,
  validateFile,
} from "../../services/formValidation";
import { materialTableES } from "../../services/materialTableES";

import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import MaterialTable from "material-table";
import Button from "../../components/Button/Button.js";
import Modal from "../../components/Modal/Modal";

import { Select } from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import styled from "styled-components";
import { successToast } from "../../services/toasts";
import unitsOfMeasure from "../../services/unitsOfMeasure.js";
import { handleError } from "../../utils/utils";

function isApprovedIcons(field) {
  switch (field) {
    // TODO: fix null case
    case true:
      return (
        <CheckCircleIcon style={{ color: green[500] }} titleAccess="Aprobado" />
      );
    case false:
      return <CancelIcon style={{ color: red[500] }} titleAccess="Declinado" />;
    default:
      return (
        <HourglassEmptyIcon
          style={{ color: amber[500] }}
          titleAccess="En proceso..."
        />
      );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  textField: {
    width: "25ch",
  },
  mediumWidth: {
    width: "32ch!important",
  },
  margin: {
    margin: theme.spacing(1),
  },
  icons: {
    color: "#232f3e",
    fontSize: "1.5rem",
    margin: "5px 10px 5px 5px",
  },
  heading: {
    color: "#232f3e",
  },
  fileInputLabel: {
    marginBottom: "0.5rem",
  },
}));

function SupplierDecoratives() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const format = /^([1-9]\d*|0)$/;

  useEffect(() => {
    getDecoratives();
  }, []);

  const getDecoratives = async () => {
    let res = await axios.get("/v1/suppliers/products?type=decorativo");
    setData(res.data);
  };

  const handleClose = () => {
    setOpen(false);
    getDecoratives();
  };

  return (
    <div>
      <Modal
        contentPadding={"2rem"}
        closeModal={handleClose}
        showModal={open}
        contentMaxWidth="auto"
      >
        <AddDecorative handleClose={handleClose} />
      </Modal>
      <MaterialTable
        title={"Decorativos"}
        columns={[
          {
            field: "image",
            editable: "never",
            render: (rowData) => (
              <img
                alt="producto"
                src={rowData.image}
                style={{
                  width: 40,
                  height: 40,
                  objectFit: "cover",
                  borderRadius: "100%",
                }}
              />
            ),
            cellStyle: {
              width: 20,
              maxWidth: 20,
            },
            headerStyle: {
              width: 20,
              maxWidth: 20,
            },
          },
          {
            title: "Estado",
            field: "is_approved",
            editable: "never",
            render: function (rowData) {
              return isApprovedIcons(rowData.is_approved);
            },
            cellStyle: {
              width: 20,
              maxWidth: 20,
            },
            headerStyle: {
              width: 20,
              maxWidth: 20,
            },
          },
          {
            title: "Marca",
            field: "brand",
            editable: "never",
          },
          {
            title: "Referencia",
            field: "name",
            editable: "never",
          },

          {
            title: "MUV",
            field: "min_sale_unit",
            editable: "never",
          },
          {
            /*TODO: the local argument "de-DE" on NumberFormat was the only one that allows the validations 
            with not points or commas and render it correctly, in the future would be great that the column type 
            were currency for make validations with points or commas. 
            Reference Link: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat */
            title: "Precio",
            field: "price",
            type: "float",
            render: (rowData) => {
              return (
                new Intl.NumberFormat("de-DE").format(rowData.price) + " COP"
              );
            },
            // TODO: Set up a validation without affecting the other editable fields
            // validate: (rowData) => {
            //   if (!format.test(rowData.price)) {
            //     return "Por favor ingrese el precio sin puntos ni comas";
            //   } else {
            //     return true;
            //   }
            // },
          },
          {
            title: "Inventario",
            field: "units_available",
          },
          {
            title: "Comisión",
            field: "comission_percentage",
            lookup: {
              20: "20%",
              25: "25%",
              30: "30%",
              35: "35%",
              40: "40%",
              45: "45%",
              50: "50%",
              55: "55%",
              60: "60%",
            },
          },
        ]}
        data={data}
        actions={[
          {
            icon: "add",
            tooltip: "Añadir decorativo",
            isFreeAction: true,
            onClick: (event, rowData) => {
              setOpen(true);
            },
          },
        ]}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            let editableFields = {
              price: newData.price,
              units_available: newData.units_available,
              comission_percentage: newData.comission_percentage,
            };
            await axios
              .patch(`/v1/products/${oldData.id}`, editableFields)
              .then((res) => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
              })
              .catch((error) => {
                handleError(error);
              });
          },
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                axios
                  .delete(`/v1/products/${oldData.id}`)
                  .then((res) => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                  })
                  .catch((error) => {
                    handleError(error);
                  });
                resolve();
              }, 1000);
            }),
        }}
        loading={data ? false : true}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
        }}
        localization={materialTableES}
      />
    </div>
  );
}

// TODO: show dimensions checkbox
function AddDecorative({ handleClose }) {
  const classes = useStyles();
  const [brands, setBrands] = useState();
  const [categories, setCategories] = useState();
  const [files, setFiles] = useState({
    productImage: null,
    technicalSheet: null,
    ambientImages: [],
  });
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setError, clearErrors, control } =
    useForm({
      resolver: yupResolver(decorativeFormSchema),
    });
  let comissionValues = createComissionValues(20, 65);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (data) => {
    let brands = await axios.get("/v1/suppliers/brands?type=decorativo");
    let categories = await axios.get("/v1/products/categories");
    // let categories = await axios.get("/v1/suppliers/brands");
    setBrands(brands.data);
    setCategories(categories.data);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      // Validate mandatory files
      if (!files.productImage) {
        setError("product_image", {
          type: "manual",
          message: "Por favor carga una imagen para el producto",
        });
      } else if (!files.technicalSheet) {
        setError("technical_sheet", {
          type: "manual",
          message: "Por favor carga la ficha técnica del producto",
        });
      } else if (files.ambientImages < 1 || files.ambientImages.length > 3) {
        setError("technical_sheet", {
          type: "manual",
          message:
            "Por favor carga máximo 3 imágenes de ambientación del producto",
        });
      } else {
        clearErrors();

        // Validate file extensions
        validateFile(files.technicalSheet, /pdf|jpeg|png|jpg/);

        let images = [files.productImage, ...files.ambientImages];
        for (let f of images) {
          validateFile(f, /jpeg|png|jpg/);
        }

        // default decorative to be measured in unit
        data.unit_of_measure_id = unitsOfMeasure.unit;
        // TODO: update migrations so this field can be null
        data.packing_presentation_id = 1;

        let formDataForRequest = new FormData();

        // append product cover image
        formDataForRequest.append("cover", files.productImage);

        // append product technical sheet
        formDataForRequest.append("sheet", files.technicalSheet);

        // append product ambient images
        [...files.ambientImages].forEach((image) => {
          formDataForRequest.append("ambient", image);
        });

        // convert object with designer info to form data
        for (let key in data) {
          formDataForRequest.append(key, data[key]);
        }

        await axios.post("/v1/suppliers/products", formDataForRequest);

        successToast("¡Tu producto ha sido creado exitosamente!");
        handleClose();
      }
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setError("code", {
        type: "manual",
        message: requestError ? requestError : error.message,
      });
    }
    setLoading(false);
    // TODO: close modal when product is added
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginLeft: "0.5rem",
          marginBottom: "0.5rem",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className={classes.heading}>
          Añade un decorativo
        </Typography>
      </div>
      <form
        className={classes.root}
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Row>
          <TextField
            label="Código (opcional)"
            name="code"
            inputRef={register}
            variant="outlined"
          />

          <TextField
            name="supplier_brand_id"
            onChange={(e) =>
              register({ name: "supplier_brand_id", value: e.target.value })
            }
            select
            label="Marca"
            variant="outlined"
          >
            {brands &&
              brands.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </TextField>
          <TextField
            label="Referencia"
            name="name"
            inputRef={register}
            variant="outlined"
          />
          <TextField
            name="product_category_id"
            onChange={(e) =>
              register({ name: "product_category_id", value: e.target.value })
            }
            select
            label="Categoría"
            variant="outlined"
          >
            {categories &&
              categories.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </Row>
        <Row>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            focused
          >
            <InputLabel>Unidad mínima de venta</InputLabel>
            <OutlinedInput
              name="min_sale_unit"
              inputRef={register}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              labelWidth={175}
            />
          </FormControl>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel>Precio sin IVA</InputLabel>
            <OutlinedInput
              name="price"
              inputRef={register}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
          <TextField
            name="comission_percentage"
            onChange={(e) =>
              register({ name: "comission_percentage", value: e.target.value })
            }
            select
            label="Comisión"
            variant="outlined"
          >
            {comissionValues.map((item) => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            name="units_available"
            inputRef={register}
            label="Inventario en unidades"
            variant="outlined"
          />
        </Row>
        <Row>
          <TextField
            className={clsx(classes.margin, classes.textField)}
            name="warranty"
            onChange={(e) =>
              register({ name: "warranty", value: e.target.value })
            }
            label="Garantía"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Select
                  style={{ marginLeft: "37%" }}
                  name="is_warranty_in_months"
                  label="is_warranty_in_months"
                  variant="standard"
                  defaultValue={"Select"}
                  onChange={(e) =>
                    register({
                      name: "is_warranty_in_months",
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"Select"} disabled divider>
                    Seleccionar
                  </MenuItem>
                  <MenuItem value={1}>Meses</MenuItem>
                  <MenuItem value={0}>Años</MenuItem>
                </Select>
              ),
            }}
          />
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            style={{ margin: "0.9%", minWidth: "40%" }}
            variant="outlined"
          >
            <InputLabel>Descripción</InputLabel>
            <OutlinedInput
              name="description"
              inputRef={register}
              labelWidth={90}
            />
          </FormControl>
        </Row>
        {/* TODO: Finish the checkbox that hides or shows the inputs to specify the product dimensions */}
        {/* <Row> */}
        {/* <FormControlLabel */}
        {/*   control={ */}
        {/*     <Checkbox */}
        {/*       icon={<CheckBoxOutlineBlankIcon fontSize="small" />} */}
        {/*       checkedIcon={<CheckBoxIcon fontSize="small" />} */}
        {/*       //checked={state.checkedB} */}
        {/*       //onChange={handleChange} */}
        {/*       name="checkedB" */}
        {/*       color="primary" */}
        {/*     /> */}
        {/*   } */}
        {/*   label="Especificar dimensiones" */}
        {/* /> */}
        {/* </Row> */}
        <div
          style={{
            display: "flex",
            marginLeft: "0.5rem",
            marginTop: "0.5rem",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" className={classes.heading}>
            Completa las dimensiones del producto.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "0.5rem",
            marginLeft: "0.5rem",
            alignItems: "center",
          }}
        >
          <span>
            (*) Por favor solo ingresar metros como unidad de medida. Realizar
            conversión a metros si es necesario.
          </span>
        </div>
        <Row>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel>Largo (*)</InputLabel>
            <OutlinedInput
              name="length"
              inputRef={register}
              endAdornment={<InputAdornment position="end">mts</InputAdornment>}
              labelWidth={65}
            />
          </FormControl>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel>Ancho (*)</InputLabel>
            <OutlinedInput
              name="width"
              inputRef={register}
              endAdornment={<InputAdornment position="end">mts</InputAdornment>}
              labelWidth={68}
            />
          </FormControl>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel>Profundidad (*)</InputLabel>
            <OutlinedInput
              name="depth"
              inputRef={register}
              endAdornment={<InputAdornment position="end">mts</InputAdornment>}
              labelWidth={110}
            />
          </FormControl>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel>Altura (*)</InputLabel>
            <OutlinedInput
              name="height"
              inputRef={register}
              endAdornment={<InputAdornment position="end">mts</InputAdornment>}
              labelWidth={65}
            />
          </FormControl>
        </Row>
        <Row>
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
          >
            <InputLabel>Peso</InputLabel>
            <OutlinedInput
              name="weight"
              inputRef={register}
              endAdornment={<InputAdornment position="end">kg</InputAdornment>}
              labelWidth={40}
            />
          </FormControl>
        </Row>
        <FileInputContainer>
          <IndividualFileInputContainer>
            <InputLabel className={classes.fileInputLabel}>Imagen</InputLabel>
            <input
              data-testid="product-image"
              name="product_image"
              accept="image/*"
              type="file"
              onChange={(e) =>
                setFiles({ ...files, productImage: e.target.files[0] })
              }
            />
          </IndividualFileInputContainer>
          <IndividualFileInputContainer>
            <InputLabel className={classes.fileInputLabel}>
              Ficha técnica
            </InputLabel>
            <input
              data-testid="data-sheet"
              name="product_technical_sheet"
              accept="application/pdf/image/*"
              type="file"
              onChange={(e) =>
                setFiles({ ...files, technicalSheet: e.target.files[0] })
              }
            />
          </IndividualFileInputContainer>
          {/* TODO: Make a list of uploaded "imágenes de ambientación" images for UX */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel className={classes.fileInputLabel}>
              Imágenes de ambientación
            </InputLabel>
            <input
              data-testid="product-ambient-files"
              name="product_ambient_files"
              accept="image/*"
              multiple
              type="file"
              onChange={(e) =>
                setFiles({ ...files, ambientImages: e.target.files })
              }
            />
          </div>
        </FileInputContainer>
        <div>
          {Object.keys(errors)
            .slice(0, 1)
            .map((errorName) => {
              return <p className="error">{errors[errorName].message}</p>;
            })}
        </div>
        <Row>
          <Button label="Enviar" type="submit" $isLoading={loading} />
        </Row>
      </form>
    </div>
  );
}

// Creates an object with the allowed comission values
const createComissionValues = (min, max) => {
  let arrayOfValues = [];
  let value = min;
  let index = 0;

  while (value < max) {
    arrayOfValues.push({ id: index, name: `${value}%`, value: value });
    value = value + 5;
    index++;
  }

  return arrayOfValues;
};

//TODO: Add more margen between the file inputs when width is less than 768px
const FileInputContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const IndividualFileInputContainer = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
`;

const FormHeading = styled.h4`
  margin: 0 0.5rem;
  color: gray;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Label = styled.label`
  font-family: Roboto;
  font-size: 1rem;
  color: grey;
`;

const FileInput = styled.input`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  margin: 1rem 0.5rem;
  padding: 0.25rem 1rem;
  background-color: inherit;
  border: none;
  border-bottom: 1.25px solid black;
  transition: all 300ms ease 0s;
  font-family: Roboto;

  &:focus {
    border-bottom: 1.25px solid #fdd440;
    transition: all 300ms ease 0s;
  }
`;

export default SupplierDecoratives;
