import { Link } from "react-router-dom";
import { formatNumberToPrice } from "../../../../../helpers/priceFormatting";
import styles from "./orderHeader.module.scss";
import { type Order } from "../../../types";

interface OrderHeaderProps {
  purchase: Order;
}

const OrderHeader = ({ purchase }: OrderHeaderProps) => {
  return (
    <div className={styles.orderHeader}>
      <div>
        <h3>Fecha de pedido:</h3>
        <span>{purchase.purchaseDate}</span>
      </div>
      <div>
        <h3>Total del pedido:</h3>
        {/* TODO: Create a function to calculate total of purchases */}
        <span>{formatNumberToPrice(purchase.total)}</span>
      </div>
      <div>
        <h3>N.º de pedido:</h3>
        <span>{purchase.orderNumber}</span>
      </div>

      <Link
        to={`/mis-compras/detalle/${purchase.id}`}
        className={styles.purchaseDetail}
      >
        Ver detalle compra
      </Link>
    </div>
  );
};
export default OrderHeader;
