import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

interface Breadcrumb {
  label: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface SimpleBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const SimpleBreadcrumbs = ({ breadcrumbs }: SimpleBreadcrumbsProps) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs.map((breadcrumb, index) => {
        const { label, href, onClick } = breadcrumb;
        const isLastBreadcrumb = index === breadcrumbs.length - 1;

        return (
          <div key={index}>
            {href ? (
              <Link color="inherit" href={href} onClick={onClick}>
                {label}
              </Link>
            ) : (
              <Typography color={isLastBreadcrumb ? "textPrimary" : "inherit"}>
                {label}
              </Typography>
            )}
          </div>
        );
      })}
    </Breadcrumbs>
  );
};

export default SimpleBreadcrumbs;
