import ReactPasswordChecklist from "react-password-checklist";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import styles from "./passwordValidation.module.scss";

type PasswordValidationProps = {
  value: string;
  isVisible?: boolean;
  onValidChange?: (isValid: boolean) => void;
};

const messages = {
  lowercase: "Al menos una letra minúscula",
  capital: "Al menos una letra mayúscula",
  specialChar: "Al menos un caracter especial",
  number: "Al menos un número",
  minLength: "ser al menos 8 caracteres",
};

const PasswordValidation = ({
  value,
  isVisible,
  onValidChange,
}: PasswordValidationProps) => {
  return (
    <div
      className={`${styles.passwordValidationContainer} ${
        isVisible ? styles.active : ""
      }`}
    >
      <ReactPasswordChecklist
        rules={["capital", "lowercase", "specialChar", "number", "minLength"]}
        minLength={8}
        value={value}
        messages={messages}
        onChange={(isValid) => {
          onValidChange && onValidChange(isValid);
        }}
        iconSize={15}
        className={styles.passwordValidation}
        iconComponents={{
          ValidIcon: (
            <CheckCircleIcon fontSize="small" className={styles.activeIcon} />
          ),
          InvalidIcon: <CheckCircleIcon fontSize="small" color="disabled" />,
        }}
        validTextColor="#7aa73e"
      />
    </div>
  );
};
export default PasswordValidation;
