import { Fragment } from "react";
import { RemodelingRating } from "../types";

import Modal from "../../../components/Modal/Modal";
import DefaultContent from "../../../components/Modal/DefaultContent";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";

import styled from "styled-components";

type RemodelingRatingModalProps = {
  rating: RemodelingRating;
  isShowingRatingModal: boolean;
  closeRatingModal: () => void;
  handleFinishRemodeling: () => Promise<void>;
  setRating: (rating: RemodelingRating) => void;
  isRatingConfirmationChecked: boolean;
  setIsRatingConfirmationChecked: (
    isRatingConfirmationChecked: boolean
  ) => void;
  isLoading: boolean;
  isVirtualConsultancy: boolean;
};

export default function RemodelingRatingModal({
  isShowingRatingModal,
  closeRatingModal,
  handleFinishRemodeling,
  rating,
  setRating,
  isRatingConfirmationChecked,
  setIsRatingConfirmationChecked,
  isLoading,
  isVirtualConsultancy = false,
}: RemodelingRatingModalProps) {
  return (
    <Modal showModal={isShowingRatingModal} closeModal={closeRatingModal}>
      <DefaultContent
        label="Calificación"
        heading="Cuéntanos de tu experiencia"
        onSecondaryClick={closeRatingModal}
        onSubmit={handleFinishRemodeling}
        enabled={isRatingConfirmationChecked}
        isLoading={isLoading}
      >
        <Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>¿Cómo calificarías a tu diseñador?</span>
            <Rating
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              onChange={(_, newValue) => {
                if (!newValue) return;
                setRating({ ...rating, designer_rating: newValue });
              }}
            />
          </div>
          {!isVirtualConsultancy ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>¿Cómo calificarías a los instaladores?</span>
              <Rating
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                onChange={(_, newValue) => {
                  if (!newValue) return;
                  setRating({ ...rating, installers_rating: newValue });
                }}
              />
            </div>
          ) : null}
          <div style={{ marginTop: "1.5rem" }}>
            {/* TODO: Add validation if text is greater than 40 words. */}
            <TextArea
              name="comments"
              placeholder="Agrega comentarios adicionales...(máximo 40 palabras)."
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRating({ ...rating, comments: event.target.value })
              }
              rows="5"
              maxLength="500"
            />
          </div>
          <input
            type="checkbox"
            name="ratingConfirmationChecked"
            style={{ margin: "0 0.25rem 0 0" }}
            onChange={(e) => setIsRatingConfirmationChecked(e.target.checked)}
          />{" "}
          <span>
            {" "}
            Al hacer clic en <b>Aceptar</b> das el consentimiento de que la
            remodelación ha finalizado por completo. También ya no podrás
            agregar una nueva calificación.
          </span>
          {isLoading && (
            <div
              style={{
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              <b>
                Estamos procesando tu calificación, esto puede tomar unos
                segundos
              </b>
            </div>
          )}
        </Fragment>
      </DefaultContent>
    </Modal>
  );
}

const TextArea = styled.textarea<any>`
  width: 100%;
  outline: none;
  background-color: inherit;
  border: none;
  border-bottom: 1.25px solid black;
  transition: all 300ms ease 0s;
  font-family: Roboto;

  &:focus {
    border-bottom: 1.25px solid #fdd440;
    transition: all 300ms ease 0s;
  }
`;
