// import { useParams } from "react-router";
import SimpleRating from "../../../../components/Rating/Rating";
import ImageProduct from "../../../../ecommerce/images/tv.jpg";
import styles from "./productReviewPage.module.scss";
import CustomButton from "../../../../components/Button/CustomButton";

const ProductReviewPage = () => {
  // This is the id of the product to review it can be used to fetch the product data
  // const { id } = useParams<{ id: string }>();
  return (
    <section className="layout-dashboard-container">
      <div className={styles.productReviewPage}>
        <div className="container">
          <h2 className={styles.title}>Crear Reseña</h2>
          <div className={`${styles.productReviewForm} `}>
            <div className={styles.product}>
              <div className={styles.productImage}>
                <img src={ImageProduct} alt="productImage" />
              </div>
              <div>
                <span>Cerámica plateada 30x30 </span>
              </div>
            </div>
            <hr />
            <form action="">
              <div className={styles.rating}>
                <h3>Calificación general</h3>
                <SimpleRating size="large" />
              </div>
              <hr />
              <div className={styles.addTitle}>
                <h3>Agrega un título para tu opinión</h3>
                <input
                  type="text"
                  placeholder="¿Qué es lo mas importante para compartir?"
                />
              </div>
              <hr />
              <div className={styles.addReview}>
                <h3>Agregar una reseña escrita</h3>
                <textarea
                  name=""
                  id=""
                  cols={30}
                  rows={10}
                  required
                  maxLength={1500}
                  placeholder=" Escribe tu opinión aquí..."
                ></textarea>
              </div>
              <hr />
              <div className={styles.buttonSubmit}>
                <CustomButton
                  color="primary"
                  shape="little-rounded"
                  size="full-width"
                  type="submit"
                >
                  Enviar
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProductReviewPage;
