import PersonIcon from "@material-ui/icons/Person";
import OrderInfoData from "../orderInfoData/OrderInfoData";
import styles from "./orderDataSection.module.scss";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { type OrderDetail } from "../../types";

interface OrderDataSectionProps {
  orderInformation: OrderDetail;
}

const OrderDataSection = ({
  orderInformation: orderDetail,
}: OrderDataSectionProps) => {
  return (
    <section className="section">
      {orderDetail ? (
        <div className={styles.container}>
          <OrderInfoData icon={PersonIcon} title="Cliente">
            <p>
              <span>Nombre: </span>
              {orderDetail.name} {orderDetail.lastname}
            </p>
            <p>
              <span>Correo: </span>
              {orderDetail.email}
            </p>
            <p>
              <span>Tel: </span>
              {orderDetail.phone}
            </p>
          </OrderInfoData>
          <OrderInfoData
            icon={LocalShippingIcon}
            title="Información del pedido"
          >
            <p>
              <span>Ciudad de envío: </span>
              {orderDetail.productCity}
            </p>
            <p>
              <span>Dirección de envío: </span>
              {orderDetail.productAddress}
            </p>
          </OrderInfoData>
          <OrderInfoData icon={LocationOnIcon} title="Entregar a">
            <p>
              <span>Dirección: </span>
              {orderDetail.clientAddress}
            </p>
            <p>
              <span>Ciudad: </span> {orderDetail.clientCity}
            </p>
          </OrderInfoData>
        </div>
      ) : null}
    </section>
  );
};
export default OrderDataSection;
