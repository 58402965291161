import { REDUCER_ACTION_TYPE } from "../Actions/productActions";
import { type Product } from "../../../ecommerce/models";
import { ProductDescription } from "../../../ecommerce/pages/ProductInformation/models/productDescription";

export type ProductStateType = {
  isSidebarOpen: boolean;
  products_loading: boolean;
  products_error: boolean;
  products: ProductDescription[];
  featured_products_loading: boolean;
  featured_products_error: boolean;
  featured_products: Product[];
  seasonal_offers_loading: boolean;
  seasonal_offers_error: boolean;
  seasonal_offers: Product[];
  single_product_loading: boolean;
  single_product_error: boolean;
  single_product: ProductDescription;
};

export const ProductInitialState: ProductStateType = {
  isSidebarOpen: false,
  products_loading: false,
  products_error: false,
  products: [],
  featured_products_loading: false,
  featured_products_error: false,
  featured_products: [],
  seasonal_offers_loading: false,
  seasonal_offers_error: false,
  seasonal_offers: [],
  single_product_loading: false,
  single_product_error: false,
  single_product: {} as ProductDescription,
};

export const productsReducer = (state: ProductStateType, action: any) => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.SIDEBAR_OPEN:
      return { ...state, isSidebarOpen: true };
    case REDUCER_ACTION_TYPE.SIDEBAR_CLOSE:
      return { ...state, isSidebarOpen: false };
    case REDUCER_ACTION_TYPE.GET_PRODUCTS_BEGIN:
      return { ...state, products_loading: true };
    case REDUCER_ACTION_TYPE.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products_loading: false,
        products: action.payload,
      };
    case REDUCER_ACTION_TYPE.GET_PRODUCTS_ERROR:
      return {
        ...state,
        products_loading: false,
        products_error: true,
        products: [],
      };
    case REDUCER_ACTION_TYPE.GET_FEATURED_PRODUCTS_BEGIN:
      return { ...state, featured_products_loading: true };
    case REDUCER_ACTION_TYPE.GET_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        featured_products_loading: false,
        featured_products: action.payload,
      };
    case REDUCER_ACTION_TYPE.GET_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        featured_products_loading: false,
        featured_products_error: true,
      };
    case REDUCER_ACTION_TYPE.GET_SEASONAL_OFFERS_BEGIN:
      return { ...state, seasonal_offers_loading: true };
    case REDUCER_ACTION_TYPE.GET_SEASONAL_OFFERS_SUCCESS:
      return {
        ...state,
        seasonal_offers_loading: false,
        seasonal_offers: action.payload,
      };
    case REDUCER_ACTION_TYPE.GET_SEASONAL_OFFERS_ERROR:
      return {
        ...state,
        seasonal_offers_loading: false,
        seasonal_offers_error: true,
      };
    case REDUCER_ACTION_TYPE.GET_SINGLE_PRODUCT_BEGIN:
      return {
        ...state,
        single_product_loading: true,
        single_product_error: false,
      };
    case REDUCER_ACTION_TYPE.GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        single_product_loading: false,
        single_product: action.payload,
      };
    case REDUCER_ACTION_TYPE.GET_SINGLE_PRODUCT_ERROR:
      return {
        ...state,
        single_product_loading: false,
        single_product_error: true,
      };
    default:
      throw new Error(`No Matching "${action.type}" - action type`);
  }
};
