import CustomButton from "../../../../components/Button/CustomButton";
import { getStatusText } from "../../../../utils/statusFilter";
import ItemList from "../itemList/ItemList";
import OrderHeader from "./orderHeader/OrderHeader";
import OrderHeaderDetail from "./orderHeaderDetail/OrderHeaderDetail";
import styles from "./orderItem.module.scss";
import { type Order } from "../../types";

interface OrderItemProps {
  purchase: Order;
  isViewingOrderDetail?: boolean;
}
const OrderItem = ({ purchase, isViewingOrderDetail }: OrderItemProps) => {
  const { suborders } = purchase;

  //filter suborder with product code repeated
  const subordersFiltered = suborders.filter(
    (suborder, index, array) =>
      array.findIndex((t) => t.product.code === suborder.product.code) === index
  );

  const handleButtonClick = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <article className={styles.orderItem}>
      {isViewingOrderDetail ? (
        <OrderHeaderDetail shippingCount={subordersFiltered.length} />
      ) : (
        <OrderHeader purchase={purchase} />
      )}

      <div className={styles.content}>
        {subordersFiltered.map(({ product, status, id, trackUrl }) => {
          const statusText = getStatusText(status);
          return (
            <div key={id}>
              <div className={styles.heading}>
                <div className={styles.status}>
                  {status ? (
                    <p className={styles.statusDelivered}>{statusText}</p>
                  ) : (
                    <p className={styles.statusPending}>Por entregar</p>
                  )}

                  {/*
                    //TODO: Add delivery date once we have implemented pickup and delivery 
                    {deliveryDate && (
                      <span className={styles.deliveryDate}>
                        Llega el {transformDate(deliveryDate)}
                      </span>
                    )} */}
                </div>

                <div className={styles.trackButton}>
                  <CustomButton
                    color="outlined"
                    shape="little-rounded"
                    size="small"
                    block
                    key={product.code}
                    onClick={() => handleButtonClick(trackUrl)}
                  >
                    Rastrear pedido
                  </CustomButton>
                </div>
              </div>
              <ItemList product={product} status={status} />
            </div>
          );
        })}
      </div>
    </article>
  );
};
export default OrderItem;
