import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachmentIcon from "@material-ui/icons/Attachment";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");

/**
 * Represents a notification in the inbox.
 */
function Notification({ is_read, attachment, ...props }) {
  let timeAgo = moment(props.date).fromNow();

  return (
    <StyledContainer {...props}>
      <Row>
        <Flex>
          <Read is_read={is_read} />
          <Title>{props.title}</Title>
          {attachment && <AttachmentIcon />}
        </Flex>
        <Flex>
          <Date>{timeAgo}</Date>
        </Flex>
      </Row>
      <Row>
        <Body>{props.text}</Body>
      </Row>
    </StyledContainer>
  );
}

export function NotificationDetail({ onClick, notification }) {
  let timeAgo = moment(notification.created_at).fromNow();

  return (
    <DetailView>
      <DetailViewHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIcon onClick={onClick} />
          <Title>{notification.title}</Title>
        </div>
        <Date>{timeAgo}</Date>
      </DetailViewHeader>
      <div style={{ margin: "1rem" }}>
        <p style={{ color: "gray", fontFamily: "Open Sans" }}>
          {notification.text}
        </p>
        {notification.attachment && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AttachmentIcon />
            <a href="/#" target="__blank" style={{ color: "grey" }}>
              Abrir archivo adjunto
            </a>
          </div>
        )}
      </div>
    </DetailView>
  );
}

const DetailView = styled.div`
  margin: 1rem;

  p {
    font-size: 0.8rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  .MuiSvgIcon-root {
    font-size: 1rem;
    margin-right: 5px;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const DetailViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainer = styled.div`
  font-family: "Open Sans", sans-serif;
  color: gray;
  border-bottom: 1px solid lightgrey;
  padding: 0.5rem;
  transition: all 300ms ease 0s;

  h1,
  p {
    margin: 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: all 300ms ease 0s;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 1rem;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Read = styled.span`
  margin-right: 5px;
  height: 6px;
  width: 6px;
  background-color: ${(props) => (props.is_read ? "#39984a" : "orange")};
  border-radius: 100%;
`;

const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0;
  color: #112a36;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;

  @media (min-width: 768px) {
    width: 100%;
    font-size: 1rem;
  }
`;

const Body = styled.p`
  display: inline-block;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;

  @media (min-width: 768px) {
    width: 90%;
    font-size: 0.8rem;
  }
`;

const Date = styled.span`
  color: grey;
  font-size: 0.8rem;

  @media (min-width: 768px) {
    font-size: 0.8rem;
  }
`;

Notification.propTypes = {
  is_read: PropTypes.bool,
};

Notification.defaultProps = {
  is_read: true,
};

export default Notification;
