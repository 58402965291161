import MaterialTable, { MTableToolbar } from "material-table";
import { ReactNode } from "react";
import styles from "./customMaterialTable.module.scss";

interface CustomMaterialTableProps {
  title: string;
  columns: any[];
  data: any[];
  children?: ReactNode;
}

const CustomMaterialTable = ({
  title,
  columns,
  data,
  children,
}: CustomMaterialTableProps) => {
  return (
    <div>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        components={{
          Toolbar: (props) => (
            <>
              <MTableToolbar {...props} />
              <div className={styles.containerHeader}>{children}</div>
            </>
          ),
        }}
        options={{
          searchAutoFocus: true,
          rowStyle: (rowData, index) =>
            index % 2 === 1 ? { backgroundColor: "#f4f6f8" } : {},
          headerStyle: {
            backgroundColor: "#a3afc7",
            color: "#FFF",
            fontSize: "1rem",
          },
        }}
      />
    </div>
  );
};

export default CustomMaterialTable;
