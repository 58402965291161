import { ProductDescription } from "../../../ecommerce/pages/ProductInformation/models/productDescription";
import { REDUCER_ACTION_TYPE } from "../Actions/filterActions";
import { type FilterStateType } from "../types";

export const FilterInitialState: FilterStateType = {
  filtered_products: [],
  all_products: [],
  all_products_loading: false,
  all_products_error: false,
  grid_view: true,
  sort: "price-lowest",
  filters: {
    material: [],
    brand: [],
    color: [],
    min_price: 0,
    max_price: 0,
    price: 0,
  },
  pagination: {
    totalItems: 0,
    lastPage: 0,
    totalPages: 0,
    itemsPerPage: 12,
    currentPage: 1,
    from: 0,
    to: 0,
  },
};

const filterReducer = (state: FilterStateType, action: any) => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.LOAD_PRODUCTS: {
      let itemPrice = action.payload.map(
        (item: ProductDescription) => item.price
      );
      const maxPrice = Math.max(...itemPrice);
      return {
        ...state,
        filters: { ...state.filters, max_price: maxPrice, price: maxPrice },
      };
    }
    case REDUCER_ACTION_TYPE.GET_ALL_PRODUCTS_BEGIN: {
      return {
        ...state,
        all_products_loading: true,
      };
    }
    case REDUCER_ACTION_TYPE.GET_ALL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        all_products_loading: false,
        all_products: action.payload,
      };
    }
    case REDUCER_ACTION_TYPE.GET_ALL_PRODUCTS_ERROR: {
      return {
        ...state,
        all_products_loading: false,
        all_products_error: true,
        all_products: [],
      };
    }

    case REDUCER_ACTION_TYPE.SET_GRIDVIEW: {
      return {
        ...state,
        grid_view: true,
      };
    }
    case REDUCER_ACTION_TYPE.SET_LISTVIEW: {
      return {
        ...state,
        grid_view: false,
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }

    case REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_MATERIAL: {
      const { value } = action.payload;
      let updatedFilters = { ...state.filters };
      if (updatedFilters.material.includes(value)) {
        updatedFilters.material = updatedFilters.material.filter(
          (item: any) => item !== value
        );
      } else {
        updatedFilters.material = [...updatedFilters.material, value];
      }
      return {
        ...state,
        filters: updatedFilters,
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_BRAND: {
      const { value } = action.payload;
      let updatedFilters = { ...state.filters };
      if (updatedFilters.brand.includes(value)) {
        updatedFilters.brand = updatedFilters.brand.filter(
          (item: any) => item !== value
        );
      } else {
        updatedFilters.brand = [...updatedFilters.brand, value];
      }
      return {
        ...state,
        filters: updatedFilters,
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_COLOR: {
      const { value } = action.payload;
      let updatedFilters = { ...state.filters };
      if (updatedFilters.color.includes(value)) {
        updatedFilters.color = updatedFilters.color.filter(
          (item: any) => item !== value
        );
      } else {
        updatedFilters.color = [...updatedFilters.color, value];
      }
      return {
        ...state,
        filters: updatedFilters,
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_FILTERS_BY_PRICE: {
      const { name, value } = action.payload;
      return { ...state, filters: { ...state.filters, [name]: value } };
    }

    case REDUCER_ACTION_TYPE.CLEAR_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          material: [],
          brand: [],
          color: [],
          min_price: 0,
          max_price: state.filters.max_price,
          price: state.filters.max_price,
        },
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_CURRENT_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload,
        },
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_TOTAL_ITEMS: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload,
        },
      };
    }
    case REDUCER_ACTION_TYPE.UPDATE_TOTAL_PAGES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalPages: action.payload,
        },
      };
    case REDUCER_ACTION_TYPE.UPDATE_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }
    case REDUCER_ACTION_TYPE.RESET_FILTERS:
      return { ...FilterInitialState };
    default:
      throw new Error(`No Matching "${action.type}" - action type`);
  }
};

export default filterReducer;
