import OrderItem from "../orderItem/OrderItem";
import styles from "./orderList.module.scss";
import { type Order } from "../../types";
import unorm from "unorm";

interface OrderListProps {
  purchases: Order[];
  searchText: string;
  filterType: string;
}
const OrderList = ({ purchases, filterType, searchText }: OrderListProps) => {
  // Filter purchases by date (this month, last month, this year, all)
  const filteredPurchases = purchases.filter((purchase) => {
    const purchaseDate = new Date(purchase.purchaseDate);
    const today = new Date();
    const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const thisYear = new Date(today.getFullYear(), 0, 1);
    if (filterType === "this-month") {
      return purchaseDate >= thisMonth;
    } else if (filterType === "last-month") {
      return purchaseDate >= lastMonth && purchaseDate < thisMonth;
    } else if (filterType === "this-year") {
      return purchaseDate >= thisYear;
    } else {
      return true;
    }
  });

  // Filter purchases by product name
  const searchedPurchases = filteredPurchases.filter((purchase) =>
    purchase.suborders.some((suborden) =>
      unorm
        .nfd(suborden.product.name.toLowerCase())
        .includes(unorm.nfd(searchText.toLowerCase()))
    )
  );

  return (
    <div className={styles.orderList}>
      {searchedPurchases.length === 0 && (
        <div className={styles.noPurchases}>
          <h1>¡No hay compras que coincidan con la búsqueda!</h1>
        </div>
      )}
      {searchedPurchases.map((purchase) => (
        <OrderItem purchase={purchase} key={purchase.id} />
      ))}
    </div>
  );
};
export default OrderList;
