import { amber, green } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { materialTableES } from "../../services/materialTableES";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import Alert from "@material-ui/lab/Alert";
import MaterialTable, { MTableToolbar } from "material-table";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";

import axios from "axios";
import moment from "moment";
import { errorToast, successToast } from "../../services/toasts";

const statuses = {
  1: "Pendiente",
  2: "En proceso...",
  3: "Terminado",
};

function dataIcon(field) {
  switch (field) {
    case 1:
      return <QueryBuilderRoundedIcon titleAccess="Pendiente" />;
    case 2:
      return (
        <HourglassEmptyIcon
          style={{ color: amber[500] }}
          titleAccess="En proceso..."
        />
      );
    case 3:
      return (
        <CheckCircleIcon
          style={{ color: green[500] }}
          titleAccess="Terminado"
        />
      );
    default:
      return <QueryBuilderRoundedIcon titleAccess="Pendiente" />;
  }
}

function PurchaseManagement() {
  const history = useHistory();
  const [approvalScreen, setApprovalScreen] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [detailData, setDetailData] = useState();
  const [hashid, setHashid] = useState();

  const userTokens = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let response = await axios.get("/v1/presential-consultancy/designers");
    setPurchases(response.data.info);
  };

  const handleApproval = async (rowData, hashid, isApproved) => {
    try {
      let copyPurchases = [...purchases];
      let index = copyPurchases.indexOf(rowData);
      copyPurchases[index].is_approved = isApproved;
      setPurchases(copyPurchases);

      await axios.patch(`/v1/presential-consultancy/${hashid}`, {
        is_approved: isApproved,
        client_uuid: rowData.client.uuid,
      });

      successToast(
        `Has ${isApproved ? "aprobado" : "rechazado"} la remodelación #${
          rowData.hashid
        } exitosamente`
      );

      // TODO: Send email to client...
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  let approvedPurchases = purchases.filter(
    (purchases) => purchases.is_approved === true
  );

  let pendingApproval = purchases.filter(
    (purchases) => purchases.is_approved === null
  );

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Chip
            style={{
              color: "#112A36",
              backgroundColor: "#E2CA8A",
              marginRight: 10,
            }}
            label="Aprobados"
            clickable={true}
            onClick={() => setApprovalScreen(false)}
          />
          <Chip
            style={{
              color: "#FFF",
              backgroundColor: "#5D777B",
              marginRight: 10,
            }}
            label="Por aprobar"
            avatar={
              <Avatar
                style={{
                  color: "white",
                  border: "1px solid white",
                  backgroundColor: "#5D777B",
                }}
              >
                {pendingApproval.length}
              </Avatar>
            }
            clickable={true}
            onClick={() => {
              setApprovalScreen(true);
            }}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Cliente",
      editable: "never",
      render: (rowData) =>
        `${rowData.client.first_name} ${rowData.client.last_name}`,
    },
    {
      title: "Fecha de visita",
      lookup: statuses,
      render: function (rowData) {
        return dataIcon(rowData.steps[0].step_status_id);
      },
    },
    {
      title: "Diseño",
      lookup: statuses,
      render: function (rowData) {
        return dataIcon(rowData.steps[1].step_status_id);
      },
    },
    {
      title: "Presupuesto",
      lookup: statuses,
      render: function (rowData) {
        return dataIcon(rowData.steps[2].step_status_id);
      },
    },
    {
      title: "Pago",
      lookup: statuses,
      render: function (rowData) {
        return dataIcon(rowData.steps[3].step_status_id);
      },
    },
    {
      title: "Mis pedidos",
      lookup: statuses,
      render: function (rowData) {
        return dataIcon(rowData.steps[4].step_status_id);
      },
    },
  ];

  const approvalScreenColumns = [
    {
      title: "Fecha",
      field: "created_at",
      render: (rowData) =>
        moment(rowData.created_at).toDate().toLocaleDateString(),
    },
    {
      title: "Referencia",
      field: "hashid",
    },
    {
      title: "Cliente",
      editable: "never",
      render: (rowData) =>
        `${rowData.client.first_name} ${rowData.client.last_name}`,
    },
    {
      title: "Solicitud",
      field: "is_approved",
      editable: "never",
      render: function (rowData, i) {
        if (rowData.is_approved === null) {
          return (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                label="Aprobar"
                onClick={() => {
                  handleApproval(rowData, rowData.hashid, true);
                }}
              />
              <Button
                size="small"
                label="Rechazar"
                onClick={() => {
                  handleApproval(rowData, rowData.hashid, false);
                }}
              />
            </div>
          );
        }
      },
    },
  ];

  const actions = [
    {
      icon: () => <ZoomInIcon />,
      tooltip: "Detalle",
      onClick: (event, rowData) => {
        setDetailData(rowData);
      },
    },
    {
      icon: () => <ReceiptIcon />,
      tooltip: "Crear cotización",
      onClick: (event, rowData) => {
        history.push({
          pathname: "/disenador/asesoria/productos",
          state: { quotePurchase: rowData },
        });
      },
    },
  ];

  return (
    <div className="container margin-block">
      {userTokens && !userTokens.hasBankInfo && (
        <div>
          <Alert severity="info">
            Aun no has especificado la información bancaria en donde te llegarán
            tus pagos, puedes hacerlo haciendo clic{" "}
            <Link to="configuracion">aquí</Link>
          </Alert>
        </div>
      )}
      <Modal
        showModal={detailData ? true : false}
        closeModal={() => setDetailData()}
        contentMaxWidth={"400px"}
      >
        <RemodelingDetail data={detailData} />
      </Modal>
      <MaterialTable
        title={
          approvalScreen ? "Pendientes de aprobación" : "Tus procesos activos"
        }
        columns={approvalScreen ? approvalScreenColumns : columns}
        data={approvalScreen ? pendingApproval : approvedPurchases}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <TableHeader />
            </div>
          ),
        }}
        actions={approvalScreen ? [actions[0]] : actions}
        onRowClick={
          approvalScreen
            ? false
            : (evt, selectedRow) => {
                history.push(
                  `/diseñador/compra/asesoria-presencial/${selectedRow.hashid}`
                );
              }
        }
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
        }}
        localization={materialTableES}
        isLoading={purchases ? false : true}
      />
    </div>
  );
}

const RemodelingDetail = ({ data }) => {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información general</h3>
      <span>
        <i>Referencia.</i> {data.hashid}
      </span>
      <span>
        <i>Ciudad.</i> {data.city}
      </span>
      <span>
        <i>Dirección.</i> {data.address}
      </span>

      <h3 style={{ marginTop: 10 }}>Información del cliente</h3>
      <span>
        <i>Nombre.</i> {`${data.client.first_name} ${data.client.last_name}`}
      </span>
      <span>
        <i>Celular.</i> {data.client.phone}
      </span>

      {/* TODO: datos de facturación */}
    </div>
  );
};

export default PurchaseManagement;
