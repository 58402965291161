import { yupResolver } from "@hookform/resolvers";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
} from "@material-ui/core";
import { FieldError, useForm } from "react-hook-form";
import { InferType } from "yup";
import CustomButton from "../../../../../../components/Button/CustomButton";
import InputValidationMessage from "../../../../../../components/forms/InputValidationMessage/InputValidationMessage";
import { packageTypes } from "../../../../../../constants";
import { ProductDescription } from "../../../../../../ecommerce/pages/ProductInformation/models/productDescription";
import { packageDimensionsSchema } from "../../../../../../services/formValidation";
import { useStyles } from "../../../../../../theme/FormStyles";
import { packageFields } from "../../../../data/fieldsByCategory";
import styles from "./packagingMeasuresForm.module.scss";
import { useUpdateProductPackageMeasures } from "../../../../../../utils/react-query/querySupplier/queries";
import { errorToast, successToast } from "../../../../../../services/toasts";

interface ErrorsIndexSignature {
  [key: string]: FieldError | undefined;
}

type PackagingMeasuresFormProps = {
  product: ProductDescription;
};
const PackagingMeasuresForm = ({ product }: PackagingMeasuresFormProps) => {
  const classes = useStyles();
  const { mutateAsync: updatePackageDimensions, isLoading: isLoadingUpdate } =
    useUpdateProductPackageMeasures();

  const { register, handleSubmit, errors } = useForm<
    InferType<typeof packageDimensionsSchema>
  >({
    resolver: yupResolver(packageDimensionsSchema),
    defaultValues: {
      packing_presentation_id: product.presentation_id,
      package_length: product.packing_length,
      package_width: product.packing_width,
      package_height: product.packing_height,
      package_weight: product.packing_weight,
    },
  });

  async function onSubmit(data: any) {
    const updatedPackageDimensions = await updatePackageDimensions({
      productId: product.product_id,
      packingPresentationId: product.packing_size_id,
      productPackageMeasures: data,
    });

    if (!updatedPackageDimensions) {
      errorToast("Error al actualizar las medidas del empaque");
    }

    return successToast("Medidas del empaque actualizadas correctamente");
  }

  return (
    <>
      <div className={styles.packagingMeasuresFormInfo}>
        <p>
          (*) Por favor solo ingresar centímetros como unidad de medida.
          Realizar conversión a centímetros si es necesario.
        </p>
        <p>
          (*) Por favor, tenga en cuenta que el peso del producto una vez
          empacado debe ser igual o superior al peso del producto sin empacar.
        </p>
        <p>
          <strong>Nota</strong> : utiliza un punto en lugar de una coma.
          (Ejemplo: 1.65 para valores decimales y 56 para valores enteros.)
        </p>
      </div>
      <form
        className="formProductContainer"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Paper className="paperFormContainer">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={6} className={classes.gridMarginTop}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors?.packing_presentation_id?.message}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Tipo
                </InputLabel>
                <Select
                  inputRef={register}
                  native
                  label="Tipo"
                  name="packing_presentation_id"
                >
                  {packageTypes?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <InputValidationMessage
                message={errors?.packing_presentation_id?.message}
              />
            </Grid>
            {packageFields.map(({ label, name, endAdornment }) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  key={name}
                  className={classes.gridMarginTop}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!(errors as ErrorsIndexSignature)[name]?.message}
                  >
                    <InputLabel>{` ${label} `}</InputLabel>
                    <OutlinedInput
                      name={name}
                      inputRef={register}
                      label={` ${label} `}
                      endAdornment={
                        <InputAdornment position="end">
                          {endAdornment}
                        </InputAdornment>
                      }
                      labelWidth={110}
                    />
                  </FormControl>
                  <InputValidationMessage
                    message={(errors as ErrorsIndexSignature)[name]?.message}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
        <div className="formProductSaveButton">
          <CustomButton
            type="submit"
            color="secondary"
            shape="little-rounded"
            size="medium"
            isLoading={isLoadingUpdate}
          >
            Guardar Cambios
          </CustomButton>
        </div>
      </form>
    </>
  );
};
export default PackagingMeasuresForm;
