import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import image from "../../assets/images/checkout-cart.jpeg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import DefaultContent from "../../components/Modal/DefaultContent";
import Modal from "../../components/Modal/Modal";
import Navbar from "../../components/Navbar";
import Select from "../../components/Select/Select";

import { BASE_URL } from "../../services/baseURL";
import { successToast } from "../../services/toasts";

function Checkout() {
  const { hashid } = useParams();
  const locationData = useLocation();
  const client = locationData.state && locationData.state.client;
  const formRef = useRef(null);

  const [selectedInvoices, setSelectedInvoices] = useState([]); // array containing booleans
  const [legalDocTypes, setLegalDocTypes] = useState();
  const [paymentReference, setPaymentReference] = useState();
  const [invoices, setInvoices] = useState();
  const [error, setError] = useState();
  const [checkReceiptType, setCheckReceiptType] = useState("person");
  const [receipt, setReceipt] = useState({
    showModal: false,
    type: "person",
    receiptData: {
      first_name: client.first_name,
      last_name: client.last_name,
      legal_document_type_id: 1,
      document_number: client.cedula,
    },
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getApprovedInvoices();
    getLegalDocTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLegalDocTypes = async () => {
    let response = await axios.get(`/v1/users/legaldocs`);
    setLegalDocTypes(response.data);
  };

  const getApprovedInvoices = async () => {
    let response = await axios.get(
      `/v1/remodelings/${hashid}/invoices?approved=true&paid=false`
    );

    // Set initial values
    setInvoices(response.data);
    setSelectedInvoices(new Array(response.data.length).fill(true));
    setTotal(
      response.data.reduce((accumulator, object) => {
        return accumulator + Number(object.to_pay);
      }, 0)
    );
  };

  const handleSelect = (position) => {
    const updatedSelectedState = selectedInvoices.map((item, index) =>
      index === position ? !item : item
    );

    setSelectedInvoices(updatedSelectedState);

    const totalPrice = updatedSelectedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + parseFloat(invoices[index].to_pay);
        }
        return sum;
      },
      0
    );

    setTotal(totalPrice);
  };

  // Creates a payment, Wompi required to send a unique payment id
  const handlePaymentRequest = async (event) => {
    try {
      event.preventDefault();

      let invoicesToBePaid = [];

      for (let i = 0; i < invoices.length; i++) {
        if (selectedInvoices[invoices.indexOf(invoices[i])] === true) {
          invoicesToBePaid.push(invoices[i]);
        }
      }

      if (invoicesToBePaid.length > 0) {
        let response;

        // Create the receipt first
        if (receipt.type === "person") {
          response = await axios.post(
            `/v1/remodelings/payments/receipts?person=true`,
            receipt.receiptData
          );
        } else if (receipt.type === "company") {
          response = await axios.post(
            `/v1/remodelings/payments/receipts?company=true`,
            receipt.receiptData
          );
        }

        // Create a payment and hook up the receipt
        let payment = await axios.post(`/v1/remodelings/${hashid}/payments`, {
          to_pay: total,
          invoices: invoicesToBePaid,
          receipt: { type: receipt.type, receiptData: response.data },
        });

        // Set unique payment id to state, this is what Wompi is going to use
        setPaymentReference(payment.data.uuid);
      }
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setError({
        message: requestError ? requestError : "Ha ocurrido un error",
      });
    }
  };

  useEffect(() => {
    if (!paymentReference) return;

    formRef.current.submit();
  }, [paymentReference]);

  return (
    <Fragment>
      <Navbar />
      <Modal
        showModal={receipt.showModal}
        closeModal={() => setReceipt({ ...receipt, showModal: false })}
      >
        <DefaultContent
          label="Facturación"
          heading="Datos para tu factura"
          showFooter={false}
          onSecondaryClick={() => setReceipt({ ...receipt, showModal: false })}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              onClick={() =>
                setReceipt({
                  ...receipt,
                  type: receipt.type === "person" ? "company" : "person",
                })
              }
              label={
                receipt.type === "person"
                  ? "Facturar como empresa"
                  : "Facturar como persona natural"
              }
              size="small"
            />
          </div>
          {receipt.type === "person" ? (
            <ReceiptPersonForm
              setCheck={setCheckReceiptType}
              client={client}
              legalDocTypes={legalDocTypes}
              setReceipt={(data) =>
                setReceipt({ ...receipt, showModal: false, receiptData: data })
              }
            />
          ) : (
            <ReceiptCompanyForm
              setCheck={setCheckReceiptType}
              setReceipt={(data) =>
                setReceipt({ ...receipt, showModal: false, receiptData: data })
              }
            />
          )}
        </DefaultContent>
      </Modal>

      <Background>
        <Container>
          <LeftColumn>
            <Header>
              <h2>Formulario de pago</h2>

              <p style={{ marginTop: "0.5rem" }}>
                Por favor selecciona las cotizaciones que deseas pagar y una vez
                estés listo, haz clic sobre el botón de pago Wompi.
              </p>
            </Header>
            <DividerImage src={image} />
            {error && <Alert severity="error">{error.message}</Alert>}
            <Body>
              {invoices &&
                invoices.map((invoice, index) => (
                  <InvoicesContainer>
                    <input
                      type="checkbox"
                      checked={selectedInvoices[index]}
                      onChange={() => handleSelect(index)}
                    />
                    <a href={invoice.url} target="__blank">
                      {invoice.filename}
                    </a>
                    <span>
                      {moment(invoice.created_at).toDate().toLocaleDateString()}
                    </span>
                    <span>
                      ${parseInt(invoice.to_pay).toLocaleString("es-CO")}
                    </span>
                  </InvoicesContainer>
                ))}

              <WompiForm
                formRef={formRef}
                hashid={hashid}
                totalInCents={(total * 100).toFixed()}
                reference={paymentReference}
                handlePaymentRequest={handlePaymentRequest}
              />
            </Body>
          </LeftColumn>

          <RightColumn>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2>
                <i class="fa fa-user"></i> Mis datos
              </h2>
              {checkReceiptType === "person" ? (
                <DataPerson receipt={receipt} client={client} />
              ) : (
                <DataCompany receipt={receipt} />
              )}

              <Button
                onClick={() => setReceipt({ ...receipt, showModal: true })}
                label="Modificar datos para tu factura"
                size="small"
              />
            </div>

            <TotalContainer>
              <h2>Total. ${parseInt(total).toLocaleString("es-CO")}</h2>
            </TotalContainer>
          </RightColumn>
        </Container>
      </Background>
    </Fragment>
  );
}

function ReceiptPersonForm({ client, legalDocTypes, setReceipt, setCheck }) {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    setReceipt(data);
    setCheck("person");
    successToast("¡Has actualizado tus datos exitosamente!");
  };
  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Input
            defaultValue={client.first_name}
            placeholder="Nombre"
            name="first_name"
            register={register}
          />
          <Input
            defaultValue={client.last_name}
            placeholder="Apellido"
            name="last_name"
            register={register}
          />
        </Row>
        <Row>
          {legalDocTypes && (
            <Select name="legal_document_type_id" register={register}>
              {legalDocTypes.map((docType) => (
                <option value={docType.id} key={docType.id}>
                  {docType.name}
                </option>
              ))}
            </Select>
          )}
          <Input
            defaultValue={client.cedula}
            placeholder="# de documento"
            name="document_number"
            register={register}
          />
        </Row>
        <Button label="Aceptar" />
      </StyledForm>
    </Fragment>
  );
}

function ReceiptCompanyForm({ setReceipt, setCheck }) {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    setReceipt(data);
    setCheck("company");
    successToast("¡Has actualizado tus datos exitosamente!");
  };
  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Razón social"
          name="company_name"
          register={register}
        />
        <Input placeholder="NIT" name="NIT" register={register} />
        <Input
          placeholder="Dirección"
          name="company_address"
          register={register}
        />
        <Button label="Aceptar" />
      </StyledForm>
    </Fragment>
  );
}

function DataPerson({ receipt, client }) {
  return (
    <>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "1rem",
        }}
      >
        <li>
          Nombre: {receipt.receiptData.first_name}{" "}
          {receipt.receiptData.last_name}
        </li>
        <li>CC: {receipt.receiptData.document_number}</li>
        <li>Correo: {client.email}</li>
        <li>Dirección: {client.address}</li>
      </ul>
    </>
  );
}

function DataCompany({ receipt }) {
  return (
    <>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "1rem",
        }}
      >
        <li>Razón social: {receipt.receiptData.company_name}</li>
        <li>NIT: {receipt.receiptData.NIT}</li>
        <li>Dirección de la compañía: {receipt.receiptData.company_address}</li>
      </ul>
    </>
  );
}

function WompiForm({
  formRef,
  totalInCents,
  reference,
  handlePaymentRequest,
  hashid,
}) {
  let wompiPublicKey =
    process.env.NODE_ENV === "production"
      ? "pub_prod_1i0nDDKUoKnWKEYDNg5sTRupkrKit6mS"
      : "pub_test_21cQ2tvNzNya4oRUNQ4M6bxrqxViRZZ9";

  return (
    <form
      ref={formRef}
      action="https://checkout.wompi.co/p/"
      method="GET"
      onSubmit={(event) => handlePaymentRequest(event)}
    >
      <input type="hidden" name="public-key" value={wompiPublicKey} />
      <input type="hidden" name="currency" value="COP" />
      <input type="hidden" name="amount-in-cents" value={totalInCents} />
      <input type="hidden" name="reference" value={reference} />
      <input
        type="hidden"
        name="redirect-url"
        value={`${BASE_URL}/compra/${hashid}/wompi`}
      />
      <Button
        label="Paga con Wompi"
        type="submit"
        style={{ backgroundColor: "#1a4594", color: "white" }}
      />
    </form>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Background = styled.div`
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  font-size: 16px;
  font-weight: 300;
  background-color: #606060;
  padding: 2rem 4rem;
  min-height: 80vh;

  @media (max-width: 768px) {
    padding: 1rem 0rem;
  }
`;

const Container = styled.div`
  display: flex;
  background-color: #e9e9e9;
  margin: 1rem;
  border-radius: 0 1rem 1rem 0;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = styled.div`
  padding: 2rem 4rem;
`;

const DividerImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
`;

const Body = styled.div`
  padding: 1rem 2rem;

  @media (max-width: 400px) {
    padding: 0;

    button {
      margin: auto;
    }
  }
`;

const InvoicesContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftColumn = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 4rem;
`;

const TotalContainer = styled.div`
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export default Checkout;
