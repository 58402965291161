import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import SuccessBox from "../../components/SuccessBox/SuccessBox.js";
import Button from "../../components/Button/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import styled from "styled-components";
import fileTypes from "../../services/fileTypes";
import axios from "axios";

/*
 * FIXME: when going from unit selection page to invoice, if the user reloads
 * the page there could be a bug where cumplementaries don't have units
 */
function Quoter2() {
  const history = useHistory();
  const data = useLocation();

  const selectedProducts = data.state ? data.state.selectedProducts : [];
  const quotePurchase = data.state ? data.state.quotePurchase : [];

  const [complementaries, setComplementaries] = useState([]);
  const [products, setProducts] = useState(selectedProducts);
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState();

  const [invoicePDF, setInvoicePDF] = useState(); // PDF in base64
  const [invoiceName, setInvoiceName] = useState();

  const [screens, setScreens] = useState({
    showPreview: false,
    showSuccess: false,
  });

  const handleSaveInvoice = async () => {
    setLoading(true);

    const invoiceStep = quotePurchase.steps[2];
    let formData = new FormData();

    // convert base64 to blob
    let blob = await fetch(`data:application/pdf;base64,${invoicePDF}`).then(
      (res) => res.blob()
    );

    // filename in state
    formData.append("file", blob, invoiceName);

    let upload = await axios.post(
      `/v1/remodelings/${quotePurchase.hashid}/files/${fileTypes.remodelingInvoice}`,
      formData
    );

    let response = await axios.post(
      `/v1/remodelings/${quotePurchase.hashid}/invoices?whatsapp=true`,
      {
        remodeling_process_file_id: upload.data.id,
        products: [...products, ...complementaries],
        purchase: {
          hashid: quotePurchase.hashid,
          subtotal: subtotal,
        },
      }
    );

    await axios.patch(`/v1/remodelings/steps/${invoiceStep.id}`, {
      step_status_id: 2,
    });

    setScreens({ showPreview: false, showSuccess: true });
  };

  const handleInvoicePreview = async () => {
    setScreens({ ...screens, showPreview: true });
    let requestBody = {
      purchase: {
        hashid: quotePurchase.hashid,
        address: quotePurchase.address,
        subtotal: subtotal,
      },
      client: {
        name: quotePurchase.client.first_name,
        lastName: quotePurchase.client.last_name,
        cedula: quotePurchase.client.cedula,
        email: quotePurchase.client.email,
        phone: quotePurchase.client.phone,
      },
      products: [...products, ...complementaries],
    };

    let response = await axios.post(
      `/v1/remodelings/${quotePurchase.hashid}/invoices/preview`,
      requestBody
    );
    setInvoicePDF(response.data);
  };

  const setInitialQuantities = () => {
    for (let i = 0; i < products.length; i++) {
      products[i].m2 = products[i].min_sale_unit;
      products[i].units = 1;
    }
  };

  // keep record of the units needed to complement a brand
  const getQuantitiesPerBrand = () => {
    let brands = [];

    for (let i = 0; i < products.length; i++) {
      let brandIndex = brands.findIndex(
        (brand) => brand.id === products[i].brand.id
      );
      if (brandIndex === -1) {
        brands.push({
          id: products[i].brand.id,
          m2: products[i].m2,
        });
      } else {
        brands[brandIndex].m2 += products[i].m2;
      }
    }
    setBrands(brands);
  };

  useEffect(() => {
    setInitialQuantities();
    getBrandComplementaries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getQuantitiesPerBrand();
  }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBrandComplementaries = async () => {
    const uniqueBrands = [
      ...new Set(products.map((product) => product.brand.id)),
    ];

    let complementaries = [];
    for (let i = 0; i < uniqueBrands.length; i++) {
      let response = await axios.get(
        `/v1/brands/${uniqueBrands[i]}/complementaries`
      );
      complementaries = [...complementaries, ...response.data];
    }
    setComplementaries(complementaries);
  };

  const updateProductQuantity = (inputValue, index) => {
    const calculatedUnits = Math.ceil(
      inputValue / products[index].min_sale_unit
    );
    const newProductsQuantities = [...products];

    // Disable submit button if the units wanted exceed the available units
    if (calculatedUnits > products[index].units_available) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }

    newProductsQuantities[index].m2 = inputValue;
    newProductsQuantities[index].units = calculatedUnits;

    setProducts(newProductsQuantities);
  };

  // TODO: negative numbers validation

  let subtotal = 0;
  for (let i = 0; i < products.length; i++) {
    subtotal += products[i].units * products[i].price;
    subtotal += Number(products[i].shipping_cost);
  }

  if (screens.showPreview) {
    return (
      <PreviewInvoice
        invoicePDF={invoicePDF}
        invoiceName={invoiceName}
        setInvoiceName={(inputValue) => setInvoiceName(inputValue)}
        handleSaveInvoice={() => handleSaveInvoice}
        loading={loading}
        goBack={() => setScreens({ showSuccess: false, showPreview: false })}
      />
    );
  } else if (screens.showSuccess) {
    return (
      <Paper style={{ padding: "2rem" }}>
        <SuccessBox
          title="¡Has subido tú cotización exitosamente!"
          text="Ahora tu cliente podra revisarla en su hoja de proceso"
        >
          <Link
            style={{ color: "grey", marginTop: "1rem" }}
            to="/disenador/productos/"
          >
            Volver a productos
          </Link>
        </SuccessBox>
      </Paper>
    );
  } else {
    return (
      <Paper style={{ padding: "2rem", overflow: "auto" }}>
        <TableHeader />
        <Table size="small">
          <TableRow>
            <TableCell />
            <TableCell>Marca</TableCell>
            <TableCell>Referencia</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell>Unidades</TableCell>
            <TableCell>Precio</TableCell>
            <TableCell>Costo de envío</TableCell>
            <TableCell>Disponibilidad</TableCell>
            <TableCell>Subtotal</TableCell>
          </TableRow>
          <tbody>
            {products &&
              products.map((product, index) => (
                <TableRow>
                  <TableCell>
                    <Avatar src={product.image} alt={`${product.name}`} />
                  </TableCell>
                  <TableCell>{product.brand.name}</TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <input
                        type="number"
                        value={product.m2}
                        onChange={(e) =>
                          updateProductQuantity(Number(e.target.value), index)
                        }
                      />
                      {product.unit_of_measure}
                    </div>
                  </TableCell>
                  <TableCell>{product.units}</TableCell>
                  <TableCell>
                    $ {Math.round(product.price).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    $ {Math.round(product.shipping_cost).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {product.units_available - product.units}
                  </TableCell>
                  <TableCell>
                    $
                    {Math.round(
                      Number(product.shipping_cost) +
                        product.units * product.price
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            {complementaries &&
              complementaries.map((complementary, index) => {
                let brandThatComplements = brands.find(
                  (brand) =>
                    brand.id === complementary.brand_that_complements_id
                );

                complementary.units = Math.ceil(
                  brandThatComplements.m2 / complementary.yield_m2
                );
                complementary.installation_price = 0;

                subtotal += complementary.price * complementary.units;

                return (
                  <TableRow>
                    <TableCell>
                      <Avatar
                        src={complementary.image}
                        alt={`${complementary.name}`}
                      />
                    </TableCell>
                    <TableCell>{complementary.brand.name}</TableCell>
                    <TableCell>{complementary.name}</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{complementary.units}</TableCell>
                    <TableCell>
                      $ {Math.round(complementary.price).toLocaleString()}
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      {complementary.units_available - complementary.units}
                    </TableCell>
                    <TableCell>
                      $
                      {Math.round(
                        complementary.price * complementary.units
                      ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableTotals subtotal={subtotal} />
          </tbody>
        </Table>
        <div style={{ display: "flex" }}>
          <Button
            onClick={() =>
              history.push({
                pathname: "productos",
                state: { quotePurchase: quotePurchase },
              })
            }
            label="Volver"
          />
          <Button
            disabled={disableButton}
            onClick={handleInvoicePreview}
            label="Revisar cotización"
            $isLoading={loading}
          />
        </div>
      </Paper>
    );
  }
}

function PreviewInvoice({
  invoicePDF,
  invoiceName,
  setInvoiceName,
  handleSaveInvoice,
  loading,
  goBack,
}) {
  return (
    <Paper style={{ padding: "2rem" }}>
      <div style={{ marginBottom: "1rem" }}>
        <label style={{ marginRight: ".5rem" }}>
          Por favor especifica el nombre de la cotización
        </label>
        <input
          type="text"
          onChange={(event) => setInvoiceName(event.target.value)}
        />
      </div>
      {invoicePDF ? (
        <iframe
          style={{ width: "100%", minHeight: "60vh" }}
          title="Cotización"
          src={`data:application/pdf;base64,${invoicePDF}`}
        />
      ) : (
        "Cargando PDF..."
      )}
      <Button onClick={goBack} label="Volver" />
      <Button
        onClick={handleSaveInvoice()}
        label="Subir cotización"
        $isLoading={loading}
        disabled={invoiceName ? false : true}
      />
    </Paper>
  );
}

function TableHeader() {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <h6
        style={{
          marginBottom: "1rem",
          fontFamily: "Roboto",
          fontSize: "1.25rem",
          fontWeight: "500",
          letterSpacing: "0.0075em",
        }}
      >
        Cotizador
      </h6>
      <p
        style={{
          fontFamily: "Roboto",
          marginBottom: "0.5rem",
          fontSize: "16px",
        }}
      >
        Especifica la cantidad de unidades o metros cuadrados (m²) que deseas
        para cada producto.
      </p>
      <p
        style={{
          fontFamily: "Roboto",
          marginBottom: "0.5rem",
          fontSize: "14px",
        }}
      >
        <i
          class="fa fa-info-circle"
          aria-hidden="true"
          style={{ color: "#232f3e", marginRight: "0.25rem" }}
        />
        Recuerda que después de realizada la cotización deberás enviarla lo
        antes posible al cliente, ya que los materiales se separaran por 3 días
        y no se podrán volver a separar
      </p>
    </div>
  );
}

function TableTotals({ subtotal }) {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>Subtotal</TableCell>
        <TableCell>$ {Math.round(subtotal).toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>IVA</TableCell>
        <TableCell>$ {Math.round(subtotal * 0.19).toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>Total</TableCell>
        <TableCell>
          $ {Math.round(subtotal * 0.19 + subtotal).toLocaleString()}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Container = styled.div`
  padding: 2rem;
  font-size: 16;
`;

const Avatar = styled.img`
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 100px;
`;

export default Quoter2;
