import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useParams } from "react-router-dom";

import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import CustomButton from "../../../../components/Button/CustomButton";
import useFetch from "../../../../ecommerce/hooks/useFetch";
import styles from "./OrderDetailsPage.module.scss";
import {
  OrderDataSection,
  OrderHeader,
  OrderItemsTable,
  OrderSummarySection,
} from "./components";
import { OrderDetail } from "./types";

type OrderDetailsPageProps = {
  userType: "admin" | "suppliers";
};

const OrderDetailsPage = ({ userType }: OrderDetailsPageProps) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const { orderId } = useParams<{ orderId: string }>();

  const url = `/v2/${userType}/suborders/${+orderId}`;
  const { isLoading, apiData: orderDetail } = useFetch<OrderDetail>({
    method: "GET",
    url,
  });

  if (isLoading || !orderDetail) {
    return <LoadingScreen />;
  }

  return (
    <section className="layout-dashboard-container">
      <div className={styles.btnText}>
        <CustomButton
          color="secondary"
          shape="little-rounded"
          icon={ArrowBackIosIcon}
          onClick={goBack}
        >
          volver a ordenes
        </CustomButton>
      </div>
      <div className={styles.content}>
        <OrderHeader
          orderNumber={orderDetail?.orderNumber}
          purchaseDate={orderDetail?.purchaseDate}
        />
        <OrderDataSection orderInformation={orderDetail} />
        <OrderSummarySection>
          <OrderItemsTable product={orderDetail.product} />
        </OrderSummarySection>
      </div>
    </section>
  );
};
export default OrderDetailsPage;
