import { Product } from "../../types";
import Item from "../item/Item";

interface OrderListProps {
  product: Product;
  status: string;
}
const ItemList = ({ product, status }: OrderListProps) => {
  return (
    <div>
      <Item key={product.code} product={product} status={status} />
    </div>
  );
};
export default ItemList;
