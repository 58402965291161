export const transformDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
    date
  );
  const year = date.getFullYear();

  return `${day} de ${month} de ${year}`;
};
