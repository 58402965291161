import React, { useState, useEffect, Fragment } from "react";
import { materialTableES } from "../../services/materialTableES";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";

import MaterialTable, { MTableToolbar } from "material-table";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ProductModal from "../../components/ProductModal/ProductModal";

import Modal from "../../components/Modal/Modal";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";

import axios from "axios";

/**
 * LSH's can see select products and change installation price
 * to maintain an inventory control
 */
function LSHProducts() {
  let data = useLocation();

  const [utility, setUtility] = useState(true);
  const [products, setProducts] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [productDetail, setProductDetail] = useState();

  // FIXME: we should migrate away from jssUseStyles()
  const classes = jssUseStyles();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    let response = await axios.get("/v1/products?approved=true");
    setProducts(response.data);
    setFilteredProducts(response.data);
  };

  const allProducts = () => {
    setFilteredProducts(products);
  };

  // NOTE: currently the filtering is being done via javascript, we could also
  // do it through an api endpoint, and that way we wouldn't have to load all
  // products every time we filter something
  const filterByCoatings = () => {
    // Show only products with the 'type' coating
    var coatings = products.filter((product) => {
      return product.type === "revestimiento";
    });
    setFilteredProducts(coatings);
  };

  const filterByDecoratives = () => {
    var decoratives = products.filter((product) => {
      return product.type === "decorativo";
    });
    setFilteredProducts(decoratives);
  };

  const handleClose = (event) => {
    setProductModal(false);
  };

  return (
    <Fragment>
      <Modal
        showModal={productModal}
        closeModal={handleClose}
        contentMaxWidth="1000px"
        contentMaxHeight="500px"
      >
        <ProductModal product={productDetail} />
      </Modal>
      <div className={classes.mtable}>
        <MaterialTable
          title="Catálogo de Productos"
          columns={[
            {
              width: "0px",
              field: "image",
              render: (rowData) => (
                <img
                  alt="producto"
                  src={rowData.image}
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                />
              ),
              filtering: false,
              editable: "never",
            },
            { title: "Proveedor", field: "company_name" },
            {
              title: "Marca",
              render: (rowData) => rowData.brand.name,
              customFilterAndSearch: (term, rowData) =>
                (
                  rowData.brand.name +
                  rowData.description +
                  rowData.comission_percentage / 2
                )
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) !== -1,
              editable: "never",
            },
            { title: "Referencia", field: "name", editable: "never" },
            {
              title: "Unidad de Venta",
              field: "presentation",
              editable: "never",
            },
            {
              title: "UNV de Empaque",
              field: "min_sale_unit",
              type: "numeric",
              editable: "never",
            },
            {
              title: "Precio",
              field: "price",
              type: "currency",
              currencySetting: {
                currencyCode: "COP",
                locale: "col",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
              editable: "never",
            },
            {
              title: "Utilidad (%)",
              type: "numeric",
              render: (rowData) => {
                if (utility) {
                  // Divided by two since it's the designer's comission
                  return rowData.comission_percentage / 2;
                }
              },
              editable: "never",
            },
            {
              title: "Unidades en Inventario",
              field: "units_available",
              type: "numeric",
              editable: "never",
            },
          ]}
          data={filteredProducts}
          editable={{
            onRowUpdate: async (newData, oldData) => {
              let editableFields = {
                installation_price: newData.installation_price,
              };

              await axios
                .patch(`/v1/products/${oldData.id}`, editableFields)
                .then((res) => {
                  const dataUpdate = [...filteredProducts];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setFilteredProducts([...dataUpdate]);
                })
                .catch((e) => {
                  throw e;
                });
            },
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    marginBottom: "1rem",
                  }}
                >
                  <div>
                    <Chip
                      style={{
                        color: "#112A36",
                        backgroundColor: "#E2CA8A",
                        marginRight: 10,
                      }}
                      label="Todos"
                      clickable={true}
                      onClick={allProducts}
                    />
                    <Chip
                      style={{
                        color: "#FFF",
                        backgroundColor: "#5D777B",
                        marginRight: 10,
                      }}
                      label="Revestimientos"
                      clickable={true}
                      onClick={filterByCoatings}
                    />
                    <Chip
                      style={{
                        color: "#112A36",
                        backgroundColor: "#DADDD8",
                        marginRight: 10,
                      }}
                      label="Decoración"
                      clickable={true}
                      onClick={filterByDecoratives}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      style={{ marginLeft: "1rem" }}
                      control={
                        <Switch
                          checked={utility}
                          onChange={(event) => {
                            setUtility(utility === true ? false : true);
                          }}
                        />
                      }
                      label="Mostrar utilidad"
                    />
                  </div>
                </div>
              </div>
            ),
          }}
          onRowClick={(evt, selectedRow) => {
            setProductModal(true);
            setProductDetail(selectedRow);
          }}
          options={{
            padding: "dense",
            clickable: true,
            actionsColumnIndex: -1,
          }}
          localization={materialTableES}
          isLoading={products ? false : true}
        />
      </div>
    </Fragment>
  );
}

const jssUseStyles = createUseStyles({
  mtable: {
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiToolbar-root .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  chip: {
    marginRight: 10,
  },
  chip1: {
    color: "#112A36",
    backgroundColor: "#E2CA8A",
  },
  chip2: {
    color: "#FFF",
    backgroundColor: "#5D777B",
  },
  chip3: {
    color: "#FFF",
    backgroundColor: "#AA8684",
  },
  chip4: {
    color: "#112A36",
    backgroundColor: "#DADDD8",
  },
});

export default LSHProducts;
