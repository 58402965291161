import { Column } from "material-table";

import CustomLink from "../../../../../../components/CustomLink/CustomLink";
import DocumentControls from "../../../../../../components/DocumentControls/DocumentControls";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { type Purchase } from "../../types";

type PurchasesTableColumnsProps = {
  setDetailData: (value: any) => void;
  detailData: any;
};

const PurchasesTableColumns = ({
  setDetailData,
  detailData,
}: PurchasesTableColumnsProps) => {
  const tableColumns: Array<Column<Purchase>> = [
    {
      title: "Nro. de Venta",
      type: "string",
      align: "left",
      render: (rowData) => (
        <CustomLink to={`/admin/ventas/${rowData.id}/proveedores`}>
          {rowData.orderNumber}
        </CustomLink>
      ),
    },
    {
      title: "Fecha",
      field: "purchaseDate",
      type: "date",
    },
    {
      title: "Datos de facturación",
      render: (rowData) => (
        <div>
          <CustomButton
            shape="little-rounded"
            color="outlined"
            size="small"
            className="btn btn-primary"
            onClick={() => setDetailData(rowData.billingInformation)}
          >
            Ver detalle
          </CustomButton>
        </div>
      ),
    },
    {
      title: "Factura para Cliente",
      render: (rowData) => (
        <DocumentControls
          fileData={rowData.files}
          orderId={rowData.id}
          userType="customer"
          fileName="invoice"
        />
      ),
    },
  ];

  return tableColumns;
};
export default PurchasesTableColumns;
