import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./auth";

import jwt from "jwt-decode";

function PrivateRoute({ render: Component, accessLevel, ...rest }) {
  const { authToken } = useAuth();
  let decoded = authToken && jwt(authToken);
  let isAllowed = false;

  if (Array.isArray(accessLevel)) {
    if (typeof accessLevel[0] === "number") {
      isAllowed = decoded && accessLevel.includes(decoded.role_id);
    } else {
      isAllowed =
        decoded && accessLevel.some((level) => level.includes(decoded.role_id));
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAllowed ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default PrivateRoute;
