import Box from "@material-ui/core/Box";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";

interface SimpleRatingProps {
  size?: "small" | "medium" | "large" | undefined;
}
const SimpleRating = ({ size }: SimpleRatingProps) => {
  return (
    <Box
      component="fieldset"
      mb={3}
      borderColor="transparent"
      style={{ margin: "0" }}
    >
      <Rating
        name="customized-empty"
        defaultValue={2}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        size={size}
      />
    </Box>
  );
};
export default SimpleRating;
