import { Avatar, makeStyles, Typography } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import { Link } from "react-router-dom";
import {
  useDeleteSalesPerson,
  useGetSalesPerson,
} from "../../../../../../utils/react-query/querySupplier/queries";
import ItemActionsMenu from "../../../../components/Products/ItemActionsMenu";

const useStyles = makeStyles((theme) => ({
  avatarBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  email: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#919EAB",
  },
  name: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#1c252e",
    fontFamily: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function SalesPersonTable() {
  const classes = useStyles();
  const { data: salesPeople = [], isLoading: isLoadingSalesPeople } =
    useGetSalesPerson();
  const { mutateAsync: deleteSalesPerson } = useDeleteSalesPerson();

  const handleDeleteSalesPerson = async (id: number) => {
    await deleteSalesPerson(id);
  };

  const columns: Column<SalesPeople>[] = [
    {
      title: "Avatar",
      field: "avatar",
      width: "10%",
      render: (rowData) => {
        if (!rowData.avatar) {
          return null;
        }
        return <Avatar alt={rowData.first_name} src={rowData.avatar} />;
      },
    },
    {
      title: "Nombre",
      field: "first_name",
      defaultSort: "asc",
      render: (rowData) => {
        return (
          <Link to={`/proveedor/asesor/${rowData.id}/editar`}>
            <Typography
              className={classes.name}
            >{`${rowData.first_name} ${rowData.last_name}`}</Typography>
          </Link>
        );
      },
    },
    {
      title: "Correo",
      field: "email",
      editable: "never",
    },
    {
      title: "Celular",
      field: "phone",
      editable: "never",
    },
    {
      title: "Ciudad",
      field: "city_name",
      editable: "never",
    },
    {
      title: "Perfil Profesional",
      field: "professional_profile",
      editable: "never",
    },
    {
      title: "",
      field: "actions",
      render: (rowData) => (
        <ItemActionsMenu
          itemId={rowData.id}
          editRoute={`/proveedor/asesor/${rowData.id}/editar`}
          onDelete={() => handleDeleteSalesPerson(rowData.id)}
        />
      ),
    },
  ];

  return (
    <div>
      <MaterialTable
        title={""}
        columns={columns}
        data={salesPeople as SalesPeople[]}
        components={{}}
        isLoading={isLoadingSalesPeople}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
          headerStyle: {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: "14px",
            fontWeight: 600,
            height: "4rem",
          },
          rowStyle: {
            fontSize: "14px",
            fontWeight: 400,
            color: "#1C252E",
          },
          searchFieldStyle: {
            marginBlock: "1rem",
          },
          searchFieldVariant: "outlined",
        }}
      />
    </div>
  );
}

export default SalesPersonTable;
