import BreadcrumbNav from "../../../../components/BreadcrumbNav/BreadcrumbNav";
import SalesPersonForm from "../../components/SalesPersonForm/SalesPersonForm";

const SalesPersonCreate = () => {
  return (
    <section className="layout-dashboard-container">
      <div>
        <div className="header-container">
          <h4 className="header-title">Crear nuevo Asesor</h4>
          <BreadcrumbNav
            firstText="Proveedor"
            secondText="asesor"
            secondLink="/proveedor/asesor/lista"
            thirdText={"Nuevo asesor"}
          />
        </div>
        <SalesPersonForm action="create" />
      </div>
    </section>
  );
};

export default SalesPersonCreate;
