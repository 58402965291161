import { yupResolver } from "@hookform/resolvers";
import { Paper } from "@material-ui/core";
import Axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import CustomButton from "../../../../../../components/Button/CustomButton";
import InputValidationMessage from "../../../../../../components/forms/InputValidationMessage/InputValidationMessage";
import { warehousesSchema } from "../../../../../../services/formValidation";
import { errorToast, successToast } from "../../../../../../services/toasts";
import { useGetCities } from "../../../../../../utils/react-query/queryCheckout/queries";

type DispatchAddress = {
  id: number;
  street_address: string;
  city_name: string;
};

const Warehouses = () => {
  const [warehouses, setWarehouses] = useState<DispatchAddress[]>();
  const [addressCount, setAddressCount] = useState(0);
  const [isAddingAddress, setIsAddingAddress] = useState(false);

  const { register, errors, handleSubmit, control } = useForm({
    resolver: yupResolver(warehousesSchema),
    defaultValues: {
      street_address: "",
      city_name: "",
    },
  });

  const getWarehouses = async () => {
    let { data } = await Axios.get(`/v2/suppliers/storage-address`);
    setWarehouses(data);
    setAddressCount(data?.length);
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  const handleAddNewAddress = async (data: any) => {
    try {
      if (addressCount && addressCount! < 3) {
        const response = await Axios.post(
          `/v2/suppliers/storage-address`,
          data
        );

        setWarehouses([...warehouses!, response.data]);

        successToast("Dirección de bodega agregada exitosamente");
        setIsAddingAddress(false);

        setAddressCount((prev) => prev! + 1);
      } else {
        errorToast("No puedes agregar más de 3 direcciones de bodega");
      }
    } catch (error) {
      errorToast("Error al agregar la dirección de bodega");
    }
  };

  const { data: cityOptions, isLoading } = useGetCities();

  return (
    <div>
      <Paper style={{ padding: "2rem" }}>
        <h1
          style={{
            fontWeight: "normal",
            fontSize: "1.5rem",
            color: "#232f3e",
          }}
        >
          Direcciones de despacho
        </h1>
        <br />
        <p style={{ fontSize: "1rem", color: "#232f3e" }}>
          Recuerda que si deseas cambiar una de estas direcciones, puedes
          solicitarlo a nuestro equipo de soporte enviándonos un correo a{" "}
          <a href="mailto:proveedores@fliping.co">proveedores@fliping.co</a>.
        </p>
        <br />

        {warehouses && warehouses.length > 0 ? (
          warehouses.map((warehouse, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <h3>Dirección de bodega {index + 1}</h3>
              <p>{`Calle: ${warehouse.street_address}`}</p>
              <p>{`Ciudad: ${warehouse.city_name}`}</p>
            </div>
          ))
        ) : (
          <p>No hay direcciones de bodega disponibles.</p>
        )}

        {isAddingAddress ? (
          <div>
            <h3>Agregar Nueva Dirección de Bodega</h3>

            <form onSubmit={handleSubmit(handleAddNewAddress)}>
              <Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    margin: "0 0.5rem",
                    flex: 1,
                  }}
                >
                  <StyledLabel>Calle: </StyledLabel>
                  <StyledInput
                    type="text"
                    placeholder="Ej: Av. 1 # 2 - 3"
                    name="street_address"
                    ref={register}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    margin: "0 0.5rem",
                    flex: 1,
                  }}
                >
                  <StyledLabel>Ciudad: </StyledLabel>
                  {cityOptions && cityOptions.length > 0 && (
                    <Controller
                      name="city_name"
                      control={control}
                      render={({ ...field }) => (
                        <Select
                          isLoading={isLoading}
                          placeholder="Selecciona una ciudad"
                          {...field}
                          options={cityOptions}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.label);
                          }}
                          value={cityOptions.find(
                            (option) => option.value === field.value
                          )}
                        />
                      )}
                    />
                  )}
                </div>
              </Row>
              <InputValidationMessage
                message={errors?.street_address?.message}
              />
              <InputValidationMessage message={errors?.city_name?.message} />

              <Row
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <CustomButton
                  type="submit"
                  shape="little-rounded"
                  color="primary"
                >
                  Guardar Dirección de Bodega
                </CustomButton>
                <CustomButton
                  shape="little-rounded"
                  color="secondary"
                  onClick={() => setIsAddingAddress(false)}
                >
                  Cancelar
                </CustomButton>
              </Row>
            </form>
          </div>
        ) : (
          <CustomButton
            type="submit"
            shape="little-rounded"
            disabled={addressCount >= 3}
            color="primary"
            onClick={() => setIsAddingAddress(true)}
          >
            Agregar Dirección de Bodega
          </CustomButton>
        )}
      </Paper>
    </div>
  );
};

export default Warehouses;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledLabel = styled.label`
  flex-grow: 1;
  font-family: "Open Sans";
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 300;
`;

const StyledInput = styled.input`
  flex-grow: 1;
  font-family: "Open sans";
  padding: 0.4rem 1rem;
`;
