import { IconButton, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import ImageDopper from "../../../../../../assets/svg/ImageDropper.svg";
import styles from "./ImagesForm.module.scss";
import { Image } from "../../../../../../ecommerce/models";
import CustomButton from "../../../../../../components/Button/CustomButton";
import InputValidationMessage from "../../../../../../components/forms/InputValidationMessage/InputValidationMessage";

type FileUploaderProps = {
  fileldChange: (FILES: File[]) => void;
  images: Image[];
  isLoading: boolean;
  onDeleteImage: (id: number) => void;
  isButtonDisabled: boolean;
  setIsButtonDisabled: (isDisabled: boolean) => void;
  setDeletedImages: (images: Image[]) => void;
};

const MultipleFileUploader = ({
  fileldChange,
  images,
  isLoading,
  onDeleteImage,
  isButtonDisabled,
  setIsButtonDisabled,
  setDeletedImages,
}: FileUploaderProps) => {
  const originalLength = useRef(images.length);

  const [preloadedImages, setPreloadedImages] = useState<Image[]>(images);
  const [file, setFile] = useState<File[]>([]);
  const [fileUrl, setFileUrl] = useState<string[]>([]);
  const totalImages = preloadedImages.length + file.length;

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      let newFiles: FileWithPath[] = [];
      setFile((prevFiles) => {
        newFiles = [...prevFiles, ...acceptedFiles];
        return newFiles;
      });

      fileldChange(newFiles);

      setFileUrl((prevUrls) => [
        ...prevUrls,
        ...acceptedFiles.map((file) => URL.createObjectURL(file)),
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file, fileldChange]
  );

  useEffect(() => {
    if (
      preloadedImages.length !== originalLength.current ||
      file.length !== 0
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [file, preloadedImages, setIsButtonDisabled]);

  // Reset all states to their initial value.
  const resetStates = () => {
    setPreloadedImages(images);
    setFile([]);
    setFileUrl([]);
    setIsButtonDisabled(true);
    setDeletedImages([]);
  };

  const handleDeleteImage = (index: number) => {
    setFile((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index);
      fileldChange(newFiles);
      return newFiles;
    });
    setFileUrl((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleDeletePreloadedImage = (id: number) => {
    setPreloadedImages((prevImages) =>
      prevImages.filter((image) => image.id !== id)
    );
    onDeleteImage(id);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".svg"],
    },
    disabled: totalImages >= 5 ? true : false,
  });

  return (
    <Paper className="paperFormContainer">
      <div
        {...getRootProps()}
        className={`${styles.imageDropperContainer} ${
          totalImages > 5 || totalImages === 0 ? styles.errorMessage : ""
        }`}
      >
        <input {...getInputProps()} />
        <div className={styles.fileUploaderBox}>
          <img src={ImageDopper} alt="file-upload" />

          <h3>Arrastra o selecciona un archivo</h3>
          <p>
            Suelta archivos aquí o haz clic para
            <span>navegar</span> a través de tu equipo.
          </p>
        </div>
      </div>
      <InputValidationMessage
        message={
          totalImages > 5
            ? "Por favor, selecciona hasta un máximo de 5 imágenes. ¡Gracias!"
            : totalImages === 0
            ? "Por favor, selecciona al menos una imagen. ¡Gracias!"
            : ""
        }
      />
      <div className={styles.fileUploadedContainer}>
        {preloadedImages &&
          preloadedImages.map(({ id, url }) => (
            <div key={id} className={styles.fileUploadedBox}>
              <img src={url} alt={url} className={styles.fileUploaderImg} />
              <IconButton
                aria-label="delete"
                className={styles.deleteButton}
                onClick={() => handleDeletePreloadedImage(id)}
              >
                <CloseIcon fontSize="small" className={styles.deleteIcon} />
              </IconButton>
            </div>
          ))}
        {fileUrl.map((url, index) => (
          <div key={index} className={styles.fileUploadedBox}>
            <img src={url} alt={url} className={styles.fileUploaderImg} />
            <IconButton
              aria-label="delete"
              className={styles.deleteButton}
              onClick={() => handleDeleteImage(index)}
            >
              <CloseIcon fontSize="small" className={styles.deleteIcon} />
            </IconButton>
          </div>
        ))}
      </div>
      <div className="formProductSaveButton">
        <CustomButton
          type="button"
          color="outlined"
          shape="little-rounded"
          size="small"
          disabled={isButtonDisabled}
          onClick={resetStates}
        >
          Cancelar
        </CustomButton>
        <CustomButton
          type="submit"
          color="secondary"
          shape="little-rounded"
          size="small"
          disabled={isButtonDisabled || totalImages > 5 || totalImages === 0}
          isLoading={isLoading}
        >
          Guardar Cambios
        </CustomButton>
      </div>
    </Paper>
  );
};
export default MultipleFileUploader;
