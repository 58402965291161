import { Fragment } from "react";
import { stepNames, StepName } from "../RemodelingStatusTable/StatusTable";
import { stepHelpModalContent } from "./StepHelpModalContent";

import Modal from "../../../components/Modal/Modal";
import DefaultContent from "../../../components/Modal/DefaultContent";

type StepHelpModalProps = {
  closeStepHelpModal: () => void;
  isShowingStepHelpModal: boolean;
  stepName: StepName;
};

export default function StepHelpModal({
  closeStepHelpModal,
  isShowingStepHelpModal,
  stepName,
}: StepHelpModalProps) {
  return (
    <Fragment>
      <Modal showModal={isShowingStepHelpModal} closeModal={closeStepHelpModal}>
        <DefaultContent
          heading={`${stepNames[stepName]}`}
          label={`Información`}
          fontAwesomeHeadingIcon="info-circle"
          fontAwesomeHeadingIconColor={"lightskyblue"}
          showFooter={false}
        >
          {stepHelpModalContent[stepName]}
        </DefaultContent>
      </Modal>
    </Fragment>
  );
}

