const informationFieldsForm = {
  minimumSellingUnit:
    "Hace referencia a la cantidad de m² que tiene una caja, un paquete o un rollo",
  comission:
    "Ten en cuenta que este porcentaje de utilidad se dividirá en partes iguales entre el profesional y la plataforma",
  name: "Este será el encabezado de tu producto",
  material:
    "Especifica por favor el material o materiales del producto. Ej: Latón, Madera, Mármol, etc",
  uniqueProduct:
    "Esto quiere decir que tu producto es único e irrepetible. Ej: Cuadro al óleo, mesa de tronco",
  deliveryTime:
    "Al tiempo de entrega por favor sumar 3 días que tarda la transportadora en entregar los productos",
  color:
    "Selecciona por favor el color o el acabado más cercano a tu producto. Si tu producto incluye más de un color, por favor, selecciona la opción 'Multicolor'.",
  shippingAddress:
    "Esta será la dirección de la bodega donde se almacenará tu producto. Ten en cuenta que la transportadora recogerá el producto en esta dirección.",
  homeAndWellnessMaterial:
    "Especifica por favor el material o materiales del producto. Ej: Seda, Poliester, Algodón, Vidrio, Madera, etc",
  petsMaterial:
    "Especifica por favor el material o materiales del producto. Ej: Algodón, Plástico, Vidrio, Madera, etc",
  size: "Solo aplica para ropa de cama",
  product_content:
    "Especificar en ml o gr el contenido del producto, si es un set o kit por favor especificar las unidades",
};

export default informationFieldsForm;
