import React from "react";
import styled from "styled-components";

/**
 */
function Input({ placeholder, register, ...props }) {
  return (
    <StyledInput
      placeholder={placeholder}
      placeholderTextColor="grey"
      ref={register}
      {...props}
    />
  );
}

const StyledInput = styled.input.attrs((props) => ({
  type: props.type,
}))`
  width: 90%;
  outline: none;
  margin: 1rem 0.5rem;
  padding: 0.25rem 1rem;
  background-color: inherit;
  border: none;
  border-bottom: 1.25px solid black;
  transition: all 300ms ease 0s;

  &:focus {
    border-bottom: 1.25px solid #fdd440;
    transition: all 300ms ease 0s;
  }
`;

Input.defaultProps = {};

export default Input;
