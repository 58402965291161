import { Paper } from "@material-ui/core";
import { useCallback, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import styles from "../../pages/ProductEditor/components/ImagesForm/ImagesForm.module.scss";

type FileUploaderProps = {
  mediaUrl: string;
  fileldChange: (FILES: File[]) => void;
  error: any;
};

const SalesPersonAvatar = ({
  mediaUrl,
  fileldChange,
  error,
}: FileUploaderProps) => {
  const [fileUrl, setFileUrl] = useState(mediaUrl);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      fileldChange(acceptedFiles);
      setFileUrl(URL.createObjectURL(acceptedFiles[0]));
    },
    [fileldChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg", ".webp"],
    },
    multiple: false,
  });

  return (
    <Paper className="paperFormContainer">
      <div className={`${styles.fileUploadContainer} `}>
        <div
          className={`${styles.fileUploadImage} ${
            error?.avatar?.message ? styles.error : ""
          }`}
        >
          <input {...getInputProps()} />
          <div className={`${styles.fileUploadArea} `} {...getRootProps()}>
            {fileUrl ? (
              <>
                <span className={styles.filePreview}>
                  <span className={styles.filePreviewImage}>
                    <img alt="Avatar" src={fileUrl} />
                  </span>
                </span>
                <div className={styles.fileUploadHover}>
                  <AddAPhotoIcon />
                  <span>Actualizar Imagen</span>
                </div>
              </>
            ) : (
              <div
                className={`${styles.fileUploadPlaceholder} ${
                  error?.avatar?.message
                    ? styles.fileUploadPlaceholderError
                    : ""
                }`}
              >
                <AddAPhotoIcon />
                <span>Cargar Imagen</span>
              </div>
            )}
          </div>
        </div>

        <span className={styles.fileUploadInstructions}>
          Permitidos *.jpeg, *.jpg, *.png, *.gif *.webp
          <br />
          tamaño máximo de 15 MB
        </span>

        {error?.avatar?.message && (
          <p className={styles.errorMessageImage}>{error?.avatar?.message}</p>
        )}

        <h6 className={styles.singleFileLable}>
          Haz clic o arrastra la foto para reemplazarla.
        </h6>
      </div>
    </Paper>
  );
};

export default SalesPersonAvatar;
