import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router";
import BreadcrumbNav from "../../../../components/BreadcrumbNav/BreadcrumbNav";
import CustomButton from "../../../../components/Button/CustomButton";
import ProductTable from "./ProductTable";

const SupplierProducts = () => {
  const history = useHistory();
  return (
    <section className="layout-dashboard-container">
      <div className="header-container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>
            <h4 className="header-title">Lista Productos</h4>
            <BreadcrumbNav
              firstText="Proveedor"
              secondText="Producto"
              secondLink="/proveedor/producto/lista"
              thirdText={"Lista"}
            />
          </div>
          <CustomButton
            color="secondary"
            shape="little-rounded"
            icon={AddIcon}
            onClick={() => history.push("/proveedor/producto/nuevo")}
          >
            Nuevo producto
          </CustomButton>
        </Box>
      </div>
      <ProductTable />
    </section>
  );
};
export default SupplierProducts;
