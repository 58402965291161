import { Badge } from "@material-ui/core";
import styles from "./orderStatusBadge.module.scss";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";

interface OrderStatusBadgeProps {
  status: string;
  count: number;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "rgb(163, 175, 199)",
    },
  })
)(Badge);

const OrderStatusBadge = ({ status, count }: OrderStatusBadgeProps) => {
  return (
    <span className={styles.container}>
      <span>{status}</span>

      <StyledBadge
        className={styles.prueba}
        badgeContent={count}
        color="primary"
        showZero={true}
      />
    </span>
  );
};

export default OrderStatusBadge;
