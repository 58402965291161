// import { useHistory } from "react-router";

import { useHistory } from "react-router";
import Button from "../../../../components/EcommerceActionButton/Button";
import { formatNumberToPrice } from "../../../../helpers/priceFormatting";
import { type Product } from "../../types";
import styles from "./item.module.scss";
import useCompanyStore from "../../../../Store/supplierCompany/companyData";

interface OrderItemProps {
  product: Product;
  status: string;
}

const Item = ({ product, status }: OrderItemProps) => {
  const history = useHistory();
  const { companyName } = useCompanyStore();
  // TODO : Uncomment this code when the review page is ready
  // const handleReview = () => {
  //   history.push(`/mis-compras/calificar/${product.code}`);
  // };

  const handleBuyAgain = () => {
    history.push(
      `/${companyName}/productos/detalles-del-producto/${product.code}`
    );
  };
  return (
    <article className={styles.item}>
      <div className={styles.content}>
        <div className={styles.itemProduct}>
          <div className={styles.itemImage}>
            <img src={product.imageUrl} alt="productImage" />
          </div>
          <div className={styles.itemData}>
            <p className={styles.itemTitle}>{product.name}</p>
            <p className={styles.itemInfo}>{product.amount} unidad</p>
            <p className={styles.itemPrice}>
              {formatNumberToPrice(product.price)}
            </p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {/* 
           //TODO: This will be removed while defining whether it targets the current ecommerce or is removed completely
            */}
          <Button
            color="primary"
            shape="little-rounded"
            size="small"
            block
            onClick={handleBuyAgain}
          >
            Volver a comprar
          </Button>
          <Button color="secondary" shape="little-rounded" size="small" block>
            <a
              href="https://api.whatsapp.com/send/?phone=573023681070&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ayuda con la compra
            </a>
          </Button>

          {/*
           // TODO : Uncomment this code when the review page is ready
           {status ? (
            <Button
              color="outlined"
              shape="little-rounded"
              size="small"
              block
              onClick={handleReview}
            >
              Escribir una opinión
            </Button>
          ) : null} */}
        </div>
      </div>
    </article>
  );
};
export default Item;
