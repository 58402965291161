import { useState } from "react";
import { useForm } from "react-hook-form";

import Paper from "@material-ui/core/Paper";
import Button from "../../components/Button/Button";

import axios from "axios";
import styled from "styled-components";
import { successToast } from "../../services/toasts";

function CreateMessage() {
  const { register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState();
  const [recipients, setRecipients] = useState({
    designers: false,
    lsh: false,
  });

  const onSubmit = async (data) => {
    setLoading(true);

    let sendTo = Object.keys(recipients).filter(
      (key) => recipients[key] === true
    );

    if (attachment) {
      // TODO: add request to add file to message
      data.attachment = attachment;
    }

    await axios.post(`/v1/messages?recipient=${sendTo}`, data);
    setLoading(false);
    successToast("Tu mensaje ha sido enviado exitosamente");
  };

  return (
    <section className="layout-dashboard-container">
      <Paper style={{ padding: "2rem" }}>
        <h1
          style={{ fontWeight: "normal", fontSize: "1.5rem", color: "#232f3e" }}
        >
          Crear un mensaje global
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <StyledLabel htmlFor="title">Titulo</StyledLabel>
            <input name="title" type="text" ref={register} />
          </Container>
          <Container>
            <StyledLabel htmlFor="message">Mensaje</StyledLabel>
            <textarea name="text" cols="30" rows="10" ref={register} />
            <input
              type="file"
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </Container>
          <CheckboxContainer>
            <label>
              <input
                type="checkbox"
                onChange={(event) =>
                  setRecipients({
                    ...recipients,
                    designers: event.target.checked,
                  })
                }
              />
              Diseñador
            </label>
          </CheckboxContainer>
          <CheckboxContainer>
            <label>
              <input
                type="checkbox"
                onChange={(event) =>
                  setRecipients({ ...recipients, lsh: event.target.checked })
                }
              />
              LSH
            </label>
          </CheckboxContainer>
          <Button $isLoading={loading} label="Enviar" type="submit" />
        </form>
      </Paper>
    </section>
  );
}

const StyledLabel = styled.label`
  color: #24292e;
  font-weight: 600;
  margin-top: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  padding-left: 20px;
  line-height: 1.5;
  margin-top: 0.5rem;
  font-size: 1rem;

  input[type="checkbox"] {
    float: left;
    margin: 5px 0 0 -20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  input,
  textarea {
    margin: 1rem 0;
    font-family: "Open sans";
    padding: 0.25rem 1rem;
    margin-top: 0.5rem;
  }

  .MuiSvgIcon-root {
    color: #232f3e;
    cursor: pointer;
  }
`;

export default CreateMessage;
