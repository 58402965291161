import styled from "styled-components";

export const StatusPill = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roboto";
  color: #505659;
`;

export const StatusDot = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const BeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const Bead = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  z-index: 1;
`;

export const BeadLine = styled.div`
  display: block;
  width: 1px;
  position: absolute;
  height: 100rem;
  background: #afafaf;
  bottom: 50%;
  z-index: 0;
`;

