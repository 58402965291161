import { useState, useEffect, Fragment } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import ClientLoginCard from "../../components/ClientLoginCard/ClientLoginCard";
import "./ConsultancySections/ConsultancySections.css";

function HomeLogin() {
  const [cities, setCities] = useState();
  const [openOthers, setOpenOthers] = useState(false);
  const [openClients, setOpenClient] = useState(false);

  const handleOpen = () => {
    setOpenOthers(true);
  };

  const handleOpenClient = () => {
    setOpenClient(true);
  };

  const handleCloseOthers = () => {
    localStorage.clear();
    setOpenOthers(false);
    return false;
  };

  const handleCloseClient = () => {
    localStorage.clear();
    setOpenClient(false);
    return false;
  };

  useEffect(() => {
    getData();
    // FIXME: getIP is not working, since it's an external
    // service (ip-api) it could be that we need to pay for the
    // services.
  }, []);

  const getData = async () => {
    let res = await axios.get("/v1/cities");
    setCities(res.data);
  };

  return (
    <>
      <Navbar />
      <section>
        <div className="login__background">
          <div className="container__form">
            <ClientLoginCard />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default HomeLogin;
