import { Paper } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Accept, FileWithPath, useDropzone } from "react-dropzone";
import pdfLogo from "../../../../../../assets/images/file-pdf-solid-240.png";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { UploadedFile } from "../../../../../../ecommerce/models";
import styles from "./ImagesForm.module.scss";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import DescriptionIcon from "@material-ui/icons/Description";

type FileUploaderProps = {
  fileldChange: (FILES: File[]) => void;
  image?: UploadedFile | null;
  isLoading: boolean;
  isButtonDisabled: boolean;
  setIsButtonDisabled: (isDisabled: boolean) => void;
  fileType: "image" | "pdf";
};

const SingleFileUploader = ({
  fileldChange,
  image = null,
  isLoading,
  isButtonDisabled,
  setIsButtonDisabled,
  fileType,
}: FileUploaderProps) => {
  const [file, setFile] = useState<File[]>([]);
  const [fileUrl, setFileUrl] = useState<{ url: string; name: string } | null>(
    image ? { url: image.url, name: image.name } : null
  );

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFile(acceptedFiles);
      fileldChange(acceptedFiles);
      setFileUrl({
        url: URL.createObjectURL(acceptedFiles[0]),
        name: acceptedFiles[0].name,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file]
  );
  const acceptedFormats: Accept =
    fileType === "image"
      ? { "image/*": [".png", ".jpg", ".jpeg", ".svg"] }
      : { "application/pdf": [".pdf"] };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFormats,
    multiple: false,
  });

  useEffect(() => {
    setIsButtonDisabled(file.length === 0);
  }, [file, setIsButtonDisabled]);

  const handleCancel = () => {
    setFile([]);
    if (image) {
      setFileUrl({ url: image.url, name: image.name });
    } else {
      setFileUrl(null);
    }
  };

  return (
    <Paper className="paperFormContainer">
      <>
        <div className={styles.fileUploadContainer}>
          <div className={styles.fileUploadImage}>
            <input {...getInputProps()} />
            <div className={styles.fileUploadArea} {...getRootProps()}>
              {fileUrl ? (
                <>
                  <span className={styles.filePreview}>
                    <span className={styles.filePreviewImage}>
                      <img
                        alt={fileUrl.url}
                        src={fileType === "pdf" ? pdfLogo : fileUrl.url}
                      />
                    </span>
                  </span>
                  <div className={styles.fileUploadHover}>
                    {fileType === "pdf" ? (
                      <DescriptionIcon />
                    ) : (
                      <AddAPhotoIcon />
                    )}
                    <span>
                      {fileType === "pdf"
                        ? "Actualizar Archivo"
                        : "Actualizar Imagen"}
                    </span>
                  </div>
                </>
              ) : (
                <div className={styles.fileUploadPlaceholder}>
                  {fileType === "pdf" ? <DescriptionIcon /> : <AddAPhotoIcon />}
                  <span>
                    {fileType === "pdf" ? "Cargar Archivo" : "Cargar Imagen"}
                  </span>
                </div>
              )}
            </div>
          </div>
          {fileType === "image" && (
            <span className={styles.fileUploadInstructions}>
              Permitidos *.jpeg, *.jpg, *.png, *.gif
              <br />
              tamaño máximo de 2 Mb
            </span>
          )}

          {fileType === "pdf" && fileUrl ? (
            <p className={styles.fileNameDisplay}>
              {fileUrl?.name?.length > 35
                ? fileUrl?.name.substring(0, 35) + "..."
                : fileUrl?.name}
            </p>
          ) : null}

          {fileType === "pdf" ? (
            <h6 className={styles.singleFileLable}>
              Haz clic o arrastra el archivo para reemplazarla.
            </h6>
          ) : (
            <h6 className={styles.singleFileLable}>
              Haz clic o arrastra la foto para reemplazarla.
            </h6>
          )}
        </div>
      </>
      <div className="formProductSaveButton">
        <CustomButton
          type="button"
          color="outlined"
          shape="little-rounded"
          size="small"
          disabled={isButtonDisabled}
          onClick={handleCancel}
        >
          Cancelar
        </CustomButton>
        <CustomButton
          type="submit"
          color="secondary"
          shape="little-rounded"
          size="small"
          disabled={isButtonDisabled}
          isLoading={isLoading}
        >
          Guardar Cambios
        </CustomButton>
      </div>
    </Paper>
  );
};
export default SingleFileUploader;
