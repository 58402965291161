import Axios from "axios";
import { warningToast } from "../../services/toasts";

export const asyncCart = async () => {
  let cart = JSON.parse(localStorage?.getItem("cart") as string);

  if (cart && cart.length > 0) {
    cart = cart.map((item: any) => ({
      productId: item.product_id,
      quantity: item.quantity,
    }));

    try {
      const response = await Axios.post("v2/cart/syncCart", {
        cartItems: cart,
      });

      if (response.data.code === "SUCCESS_WITH_REMOVED_ITEMS") {
        setTimeout(() => {
          warningToast(
            "Algunos productos no están disponibles en la cantidad que solicitaste, por favor revisa tu carrito"
          );
        }, 3000);
      }
    } catch (error) {
      throw error;
    }
  }
};
