import useFetchData from "../../hooks/useFetchData";
import { ProductDescription } from "../pages/ProductInformation/models/productDescription";

interface response {
  data: ProductDescription | null;
  isLoading: boolean;
  errorStatus: boolean | string | null;
}

export const GetProductById = (producId: number): response => {
  const { data, isLoading, errorStatus } = useFetchData(
    `/v2/client/products/${producId}`
  );
  return { data, isLoading, errorStatus };
};
