import { Fragment } from "react";
import Button from "../Button/Button";
import styled from "styled-components";

type DefaultContentProps = {
  heading: string;
  label?: string;
  fontAwesomeHeadingIcon?: string;
  fontAwesomeHeadingIconColor?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  showFooter?: boolean;
  showSecondaryButton?: boolean;
  onSubmit?: () => void;
  onSecondaryClick?: () => void;
  enabled?: boolean;
};

const DefaultModalContent = ({
  label,
  fontAwesomeHeadingIcon,
  fontAwesomeHeadingIconColor = "black",
  heading,
  children,
  isLoading = false,
  showFooter = true,
  showSecondaryButton = true,
  onSubmit,
  onSecondaryClick,
  enabled = true,
}: DefaultContentProps) => {
  return (
    <Wrapper>
      <div style={{ marginBottom: "1.5rem" }}>
        <h4
          style={{
            fontSize: "0.75rem",
            color: "#5a6872",
            fontWeight: "600",
            textTransform: "uppercase",
            margin: "0 0 0.5rem 0",
          }}
        >
          {label}
        </h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          {fontAwesomeHeadingIcon && (
            <i
              className={`fa fa-${fontAwesomeHeadingIcon}`}
              style={{
                color: fontAwesomeHeadingIconColor,
                fontSize: "1.25rem",
                marginRight: "0.5rem",
              }}
            ></i>
          )}
          <h2 style={{ margin: "0" }}>{heading}</h2>
        </div>
      </div>
      <div>{children}</div>
      <div
        style={{
          padding: "2rem 3rem",
          display: "flex",
          marginLeft: "-3rem",
          marginRight: "-3rem",
          flexWrap: "wrap",
        }}
      >
        {showFooter && (
          <Fragment>
            {showSecondaryButton && (
              // @ts-ignore
              <Button onClick={onSecondaryClick} label="Cancelar" />
            )}
            {/* @ts-ignore */}
            <Button
              onClick={onSubmit}
              label="Aceptar"
              $isLoading={isLoading}
              disabled={!enabled}
            />
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2.5rem 3rem 0 3rem;
`;

export default DefaultModalContent;
